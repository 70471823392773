<template>
  <div class="features-list">
    <div
      class="feature svg-icon icon-pci-logo-mask"
      :style="headerCloseIconStyle"
    />
    <div
      class="feature svg-icon icon-secure-3d-logo-mask"
      :style="headerCloseIconStyle"
    />
    <div
      v-if="flowDetails.isZerohashPayoutStrategy()"
      class="feature svg-icon icon-zero-hash-logo-mask"
      :style="headerCloseIconStyle"
    />
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';

export default defineComponent({
  name: 'FeaturesList',

  computed: {
    ...mapGetters('transaction', ['flowDetails']),
  },
});
</script>

<style lang="scss" scoped>
.features-list {
  display: flex;
  flex: 1;
  justify-content: center;

  @media (max-width: $mobile-max) {
    margin-bottom: rem(24);
  }

  @media (min-width: $mobile-max) {
    justify-content: flex-start;
  }
}

.feature {
  &.svg-icon {
    height: rem(30);
    background-color: #808080;
  }

  &:not(:last-child) {
    margin-right: rem(30);
  }

  &.icon-pci-logo-mask {
    width: rem(106);
  }

  &.icon-secure-3d-logo-mask {
    width: rem(89);
  }

  &.icon-zero-hash-logo-mask {
    width: rem(119);
  }

  @media (max-width: $screen-sm-min) {
    &:not(:last-child) {
      margin-right: rem(14);
    }
  }
}
</style>
