<template>
  <div
    class="sales-funnel-content"
    :class="{ 'has-border': hasBorder }"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: 'Content',

  props: {
    hasBorder: { type: Boolean, required: false, default: false },
  },
};
</script>

<style scoped lang="scss">
.sales-funnel-content {
  margin-top: rem(24);
  margin-left: auto;
  margin-right: auto;

  &.has-border {
    border-radius: rem(6);
    border: 1px solid #e6e6e6;
    padding: rem(16);
  }

  @media screen and (min-width: $screen-lg-min) {
    margin-top: rem(40);
  }
}
</style>
