import cardPaymentClient from './clients/card-payment-client';
import store from '@/store';
import { captureMessage } from '../plugins/sentry';

let riskInstance = null;
let riskPromise = null;
export default {
  init() {
    if (riskPromise) return riskPromise;
    riskPromise = new Promise(async (resolve, reject) => {
      const { data: settings } = await cardPaymentClient.getRiskSettings();
      const script = document.createElement('script');
      script.src = process.env.VUE_APP_CHECKOUT_RISK_JS_SCRIPT_URL;
      script.onload = () => {
        riskInstance = window.Risk.init(settings.key);
        resolve();
      };
      script.onerror = reject;
      document.head.appendChild(script);
    });
    return riskPromise;
  },
  async publishRiskData() {
    try {
      await riskPromise;
      return riskInstance.publishRiskData();
    } catch (e) {
      captureMessage({
        message: `Can not get RiskJs deviceId, reason: ${e.message}`,
      });
      return null;
    }
  },
  getDeviceSessionId() {
    if (!this.isEnabled()) return null;
    return this.publishRiskData();
  },
  getBridgerpayDeviceSessionId() {
    if (!this.isBridgerpayEnabled()) return null;
    return this.publishRiskData();
  },
  isEnabled() {
    return store.getters['feature/riskJsFeature'].isEnabled();
  },
  isBridgerpayEnabled() {
    return store.getters['feature/riskJsBridgerpayFeature'].isEnabled();
  },
};
