import TransactionFlowScreen from '@/views/TransactionFlowScreen/TransactionFlowScreen';

export const NAME = 'transaction';

export default {
  path: '/transaction',
  name: NAME,
  component: TransactionFlowScreen,
  meta: {
    shouldBePreloaded: true,
    requiresAuth: true,
  },
};
