<template>
  <div class="modal-heading">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'ModalHeading',
};
</script>

<style scoped lang="scss">
.modal-heading {
  font-weight: 700;
  font-size: rem(16);
  line-height: 1.25;
  text-align: center;
  padding: rem(22) rem(24) rem(28) rem(24);

  @media screen and (min-width: $screen-lg-min) {
    font-size: rem(28);
    line-height: 1;
    padding: rem(62) rem(24) rem(32) rem(24);
  }
}
</style>
