<template>
  <Content class="zh-compliance-checkbox">
    <FormRow>
      <FormCheckbox
        id="zhCompliance"
        name="zhCompliance"
        :error="error"
        @change="$emit('change', $event)"
      >
        <template #label>
          <i18n path="transaction-flow.zero-hash-compliance.checkbox">
            <template #userAgreementLink>
              <a
                :href="link"
                target="_blank"
              >{{
                $t('transaction-flow.zero-hash-user-agreement')
              }}</a>
            </template>
            <template #privacyPolicyLink>
              <a
                href="https://zerohash.zendesk.com/hc/en-us/articles/360009900494-Privacy-Policy"
                target="_blank"
              >{{ $t('transaction-flow.zero-hash-privacy-policy') }}</a>
            </template>
            <template #regulatoryDisclosureLink>
              <a
                href="https://zerohash.zendesk.com/hc/en-us/articles/360008819314-State-Money-Transmitter-Disclosures"
                target="_blank"
              >{{ $t('transaction-flow.zero-hash-regulatory-disclosure') }}</a>
            </template>
          </i18n>
        </template>
      </FormCheckbox>
    </FormRow>
  </Content>
</template>

<script>
import Content from '@paybis/frontend-common-lib/src/components/content';
import FormCheckbox from '@paybis/frontend-common-lib/src/components/form/form-checkbox';
import FormRow from '@paybis/frontend-common-lib/src/components/form/form-row';
import { useI18n } from 'vue-i18n';
import { mapGetters } from 'vuex';

export default {
  name: 'ZeroHashComplianceCheckbox',
  components: {
    Content,
    FormRow,
    FormCheckbox,
  },
  props: {
    hasError: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const { t } = useI18n();

    return {
      t,
    };
  },
  computed: {
    ...mapGetters('feature', ['zeroHashLinkFeature']),
    error() {
      return this.hasError
        ? this.t('transaction-flow.zero-hash-compliance.checkbox.error')
        : '';
    },
    link() {
      const baseUrl = 'https://www.zerohash.com';

      return this.zeroHashLinkFeature.isEnabled() ? `${baseUrl}/paybispl/` : `${baseUrl}/paybis`;
    },
  },
};
</script>

<style lang="scss" scoped>
.zh-compliance-checkbox {
  padding-top: rem(16);
}
</style>
