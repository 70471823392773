<template>
  <span>
    <span
      class="timerText"
    >{{ $t('transaction-flow.dynamic-crypto-timer.expires-in', {
      time: timeFormatted,
    }) }}</span>
    <Tooltip left>
      <!-- eslint-disable-next-line -->
      <span v-html="$t('transaction-flow.tooltip.message')" />
    </Tooltip>
  </span>
</template>

<script>
import { defineComponent } from 'vue';

import Tooltip from '@/components/Tooltip';

export default defineComponent({
  name: 'FakeFrozenTimer',
  components: {
    Tooltip,
  },
  props: {
    time: {
      type: Number,
      required: true,
    },
  },

  computed: {
    timeFormatted() {
      return new Date(this.time)
        .toLocaleTimeString([], { minute: '2-digit', second: '2-digit' });
    },
  },
});
</script>

<style lang="scss" scoped>
.tooltip {
  margin-left: 4px;
}

</style>

<style lang="scss">
.tooltip__content a{
  color: $white;
  text-decoration: none;
}
</style>
