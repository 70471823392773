<template>
  <ExchangeFormInput
    :label="$t('widget.exchange-form.from-input-label')"
    :amount="amountFrom"
    :currency="quote.currencyCodeFrom"
    :currency-source="quote.currencySourceFrom"
    :is-loading="fromInputIsLoading || fromFiatInputIsLoading"
    :error="serverError"
    class="exchange-form__body-input"
    @amountChange="onAmountFromChange"
    @currencyTap="onCurrencyFromChange"
  />

  <ExchangeFormInput
    :label="$t('widget.exchange-form.to-input-label')"
    :amount="amountTo"
    :currency="currencyTo"
    :currency-source="$t(`currencies.${quote.currencyCodeTo}`)"
    :is-loading="toInputIsLoading || fromFiatInputIsLoading"
    :is-currency-disabled="quote.currencyCodeToFixed"
    class="exchange-form__body-input"
    @amountChange="onAmountToChange"
    @currencyTap="onCurrencyToChange"
  />

  <ExchangeFormSecondaryInput
    :readonly="fromInputIsLoading || toInputIsLoading"
    :amount="amountReceived"
    :currency="quote.currencyCodeFrom"
    @amountChange="onAmountFiatChange"
  />
</template>

<script>
import { computed, ref, watch } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import debounce from 'lodash.debounce';
import { useI18n } from 'vue-i18n';

import useCurrencyModal, { ModalTargetEnum } from '../composables/useCurrencyModal';

import ExchangeFormInput from '../ExchangeFormInput';
import ExchangeFormSecondaryInput from '../ExchangeFormSecondaryInput';
import { CRITICAL_ERROR } from '@/router/routes';

const INPUT_DEBOUNCE_TIME = 1000;

export const InputLoadingDirectionEnum = {
  from: Symbol('from'),
  to: Symbol('to'),
};

export default {
  name: 'ExchangeFormBody',

  components: {
    ExchangeFormInput,
    ExchangeFormSecondaryInput,
  },

  props: {
    isLoading: {
      type: Boolean,
      default: true,
    },
    quote: {
      type: Object,
      default: () => ({}),
    },
  },

  setup(props) {
    const store = useStore();
    const router = useRouter();
    const { t } = useI18n({ useScope: 'global' });

    const serverError = computed(() => {
      const unexpectedQuoteError = store.getters['exchangeForm/unexpectedQuoteError'];
      if (unexpectedQuoteError) {
        return t(unexpectedQuoteError);
      }

      return store.getters['exchangeForm/error'];
    });

    const { showCurrencyModal } = useCurrencyModal();

    const loadingDirection = ref(InputLoadingDirectionEnum.from);
    const fromFiatInputActive = ref(false);

    const fromInputIsLoading = computed(
      () => props.isLoading && loadingDirection.value === InputLoadingDirectionEnum.to,
    );
    const toInputIsLoading = computed(
      () => props.isLoading && loadingDirection.value === InputLoadingDirectionEnum.from,
    );
    const fromFiatInputIsLoading = computed(
      () => props.isLoading && fromFiatInputActive.value,
    );

    const currencyTo = computed(() => store.getters['exchangeForm/getCurrencyBy'](props.quote.currencyCodeTo));

    watch(currencyTo, value => {
      if (value) return;

      router.push({ name: CRITICAL_ERROR });
    }, { immediate: true });

    const setAmountFiatDebounced = debounce(
      value => store.dispatch('exchangeForm/setAmountFiat', value),
      INPUT_DEBOUNCE_TIME,
    );
    const setAmountFromDebounced = debounce(
      value => store.dispatch('exchangeForm/setAmountFrom', value),
      INPUT_DEBOUNCE_TIME,
    );
    const setAmountToDebounced = debounce(
      value => store.dispatch('exchangeForm/setAmountTo', value),
      INPUT_DEBOUNCE_TIME,
    );

    const onAmountFiatChange = async value => {
      if (!value || parseFloat(value) === 0) {
        store.dispatch('exchangeForm/deleteQuote');
        setAmountFromDebounced.cancel();
        return;
      }
      loadingDirection.value = InputLoadingDirectionEnum.to;
      fromFiatInputActive.value = true;
      await store.dispatch('exchangeForm/setLoading', true);
      await setAmountFiatDebounced(value);
    };
    const onAmountFromChange = async value => {
      if (!value || parseFloat(value) === 0) {
        store.dispatch('exchangeForm/deleteQuote');
        setAmountFromDebounced.cancel();
        return;
      }
      loadingDirection.value = InputLoadingDirectionEnum.from;
      fromFiatInputActive.value = false;
      await store.dispatch('exchangeForm/setLoading', true);
      await setAmountFromDebounced(value);
    };
    const onAmountToChange = async value => {
      if (!value || parseFloat(value) === 0) {
        store.dispatch('exchangeForm/deleteQuote');
        setAmountToDebounced.cancel();
        return;
      }
      loadingDirection.value = InputLoadingDirectionEnum.to;
      fromFiatInputActive.value = false;
      await store.dispatch('exchangeForm/setLoading', true);
      await setAmountToDebounced(value);
    };

    const onCurrencyFromChange = () => (
      showCurrencyModal({ quote: props.quote, position: ModalTargetEnum.from })
    );
    const onCurrencyToChange = () => (
      showCurrencyModal({ quote: props.quote, position: ModalTargetEnum.to })
    );

    const amountFrom = computed(() => (fromInputIsLoading.value ? '' : props.quote.amountFrom));
    const amountTo = computed(() => (toInputIsLoading.value ? '' : props.quote.amountTo));
    const amountReceived = computed(() => {
      if (toInputIsLoading.value) {
        return 0;
      }

      return props.quote.amountReceived?.amount || 0;
    });

    return {
      serverError,

      amountFrom,
      fromInputIsLoading,
      onAmountFromChange,
      onCurrencyFromChange,

      amountTo,
      toInputIsLoading,
      onAmountToChange,
      onCurrencyToChange,
      currencyTo,
      fromFiatInputIsLoading,

      amountReceived,
      onAmountFiatChange,
    };
  },
};
</script>

<style lang="scss" scoped>
.exchange-form__body-input+.exchange-form__body-input {
  margin-top: 3rem;

  @media (min-width: $laptop-min) and (max-height: $widget-height-sm) {
    margin-top: 6.25vh;
  }

  @media (min-width: $desktop-xlg-min) and (max-height: $widget-height-xlg) {
    margin-top: 6.26vh;
  }

  @media (min-width: $desktop-xxlg-min) and (max-height: $widget-height-xxlg) {
    margin-top: 6.36vh;
  }
}
</style>
