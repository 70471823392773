<template>
  <component
    :is="component"
    v-if="!isLoading"
    :flow-name="view"
    :session="session"
    :country-code="countryCode"
    :theme="theme"
    :source-info="sourceInfo"
    :is-back-button-availaibe="isBackButtonAvailaibe"
    :is-apple-pay-one-click-flow="isApplePayOneClickFlow"
    @success="onSessionStarted"
    @success-phone="onPhoneSessionStarted"
    @success-otp="onSuccessOtp"
    @success-otp-phone="onSuccessOtpPhone"
    @back="onBack"
    @change-flow="onChangeStep"
    @critical-error="onCriticalError"
    @session-expired="onSessionExpired"
    @session-restart="onSessionRestart"
  />
</template>

<script>
import LoginEmail from './views/LoginEmail';
import SignupEmail from './views/SignupEmail';
import SalesFunnelEmail from './views/SalesFunnelEmail';
import ForgotPassword from './views/ForgotPassword';
import PhoneCommon from './views/PhoneCommon';
import PhoneFunnel from './views/PhoneFunnel';
import EmailOtp from './views/EmailOtp';
import PhoneOtp from './views/PhoneOtp';

import AuthService from './services/auth';
import AuthStorage from './services/auth-storage';
import TokenService from './services/token';
import PartnerService from './services/partner';
import { AuthValidationService } from './services/auth-validation';

import { captureEvent } from './services/sentry';

import { AUTHENTICATED_EVENT, ERROR_EVENT, LOADED_EVENT } from './constants';
import UserLocaleStorage from './services/user-locale-storage';
import Partner from './models/partner';

import eventBus from './helpers/eventBus';

const LOGIN_STEP = 'login';
const SIGNUP_STEP = 'signup';
const FUNNEL_STEP = 'funnel';
const FORGOT_STEP = 'forgot';
const EMAIL_OTP_STEP = 'otp.email';
const PHONE_OTP_STEP = 'otp.phone';
const PHONE_COMMON_STEP = 'phone.common';
const PHONE_FUNNEL_STEP = 'phone.funnel';
const WIDGET_FLOW = 'widget';

const STEPS_TO_COMPONENT_MAP = {
  [LOGIN_STEP]: LoginEmail,
  [SIGNUP_STEP]: SignupEmail,
  [FUNNEL_STEP]: SalesFunnelEmail,
  [FORGOT_STEP]: ForgotPassword,
  [EMAIL_OTP_STEP]: EmailOtp,
  [PHONE_COMMON_STEP]: PhoneCommon,
  [PHONE_FUNNEL_STEP]: PhoneFunnel,
  [PHONE_OTP_STEP]: PhoneOtp,
  [WIDGET_FLOW]: SalesFunnelEmail,
};

const AVAILABLE_STEPS = [
  LOGIN_STEP,
  SIGNUP_STEP,
  FUNNEL_STEP,
  FORGOT_STEP,
  EMAIL_OTP_STEP,
  PHONE_COMMON_STEP,
  PHONE_FUNNEL_STEP,
  PHONE_OTP_STEP,
  WIDGET_FLOW,
];

export default {
  name: 'Authorization',

  provide() {
    return {
      startAuthSession: this.startAuthSession,
      emailHintOverride: this.i18nOverrides && this.i18nOverrides.emailHint,
    };
  },

  props: {
    view: {
      type: String,
      required: true,
      validator: value => AVAILABLE_STEPS.indexOf(value) !== -1,
    },

    countryCode: {
      type: String,
      required: false,
    },

    email: {
      type: String,
      required: false,
      default: '',
    },

    locale: {
      type: String,
      required: false,
      default: 'en',
    },

    theme: {
      type: String,
      required: false,
    },

    sourceInfo: {
      type: Object,
      required: false,
      default: null,
    },

    i18nOverrides: {
      type: Object,
      required: false,
      default: null,
    },

    partnerId: {
      type: String,
      required: false,
      default: null,
    },

    requestId: {
      type: String,
      required: false,
      default: null,
    },

    isNewExchangeFormFlow: {
      type: Boolean,
      default: false,
    },

    isApplePayOneClickFlow: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      previous: null,
      step: this.view,
      session: null,
      isLoading: true,
    };
  },

  computed: {
    component() {
      return STEPS_TO_COMPONENT_MAP[this.step];
    },
    isEmailProvided() {
      return String(this.email).length > 0;
    },
    isBackButtonAvailaibe() {
      return (this.step === WIDGET_FLOW && this.isNewExchangeFormFlow)
        || (this.step === EMAIL_OTP_STEP && this.isApplePayOneClickFlow);
    },
  },

  watch: {
    locale(newLocale, locale) {
      if (newLocale === locale) {
        return;
      }

      UserLocaleStorage.storeLocale(newLocale);
    },

    view(newView, previousView) {
      if (newView === previousView) {
        return;
      }

      this.step = newView;
    },

    step(newStep, previousStep) {
      if (newStep === previousStep) {
        return;
      }

      if (this.previous === newStep) {
        this.previous = null;
        this.$emit('change-step', newStep);

        return;
      }

      // Prevent ability to open entry view if email passed
      if (this.email && newStep === EMAIL_OTP_STEP && !this.isApplePayOneClickFlow) {
        return;
      }

      this.$emit('change-step', newStep, previousStep);
      this.previous = previousStep;
    },

    partnerId: {
      handler(id) {
        PartnerService.setPartner(new Partner(id));
      },
      immediate: true,
    },
  },

  async created() {
    if (navigator.virtualKeyboard) {
      navigator.virtualKeyboard.addEventListener('geometrychange', event => {
        captureEvent(event);
      });
    }

    UserLocaleStorage.storeLocale(this.locale);

    const AuthValidator = new AuthValidationService({
      requestId: this.requestId,
      email: this.email,
      sourceInfo: this.sourceInfo,
      locale: this.locale,
      isEmailProvided: this.isEmailProvided,

      onInvalidData: this.restartSessionWithLogout,
      onValidData: this.emitAuthTokens,
      onValidSessionExist: this.resumeStoredSession,
      onEmptyData: this.makeApplicationLoaded,
      onLoginAttempt: this.startAuthSession,
    });

    await AuthValidator.validateUserSession();
  },

  methods: {
    makeApplicationLoaded() {
      this.isLoading = false;
      eventBus.emit(LOADED_EVENT);
    },

    onSessionRestart(email) {
      this.startAuthSession(email);
    },

    onSessionExpired(error) {
      this.step = this.view;
    },

    onCriticalError(error) {
      this.step = this.view;
      eventBus.emit(ERROR_EVENT, null, {
        error,
      });
    },

    onChangeStep(step) {
      if (typeof STEPS_TO_COMPONENT_MAP[step] === 'undefined') {
        return;
      }

      this.step = step;
    },

    onSessionStarted(session) {
      this.session = session;
      this.step = EMAIL_OTP_STEP;
    },

    onSuccessOtp(tokens) {
      if (tokens !== null) {
        return this.emitAuthTokens(tokens);
      }

      this.openPhoneInput();
    },

    onPhoneSessionStarted() {
      this.step = PHONE_OTP_STEP;
    },

    onSuccessOtpPhone(tokens) {
      eventBus.emit(AUTHENTICATED_EVENT, null, {
        tokens,
      });
    },

    onBack() {
      if (!this.previous) {
        return;
      }

      this.step = this.previous;
    },

    openPhoneInput() {
      if (this.view === 'funnel') {
        this.step = PHONE_FUNNEL_STEP;
        return;
      }

      this.step = PHONE_COMMON_STEP;
    },

    startAuthSession(email, locale = this.$i18n.locale) {
      return AuthService.startSession(email, locale, this.view, this.theme, this.isEmailProvided, this.sourceInfo)
        .then(session => this.onSessionStarted(session))
        .catch(error => {
          this.onChangeStep(this.view);
          this.onCriticalError(error);
        }).finally(() => {
          this.makeApplicationLoaded();
        });
    },

    emitAuthTokens(tokens) {
      eventBus.emit(AUTHENTICATED_EVENT, null, {
        tokens,
      });
    },

    resumeStoredSession() {
      this.session = AuthStorage.getSession();

      this.makeApplicationLoaded();

      if (this.session.hasPhoneOtpSession()) {
        return this.onPhoneSessionStarted();
      }

      if (this.session.getEmailOtpSession().isConfirmed()) {
        return this.openPhoneInput();
      }

      this.onSessionStarted(this.session);
    },

    async restartSessionWithLogout() {
      AuthStorage.clearStoredSession();

      if (TokenService.hasToken()) {
        await AuthService.logout();
      }

      if (this.email) {
        this.startAuthSession(this.email, this.locale);
        return;
      }

      this.makeApplicationLoaded();
    },
  },
};
</script>
