<template>
  <iframe
    class="iframe"
    :width="props.width"
    :style="{
      height: frameHeight
    }"
    :src="frameSrc"
    sandbox="allow-same-origin allow-scripts"
    @load="loadHandler"
  />
</template>

<script setup>
import { onBeforeUnmount, ref, watch } from 'vue';

import { stringify } from 'qs';

const props = defineProps({
  url: {
    type: String,
    required: true,
  },
  width: {
    type: String,
    required: true,
  },
  height: {
    type: String,
    default: '250px',
  },
  props: {
    type: Object,
    required: true,
  },
  translations: {
    type: Object,
  },
  theme: {
    type: Object,
  },
  actions: {
    type: Array,
    required: true,
  },
  emits: {
    type: Array,
    required: true,
  },
});

const frameHeight = ref(props.height);

const emit = defineEmits([]);

const params = {
  actionList: JSON.stringify(props.actions),
  emitList: JSON.stringify(props.emits),
};

const propsChannel = new MessageChannel();
onBeforeUnmount(() => {
  propsChannel.port1.close();
  propsChannel.port2.close();
});
watch(() => props.props, props => propsChannel.port1.postMessage(props), { deep: true });

const emitsChannel = new MessageChannel();
onBeforeUnmount(() => {
  emitsChannel.port1.close();
  emitsChannel.port2.close();
});
emitsChannel.port1.onmessage = event => {
  if (props.emits.includes(event.data.name)) {
    emit(event.data.name, event.data.payload);
  }

  if (event.data.name === 'height') {
    frameHeight.value = String(event.data.payload);
  }
};

const actionChannel = new MessageChannel();
onBeforeUnmount(() => {
  actionChannel.port1.close();
  actionChannel.port2.close();
});
defineExpose(props.actions.reduce((result, action) => {
  result = {
    ...result,
    [action]: (...payload) => actionChannel.port1.postMessage({ name: action, payload }),
  };
  return result;
}, {}));

const translateChannel = new MessageChannel();
onBeforeUnmount(() => {
  translateChannel.port1.close();
  translateChannel.port2.close();
});
watch(() => props.translations, props => translateChannel.port1.postMessage(props), { deep: true });

const themeChannel = new MessageChannel();
onBeforeUnmount(() => {
  themeChannel.port1.close();
  themeChannel.port2.close();
});
watch(() => props.theme, theme => themeChannel.port1.postMessage(theme), { deep: true });

const loadHandler = event => {
  const payload = {
    name: 'init',
    props: props.props,
    translations: props.translations,
    theme: props.theme,
  };
  event.target?.contentWindow?.postMessage(payload, props.url, [
    propsChannel.port2,
    emitsChannel.port2,
    actionChannel.port2,
    translateChannel.port2,
    themeChannel.port2,
  ]);
  emit('ready');
};

const frameParams = stringify(params);
const frameSrc = `${props.url}?${frameParams}`;
</script>

<style scoped>
.iframe {
  border: none;
  transition: height 0.1s;
  transform: translateY(0);
}
</style>
