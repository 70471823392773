import ApplePayOneClickScreen from '@/views/ApplePayOneClick/ApplePayOneClickScreen';

export const NAME = 'applePay';

export default {
  path: '/apple-pay-one-click',
  name: NAME,
  component: ApplePayOneClickScreen,
  meta: {
    shouldBePreloaded: true,
    requiresAuth: false,
  },
};
