<template>
  <div class="exchange-input__wrapper">
    <div
      class="exchange-input"
      :class="{
        'is-focused': isFocused,
        'is-loading': isLoading,
        'is-disabled': isDisabled,
        'has-error': error
      }"
    >
      <label
        class="exchange-input__label"
      >
        {{ label }}
      </label>

      <div
        v-if="isLoading"
        class="loading-icon"
      />

      <input
        class="exchange-input__input"
        type="text"
        inputmode="decimal"
        step="0.01"
        :value="inputValue"
        :disabled="isDisabled || isLoading"
        :placeholder="isLoading ? '' : '0.00'"

        @input="onUpdateValue"
        @focus="onInputFocus"
        @blur="onInputBlur"
      >

      <button
        v-intro="{ dataIntro: introHintMessage, dataStep: introHintStep }"
        class="currency btn-primary"
        :class="{ 'is-currency-disabled': isCurrencyDisabled }"
        :disabled="isCurrencyDisabled"
        @click="onCurrencyChange"
      >
        <div class="currency__name">
          {{ currency }}
        </div>
        <div class="currency__source">
          {{ currencySource }}
        </div>

        <i class="currency__icon svg-icon icon-caret-white-mask" />
      </button>
    </div>

    <span
      v-if="error"
      class="exchange-input__error"
    >
      {{ error }}
    </span>
  </div>
</template>

<script>
import { computed, ref } from 'vue';
import { filterNumberValue } from '@/utils/filterNumberValue';

export default {
  name: 'ExchangeFormInput',

  props: {
    label: {
      type: String,
      default: '',
    },
    amount: {
      type: String,
      default: null,
    },
    currency: {
      type: String,
      required: true,
    },
    currencySource: {
      type: String,
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isCurrencyDisabled: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      default: '',
    },
    introHintMessage: {
      type: String,
      default: '',
    },
    introHintStep: {
      type: Number,
    },
  },

  emits: ['amountChange', 'currencyTap'],

  setup(props, { emit }) {
    const isFocused = ref(false);

    const inputValue = computed(() => (props.isLoading ? '' : props.amount || ''));

    const onInputFocus = () => {
      isFocused.value = true;
    };
    const onInputBlur = () => {
      isFocused.value = false;
    };
    const onUpdateValue = ({ target }) => {
      target.value = filterNumberValue(target.value);

      const newValue = target.value;

      if ((props.amount === null && !(newValue > 0)) || newValue === props.amount) return;

      emit('amountChange', target.value);
    };
    const onCurrencyChange = value => {
      emit('currencyTap', value);
    };

    return {
      inputValue,
      isFocused,

      onInputFocus,
      onInputBlur,
      onUpdateValue,
      onCurrencyChange,
    };
  },
};
</script>

<style lang="scss" scoped>
$disabled-input-color: darken(#fff, 5%);

.btn-primary {
  &:disabled {
    background-color: $black;
    border-color: transparent;
    color: $white;
    pointer-events: none;
  }
}

.exchange-input {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  border-radius: rem(6);
  padding: rem(6);
  width: 100%;

  transition: box-shadow 0.2s linear, border-color 0.2s linear,
  border-width 0.2s linear, padding 0.2s linear;

  &:hover {
    box-shadow: 0px 3px 6px $black-15;
  }

  &.is-focused {
    box-shadow: 0px 3px 6px $black-15;
  }

  &.is-loading {
    background: $disabled-input-color;
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    border-radius: rem(6);
    border: 2px solid $black;
    pointer-events: none;
    transition: border-color 0.2s linear, border-width 0.2s linear;
  }

  &__wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  &.is-focused:after {
    border-width: 1px;
    border-color: $violet;
  }

  &.has-error:after {
    border-color: $red;
  }

  &.has-error:hover {
    border-color: rgba(231, 60, 64, 0.25);
  }

  &__label {
    position: absolute;
    top: rem(-24);
    left: 0;
    font-size: rem(16);
    line-height: 1.14;
    transition: left 0.2s linear, top 0.2s linear;
  }

  [dir='rtl'] &__label {
    right: 0;
    left: unset;
  }

  &__input {
    position: relative;
    font-weight: 700;
    font-size: rem(24);
    line-height: 1;
    padding: 0 rem(10);
    min-width: 0;
    min-height: rem(52);
    flex: 1;
    box-shadow: none;
    -moz-appearance: textfield;
  }

  &__input:focus {
    outline: none;
  }

  &__input::-webkit-inner-spin-button,
  &__input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &__error {
    display: block;
    width: 100%;
    margin-top: rem(4);
    font-size: rem(12);
    line-height: 1.33;
    color: $red;
  }

  @media screen and (min-width: $screen-lg-min) {
    &__label {
      top: rem(-24);
    }
  }

  @media (min-width: $laptop-min) and (max-height: $widget-height-sm) {
    &__label {
      font-size: 2vh;
      top: -3.1vh;
    }

    &__input {
      font-size: 3.1vh;
      min-height: 6.77vh;
    }
  }

  @media (min-width: $desktop-xlg-min) and (max-height: $widget-height-xlg) {
    &__label {
      font-size: 2.09vh;
      top: -3.13vh;
    }

    &__input {
      font-size: 3.13vh;
      min-height: 6.73vh;
    }
  }

  @media (min-width: $desktop-xxlg-min) and (max-height: $widget-height-xxlg) {
    &__label {
      font-size: 2.09vh;
      top: -3.18vh;
    }

    &__input {
      font-size: 3.18vh;
      min-height: 6.7vh;
    }
  }
}

.loading-icon {
  @include spinerIcon;
  height: 100%;
  padding: 6px;

  &:after {
    width: 40px;
    height: 40px;

    @media (min-width: $laptop-min) and (max-height: $widget-height-sm) {
      width: calcVh(40, $widget-h-sm);
      height: calcVh(40, $widget-h-sm);
    }

    @media (min-width: $desktop-xlg-min) and (max-height: $widget-height-xlg) {
      width: calcVh(40, $widget-h-xlg);
      height: calcVh(40, $widget-h-xlg);
    }

    @media (min-width: $desktop-xxlg-min) and (max-height: $widget-height-xxlg) {
      width: calcVh(40, $widget-h-xxlg);
      height: calcVh(40, $widget-h-xxlg);
    }
  }
}

.currency {
  position: relative;
  font-weight: 700;
  border-radius: rem(4);
  height: 100%;
  max-width: 60%;
  padding: rem(9) rem(36) rem(9) rem(12);
  text-align: left;
  cursor: pointer;
  white-space: nowrap;

  &__icon {
    background-color: #fff;
  }

  &:not(.is-currency-disabled) .currency__icon {
    position: absolute;
    top: rem(18);
    right: rem(8);
    width: 16px;
    height: 16px;
  }

  [dir='rtl'] &::after {
    left: rem(8);
    right: unset;
  }

  &__name {
    display: block;
    overflow: hidden;
    font-size: rem(22);
    line-height: 0.91;
    text-overflow: ellipsis;
  }

  &__source {
    display: block;
    font-size: rem(12);
    line-height: 1;
    margin-top: rem(2);
    white-space: nowrap;
  }

  @media (min-width: $laptop-min) and (max-height: $widget-height-sm) {
    &::after {
      top: 2.08vh;
    }

    &__name {
      font-size: 2.86vh;
      line-height: 2.6vh;
    }

    &__source {
      font-size: 1.56vh;
    }
  }

  @media (min-width: $desktop-xlg-min) and (max-height: $widget-height-xlg) {
    &::after {
      top: 2.09vh;
    }

    &__name {
      font-size: 2.9vh;
      line-height: 2.67vh;
    }

    &__source {
      font-size: 1.62vh;
    }
  }

  @media (min-width: $desktop-xxlg-min) and (max-height: $widget-height-xxlg) {
    &::after {
      top: 2.12vh;
    }

    &__name {
      font-size: 2.92vh;
      line-height: 2.65vh;
    }

    &__source {
      font-size: 1.59vh;
    }
  }
}

</style>
