<template>
  <div class="electroinc-payment-waiting">
    <Title>
      {{
        $t('transaction-flow.steps.payment-waiting.electronic.title')
      }}
    </Title>
    <Paragraph>
      {{ waitingTimeLabel }}
    </Paragraph>
    <FunnelLoader />
  </div>
</template>

<script>
import Paragraph from '@paybis/frontend-common-lib/src/components/paragraph';
import Title from '@paybis/frontend-common-lib/src/components/title';
import { mapGetters } from 'vuex';
import FunnelLoader from './funnel-loader';
import { useI18n } from 'vue-i18n';

export default {
  components: {
    FunnelLoader,
    Title,
    Paragraph,
  },
  data() {
    const { t } = useI18n();

    return {
      t,
    };
  },
  computed: {
    ...mapGetters('transaction', ['payment']),
    waitingTimeLabel() {
      let key = `transaction-flow.money-service.${this.payment.slug}.payment-waiting.electronic.waiting-time`;
      if (!this.t(key)) {
        key = 'transaction-flow.steps.payment-waiting.electronic.waiting-time';
      }

      return this.t(key);
    },
  },
};
</script>
