<template>
  <div class="apple-pay-one-click">
    <div class="apple-pay-one-click__item">
      <div class="apple-pay-one-click__item-header">
        <button
          class="apple-pay-one-click__toggle is-selected"
        >
          {{ $t('shared.select.select') }}
        </button>
        <div class="apple-pay-one-click__item-icon">
          <img
            :src="
              require(`@paybis/frontend-common-lib/src/assets/images/info-apple-pay.svg`)
            "
            alt="logo"
          >
        </div>
        <div class="card-select__item-main">
          <div class="card-select__item-placeholder">
            {{ $t('transaction-flow.apple-pay.select.label') }}
          </div>
        </div>
      </div>
      <div class="apple-pay-one-click__item-content">
        <div class="apple-pay-one-click__item-footer">
          <SecureStamp class="apple-pay-one-click__item-secure" />

          <ApplePayOneClickButton
            class="apple-pay-one-click__item-pay apple-pay-one-click__item-pay--apple"
            :lang="language"
            :amount="amount"
            :amount-currency="amountCurrency"
            :country-code="userCountry"
            :settings="applePaySettings"
            @pay="handlePay"
            @error="emit('error', $event)"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import SecureStamp from '@paybis/frontend-transaction-funnnel-app/src/components/secure-stamp.vue';
import ApplePayOneClickButton from './ApplePayOneClickButton.vue';
import { computed } from 'vue';
import store from '@/store';

const emit = defineEmits(['applePay', 'applePayError']);

const language = computed(() => {
  return store.getters.language;
});

const quote = computed(() => {
  return store.getters['widgetQuote/quote'];
});
const amount = computed(() => {
  return quote.value.amountFrom;
});

const amountCurrency = computed(() => {
  return quote.value.currencyCodeFrom;
});

const userCountry = computed(() => {
  return store.getters.customerCountryCode;
});

const applePaySettings = computed(() => {
  return store.getters['card/applePaySettings'];
});

function handlePay(event) {
  emit('pay', event);
}
</script>

<style scoped lang="scss">
.apple-pay-one-click {
  width: 100%;

  &__item {
    margin-left: rem(32);
    padding: rem(10) 0;
  }

  &__item-header {
    position: relative;
    display: flex;
    cursor: pointer;
    align-items: center;
  }

  &__item-content {
    padding-top: rem(20);
    padding-bottom: rem(14);
  }

  &__item-footer {
    padding-top: rem(24);
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__item-header + &__item-footer {
    padding-top: rem(16);
    padding-bottom: rem(14);
  }

  [dir='rtl'] &__item {
    margin-right: rem(32);
    margin-left: unset;
  }

  &__item {
    border-bottom: 1px solid $black-10;
  }

  &__item-icon {
    display: flex;
    background-color: $grey-04;
    border-radius: 50%;
    width: rem(40);
    height: rem(40);

    &-svg {
      width: rem(16);
      height: rem(16);
      margin: auto;
    }
  }

  &__item-icon img {
    display: block;
    max-width: rem(30);
    max-height: rem(30);
    margin: auto;
  }

  &__toggle {
    position: absolute;
    left: rem(-32);
    top: 50%;
    bottom: 50%;
    margin-top: rem(-8);
    font-size: 0;
    width: rem(16);
    height: rem(16);
    border-radius: 50%;
    border: 1px solid #dbdbdb;
    background-color: #f5f5f5;
  }

  [dir='rtl'] &__toggle {
    right: rem(-32);
    left: unset;
  }

  &__toggle.is-selected:after {
    content: '';
    display: block;
    width: rem(8);
    height: rem(8);
    border-radius: 50%;
    background-color: $black;
    margin: auto;
  }

  &__item-main {
    flex: 1;
    margin: 0 0 0 rem(16);
  }

  [dir='rtl'] &__item-main {
    margin: 0 rem(16) 0 0;
  }

  &__item-placeholder {
    font-weight: 700;
    font-size: rem(16);
    line-height: rem(40);
  }

  @media screen and (min-width: $screen-lg-min) {
    padding: rem(8) rem(24) rem(8) rem(16);

    [dir='rtl'] & {
      padding: rem(8) rem(16) rem(8) rem(24);
    }

    &__item-main {
      display: flex;
      justify-content: space-between;
    }

    &__item {
      border-bottom: 1px solid $black-10;
    }
  }

  &__item-pay {
    width: 100%;

    &--apple,
    &--google {
      justify-content: center;
      padding: rem(12) rem(28);
      display: flex;
      align-items: center;
    }

    &.is-loading::after {
      margin-left: -1rem;
      margin-top: -1rem;
      width: 2rem;
      height: 2rem;
    }
  }

  &__item-secure {
    display: none;
  }

  @media screen and (min-width: $screen-lg-min) {
    &__item-pay {
      width: auto;
    }

    &__item-secure {
      display: flex;
    }
  }
}
</style>
