import { AppCriticalError } from '@/exceptions/AppCriticalError';
import { DEVICE_NOT_IDENTIFIED } from '@/views/CriticalErrorScreen';
import { DeviceIdentificationError } from '@/exceptions/DeviceIdentificationError';
import router from '@/router';
import { isCriticalResponseError } from '@/network/errorHandler';
import { CRITICAL_ERROR } from '@/router/routes';

export const redirectIfCriticalError = event => {
  const { error, reason = {} } = event;

  if (reason instanceof DeviceIdentificationError) {
    router.push({
      name: CRITICAL_ERROR,
      query: {
        errorType: DEVICE_NOT_IDENTIFIED,
      },
    });
    return;
  }

  if (
    error instanceof AppCriticalError
    || reason instanceof AppCriticalError
    || isCriticalResponseError(reason)
  ) {
    router.push({
      name: CRITICAL_ERROR,
    });
  }
};
