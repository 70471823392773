<template>
  <span>
    <span
      v-if="time !== null"
      ref="timerText"
      class="timerText"
      v-html="
        $t('transaction-flow.dynamic-crypto-timer.expires-in', {
          time: formattedTime
        })
      "
    />
    <span
      v-else
      v-html="
        $t(
          'transaction-flow.dynamic-crypto-timer.crypto-currency-rate-is-fixed'
        )
      "
    />
    <Tooltip left>
      <span v-html="$t('transaction-flow.tooltip.message')" />
    </Tooltip>
  </span>
</template>

<script>
// @TODO: get rid of vuex dependencies (use props instead)
import { mapGetters } from 'vuex';
import moment from 'moment';

import Tooltip from './tooltip';

const TIMER_RESTART_DURATION_SEC = 60;
const SECOND_IN_MILLISECONDS = 1000;

export default {
  name: 'DynamicCryptoTimer',

  components: {
    Tooltip,
  },

  props: {
    time: { type: Object, required: false, default: null },
  },

  data() {
    return {
      intervalId: null,
      date: null,
    };
  },

  computed: {
    ...mapGetters('cryptoTimer', ['secondsLeft']),
    formattedTime() {
      return moment
        .utc(this.displayedSecondsLeft * SECOND_IN_MILLISECONDS)
        .format('mm:ss');
    },
    displayedSecondsLeft() {
      if (this.secondsLeft < 0) {
        // after timer ends "restarts" the timer from TIMER_RESTART_DURATION_SEC
        return (
          TIMER_RESTART_DURATION_SEC
          - Math.abs(this.secondsLeft % TIMER_RESTART_DURATION_SEC)
        );
      }

      return this.secondsLeft;
    },
  },

  mounted() {
    if (this.$refs.timerText !== undefined) {
      this.$refs.timerText.style.width = `${this.$refs.timerText.clientWidth + 15}px`;
    }
  },
};
</script>
