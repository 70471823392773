import { reactive } from 'vue';
import { useStore } from 'vuex';

import eventBus from '@/helpers/eventBus';
import applePayNotificationService from '@/services/applePayNotificationService';

export const useTransactionEventsRegistrator = () => {
  const store = useStore();
  const eventsStatus = reactive({
    isRegistered: false,
  });

  const resetFunnelCardErrorState = () => {
    store.dispatch('external/resetFunnelCardErrorState');
    store.dispatch('setPaymentProcessorNotFound', false);
  };

  const onPaymentDetailsLoaded = () => {
    // Handle apple pay notification window
    if (applePayNotificationService.isApplePayDismissed()) {
      applePayNotificationService.clearApplePayDismissed();
    }
  };

  const onPaymentSelectCard = () => {
    resetFunnelCardErrorState();
  };

  const onPaymentStarts = () => {
    resetFunnelCardErrorState();

    applePayNotificationService.setApplePayDismissed();
  };

  const onPaymentFails = () => {
    applePayNotificationService.clearApplePayDismissed();

    store.dispatch('paymentErrorPopup/disallowPaymentPopup', null, {
      root: true,
    });
  };

  const onCanUseApplePay = () => {
    if (applePayNotificationService.isApplePayDismissed()) {
      return;
    }

    store.dispatch('showApplePayNotificationPopup');
  };

  const onPaymentProcessorNotFound = () => {
    applePayNotificationService.clearApplePayDismissed();

    store.dispatch('setPaymentProcessorNotFound', true);
    store.dispatch('paymentErrorPopup/disallowPaymentPopup', null, {
      root: true,
    });
  };

  return {
    unregister() {
      if (!eventsStatus.isRegistered) {
        return;
      }

      eventBus.detach('transaction-flow.card-payment-details.loaded', onPaymentDetailsLoaded);
      eventBus.detach('transaction-flow.payment.select-card', onPaymentSelectCard);
      eventBus.detach('transaction-flow.payment.starts', onPaymentStarts);
      eventBus.detach('transaction-flow.payment.fails', onPaymentFails);
      eventBus.detach('transaction-flow.payment-details.can-use-apple-pay', onCanUseApplePay);
      eventBus.detach('transaction-flow.payment.payment-processor-not-found', onPaymentProcessorNotFound);

      eventsStatus.isRegistered = false;
    },

    register() {
      if (eventsStatus.isRegistered) {
        return;
      }

      eventBus.once('transaction-flow.card-payment-details.loaded', onPaymentDetailsLoaded);
      eventBus.on('transaction-flow.payment.select-card', onPaymentSelectCard);
      eventBus.on('transaction-flow.payment.starts', onPaymentStarts);
      eventBus.on('transaction-flow.payment.fails', onPaymentFails);
      eventBus.on('transaction-flow.payment-details.can-use-apple-pay', onCanUseApplePay);
      eventBus.on('transaction-flow.payment.payment-processor-not-found', onPaymentProcessorNotFound);

      eventsStatus.isRegistered = true;
    },
  };
};
