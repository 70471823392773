<template>
  <div class="additional-information">
    <div class="additional-information__item">
      <div
        class="additional-information__icon additional-information__icon--map"
      />
      {{ $t('widget.info.coverage') }}
    </div>

    <div class="additional-information__item">
      <div
        class="additional-information__icon additional-information__icon--fin"
      />
      {{ $t('widget.info.department') }}
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'AdditionalInformation',
});
</script>

<style lang="scss" scoped>
.additional-information {
  display: flex;
  font-size: rem(12);
  line-height: rem(16);

  @media (max-width: $tablet-max) {
    display: block;
  }

  @media (min-width: $laptop-min) and (max-height: $widget-height-sm) {
    font-size: calcVh(14, $widget-h-sm);
    line-height: calcVh(18, $widget-h-sm);
  }

  @media (min-width: $desktop-xlg-min) and (max-height: $widget-height-xlg) {
    font-size: calcVh(14, $widget-h-xlg);
    line-height: calcVh(18, $widget-h-xlg);
  }

  @media (min-width: $desktop-xxlg-min) and (max-height: $widget-height-xxlg) {
    font-size: calcVh(14, $widget-h-xxlg);
    line-height: calcVh(18, $widget-h-xxlg);
  }

  &__item {
    display: flex;
    align-items: flex-start;

    &:first-child {
      margin-right: rem(20);

      @media (max-width: $tablet-max) {
        margin-bottom: 16px;
      }
    }
  }

  &__icon {
    flex-shrink: 0;
    margin-right: rem(16);
    background-size: 100% 100%;

    &--map {
      height: rem(23);
      width: rem(36);
      background-image: url(~@/assets/images/widget/us-map.png);
    }

    &--fin {
      width: rem(36);
      height: rem(36);
      background-image: url(~@/assets/images/widget/fin.png);
    }
  }
}
</style>
