<template>
  <div
    class="form-input__wrapper"
    :class="{
      'has-error': hasError,
      'is-disabled': isDisabled,
      'has-tip': hasTipSlot,
    }"
  >
    <div
      v-if="label || hasTipSlot || hasFixedTipSlot"
      class="form-input__top"
    >
      <label
        v-if="label"
        :for="id"
        class="form-input__label"
      >
        {{ label }}
      </label>

      <span
        v-if="hasTipSlot"
        class="form-input__tip"
      >
        <slot name="tip" />
      </span>

      <span
        v-if="hasFixedTipSlot"
        class="form-input__fixed-tip"
      >
        <slot name="fixedTip" />
      </span>
    </div>

    <input
      :id="id"
      ref="input"
      :value="value"
      :name="name"
      :type="type"
      :inputmode="inputmode"
      :pattern="pattern"
      :placeholder="placeholder"
      :disabled="isDisabled"
      class="form-input__input"
      :minlength="minLength"
      :maxlength="maxLength"
      :min="min"
      :max="max"
      :step="step"
      :required="required"
      :class="{ 'is-regular': isRegular, 'is-uppercase': displayUppercase }"
      :autocomplete="isAutocomplete"
      :data-testid="dataTestid"
      @input="setInputValue"
      @focus="$emit('focus')"
      @keyup="$emit('keyup', $event)"
      @keydown="$emit('keydown', $event)"
      @keypress="onKeyPress"
      @paste.prevent="$emit('paste', $event)"
      @click="$emit('click', $event)"
    >

    <div class="form-input__comment">
      <slot
        v-if="hasCommentSlot"
        name="comment"
      />
    </div>

    <span
      v-if="hasError"
      class="form-input__error"
    >
      {{ error }}
    </span>
  </div>
</template>

<script>
// TODO should be removed in favor of upcoming UI kit components
export default {
  name: 'FormInput',

  props: {
    id: {
      type: String, required: false, default: '',
    },
    name: {
      type: String, required: false, default: '',
    },
    placeholder: {
      type: String, required: false, default: '',
    },
    type: {
      type: String, required: false, default: 'text',
    },
    inputmode: {
      type: String, required: false, default: 'text',
    },
    label: {
      type: String, required: false, default: '',
    },
    hasError: {
      type: Boolean, required: false, default: false,
    },
    error: {
      type: String, required: false, default: '',
    },
    value: {
      type: String, required: false, default: '',
    },
    isRegular: {
      type: Boolean, required: false, default: false,
    },
    isDisabled: {
      type: Boolean, required: false, default: false,
    },
    displayUppercase: {
      type: Boolean, required: false, default: false,
    },
    minLength: {
      type: String, required: false,
    },
    maxLength: {
      type: String, required: false,
    },
    min: {
      type: String, required: false,
    },
    max: {
      type: String, required: false,
    },
    step: {
      type: String, required: false,
    },
    required: {
      type: Boolean, required: false,
    },
    autocomplete: {
      type: Boolean, required: false, default: true,
    },
    dataTestid: {
      type: String, required: false, default: null,
    },
    focus: {
      type: Boolean, required: false, default: false,
    },
    pattern: {
      type: String, required: false,
    },
  },

  emits: ['focus', 'input'],

  computed: {
    hasTipSlot() {
      return !!this.$slots.tip;
    },

    hasFixedTipSlot() {
      return !!this.$slots.fixedTip;
    },

    hasCommentSlot() {
      return !!this.$slots.comment;
    },

    isAutocomplete() {
      if (!this.autocomplete) {
        return 'off';
      }

      return null;
    },
  },

  watch: {
    focus(newFocus) {
      if (!this.$refs.input || !newFocus) {
        return;
      }

      const inputRef = this.$refs.input;

      inputRef.focus();
      inputRef.select();
    },
  },

  mounted() {
    this.disableAutoComplete();
  },

  methods: {
    setInputValue(event) {
      this.$emit('input', event.target.value);
    },
    disableAutoComplete() {
      const element = this.$refs.input;
      if (element.getAttribute('autocomplete')) {
        // HACK: disable autocomplete
        element.setAttribute('readonly', 'readonly');
        element.style.backgroundColor = 'inherit';

        setTimeout(() => {
          element.removeAttribute('readonly');
        }, 500);
      }
    },
    onKeyPress(event) {
      if (this.maxLength && event.target.value.length >= this.maxLength) {
        event.preventDefault();
        this.$emit('input', event.key);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../assets/scss/variables';

.form-input {
  &__wrapper {
    width: 100%;
    position: relative;
  }

  &__wrapper.is-small {
    display: flex;
    align-items: center;
  }

  &__top {
    display: flex;
    justify-content: space-between;
  }

  &__label {
    font-size: 16px;
    line-height: 1.25;
  }

  &__tip {
    color: $grey-30;
    font-size: 14px;
    line-height: 1.14;
  }

  .is-disabled &__label {
    color: $grey-30;
  }

  &__top + &__input {
    margin-top: 4px;
  }

  &__input {
    display: block;
    width: 100%;
    background-color: #fff;
    border: none;
    border-radius: 6px;
    font-weight: 700;
    font-size: 16px;
    line-height: 1.25;
    padding: 14px;
    box-shadow: 0 0 0 2px $black-10;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    transition: box-shadow 0.2s linear;
  }

  &__input::placeholder {
    font-weight: 400;
  }

  &__input[type="number"] {
    -moz-appearance:textfield;
  }

  &__input:hover {
    box-shadow: 0 0 0 2px $black-20;
  }

  &__input:focus {
    outline: none;
    box-shadow: 0 0 0 1px $violet-60;
  }

  &__input:disabled {
    background-color: $grey-04;
    color: $grey;
    box-shadow: 0 0 0 2px $grey-04;
  }

  &__input.is-regular {
    font-family: $font-family;
  }

  .is-small &__input {
    padding: 4px 6px;
    max-width: 52px;
  }

  .has-error &__input {
    box-shadow: 0 0 0 2px $red;
  }

  .has-error &__input:hover {
    box-shadow: 0 0 0 2px rgba(231, 60, 64, 0.25);
  }

  &__input::-webkit-inner-spin-button,
  &__input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &__input::-ms-clear {
    display: none;
  }

  &__input::-webkit-input-placeholder {
    font-family: $font-family;
    color: $grey-30;
  }

  &__input:-ms-input-placeholder {
    font-family: $font-family;
    color: $grey-30;
  }

  &__input::placeholder {
    font-family: $font-family;
    color: $grey-30;
  }

  &__error {
    display: block;
    margin-top: 4px;
    font-size: 12px;
    line-height: 1.33;
    color: $red;
  }

  @media screen and (max-width: $screen-md-max) {
    &__wrapper.has-tip {
      padding-bottom: 28px;
    }

    .has-tip &__tip {
      position: absolute;
      bottom: 2px;
    }

    .is-small &__error {
      margin-left: 8px;
    }
  }

  @media screen and (min-width: $screen-lg-min) {
    &__wrapper.is-small {
      flex-direction: column;
      align-items: flex-end;
    }
  }

  [dir='rtl'] & {
    &__logo {
      img {
        right: auto;
        left: 16px;
      }
    }
  }
}
</style>
