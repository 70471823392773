import axios from 'axios';
import axiosRetry from 'axios-retry';

import { checkResponseBody, checkErrorBody } from './interceptors/emptyBodyInterceptors';
// eslint-disable-next-line import/no-cycle
import { handleResponseError, addCorrelationId } from './interceptors/errorInterseptors';

import { refreshExpiredToken } from '@/overrides/AuthFlow';
import { addAuthHeader } from '@/overrides/AuthFlow/exports';

const noopSuccess = Promise.resolve.bind(Promise);
const noopFail = Promise.reject.bind(Promise);

const factory = axiosOptions => {
  const instance = axios.create({
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-PB-User-Agent': `pb-widget/${process.env.npm_package_version}`,
    },
    timeout: 30000,
    ...axiosOptions,
  });

  axiosRetry(instance, {
    retries: 1,
    retryDelay: retryCount => retryCount * 500,
  });

  // order matters
  instance.interceptors.request.use(addAuthHeader, noopFail);
  instance.interceptors.request.use(addCorrelationId, noopFail);

  instance.interceptors.response.use(checkResponseBody, checkErrorBody);
  instance.interceptors.response.use(noopSuccess, handleResponseError);
  instance.interceptors.response.use(noopSuccess, refreshExpiredToken.bind(instance));

  return instance;
};

export default factory;
