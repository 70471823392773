<template>
  <div class="details-list">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'DetailsList',
};
</script>

<style scoped lang="scss">
.details-list > :not(:last-child) {
  padding: rem(16) 0;
  border-bottom: 1px solid rgba($black, 0.08);
}
</style>
