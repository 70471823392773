const CRYPTO_ADDRESS = 'account-address';
const CURRENCY_CODE_TO = 'crypto-currency';
const CURRENCY_CODE_FROM = 'fiat-currency';
const AMOUNT_FROM = 'fiat-amount';
const AMOUNT_TO = 'crypto-amount';
const TRANSACTION_FLOW = 'mode';
const PAYMENT_METHOD = 'payment-method';
const BANK_RESIDENCY = 'bank-residency';

const checkForValue = value => value !== null && value !== '' && value !== undefined;

export const handleUrlParams = urlParams => {
  const cryptoAddress = urlParams.get(CRYPTO_ADDRESS);
  const currencyCodeTo = urlParams.get(CURRENCY_CODE_TO);
  const currencyCodeFrom = urlParams.get(CURRENCY_CODE_FROM);
  const amountFrom = urlParams.get(AMOUNT_FROM);
  const amountTo = urlParams.get(AMOUNT_TO);
  const transactionFlow = urlParams.get(TRANSACTION_FLOW);
  const paymentMethod = urlParams.get(PAYMENT_METHOD);
  const bankResidency = urlParams.get(BANK_RESIDENCY);

  return {
    cryptoAddress,
    currencyCodeTo,
    currencyCodeFrom,
    amountFrom,
    amountTo,
    transactionFlow: transactionFlow !== null && transactionFlow === 'sell' ? 'sellCrypto' : 'buyCrypto',
    paymentMethod,
    bankResidency,
  };
};

export const supports = urlParams => {
  const cryptoAddress = urlParams.get(CRYPTO_ADDRESS);
  const currencyCodeTo = urlParams.get(CURRENCY_CODE_TO);
  const currencyCodeFrom = urlParams.get(CURRENCY_CODE_FROM);

  return [cryptoAddress, currencyCodeTo, currencyCodeFrom].every(checkForValue);
};
