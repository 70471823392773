<template>
  <span>
    <CardAuthorizationFailed
      v-if="isFailed"
      :error="error"
    />
    <CardAuthorization v-else-if="transaction.isPayoutAuthorizationPending()" />
    <CardAuthorizationReview v-else />
    <Actions>
      <template
        v-if="payout.cardAuthorizable()"
        #submit
      >
        <button
          :disabled="isLocked"
          class="btn btn-lg btn-primary"
          :class="{ 'is-loading': isLocked }"
          @click="submit()"
        >
          {{ $t('shared.navigation.button.go-to-authorization') }}
        </button>
      </template>
    </Actions>
  </span>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';

import Actions from '../components/actions';
import CardAuthorization from '../components/card-authorization';
import CardAuthorizationFailed from '../components/card-authorization-failed';
import CardAuthorizationReview from '../components/card-authorization-review';

export default {
  components: {
    CardAuthorization,
    CardAuthorizationReview,
    CardAuthorizationFailed,
    Actions,
  },
  computed: {
    ...mapGetters('transaction', ['transaction', 'payout']),
    ...mapGetters(['isLocked']),
    ...mapGetters('cardAuth', ['error', 'isFailed']),
  },
  methods: {
    ...mapActions('transaction', ['authorizeTransaction']),
    ...mapMutations(['lock']),
    submit() {
      this.lock();
      this.authorizeTransaction();
    },
  },
};
</script>
