<template>
  <transition name="fade">
    <div
      v-if="isShown"
      class="modal-holder__background"
      :class="{ 'modal-holder__background--without-blur': isWithoutBlur }"
      @click.self="holderDismiss"
    >
      <component :is="ModalContentComponent" />
    </div>
  </transition>
</template>

<script>
import modalManager from './modalManager';

export default {
  name: 'ModalHolder',

  props: {
    name: {
      type: String,
      default: 'default',
    },
    componentName: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    holderPropsLink: {},
  }),
  computed: {
    isShown() {
      return this.holderPropsLink.isShown;
    },
    isWithoutBlur() {
      return this.holderPropsLink.isWithoutBlur;
    },
    ModalContentComponent() {
      return this.holderPropsLink.component;
    },
  },
  created() {
    this.holderPropsLink = modalManager.registerTarget({
      name: this.name,
    });
  },
  beforeUnmount() {
    modalManager.unregisterTarget({
      name: this.name,
    });
  },
  methods: {
    holderDismiss() {
      this.ModalContentComponent.holderDismiss();
    },
  },
};
</script>

<style lang="scss">
$dim-color: rgba(0, 0, 0, 0.5);

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.modal-holder {
  &__background {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    backdrop-filter: blur(10px);
    background-color: $dim-color;
    z-index: 1000;

    display: flex;
    justify-content: center;
    align-items: center;

    &--without-blur {
      backdrop-filter: none;
    }
  }
}
</style>
