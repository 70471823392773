import { computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

import { useIsAppInitialized } from '@/composables/useIsAppInitialized';
import { usePaymentMethodsInit } from '@/composables/usePaymentMethodsInit';
// eslint-disable-next-line import/no-cycle
import { COOLING_OFF_SCREEN } from '@/router/routes';
import { AuthValidationService } from '@paybis/frontend-authentication-app/src/services/auth-validation';
import TokenService from '@paybis/frontend-authentication-app/src/services/token';
import { useExchangeFormRouting } from '@/composables/useExchangeFormRouting';

export default {
  name: 'ExchangeFormPreloader',

  setup() {
    const store = useStore();
    const router = useRouter();
    const { getAppInitializedPromise } = useIsAppInitialized();
    const { waitPaymentMethods } = usePaymentMethodsInit();
    
    const { navigate } = useExchangeFormRouting();

    const userEmail = computed(() => store.getters['request/email']);
    const sourceInfo = computed(() => store.getters.sourceInfo);
    const isShowCoolingOffPopUp = computed(() => store.getters.isShowCoolingOffPopUp);

    onMounted(async () => {
      await getAppInitializedPromise();

      const AuthValidator = new AuthValidationService({
        email: userEmail.value,
        sourceInfo: sourceInfo.value,
        onInvalidData: () => {
          TokenService.removeTokens();
        },
        onValidData: async (tokens) => {
          store.dispatch('authenticate', { tokens });
          store.dispatch('getFeatureFlags');

          await store.dispatch('loadClientData');

          if (isShowCoolingOffPopUp.value) {
            await waitPaymentMethods();
        
            store.dispatch('v2/widgetQuote/updatePaymentMethodByQuote', {}, { root: true });
        
            router.push({
              name: COOLING_OFF_SCREEN,
            });
          }
        },
      });

      await AuthValidator.validateUserTokens();

      if (isShowCoolingOffPopUp.value) {
        return;
      }

      await navigate();
    });

    return {};
  },
};
