<template>
  <div
    class="form-input__wrapper"
    :class="{
      'has-error': hasError,
      'is-disabled': isDisabled,
      'has-tip': hasTipSlot
    }"
  >
    <div
      v-if="label || hasTipSlot || hasFixedTipSlot"
      class="form-input__top"
    >
      <label
        v-if="label"
        :for="id"
        class="form-input__label"
      >
        {{ label }}
        <span
          v-if="mark"
          class="form-input__mark"
        >
          {{ mark }}
        </span>
      </label>

      <span
        v-if="hasTipSlot"
        class="form-input__tip"
      >
        <slot name="tip" />
      </span>

      <span
        v-if="hasFixedTipSlot"
        class="form-input__fixed-tip"
      >
        <slot name="fixedTip" />
      </span>
    </div>

    <slot
      :attrs="attrs"
      :listeners="listeners"
    >
      <input
        ref="input"
        v-bind="attrs"
        v-on="listeners"
      >
    </slot>

    <div
      v-if="logo"
      class="form-input__logo"
    >
      <img
        :src="require(`../../assets/images/info-${logo}.svg`)"
        alt="card logo"
      >
    </div>

    <div class="form-input__comment">
      <slot
        v-if="hasCommentSlot"
        name="comment"
      />
    </div>

    <span
      v-if="hasError"
      class="form-input__error"
      v-html="error"
    />
  </div>
</template>

<script>
export default {
  name: 'FormInput',

  props: {
    id: { type: String, required: false, default: '' },
    name: { type: String, required: false, default: '' },
    placeholder: { type: String, required: false, default: '' },
    type: { type: String, required: false, default: 'text' },
    inputmode: { type: String, required: false, default: 'text' },
    label: { type: String, required: false, default: '' },
    mark: { type: String, required: false, default: '' },
    hasError: { type: Boolean, required: false, default: false },
    error: { type: String, required: false, default: '' },
    value: { type: [String, Number], required: false, default: '' },
    modelValue: { type: [String, Number], required: false, default: '' },
    isRegular: { type: Boolean, required: false, default: false },
    isDisabled: { type: Boolean, required: false, default: false },
    displayUppercase: { type: Boolean, required: false, default: false },
    minLength: { type: String, required: false },
    maxLength: { type: String, required: false },
    min: { type: String, required: false },
    max: { type: String, required: false },
    step: { type: String, required: false },
    required: { type: Boolean, required: false },
    autocomplete: { type: Boolean, required: false, default: true },
    logo: { type: String, required: false, default: '' },
    dataTestid: { type: String, required: false, default: null },
    focus: { type: Boolean, required: false, default: false },
  },

  emits: ['focus', 'input', 'update:modelValue'],

  computed: {
    hasTipSlot() {
      return !!this.$slots.tip;
    },

    hasFixedTipSlot() {
      return !!this.$slots.fixedTip;
    },

    hasCommentSlot() {
      return !!this.$slots.comment;
    },

    isAutocomplete() {
      if (!this.autocomplete) {
        return 'off';
      }

      return null;
    },

    attrs() {
      return {
        id: this.id,
        value: this.value,
        name: this.name,
        type: this.type,
        inputmode: this.inputmode,
        placeholder: this.placeholder,
        disabled: this.isDisabled,
        class: { 'form-input__input': true, 'is-regular': this.isRegular, 'is-uppercase': this.displayUppercase },
        minlength: this.minLength,
        maxlength: this.maxLength,
        min: this.min,
        max: this.max,
        step: this.step,
        required: this.required,
        autocomplete: this.isAutocomplete,
        'data-testid': this.dataTestid,
      };
    },
    listeners() {
      return {
        focus: () => this.$emit('focus'),
        blur: () => this.$emit('blur'),
        input: $event => this.$emit('input', $event.target.value),
      };
    },
  },

  watch: {
    focus(newFocus) {
      if (!this.$refs.input || !newFocus) {
        return;
      }

      const inputRef = this.$refs.input;

      inputRef.focus();
      inputRef.select();
    },
  },

  mounted() {
    if (this.$refs.input) {
      this.disableAutoComplete();
    }
  },

  methods: {
    disableAutoComplete() {
      const element = this.$refs.input;
      if (element.getAttribute('autocomplete')) {
        // HACK: disable autocomplete
        element.setAttribute('readonly', 'readonly');
        element.style.backgroundColor = 'inherit';

        setTimeout(() => {
          element.removeAttribute('readonly');
        }, 500);
      }
    },
    inputHandler(event) {
      this.$emit('input', event.target.value);
      this.$emit('update:modelValue', event.target.value);
    },
  },
};
</script>

<style lang="scss">
.form-input {
  &__wrapper {
    width: 100%;
    position: relative;
  }

  &__logo {
    display: block;
    position: relative;

    img {
      position: absolute;
      right: rem(16);
      bottom: rem(12);
    }
  }

  &__wrapper.is-small {
    display: flex;
    align-items: center;
  }

  &__top {
    display: flex;
    justify-content: space-between;
  }

  &__label {
    font-size: rem(16);
    line-height: 1.25;
  }

  &__mark {
    margin-left: rem(2);
    color: $grey-30;
  }

  &__tip {
    color: $grey-30;
    font-size: rem(14);
    line-height: 1.14;
  }

  .is-disabled &__label {
    color: $grey-30;
  }

  &__top + &__input {
    margin-top: rem(4);
  }

  &__input {
    display: block;
    width: 100%;
    background-color: $white;
    border: none;
    border-radius: rem(6);
    font-weight: 700;
    font-size: rem(16);
    line-height: 1.25;
    padding: rem(14);
    box-shadow: 0 0 0 2px $black-10;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    transition: box-shadow 0.2s linear;
  }

  &__input::placeholder {
    font-weight: 400;
  }

  &__input:hover {
    box-shadow: 0 0 0 2px $black-20;
  }

  &__input:focus {
    outline: none;
    box-shadow: 0 0 0 1px $violet-60;
  }

  &__input:disabled {
    background-color: $grey-04;
    color: $grey;
    box-shadow: 0 0 0 2px $grey-04;
  }

  &__input.is-regular {
    font-family: $font-family;
  }

  .is-small &__input {
    padding: rem(4) rem(6);
    max-width: rem(52);
  }

  .has-error &__input {
    box-shadow: 0 0 0 2px $red;
  }

  .has-error &__input:hover {
    box-shadow: 0 0 0 2px rgba(231, 60, 64, 0.25);
  }

  &__input::-webkit-inner-spin-button,
  &__input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &__input::-ms-clear {
    display: none;
  }

  &__input::-webkit-input-placeholder {
    font-family: $font-family;
    color: $grey-30;
  }

  &__input:-ms-input-placeholder {
    font-family: $font-family;
    color: $grey-30;
  }

  &__input::placeholder {
    font-family: $font-family;
    color: $grey-30;
  }

  &__error {
    display: block;
    margin-top: rem(4);
    font-size: rem(12);
    line-height: 1.33;
    color: $red;
  }

  @media screen and (max-width: $screen-md-max) {
    &__wrapper.has-tip {
      padding-bottom: rem(28);
    }

    .has-tip &__tip {
      position: absolute;
      bottom: rem(2);
    }

    .is-small &__error {
      margin-left: rem(8);
    }
  }

  @media screen and (min-width: $screen-lg-min) {
    &__wrapper.is-small {
      flex-direction: column;
      align-items: flex-end;
    }
  }

  [dir="rtl"] & {
    &__logo {
      img {
        right: auto;
        left: rem(16);
      }
    }
  }
}
</style>
