<template>
  <span>
    <Pictogram
      :src="
        require('@paybis/frontend-common-lib/src/assets/images/verification-process.svg')
      "
    />
    <Paragraph :bold="true">
      {{
        $t(
          'transaction-flow.steps.payment-details.payment-status-waiting-screen.paragraph',
        )
      }}
    </Paragraph>
    <Loader class="mt-32 mb-32 ml-auto mr-auto" />
  </span>
</template>

<script>
import Loader from '@paybis/frontend-common-lib/src/components/loader';
import Paragraph from '@paybis/frontend-common-lib/src/components/paragraph';
import Pictogram from '@paybis/frontend-common-lib/src/components/pictogram';

export default {
  name: 'PaymentStatusWaiting',

  components: {
    Pictogram,
    Paragraph,
    Loader,
  },
};
</script>
