// eslint-disable-next-line import/named
import { REQUEST_ID, LOCALE, CAN_CLOSE } from '@/utils/getParams';

export const redirectToWidget = ({ requestId, locale }) => {
  const urlSearchParams = new URLSearchParams();

  urlSearchParams.append(REQUEST_ID, requestId);
  urlSearchParams.append(CAN_CLOSE, true);

  if (locale !== null) {
    urlSearchParams.append(LOCALE, locale);
  }

  const currentIframeHref = new URL(document.location.href);
  const urlOrigin = currentIframeHref.origin;

  return window.open(`${urlOrigin}/?${urlSearchParams.toString()}`, '_blank');
};
