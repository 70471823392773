<template>
  <TextButton
    icon="chat"
    :loading="isLoading"
    @click.prevent="showChat"
  >
    {{ $t('widget.live-chat') }}
  </TextButton>
</template>

<script>
import { defineComponent } from 'vue';
import { SupportChatHelper, isLoading } from './SupportChat.utils';
import TextButton from '@/components/TextButton';

export default defineComponent({
  name: 'SupportChat',

  components: { TextButton },

  props: {
    chatKey: {
      type: String,
      required: true,
    },
    lang: {
      type: String,
      required: true,
    },
  },

  setup(props) {
    const showChat = () => {
      if (!props.chatKey) return;
      SupportChatHelper.open({
        chatKey: props.chatKey,
        lang: props.lang,
      });
    };

    return {
      showChat,
      isLoading,
    };
  },
});
</script>
