<template>
  <div class="payment-select-item">
    <div
      class="payment-select-item__header"
      @click="handleSelectPayment"
    >
      <button
        class="payment-select__toggle"
        :class="{
          'is-selected': payment.getId() === selected,
        }"
      >
        {{ $t('shared.select.select') }}
      </button>
      <div class="payment-select-item__icon">
        <img
          :src="payment.getIconUrl()"
          alt="logo"
        >
      </div>
      <div class="payment-select-item__main">
        <div class="payment-select-item__primary">
          <div class="payment-select-item__number">
            {{ payment.getBankName() }}
            <p class="payment-select-item__holder">
              {{ payment.getAccountNumber() + ' • ' + payment.getAccountName() }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="payment.getId() === selected"
      class="payment-select-item__content"
    >
      <div class="payment-select-item__footer">
        <SecureStamp class="payment-select-item__secure" />

        <button
          class="btn btn-lg btn-primary payment-select-item-pay"
          :class="{
            'is-loading': isPayButtonDisabled,
          }"
          :disabled="isPayButtonDisabled"
          @click="$emit('pay', payment)"
        >
          {{ $t('shared.navigation.button.pay') }}
        </button>
      </div>
    </div>
  </div>
</template>
<script setup>
import { computed, defineEmits, defineProps } from 'vue';
import SecureStamp from '../secure-stamp';
import store from '@/store';

const props = defineProps({
  payment: {
    type: Object,
    required: true,
  },
  selected: {
    type: String,
    default: 'new',
    required: true,
  },
  isLoading: {
    type: Boolean,
    default: false,
    required: true,
  },
});

const emit = defineEmits(['selectPayment']);

const isLocked = computed(() => store.getters.isLocked);
const isPayButtonDisabled = computed(() => isLocked.value || props.isLoading);

function handleSelectPayment() {
  emit('selectPayment', props.payment.getId());
}
</script>

<style lang="scss" scoped>
.payment-select-item {
   margin-left: rem(32);
   padding: rem(10) 0;

   border-bottom: 1px solid $black-10;

  &__header {
    position: relative;
    display: flex;
    cursor: pointer;
  }

  &__content {
    padding-top: rem(20);
    padding-bottom: rem(14);
  }

  &__footer {
    padding-top: rem(24);
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__header + &__footer {
    padding-top: rem(16);
    padding-bottom: rem(14);
  }

  [dir='rtl'] {
    margin-right: rem(32);
    margin-left: unset;
  }

  &__icon {
    display: flex;
    background-color: $grey-04;
    border-radius: 50%;
    width: rem(40);
    height: rem(40);
  }

  &__primary {
    display: flex;
    align-items: center;
  }

  &__icon img {
    display: block;
    max-width: rem(30);
    max-height: rem(30);
    margin: auto;
    border-radius: 50%;
  }

  .payment-select__toggle {
    position: absolute;
    left: rem(-32);
    top: 50%;
    bottom: 50%;
    margin-top: rem(-8);
    font-size: 0;
    width: rem(16);
    height: rem(16);
    border-radius: 50%;
    border: 1px solid #dbdbdb;
    background-color: #f5f5f5;
  }

  [dir='rtl'] .payment-select__toggle {
    right: rem(-32);
    left: unset;
  }

  .payment-select__toggle.is-selected:after {
    content: '';
    display: block;
    width: rem(8);
    height: rem(8);
    border-radius: 50%;
    background-color: $black;
    margin: auto;
  }

  &__main {
    flex: 1;
    margin: 0 0 0 rem(16);
  }

  [dir='rtl'] &__main {
    margin: 0 rem(16) 0 0;
  }

  &__number {
    font-weight: 700;
    font-size: rem(16);
    line-height: 1.25;
  }

  &__holder {
    font-size: rem(12);
    line-height: 1.17;
    color: $grey-30;
  }

  @media screen and (min-width: $screen-lg-min) {
    &__main {
      display: flex;
      justify-content: space-between;
    }

    .payment-select-item {
      border-bottom: none;
    }

    .payment-select-item:not(:last-child) {
      border-bottom: 1px solid $black-10;
    }
  }

  &-pay {
    width: 100%;
  }

  &__secure {
    display: none;
  }

  @media screen and (min-width: $screen-lg-min) {
    &-pay {
      width: auto;
    }

    &__secure {
      display: flex;
    }
  }
}
</style>
