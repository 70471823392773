<template>
  <div
    class="merchant-redirect"
    v-html="$t('widget.merchant-redirect.expires-in', {time: counter})"
  />
</template>

<script setup>
import { defineProps, onBeforeUnmount, ref } from 'vue';

const props = defineProps({
  url: {
    type: String,
    required: true,
  },
});

const counter = ref(10);

let intervalId = setInterval(() => {
  counter.value -= 1;
  if (counter.value === 1) {
    removeInterval();
    window.location.href = props.url;
  }
}, 1000);

onBeforeUnmount(() => {
  removeInterval();
});

function removeInterval() {
  if (intervalId) clearInterval(intervalId);
  intervalId = null;
}

</script>

<style lang="scss">
.merchant-redirect {
  font-size: rem(16);
  line-height: rem(20);
  margin-bottom: rem(16);

  strong {
    font-size: rem(18);
  }

  @media (min-width: $laptop-min) and (max-height: $widget-height-sm) {
    font-size: calcVh(16, $widget-h-sm);
    line-height: calcVh(20, $widget-h-sm);
    margin-bottom: calcVh(16, $widget-h-sm);

    strong {
      font-size: calcVh(18, $widget-h-sm);
    }
  }
}
</style>
