import client from '@/network/clients/assets';

export const fetchCryptoAssets = () => {
  const data = client.get('')
    .then(response => {
      return response.data.reduce((assets, asset) => {
        assets[asset.id] = asset;
        return assets;
      }, {});
    });

  return data;
};
