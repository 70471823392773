<template>
  <div class="sales-funnel-bank-details">
    <div
      v-if="orderal"
      class="sales-funnel-bank-details__orderal"
    >
      {{ orderal }}
    </div>
    <div
      v-if="title"
      class="sales-funnel-bank-details__title"
    >
      {{ title }}
    </div>
    <DetailsList class="sales-funnel-bank-details__list">
      <slot />
    </DetailsList>
    <div
      v-if="comment"
      class="sales-funnel-bank-details__footer"
    >
      {{ comment }}
    </div>
  </div>
</template>

<script>
import DetailsList from './details-list';

export default {
  name: 'BankDetails',

  components: {
    DetailsList,
  },

  props: {
    orderal: { type: [Number, String], required: false, default: '' },
    title: { type: String, required: false, default: '' },
    comment: { type: String, required: false, default: '' },
    items: { type: Array, required: false, default: null },
    loading: { type: Boolean, default: false },
  },
};
</script>

<style scoped lang="scss">
.sales-funnel-bank-details {
  position: relative;
  width: 100%;
  border: 1px solid $black-06;
  border-radius: rem(6);
  padding: rem(8) rem(16) rem(24) rem(16);

  &__orderal {
    position: absolute;
    top: rem(12);
    left: rem(12);
    width: rem(20);
    font-weight: 700;
    font-size: rem(14);
    line-height: rem(20);
    border-radius: 50%;
    color: $white;
    background-color: $black;
    text-align: center;
  }

  [dir='rtl'] &__orderal {
    right: rem(12);
    left: unset;
  }

  &__title {
    max-width: rem(274);
    margin: 0 auto;
    font-weight: 700;
    font-size: rem(20);
    line-height: 1.4;
    text-align: center;
  }

  &__row {
    margin-bottom: rem(32);
  }

  &__list {
    width: 100%;
  }

  &__item {
    padding: rem(16) 0;
    border-bottom: 1px solid rgba($black, 0.08);
  }

  &__footer {
    margin-top: rem(24);
    font-size: rem(14);
    line-height: 1.43;
    max-width: rem(250);
    color: $grey-30;
  }

  @media screen and (min-width: $screen-lg-min) {
    padding: rem(16) rem(24) rem(32) rem(24);
  }
}
</style>
