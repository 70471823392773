<template>
  <div
    v-if="hasPendingTransaction && isFeatureEnabled && isCoinmamaTheme"
    class="pending-transaction-notification"
  >
    <span>{{ $t('widget.pending-transaction-notification.message') }}</span>
    <button
      class="btn btn-primary btn-sm"
      :class="{ 'is-loading': isButtonLoading }"
      @click="redirectToTransaction"
    >
      {{ $t('widget.pending-transaction-notification.button-text') }}
    </button>
  </div>
</template>

<script setup>
import { useStore } from 'vuex';
import { computed, ref, watchEffect } from 'vue';
import WidgetService from '@/services/widget/widgetService.v1';

const firstPendingTransaction = ref(null);
const isButtonLoading = ref(false);
const hasPendingTransaction = computed(() => firstPendingTransaction.value !== null);
const store = useStore();
const isFeatureEnabled = computed(() => store.getters['feature/widgetPendingTransactionNotificationFeature'].isEnabled());
const isCoinmamaTheme = computed(() => store.getters.themeConfig?.serviceName === 'Coinmama');

// XXX: Temporary method to get pending transactions
const getFirstPendingTransaction = async () => {
  const { data } = await WidgetService.getTransactionHistory({
    search: '',
    productType: 'widget',
    businessAccountProductId: store.getters['transactionHistory/businessAccountProductId'],
    pageSize: 10,
    page: 1,
  });

  const pendingTransactionList = data.filter(item => item.status === 'verification');

  return pendingTransactionList.length === 0 ? null : pendingTransactionList[0];
};

let isTransactionRequestDone = false;
watchEffect(async () => {
  // XXX: this will cause the request to trigger twice, but second time should be from browser cache
  if (isTransactionRequestDone || !isCoinmamaTheme.value || !isFeatureEnabled.value) {
    return;
  }

  try {
    firstPendingTransaction.value = await getFirstPendingTransaction();
    isTransactionRequestDone = true;
  } catch (e) {
    // XXX: ignore errors, we just don't show the notification
  }
});

const redirectToTransaction = async () => {
  isButtonLoading.value = true;
  await store.dispatch('request/redirectToInvoice', {
    invoiceId: firstPendingTransaction.value.invoice,
  });
  isButtonLoading.value = false;
};
</script>

<style scoped lang="scss">
.pending-transaction-notification {
  background: #D6E5FF;
  padding: .5rem;
  border-radius: .375rem;
  display: flex;
  align-content: space-between;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;

  & span {
    flex: content;
  }
  & button {
    flex: content;
    width: auto;
  }
}
</style>
