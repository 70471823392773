<template>
  <div class="verification-code-input">
    <FormInput
      v-for="(item, index) in codeCharacters"
      :key="`otp-code-${index}`"
      type="number"
      class="verification-code-input__item"
      :class="{ 'is-invalid': isInvalid }"
      :is-disabled="isDisabled"
      :max-length="maxLength"
      min="0"
      max="9"
      pattern="^[0-9]?$"
      inputmode="numeric"
      :value="item"
      :focus="currentFocus === index"
      @input="onInputUpdate($event, index)"
      @keydown="onKeyDown($event, index)"
      @keyup.enter="onEnterPress"
      @paste.prevent="onCodePaste"
      @focus="onInputFocus(index)"
      @click="onInputClick"
    />
  </div>
</template>

<script>
import FormInput from '../FormInput';

const VERIFICATION_CODE_LENGTH = 6;

export default {
  name: 'VerificationCodeInput',

  components: {
    FormInput,
  },

  props: {
    value: {
      type: String,
      required: true,
    },
    isInvalid: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    codeCharacters: new Array(VERIFICATION_CODE_LENGTH),
    currentFocus: 0,
  }),

  computed: {
    currentCode() {
      return this.codeCharacters.join('');
    },

    isAllInputsFilled() {
      const inputsCount = this.codeCharacters.length;

      return this.currentCode.length === inputsCount;
    },

    // Fix for ios and chrome mostly. If ios see maxLength 1 he put only 1 character.
    // https://paybis.atlassian.net/browse/PD-8329
    maxLength() {
      if (!this.isIos) return '1';
      return this.isAllInputsFilled ? '1' : VERIFICATION_CODE_LENGTH.toString();
    },

    isIos() {
      return [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod',
      ].includes(navigator.platform)
          // iPad on iOS 13 detection
          || (navigator.userAgent.includes('Mac') && 'ontouchend' in document);
    },
  },

  watch: {
    // whenever question changes, this function will run
    value(currentCode) {
      const newCodeCharacters = new Array(VERIFICATION_CODE_LENGTH);

      currentCode.split('').forEach((value, index) => {
        newCodeCharacters.splice(index, 1, value);
      });

      this.codeCharacters = newCodeCharacters;
    },
  },

  methods: {
    onInputClick(event) {
      event.stopImmediatePropagation();
    },

    onCodePaste(event) {
      if (!event.clipboardData) {
        return;
      }

      const clipboardData = event.clipboardData.getData('text');

      this.setOtpValue(clipboardData);
    },

    setOtpValue(inputValue) {
      const value = inputValue.substring(0, VERIFICATION_CODE_LENGTH);

      const isNumeric = /^\d+$/.test(value);

      if (!isNumeric) {
        return;
      }

      // Copy to prevent change by link
      const slicedCodeCharacters = this.codeCharacters.slice();
      const digits = value.split('');
      const closestInputIndex = this.getClosestInputIndexFor(digits);

      slicedCodeCharacters.splice(closestInputIndex, digits.length, ...digits);

      this.codeCharacters = slicedCodeCharacters.slice(
        0,
        VERIFICATION_CODE_LENGTH,
      );

      this.currentFocus = this.currentCode.length;
      this.$emit('input', this.currentCode);

      if (this.isAllInputsFilled) {
        this.$emit('filled', this.currentCode);
      }
    },

    onInputUpdate(inputValue, index) {
      const value = String(inputValue);

      if (!value) {
        return false;
      }

      if (value.length > 1) {
        this.setOtpValue(value);
        return;
      }

      let nextFocusValue = index + value.length;

      if (nextFocusValue > VERIFICATION_CODE_LENGTH) {
        nextFocusValue = VERIFICATION_CODE_LENGTH;
      }

      this.currentFocus = nextFocusValue;
      this.codeCharacters.splice(index, 1, ...value);
      this.codeCharacters.length = VERIFICATION_CODE_LENGTH;

      this.$emit('input', this.currentCode);

      if (!this.isAllInputsFilled) {
        return this.$emit('incomplete', this.currentCode);
      }

      this.$emit('filled', this.currentCode);
    },

    onKeyDown(event, index) {
      const { key } = event;
      const backCodes = ['Backspace', 'Delete'];

      if (backCodes.includes(key)) {
        this.handleDelete(index);
        event.preventDefault();
        return;
      }

      // Paste
      if (event.keyCode === 86 && (event.ctrlKey || event.metaKey)) {
        return;
      }

      // Enter, Tab
      if (event.keyCode === 13 || event.keyCode === 9) {
        return;
      }

      // Numeric keys and numpad keys
      if ((event.keyCode >= 48 && event.keyCode <= 57) || (event.keyCode >= 96 && event.keyCode <= 105)) {
        return;
      }

      event.preventDefault();
    },

    onInputFocus(index) {
      this.currentFocus = parseInt(index, 10);
    },

    getClosestInputIndexFor(digits) {
      if (this.currentFocus < 0) {
        this.currentFocus = 0;
      }
      const maxPossibleLength = this.currentFocus + digits.length;

      if (maxPossibleLength > VERIFICATION_CODE_LENGTH) {
        const difference = Math.abs(
          VERIFICATION_CODE_LENGTH - maxPossibleLength,
        );

        return this.currentFocus - difference;
      }

      return this.currentFocus;
    },

    onEnterPress() {
      this.$emit('submit', this.currentCode);
    },

    handleDelete(index) {
      this.currentFocus = Math.max(0, index - 1);

      if (this.codeCharacters[index]) {
        this.codeCharacters.splice(index, 1, '');
      }

      if (!this.isAllInputsFilled) {
        this.$emit('incomplete', this.currentCode);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.verification-code-input {
  display: flex;
  justify-content: center;
  width: 100%;
  padding-top: 16px;
  padding-bottom: 16px;

  @media screen and (min-width: 768px) {
    margin: 0 -0.5rem;
  }
}

.verification-code-input__item {
  flex: 1;
  max-width: 3.5rem;
  appearance: textfield;
  margin-left: 4px;
  margin-right: 4px;

  ::v-deep input {
    min-height: 3rem;
    font-size: 26px;
    text-align: center;
    padding: 8px;
  }

  @media screen and (min-width: 768px) {
    margin-left: 6px;
    margin-right: 6px;
  }

  &.is-invalid {
    ::v-deep input {
      box-shadow: 0 0 0 2px #e73c40;
    }
  }
}
</style>
