const cached = new Map();

export default {
  mounted(el) {
    const fakeInput = document.createElement('div');

    const styles = window.getComputedStyle(el);
    fakeInput.style.fontFamily = styles.fontFamily;
    fakeInput.style.fontSize = styles.fontSize;
    fakeInput.style.fontStyle = styles.fontStyle;
    fakeInput.style.fontWeight = styles.fontWeight;
    fakeInput.style.letterSpacing = styles.letterSpacing;
    fakeInput.style.textTransform = styles.textTransform;
    fakeInput.style.borderLeftWidth = styles.borderLeftWidth;
    fakeInput.style.borderRightWidth = styles.borderRightWidth;
    fakeInput.style.paddingLeft = styles.paddingLeft;
    fakeInput.style.paddingRight = styles.paddingRight;

    fakeInput.style.position = 'absolute';
    fakeInput.style.top = '0';
    fakeInput.style.left = '-9999px';
    fakeInput.style.overflow = 'hidden';
    fakeInput.style.visibility = 'hidden';
    fakeInput.style.whiteSpace = 'nowrap';
    fakeInput.style.height = '0';
    cached.set(el, fakeInput);
    document.body.appendChild(fakeInput);
  },
  updated(el, binding) {
    const fakeInput = cached.get(el);
    fakeInput.innerText = binding.value;
    el.style.width = `${Math.max(fakeInput.scrollWidth, binding.arg)}px`;
  },
  unmounted(el) {
    cached.get(el).remove();
    cached.delete(el);
  },
};
