<template>
  <div>
    <router-view v-if="canShow" />
    <FunnelLoader v-else />
  </div>
</template>

<script>
import { captureException } from '@paybis/frontend-common-lib/src/plugins/sentry';
import { mapActions, mapGetters } from 'vuex';

import FunnelLoader from '@paybis/frontend-common-lib/src/components/funnel-loader';
import { STEP_ERROR } from './models/steps';

import eventBus from './helpers/eventBus';

export default {
  components: {
    FunnelLoader,
  },
  data() {
    return {
      initiated: false,
      parentLevelRouteName: null,
    };
  },
  computed: {
    ...mapGetters('transaction', ['transaction', 'baseVerification']),
    ...mapGetters('transaction/navigation', ['current']),
    ...mapGetters('error', ['isCriticalError', 'error']),

    status() {
      return {
        transactionStatus: this.transaction.status,
        baseVerificationStatus: this.baseVerification.status,
        requestStatus: this.requestStatus,
      };
    },
    canShow() {
      // tightly coupled with the translation library we use in the app
      return this.$i18n.locale !== 'none';
    },
    loaded() {
      return this.initiated && this.canShow;
    },
  },
  watch: {
    loaded: {
      handler(newValue, prevValue) {
        if (!newValue || newValue === prevValue) {
          return;
        }

        eventBus.emit('transaction-flow.loaded');
      },
      immediate: true,
    },
    status() {
      if (
        this.$route.name === STEP_ERROR
        || this.$route.name === this.current.name
        || (this.parentLevelRouteName !== null && this.parentLevelRouteName === this.current.name)
      ) {
        return;
      }

      this.$router.push({ name: this.current.name });
      this.parentLevelRouteName = this.current.name;
    },
    isCriticalError(newValue) {
      if (newValue && this.$route.name !== STEP_ERROR) {
        const { response } = this.error;
        captureException({
          error: this.error,
          extra: {
            response,
          },
        });

        this.$router.push({ name: STEP_ERROR });
      }
    },
    $route() {
      window.scrollTo(0, 0);
    },
  },
  mounted() {
      this.initTransaction().then(() => {
      this.initiated = true;
      this.$router.push({ name: this.current.name });
    });
  },
  methods: {
    ...mapActions('transaction', {
      initTransaction: 'init',
    }),
  },
};
</script>
