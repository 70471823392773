// eslint-disable-next-line import/no-cycle
import client from '@/network/clients/widget';

export default {
  async getRequest(requestId) {
    const options = {
      skipAuthHeader: true,
    };

    const { data } = await client.get(`/v2/request/${requestId}`, options);

    return data;
  },

  async getPaymentMethods({ requestId, currencyFrom, currencyTo }) {
    const { data } = await client.get(`/v2/request/${requestId}/payment-methods/${currencyFrom}/${currencyTo}`);

    return data;
  },

  async getPayoutMethods({ requestId, currencyFrom, currencyTo }) {
    const { data } = await client.get(`/v2/request/${requestId}/payout-methods/${currencyFrom}/${currencyTo}`);

    return data;
  },

  async refreshPaymentOrPayoutMethods(requestBody) {
    const options = {
      skipAuthHeader: true,
    };

    const method = requestBody.paymentMethod ? 'payment-methods' : 'payout-methods';
    const { data } = await client.post(`/v2/${method}/refresh`, requestBody, options);

    return data;
  },

  async getQuote({ quoteId }) {
    const options = {
      skipAuthHeader: true,
    };

    const { data } = await client.get(`/v2/quote/${quoteId}`, options);

    return data;
  },

  async createQuote({ requestId, requestBody, locale }) {
    const options = {
      skipAuthHeader: true,
      headers: {
        'X-User-Locale': locale,
      },
    };

    const { data } = await client.post(`/v2/request/${requestId}/quote`, requestBody, options);

    return data;
  },

  async createTransaction(requestBody) {
    const { data } = await client.post('/v2/transaction', requestBody);

    return data;
  },

  async getExchangeCurrenciesForSell(requestId) {
    const { data } = await client.get(`/v2/request/${requestId}/currency-pairs/sell-crypto`);

    return data;
  },

  async getExchangeCurrenciesForBuy(requestId) {
    const { data } = await client.get(`/v2/request/${requestId}/currency-pairs/buy-crypto`);

    return data;
  },

  async getBusinessAccountProductSettings({ businessAccountProductUuid, params = {} }) {
    const { data } = await client.get(`/v2/product/${businessAccountProductUuid}/settings`, {
      params,
    });

    return data;
  },

  async getAuthToken(requestId) {
    const { data } = await client.post(`/v2/request/${requestId}/auth-token`);

    return data;
  },

  async initiateFrictionlessPayment(requestId) {
    const { data } = await client.post(`/v2/request/${requestId}/initiate-payment`);

    return data;
  },
};
