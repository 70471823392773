<template>
  <div class="language-switcher">
    <div
      v-for="locale in availableLanguages"
      :key="locale"
      class="language-switcher__item"
      :class="{ disabled: locale === currentLanguage }"
      @click="changeLanguage(locale)"
    >
      <div class="language-switcher__item-name">
        {{ $t(`widget.supported-languages.${locale}`) }}
      </div>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent } from 'vue';
import { useStore } from 'vuex';
import userLocaleStorage from '@paybis/frontend-authentication-app/src/services/user-locale-storage';

export default defineComponent({
  name: 'LanguageSwitcher',

  emits: ['switch'],

  setup() {
    const store = useStore();

    const currentLanguage = computed(() => store.getters.locale);
    const { availableLanguages } = store.getters;

    const changeLanguage = language => {
      store.dispatch('setLocale', language);
      userLocaleStorage.storeLocale(language);
    };

    return {
      currentLanguage,
      availableLanguages,
      changeLanguage,
    };
  },
});
</script>

<style lang="scss" scoped>
.language-switcher {
  &__item {
    display: flex;
    font-size: rem(16);
    padding: rem(12) 0;
    line-height: rem(20);
    border-top: 1px solid $black-06;
    cursor: pointer;
    align-items: center;
    justify-content: space-between;

    &:hover:not(.disabled) {
      opacity: 0.6;
    }

    &:last-child {
      border-bottom: 1px solid $black-06;
    }

    &.disabled {
      opacity: 0.3;
      cursor: default;
    }
  }

  &__item-name {
    flex-grow: 1;
    font-weight: 700;
  }
}
</style>
