<template>
  <div class="exchange-form__footer">
    <button
      class="exchange-form__footer-button btn btn-widget btn-primary"
      :class="{
        'is-loading': isLoading,
      }"
      :disabled="disableContinue"
      @click="onContinue"
    >
      {{ $t('widget.exchange-form.continue') }}
    </button>
    <div class="exchange-form__payment-services">
      <div
        v-for="(src, index) in icons"
        :key="index"
        class="exchange-form__payment-service"
      >
        <img
          :src="src"
          class="exchange-form__payment-service-image"
        >
      </div>
    </div>
  </div>
</template>

<script>
import mastercard from '@/assets/images/widget/mastercard.svg';
import visa from '@/assets/images/widget/visa.svg';
import applepay from '@/assets/images/widget/applepay.svg';
import googlepay from '@/assets/images/widget/googlepay.svg';
import { useStore } from 'vuex';
import { computed } from 'vue';

export default {
  name: 'ExchangeFormFooter',
  props: {
    disableContinue: {
      type: Boolean,
      default: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['continue'],

  setup(props, { emit }) {
    const store = useStore();

    const isSellCryptoFlow = computed(() => store.getters.isSellCryptoFlow);

    const defaultIcons = isSellCryptoFlow.value
      ? [mastercard, visa]
      : [mastercard, visa, applepay, googlepay];

    const iconsFromPartner = isSellCryptoFlow.value
      ? store.getters.themeConfig?.sellCryptoCustomExchangeFormFooterIcons
      : store.getters.themeConfig?.buyCryptoCustomExchangeFormFooterIcons;

    const icons = iconsFromPartner ?? defaultIcons;

    const onContinue = () => {
      if (props.disableContinue) return;
      emit('continue');
    };

    return {
      onContinue,
      icons,
    };
  },
};
</script>

<style lang="scss" scoped>
.exchange-form {
  &__footer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: rem(56);

    @media (max-width: $tablet-max) {
      margin-top: rem(20);
    }

    @media screen and (max-width: $screen-lg-max) {
      margin-top: 2.5rem;
    }

    @media (min-width: $laptop-min) and (max-height: $widget-height-sm) {
      margin-top: 7.29vh;
    }

    @media (min-width: $desktop-xlg-min) and (max-height: $widget-height-xlg) {
      margin-top: 6.5vh;
    }

    @media (min-width: $desktop-xxlg-min) and (max-height: $widget-height-xxlg) {
      margin-top: 4.95vh;
    }
  }

  &__footer-button {
    width: fit-content;

    @media screen and (max-width: $tablet-max) {
      width: 100%;
    }
  }

  &__payment-services {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
  }

  &__payment-service {
    &:not(:first-child) {
      margin-left: rem(14);
    }
  }

  &__payment-service-image {
    width: rem(46);
    height: rem(40);
  }

  @media (min-width: $laptop-min) and (max-height: $widget-height-sm) {
    &__payment-services {
      margin-top: 2.1vh;
    }

    &__payment-service-image {
      width: auto;
      max-width: 40px;
      height: 5.2vh;
    }
  }

  @media (min-width: $desktop-xlg-min) and (max-height: $widget-height-xlg) {
    &__payment-services {
      margin-top: 2.09vh;
    }

    &__payment-service-image {
      width: auto;
      height: 5.34vh;
    }
  }

  @media (min-width: $desktop-xxlg-min) and (max-height: $widget-height-xxlg) {
    &__payment-services {
      margin-top: 2.21vh;
    }

    &__payment-service-image {
      width: auto;
      height: 5.3vh;
    }
  }
}
</style>
