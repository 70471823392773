import { ref } from 'vue';
import getParams from '@/utils/getParams';

const { switchExchangeFormFlow } = getParams();

const isNewExchangeFormFeatureFlagEnabled = !!process.env.VUE_APP_NEW_EXCHANGE_FORM_FLOW;

const isNewExchangeFormFlow = ref(null);

export const isNewExchangeFormFlowEnabled = () => {
  if (isNewExchangeFormFlow.value === null) {
    isNewExchangeFormFlow.value = switchExchangeFormFlow
      ? !isNewExchangeFormFeatureFlagEnabled
      : isNewExchangeFormFeatureFlagEnabled;
  }

  return isNewExchangeFormFlow.value;
};
