<template>
  <div
    class="modal-content"
    :class="[`modal-content--${size}`]"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: 'ModalContent',

  props: {
    size: {
      type: String,
      required: false,
      default: 'lg',
      validator(value) {
        return ['lg'].includes(value);
      },
    },
  },
};
</script>

<style scoped lang="scss">
.modal-content {
  margin: 0 auto;
  width: 100%;
  padding: 0 rem(24);

  &:first-child {
    padding-bottom: rem(22);
  }

  &:last-child {
    padding-bottom: rem(32);
  }

  .modal--error & {
    padding: rem(36) rem(16) rem(32);
  }

  @media screen and (min-width: $screen-lg-min) {
    &--lg {
      width: rem(416);
    }

    &--lg--px-0 {
      padding: 0;
    }

    &:first-child {
      padding-bottom: rem(62);
    }

    &:last-child {
      padding-bottom: rem(56);
    }

    .modal--error & {
      padding: rem(36) rem(56) rem(32);
    }
  }
}
</style>
