<template>
  <main class="main">
    <div class="sales-funnel-wrapper">
      <div class="sales-funnel-wrapper__container">
        <div
          class="sales-funnel-wrapper__header"
          style="height: 4rem;"
        >
          <ul class="sales-funnel-header-steps" />
        </div>
        <div class="sales-funnel-wrapper__body">
          <div
            class="loader-container"
            style="margin: 9rem 0 10rem 0;"
          />
        </div>
        <div
          class="sales-funnel-wrapper__footer"
          style="height: 10rem;"
        />
      </div>
      <div class="sales-funnel-wrapper__postfix">
        {{ $t("transaction-flow.shared.secured") }}
      </div>
    </div>
  </main>
</template>

<script>
export default {
  name: 'FunnelLoader',
};
</script>
