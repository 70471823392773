import { TokenService } from '@/overrides/AuthFlow/exports';
import storage from '@/services/appPersistStorage';
import { setCanClose, setLayout, setStandaloneParams } from '@/services/widgetSettings';
import { handleUrlParams } from '@/standalone/handleParams';
import { layoutValidator, localeValidator } from '@/validators';

export const REQUEST_ID = 'requestId';
export const LOCALE = 'locale';
export const LANG = 'lang';
export const REFRESH_TOKEN = 'refreshToken';
export const ONE_TIME_TOKEN = 'oneTimeToken';
export const CAN_CLOSE = 'canClose';
export const BUSINESS_ACCOUNT_PRODUCT_UUID = 'partnerId';
export const TRANSACTION_FLOW = 'transactionFlow';
export const PARTNER_USER_ID = 'partnerUserId';
export const SUCCESS_RETURN_URL = 'successReturnURL';
export const FAILURE_RETURN_URL = 'failureReturnURL';
export const SWITCH_EXCHANGE_FORM_FLOW = 'switchExchangeFormFlow';
export const LAYOUT = 'layout';
export const INDEX_PAGE = 'indexPage';

const url = new URL(window.location);

const removeParamsFromUrl = params => {
  params.forEach(param => {
    url.searchParams.delete(param);
  });

  window.history.replaceState(null, null, url);
};

const getRequestParamsByUrlParams = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const requestId = urlParams.get(REQUEST_ID);
  const refreshToken = urlParams.get(REFRESH_TOKEN);
  const oneTimeToken = urlParams.get(ONE_TIME_TOKEN);
  const canClose = urlParams.get(CAN_CLOSE);
  const businessAccountProductUuid = urlParams.get(BUSINESS_ACCOUNT_PRODUCT_UUID);
  const partnerUserId = urlParams.get(PARTNER_USER_ID);
  const successReturnURL = urlParams.get(SUCCESS_RETURN_URL);
  const failureReturnURL = urlParams.get(FAILURE_RETURN_URL);
  const transactionFlow = getTransactionFlowParam(urlParams);
  const switchExchangeFormFlow = urlParams.get(SWITCH_EXCHANGE_FORM_FLOW);
  const layout = layoutValidator(urlParams.get(LAYOUT));
  const indexPage = getIndexPage(urlParams);
  let locale = urlParams.get(LOCALE) || urlParams.get(LANG);
  try {
    localeValidator(locale);
  } catch {
    locale = null;
  }

  return {
    requestId,
    locale,
    refreshToken,
    oneTimeToken,
    canClose,
    businessAccountProductUuid,

    ...handleUrlParams(urlParams),
    transactionFlow,
    switchExchangeFormFlow,
    indexPage,
    partnerUserId,
    successReturnURL,
    failureReturnURL,
    layout,
  };
};

const getTransactionFlowParam = urlParams => {
  const value = urlParams.get(TRANSACTION_FLOW);
  if (['buyCrypto', 'sellCrypto'].includes(value)) {
    return value;
  }
  return null;
};

const getIndexPage = urlParams => {
  const value = urlParams.get(INDEX_PAGE);
  if (['paymentMethodList', 'exchangeForm', 'applePay'].includes(value)) {
    return value;
  }
  return null;
};

export const removeSensitiveDataFromUrl = () => removeParamsFromUrl([
  REFRESH_TOKEN,
  ONE_TIME_TOKEN,
  CAN_CLOSE,
]);

export default () => {
  const requestParams = getRequestParamsByUrlParams();
  const {
    refreshToken,

    cryptoAddress,
    currencyCodeTo,
    currencyCodeFrom,
    amountFrom,
    amountTo,
    transactionFlow,
    paymentMethod,
    bankResidency,
    layout,

    canClose = false,
  } = requestParams;
  if (refreshToken) {
    TokenService.setRefreshToken(refreshToken);
  }

  setCanClose(canClose);
  setLayout(layout);

  setStandaloneParams({
    cryptoAddress,
    currencyCodeTo,
    currencyCodeFrom,
    amountFrom,
    amountTo,
    transactionFlow,
    paymentMethod,
    bankResidency,
  });

  if (process.env.NODE_ENV === 'production') {
    return requestParams;
  }

  // XXX: only for development purposes
  const key = 'REQUEST_ID';
  if (requestParams.requestId) {
    storage.setItem(key, requestParams.requestId);

    return requestParams;
  }

  const requestId = storage.getItem(key);
  if (requestId && requestId !== 'undefined') {
    requestParams.requestId = requestId;
  }

  return requestParams;
};
