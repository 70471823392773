<template>
  <div class="exchange-form">
    <div class="exchange-form__title">
      {{ $t('widget.exchange-form.title') }}
    </div>
    <div class="exchange-form__wrapper">
      <ExchangeFormBody
        :is-loading="isLoading"
        :quote="quote"
      />

      <ExchangeFormFooter
        :disable-continue="isContinueDisabled"
        :is-loading="isNextStepLoading"
        @continue="onContinue"
      />
    </div>

    <SelectCurrencyModal />
  </div>
</template>

<script>
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

import ExchangeFormBody from './ExchangeFormBody';
import ExchangeFormFooter from './ExchangeFormFooter';
import SelectCurrencyModal from './SelectCurrencyModal';
import { refreshQuoteSubscriber, stopQuoteAutorefresh } from '@/store/widgetQuote';
import { isNewExchangeFormFlowEnabled } from '@/services/exchangeFormFlow';
import { AUTH, TRANSACTION } from '@/router/routes';

export default {
  name: 'ExchangeForm',

  components: {
    ExchangeFormBody,
    ExchangeFormFooter,
    SelectCurrencyModal,
  },

  setup() {
    const store = useStore();
    const router = useRouter();

    const quote = computed(() => store.getters['exchangeForm/quote']);
    const isQuoteExist = computed(() => store.getters['widgetQuote/isQuoteReady']);
    const isLoading = computed(() => store.getters['exchangeForm/isLoading']);
    const isNextStepLoading = ref(false);

    const onContinue = async () => {
      stopQuoteAutorefresh();
      isNextStepLoading.value = true;

      try {
        if (isNewExchangeFormFlowEnabled()) {
          if (store.getters.jwtTokenExists()) {
            router.push({
              name: TRANSACTION,
            });

            return;
          }

          router.push({
            name: AUTH,
          });

          return;
        }

        await store.dispatch('createTransaction');

        router.push({
          name: TRANSACTION,
        });
      } catch {
        refreshQuoteSubscriber(store);
        isNextStepLoading.value = false;
      }
    };

    const isContinueDisabled = computed(() => {
      const isQuoteReady = isQuoteExist.value && !isLoading.value;
      const isFilledInputs = quote.value && quote.value.amountFrom && quote.value.amountTo;
      return !isQuoteReady || !isFilledInputs;
    });

    return {
      quote,
      isLoading,
      isNextStepLoading,
      isContinueDisabled,
      onContinue,
    };
  },
};
</script>

<style lang="scss" scoped>
.exchange-form {

  &__title {
    display: inline-block;
    width: 100%;
    font-size: rem(28);
    font-weight: 700;
    line-height: rem(32);
    margin-bottom: rem(40);
    text-align: center;

    @media screen and (max-width: $tablet-max) {
      display: block;
      font-size: 24px;
      margin: 8px 0 24px;
      line-height: 26px;
      padding-right: 30px;
    }

    @media screen and (min-width: $laptop-min) {
      display: inline-block;
      width: 100%;
    }

    @media (min-width: $laptop-min) and (max-height: $widget-height-sm) {
      font-size: 3.6vh;
      line-height: 4.16vh;
      margin-bottom: 4vh;
    }

    @media (min-width: $desktop-xlg-min) and (max-height: $widget-height-xlg) {
      font-size: 3.7vh;
      line-height: 4.18vh;
      margin-bottom: 7.31vh;
    }

    @media (min-width: $desktop-xxlg-min) and (max-height: $widget-height-xxlg) {
      font-size: 3.71vh;
      line-height: 4.24vh;
      margin-bottom: 7.42vh;
    }
  }

  &__wrapper {
    margin: 40px auto 0;
    width: rem(350);

    @media screen and (max-width: $tablet-max) {
      margin-top: 48px;
      width: auto;
    }

    @media (min-width: $laptop-min) and (max-height: $widget-height-sm) {
      margin: 6.25vh auto 0;
    }

    @media (min-width: $desktop-xlg-min) and (max-height: $widget-height-xlg) {
      margin: 5.57vh auto 0;
    }

    @media (min-width: $desktop-xxlg-min) and (max-height: $widget-height-xxlg) {
      margin: 4.24vh auto 0
    }
  }
}
</style>
