<template>
  <div class="payment-method">
    <HeaderTitle
      class="payment-method-header"
      :is-back-button-available="requestQuoteId || paymentMethod.paymentMethod"
      @back="goToExchangeForm"
    >
      <h3
        v-if="isCurrenciesLoaded"
        class="payment-method-currency"
      >
        <button
          v-intro="{dataIntro: fiatSelectHintMessage, dataStep: 1 }"
          class="payment-method-currency-selector"
          @click="selectCurrency"
        >
          <i
            class="svg-icon currency-flag"
            :class="`icon-flag-${apmHeaderCurrency.toLowerCase()}`"
          />
          {{ apmHeaderCurrency }}
          <i class="svg-icon icon-arrow-down-small currency-select-arrow" />
        </button>
      </h3>
    </HeaderTitle>
    <Subtitle class="payment-method-subtitle">
      {{ subTitle }}
    </Subtitle>
    <div class="payment-method-body">
      <template v-if="isLoaded">
        <PaymentMethodListItem
          v-for="item in paymentMethods"
          :key="item.paymentMethod"
          :item="item"
          class="payment-method-body-item"
          :class="{
            'selected': item.paymentMethod === paymentMethod.paymentMethod,
            'disabled': false,
          }"
          @select="selectPaymentMethod"
        />
      </template>
      <div
        v-else
        class="loader"
      />
    </div>
  </div>

  <SelectCurrencyModal />
</template>

<script setup>
import { computed, onMounted, onUnmounted, watch } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import PaymentMethodListItem from './PaymentMethodListItem';
import SelectCurrencyModal, { useSelectCurrencyModal } from '@/components/v2/SelectCurrencyModal';
import { HeaderTitle } from '@/overrides/AuthFlow/exports';
import { updateCurrencyIcon, initCurrencyIcon } from '@/composables/apmCurrencyIcon';
import { usePaymentMethodsInit } from '@/composables/usePaymentMethodsInit';

import { usePaymentMethodRouting } from './usePaymentMethodRouting';
import { EXCHANGE_FORM_V2, START_TRANSACTION } from '@/router/routes';
import {
  refreshQuoteSubscriber,
  stopQuoteAutorefresh,
} from '../../../store/widgetQuote';
import Subtitle from '@paybis/frontend-common-lib/src/components/subtitle';
import { useRoutePreloader } from '@/router/PreloadRouterView';
import { IntroService } from '@/services/introInitService';

const store = useStore();
const router = useRouter();
const { t } = useI18n();

const { canRedirectToTransaction, setRedirectToTransaction } = usePaymentMethodRouting();
const { waitPaymentMethods, isLoaded } = usePaymentMethodsInit();

onMounted(() => {
  stopQuoteAutorefresh();
  refreshQuoteSubscriber(store);
  createQuote();
});

const subTitle = computed(() => (isSellCryptoFlow.value ? t('widget.payout-method-select-subtitle') : t('widget.payment-method-select-subtitle')));
const fiatSelectHintMessage = computed(() => {
   return isSellCryptoFlow.value ? t('widget.fiat-payout-select-dropdown.hint-message') : t('widget.fiat-payment-select-dropdown.hint-message');
});
const isSellCryptoFlow = computed(() => store.getters.isSellCryptoFlow);

const currencyFrom = computed(() => store.getters['exchangeForm/fromCurrency']);
const currencyTo = computed(() => store.getters['exchangeForm/toCurrencyCode']);
const apmHeaderCurrency = isSellCryptoFlow.value ? currencyTo : currencyFrom;
const { setSelfPreloadState } = useRoutePreloader();
const isCurrenciesLoaded = computed(() => store.getters['exchangeForm/isLoaded']);

// XXX: get payment methods block
// eslint-disable-next-line max-len
watch([currencyFrom, currencyTo], () => {
  waitPaymentMethods();
});

watch(isCurrenciesLoaded, isLoaded => {
  if (!isLoaded) {
    return;
  }

  setSelfPreloadState();
}, { immediate: true });

const paymentMethods = computed(() => {
  if (isSellCryptoFlow.value) {
    return [
      store.getters['v2/paymentMethods/payoutMethods'].find(value => paymentMethod.value.paymentMethod === value.paymentMethod),
      ...store.getters['v2/paymentMethods/payoutMethods'].filter(value => paymentMethod.value.paymentMethod !== value.paymentMethod),
    ].filter(el => el);
  }

  return [
    store.getters['v2/paymentMethods/paymentMethods'].find(value => paymentMethod.value.paymentMethod === value.paymentMethod),
    ...store.getters['v2/paymentMethods/paymentMethods'].filter(value => paymentMethod.value.paymentMethod !== value.paymentMethod),
  ].filter(el => el);
});
const paymentMethod = computed(() => {
  if (isSellCryptoFlow.value) {
    return store.getters['v2/paymentMethods/payoutMethod'];
  }

  return store.getters['v2/paymentMethods/paymentMethod'];
});

// XXX: set apm currency icon
watch(paymentMethod, value => {
  initCurrencyIcon(value.icon);
});

// XXX: router block
const goToExchangeForm = () => {
  setRedirectToTransaction(false);
  router.push({
    name: EXCHANGE_FORM_V2,
  });
};

const createQuote = () => {
  const { amountFrom, amountTo, amountReceived } = store.getters['widgetQuote/quote'];

  const isFromFiatAmount = !!store.getters['exchangeForm/fromFiatAmount'];

  let amount;

  if (isFromFiatAmount) {
    amount = amountReceived;
  } else {
    amount = store.getters['exchangeForm/direction'] === 'from' ? amountFrom : amountTo;
  }

  if (amount) {
    store.dispatch('v2/widgetQuote/fetchQuote', {
      amount,
      currencyCodeFrom: store.getters['exchangeForm/fromCurrency'],
      currencyCodeTo: store.getters['exchangeForm/toCurrencyCode'],
      directionChange: store.getters['exchangeForm/direction'],
      isReceivedAmount: store.getters['exchangeForm/isReceivedAmount'],
      promoCode: store.getters['promoCode/promoCode'],
    }, { root: true });
  }
};

// Select fiat currency
const popularCurrenciesFrom = computed(() => store.getters['exchangeForm/popularCurrenciesFrom'](currencyTo.value)
  .map(({ from }) => ({
    code: from,
    currency: from,
    name: t(`currencies.${from}`),
  })));

const popularCurrenciesTo = computed(() => store.getters['exchangeForm/popularCurrenciesTo'](currencyFrom.value)
  .map(({ to, details }) => ({
    code: to,
    currency: details?.toCurrency || to,
    name: t(`currencies.${to}`),
  })));

const allCurrenciesFrom = computed(() => store.getters['exchangeForm/allFiatCurrencies']
  .map(({ from }) => ({
    code: from,
    currency: from,
    name: t(`currencies.${from}`),
  })).sort((firstCurrency, secondCurrency) => firstCurrency.code.localeCompare(secondCurrency.code)));

const allCurrenciesTo = computed(() => store.getters['exchangeForm/allFiatCurrencies']
  .map(({ to }) => ({
    code: to,
    currency: to,
    name: t(`currencies.${to}`),
  })).sort((firstCurrency, secondCurrency) => firstCurrency.code.localeCompare(secondCurrency.code)));

const { showCurrencyModal } = useSelectCurrencyModal();

const selectCurrency = async () => {
  const popularCurrencies = isSellCryptoFlow.value ? popularCurrenciesTo : popularCurrenciesFrom;
  const allCurrencies = isSellCryptoFlow.value ? allCurrenciesTo : allCurrenciesFrom;

  const selectedCurrency = await showCurrencyModal({
    popularCurrencies,
    allCurrencies,
    extra: { hideIcons: true },
  });

  store.dispatch('v2/paymentMethods/unsetPaymentMethod');
  store.dispatch('v2/paymentMethods/unsetPayoutMethod');

  if (isSellCryptoFlow.value) {
    if (selectedCurrency === currencyTo.value) return;

    store.dispatch('exchangeForm/setCurrencyCodeTo', selectedCurrency);
  } else {
    if (selectedCurrency === currencyFrom.value) return;

    store.dispatch('exchangeForm/setCurrencyCodeFrom', selectedCurrency);
  }
};

const selectPaymentMethod = async selectedPaymentMethod => {
  if (isSellCryptoFlow.value) {
    store.dispatch('v2/paymentMethods/setPayoutMethod', selectedPaymentMethod);
  } else {
    store.dispatch('v2/paymentMethods/setPaymentMethod', selectedPaymentMethod);
  }

  store.dispatch('exchangeForm/setCurrencyPairsByPaymentMethod', {
    paymentMethod: selectedPaymentMethod.paymentMethod,
  });

  updateCurrencyIcon(selectedPaymentMethod.icon);

  if (!canRedirectToTransaction.value) {
    router.push({
      name: EXCHANGE_FORM_V2,
    });

    return;
  }

  // XXX: When quote is locked by partner
  isLoaded.value = false;
  if (store.getters.isRequestWithQuote || selectedPaymentMethod.quote?.id) {
    if (store.getters['v2/widgetQuote/quoteId'] !== store.getters['request/quoteId']) {
      await store.dispatch('exchangeForm/assignQuoteToRequest');
    }

    const createTransactionPayload = {};

    if (isSellCryptoFlow.value) {
      createTransactionPayload.payoutMethod = selectedPaymentMethod;
    } else {
      createTransactionPayload.paymentMethod = selectedPaymentMethod;
    }

    await store.dispatch('createTransaction', createTransactionPayload);
  }

  router.push({
    name: START_TRANSACTION,
  });
};

onMounted(() => {
    setTimeout(() => {
      IntroService.startIntro('fiat-select-dropdown');
    }, 100);
});

onUnmounted(() => {
  IntroService.exitIntroSession();
});
</script>

<script>
export default {
  name: 'PaymentMethodsScreen',
};
</script>

<style lang="scss" scoped>
.payment-method {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .payment-method-subtitle {
    margin-bottom: 20px
  }
  .payment-method-header {
    margin: 0 0 20px;

    @media screen and (max-width: $tablet-max) {
      margin-top: 7px;
    }

    @media (min-width: $laptop-min) {
      margin-bottom: 3.5rem;
    }

    @media (min-width: $laptop-min) and (max-height: $widget-height-xxlg) {
      margin-bottom: 3.42vh;
    }

    .payment-method-currency {
      display: flex;
      justify-content: center;
      margin: 0;
      font-size: 24px;

      @media (min-width: $laptop-min) {
        font-size: 1.75rem;
      }

      @media (min-width: $laptop-min) and (max-height: $widget-height-sm) {
        font-size: 3.6vh;
      }

      .payment-method-currency-selector {
        display: flex;
        align-items: center;

        &:hover {
          opacity: 0.5;
        }

        .currency-select-arrow {
          height: 1.5rem;
          width: 1.5rem;
          display: block;
          background-repeat: no-repeat;
          background-position: center;
          background-size: .5rem;
        }

        .currency-flag {
          width: 18px;
          height: 18px;
          margin-right: 10px;

          @media (min-width: $laptop-min) {
            width: 1.5rem;
            height: 1.5rem;

            @media (max-height: $widget-height-xxlg) {
              width: 2.5vh;
              height: 2.5vh;
            }
          }
        }
      }
    }
  }

  .payment-method-body {
    min-height: 320px;

    @media (min-width: $laptop-min) and (max-height: $widget-height-sm) {
      min-height: 35vh;
    }

    &-item {
      &.selected {
        background: rgb(255,255,255);
        background: linear-gradient(
            90deg,
            rgba(255,255,255,0) 0%,
            rgba(245,245,245,1) 5%,
            rgba(245,245,245,1) 95%,
            rgba(255,255,255,0) 100%
        );
      }

      &.disabled {
        cursor: wait;
      }
    }

    .loader {
      @include spinerIcon($size: 30px);
      margin: 20px auto 0;
    }
  }
}
</style>
