<template>
  <div class="billing-address-form">
    <h2 class="billing-address-form__header">
      {{ $t('transaction-flow.payment-details.card-details.billing-address-header') }}
    </h2>

    <FormRow
      ref="rowCountry"
      media="lg"
    >
      <FormRowSlot
        media="lg"
        width="50%"
        gap="wide"
      >
        <FormSelect
          :id="billingAddressFields.country.getId()"
          ref="billingAddressCountry"
          :calculate-position="calculatePositionCountries"
          :append-to-body="true"
          :options="billingAddressFields.country.getOptions()"
          :value="billingAddressFields.country.getValue()"
          :name="billingAddressFields.country.getId()"
          :label="$t('transaction-flow.payment-details.card-details.country')"
          :has-error="billingAddressFields.country.hasError()"
          :error="
            $t(`validators.${billingAddressFields.country.getErrorMessage()}`)
          "
          @input="validateAndUpdate($event, billingAddressFields.country)"
          @change="onChangeCountry"
        />
      </FormRowSlot>

      <FormRowSlot
        media="lg"
        width="50%"
        gap="wide"
      >
        <FormSelect
          :id="billingAddressFields.state.getId()"
          :is-disabled="isStateDisabled"
          :options="billingAddressFields.state.getOptions()"
          :value="billingAddressFields.state.getValue()"
          :name="billingAddressFields.state.getId()"
          :label="$t('transaction-flow.payment-details.card-details.state')"
          :has-error="billingAddressFields.state.hasError()"
          :error="
            $t(`validators.${billingAddressFields.state.getErrorMessage()}`)
          "
          @input="validateAndUpdate($event, billingAddressFields.state)"
        />
      </FormRowSlot>
    </FormRow>

    <FormRow>
      <FormInput
        :id="billingAddressFields.address.getId()"
        :value="billingAddressFields.address.getValue()"
        :name="billingAddressFields.address.getName()"
        :type="billingAddressFields.address.getType()"
        :label="$t('transaction-flow.payment-details.card-details.address')"
        :has-error="billingAddressFields.address.hasError()"
        :error="
          $t(`validators.${billingAddressFields.address.getErrorMessage()}`)
        "
        inputmode="text"
        @input="validateAndUpdate($event, billingAddressFields.address)"
      />
    </FormRow>

    <FormRow media="lg">
      <FormRowSlot
        media="lg"
        width="50%"
        gap="wide"
      >
        <FormInput
          :id="billingAddressFields.city.getId()"
          :value="billingAddressFields.city.getValue()"
          :name="billingAddressFields.city.getName()"
          :type="billingAddressFields.city.getType()"
          :label="$t('transaction-flow.payment-details.card-details.city')"
          :has-error="billingAddressFields.city.hasError()"
          :error="
            $t(`validators.${billingAddressFields.city.getErrorMessage()}`)
          "
          inputmode="text"
          @input="validateAndUpdate($event, billingAddressFields.city)"
        />
      </FormRowSlot>

      <FormRowSlot
        media="lg"
        width="50%"
        gap="wide"
      >
        <FormInput
          :id="billingAddressFields.zip.getId()"
          :value="billingAddressFields.zip.getValue()"
          :name="billingAddressFields.zip.getName()"
          :type="billingAddressFields.zip.getType()"
          :label="$t('transaction-flow.payment-details.card-details.zip')"
          :has-error="billingAddressFields.zip.hasError()"
          :error="
            $t(`validators.${billingAddressFields.zip.getErrorMessage()}`)
          "
          inputmode="text"
          @input="validateAndUpdate($event, billingAddressFields.zip)"
        />
      </FormRowSlot>
    </FormRow>
  </div>
</template>

<script>
import FormRow from '@paybis/frontend-common-lib/src/components/form/form-row';
import FormRowSlot from '@paybis/frontend-common-lib/src/components/form/form-row-slot';
import FormInput from '@paybis/frontend-common-lib/src/components/form/form-input';
import FormSelect from '@paybis/frontend-common-lib/src/components/form/form-select';

export default {
  name: 'BillingAddressForm',

  components: {
    FormRow,
    FormRowSlot,
    FormInput,
    FormSelect,
  },

  props: {
    billingAddressFields: { type: Object, required: true },
  },
  computed: {
    isStateDisabled() {
      return (
        !this.billingAddressFields.country.getValue()
        || this.billingAddressFields.country.getValue() !== 'US'
      );
    },
  },
  methods: {
    calculatePositionCountries(dropdownList, component) {
      const { top, left, height } = component.$el.getBoundingClientRect();

      const scrollX = window.scrollX || window.pageXOffset;
      const scrollY = window.scrollY || window.pageYOffset;

      const topComputed = `${scrollY + top + height}px`;
      const leftComputed = `${scrollX + left}px`;
      const widthComputed = `${this.$refs.rowCountry.$el.offsetWidth}px`;

      dropdownList.style.top = topComputed;
      dropdownList.style.left = leftComputed;
      dropdownList.style.width = widthComputed;
    },
    onChangeCountry(countryCode) {
      if (countryCode !== 'US') {
        this.billingAddressFields.state.setErrorMessage(null);
        this.billingAddressFields.state.setValue(null);
      }
    },
    validateAndUpdate(value, field) {
      field.setErrorMessage(null);

      field.setValue(value);
    },
  },
};
</script>

<style scoped lang="scss">
.billing-address-form {
  margin-top: rem(16);

  &__header {
    text-align: center;
    margin-top: rem(32);
    margin-bottom: rem(16);
    font-weight: 700;
    font-size: rem(15);
    line-height: 1.14;
    color: #b3b3b3;
  }

  @media screen and (min-width: $screen-sm-min) {
    &__header {
      font-size: rem(18);
    }
  }

  ::v-deep .vs__selected {
    white-space: nowrap;
    overflow: hidden;
    display: block;
    text-overflow: ellipsis;
    padding-right: 40px;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
}
</style>
