import { captureException } from '@paybis/frontend-common-lib/src/plugins/sentry';
import { emitToParent } from '@/services/widgetApiEvents';
import { redirectIfCriticalError } from './redirectIfCriticalError';

export default (error, component, hookError) => {
  redirectIfCriticalError({ error });

  captureException({
    error,
    extra: {
      hookError,
      ...component,
    },
  });

  // TODO: this should be moved to the store or any single entry point for errors.
  emitToParent('state', {
    state: 'error',
    error,
  });
};
