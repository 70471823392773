<template>
  <div class="resend">
    <div
      class="resend__terms"
      v-html="$t('authentication-app.otp.terms')"
    />

    <div
      v-if="!isResendTimeout"
      class="resend__label"
    >
      {{
        $t('authentication-app.otp.resend', {
          time: resendTimeString,
        })
      }}
    </div>

    <div
      v-else
      class="resend__refresh"
    >
      <a
        v-show="isResendTimeout"
        :class="{ 'is-loading': isLoadingResend }"
        class="btn-link"
        href="#"
        @click.prevent="$emit('resend-otp')"
      >
        {{ $t('authentication-app.buttons.resend-otp') }}
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Resend',

  props: {
    isResendTimeout: {
      type: Boolean,
      required: true,
    },
    isLoadingResend: {
      type: Boolean,
      required: true,
    },
    resendTimeString: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../assets/scss/variables';

.resend {
  font-family: $font-family;
  font-weight: 400;
  color: #dcdcdc;
  text-align: center;

  &__label {
    white-space: nowrap;

    @media (max-width: 370px) {
      white-space: normal;
    }
  }

  &__terms {
    display: none;
  }
}
</style>
