export default class SupportedAssets {
  constructor(
    blockchain = '',
    code = '',
    currency = '',
    decimals = '',
    name = '',
    network = '',
  ) {
    this.blockchain = blockchain;
    this.code = code;
    this.currency = currency;
    this.decimals = decimals;
    this.name = name;
    this.network = network;
  }

  static createFromData({
    blockchain,
    code,
    currency,
    decimals,
    name,
    network,
  }) {
    return new this(blockchain, code, currency, decimals, name, network);
  }

  getWalletName() {
    return `${this.name} ${this.currency}`;
  }
}
