<template>
  <h1 class="sales-funnel-title">
    <slot />
  </h1>
</template>

<script>
export default {
  name: 'Title',
};
</script>

<style scoped lang="scss">
.sales-funnel-title {
  font-weight: 700;
  font-size: rem(28);
  line-height: 1.14;
  text-align: center;
  margin-bottom: rem(4);

  @media screen and (min-width: $screen-lg-min) {
    margin-bottom: rem(8);
  }
}
</style>
