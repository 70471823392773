<template>
  <div
    v-if="showRegistrationWarning"
    class="registration-warning"
    v-html="$t('authentication-app.uk-authorization-warning')"
  />
  <div v-else>
    <div class="auth-app-header">
      {{ $t('authentication-app.login.heading') }}
    </div>

    <Tabs
      :key="isTranslationsReady"
      @select="handleSwitchTab"
    >
      <Tab
        :id="$options.TAB_CODE"
        :title="$t('authentication-app.login.tabs.with-code')"
        :is-selected="selectedTab"
      />
      <Tab
        :id="$options.TAB_PASSWORD"
        :title="$t('authentication-app.login.tabs.with-password')"
        :is-selected="selectedTab"
      />
    </Tabs>

    <EmailInput
      :value="email"
      :error-message="errorMessage"
      :show-error="showErrorMessage"
      @valid="isSubmitDisabled = false"
      @invalid="isSubmitDisabled = true"
      @submit="handleSubmitEmail"
      @input="handleEmailInput"
    />

    <FormInput
      v-if="isPasswordTabSelected"
      class="password"
      :value="password"
      :label="$t('authentication-app.login.password')"
      :error="passwordErrorMessage"
      :has-error="showPasswordError"
      type="password"
      inputmode="text"
      @input="handlePasswordInput"
      @keyup.enter.native="handleSubmitEmail"
      @paste="handlePasswordPaste"
    >
      <template #fixedTip>
        <a
          href="#"
          class="btn-link btn-link-grey"
          @click.prevent="handleChangeFlow('forgot')"
        >
          {{ $t('authentication-app.login.forgot-password') }}
        </a>
      </template>
    </FormInput>

    <ReCaptchaInput
      v-if="isPasswordTabSelected"
      ref="reCaptcha"
      :error-message="reCaptchaError"
      @verify="onReCaptchaVerify"
    />

    <div class="auth-app-footer">
      <button
        :class="{ 'is-loading': isLoading }"
        :disabled="isSubmitDisabled"
        class="btn btn-primary btn-lg"
        @click="handleSubmitEmail"
      >
        {{ $t('authentication-app.buttons.login') }}
      </button>

      <div class="auth-app-footer__secondary">
        <a
          href="#"
          class="btn-link"
          @click.prevent="handleChangeFlow('signup')"
        >
          {{ $t('authentication-app.buttons.register') }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import ReCaptchaInput from '@paybis/frontend-authentication-app/src/components/ReCaptchaInput';
import EmailInput from '../../components/EmailInput';
import Tabs from '../../components/Tabs';
import Tab from '../../components/Tab';
import FormInput from '../../components/FormInput';
import AuthService from '../../services/auth';
import LegacyAuthService from '../../services/legacy-auth';
import { Session } from '../../models/session';
import { Tokens } from '../../models/tokens';
import { TooEarlyError } from '../../models/auth-error';
import { useI18n } from 'vue-i18n';

const TAB_CODE = 'code';
const TAB_PASSWORD = 'password';

export default {
  name: 'LoginEmailView',

  TAB_CODE,
  TAB_PASSWORD,

  components: {
    EmailInput,
    Tabs,
    Tab,
    FormInput,
    ReCaptchaInput,
  },

  props: {
    session: {
      type: Session,
      default: null,
    },
  },

  data() {
    const { t, te } = useI18n();

    return {
      t,
      te,
      selectedTab: TAB_CODE,
      email: '',
      password: '',
      errorMessage: '',
      isSubmitDisabled: true,
      isLoading: false,
      showErrorMessage: false,
      showPasswordError: false,
      reCaptcha: '',
      reCaptchaError: '',
      showRegistrationWarning: false,
    };
  },

  computed: {
    isTranslationsReady() {
      return this.te('authentication-app.login.tabs.with-code');
    },

    isPasswordTabSelected() {
      return this.selectedTab === TAB_PASSWORD;
    },

    passwordErrorMessage() {
      return this.showErrorMessage ? '' : this.t('authentication-app.error.password');
    },
  },

  methods: {
    onReCaptchaVerify(reCaptcha) {
      this.reCaptcha = reCaptcha;
      if (this.showErrorMessage) {
        return;
      }
      this.submitLegacyLogin();
    },

    clearErrors() {
      this.showErrorMessage = this.email === '';
      this.showPasswordError = this.isPasswordTabSelected && this.password === '';
      this.reCaptchaError = '';
    },

    handleEmailInput(email) {
      this.email = email;
      this.clearErrors();
    },

    handleSwitchTab(tabName) {
      this.showErrorMessage = false;
      this.showPasswordError = false;
      this.reCaptchaError = '';
      if (this.isLoading) {
        return;
      }
      this.selectedTab = tabName;
    },

    handleChangeFlow(flowName) {
      if (this.isLoading) {
        return;
      }
      this.$emit('change-flow', flowName);
    },

    handlePasswordPaste(event) {
      if (!event.clipboardData) {
        return;
      }

      const password = event.clipboardData.getData('text').trim();

      this.handlePasswordInput(password);
    },

    handlePasswordInput(password) {
      this.password = password;
      this.clearErrors();
    },

    handleSubmitEmail() {
      if (this.isSubmitDisabled || this.isLoading || this.showPasswordError) {
        this.showErrorMessage = this.isSubmitDisabled;
        return;
      }
      this.isLoading = true;
      if (this.isPasswordTabSelected) {
        if (this.password === '') {
          this.isLoading = false;
          this.showPasswordError = true;
          return;
        }

        this.$refs.reCaptcha.execute();

        if (this.reCaptcha === '') {
          return;
        }

        this.submitLegacyLogin();

        return;
      }
      AuthService
        .startSession(this.email, this.$i18n.locale)
        .then(session => this.$emit('success', session))
        .catch(this.handleError);
    },

    submitLegacyLogin() {
      LegacyAuthService
        .login(this.email, this.password, this.reCaptcha)
        .then(response => {
          this.$emit('success-otp', new Tokens(response));
        })
        .catch(error => {
          this.reCaptchaError = '';
          this.reCaptcha = '';
          this.$refs.reCaptcha.reset();
          if (error.statusCode === 'invalid-recaptcha') {
            this.isLoading = false;
            this.showErrorMessage = true;
            this.reCaptchaError = this.t('authentication-app.error.recaptcha');
            return;
          }
          this.handleError(error);
        });
    },

    handleError(error) {
      this.isLoading = false;
      this.showErrorMessage = true;
      if (error instanceof TooEarlyError) {
        if (this.session !== null && this.session.isEmailSessionValid()) {
          return this.$emit('change-flow', 'otp.email');
        }
        return this.errorMessage = this.t('authentication-app.error.too-early', {
          canBeResentAfter: error.getResendTimer(),
        });
      }

      const { statusCode, isCritical } = error;
      if (typeof statusCode === 'undefined' || isCritical) {
        this.showErrorMessage = false;
        return this.$emit('critical-error', error);
      }

      if (statusCode === 'registration-forbidden') {
        this.showRegistrationWarning = true;
        return;
      }

      this.showPasswordError = statusCode === 'authentication-failed';
      this.errorMessage = this.t(`authentication-app.error.${statusCode}`);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../assets/scss/variables';
@import '../../assets/scss/btn';

.password {
  margin-top: 24px;
}

.btn-link-grey{
  font-size: 0.875rem;
}

.registration-warning {
  text-align: center;
}
</style>
