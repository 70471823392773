<template>
  <div
    class="widget-header"
    :class="{ 'widget-header--light': lightLayout }"
    :style="headerBottomSpace"
  >
    <span
      v-if="showLogo"
      class="logo"
      :class="{ 'logo--light': lightLayout }"
      data-testid="logo"
    />

    <div class="widget-header__partner-name">
      {{ partnerName }}
    </div>

    <div
      v-show="showCloseButton && isCloseButtonVisible"
      class="btn-close"
      @click="toggleCloseConfirm"
    >
      <span
        class="svg-icon icon-close-mask"
        :style="headerCloseIconStyle"
      />
      <span class="icon-close-text" :style="headerCloseTextStyle">
        {{ $t('widget.close') }}
      </span>
    </div>
  </div>
</template>

<script>
import { headerCloseTextStyle, headerCloseIconStyle, headerBottomSpace } from '@/composables/errorScreenAppearance';
import { canClose } from '@/services/widgetSettings';
import { computed } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import getParams from '@/utils/getParams';
import { Layouts } from '@/constants/layouts';

export default {
  name: 'WidgetHeader',

  emits: ['close-widget'],

  setup(props, { emit }) {
    const store = useStore();
    const { t } = useI18n();
    const themeConfig = computed(() => store.getters.themeConfig);
    const showLogo = computed(() => themeConfig.value?.showLogo ?? true);
    const showCloseButton = computed(() => themeConfig.value?.showCloseButton ?? true);

    const partnerName = computed(() => {
      if (store.getters.isKinesisTheme) {
        return t('widget.partner-name.kinesis');
      }

      if (store.getters.isPaybisAcquireTheme) {
        return 'Paybis';
      }

      return themeConfig.value.serviceName;
    });

    const { layout } = getParams();
    const lightLayout = computed(() => layout === Layouts.LIGHT);

    return {
      partnerName,
      isCloseButtonVisible: canClose(),
      toggleCloseConfirm: () => emit('close-widget'),
      headerCloseTextStyle,
      headerCloseIconStyle,
      headerBottomSpace,
      showLogo,
      showCloseButton,
      lightLayout,
    };
  },

};
</script>

<style lang="scss" scoped>
.widget-header {
  padding: 1rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-areas:"logo name close";
  justify-content: center;
  align-items: center;
  position: relative;

  @media (min-width: $laptop-min) {
    padding: 40px 48px;
    min-height: 5vh;
  }

  @media (min-width: $desktop-lg-min) {
    min-height: 14vh;
  }

  @media (min-width: $laptop-min) and (min-height: $widget-height-high) {
    min-height: 17vh;
  }

  @media (min-width: $laptop-min) and (max-height: $widget-height-sm) {
    padding-top: calcVh(16, $widget-h-sm);
    padding-bottom: calcVh(16, $widget-h-sm);
  }

  @media (min-width: $desktop-xlg-min) and (max-height: $widget-height-xlg) {
    padding-top: calcVh(16, $widget-h-xlg);
    padding-bottom: calcVh(16, $widget-h-xlg);
  }

  @media (min-width: $desktop-xxlg-min) and (max-height: $widget-height-xxlg) {
    padding-top: calcVh(16, $widget-h-xxlg);
    padding-bottom: calcVh(16, $widget-h-xxlg);
  }

  @media (min-width: $desktop-xlg-min) {
    padding-top: calcVh(48, $widget-h-xxlg);
    padding-bottom: calcVh(48, $widget-h-xxlg);
  }

  &--light {
    grid-template-areas: "name logo close";
    min-height: unset;
  }

  &__partner-name {
    display: none; //we decided to show partner name only if partner dont have logo, so by default its none
    grid-area: name;
    justify-self: center;
    text-align: center;
    text-wrap: nowrap;

    font-weight: 700;
    font-size: rem(34);
    letter-spacing: -0.01em;
  }

  .btn-close {
    grid-area: close;
    z-index: 5;
    justify-self: end;

    @media (min-width: $tablet-max) {
      align-self: start;
    }
  }

  .icon-close-mask {
    background-color: $black;
    mask-image: url('~@/assets/images/widget/close.svg');
    height: 16px;
    width: 16px;
    margin-right: 5px;
    mask-repeat: no-repeat;
    mask-size: contain;
  }

  .icon-close-text {
    @media (max-width: $tablet-max) {
      display: none;
    }
  }

  .logo {
    @media (max-width: $tablet-max) {
      grid-area: logo;
    }
  }
  .logo--light {
    @media (max-width: $tablet-max) {
      align-self: center;
      justify-self: center;
    }
  }
}
</style>
