import { reactive, computed } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';

export const ModalTargetEnum = {
  from: Symbol('from'),
  to: Symbol('to'),
};

const currencyModal = reactive({
  onChange: () => {},
  currencies: {
    popular: [],
    all: [],
  },
  hideIcons: true,
  display: false,
});

export default () => {
  const store = useStore();
  const { t } = useI18n();
  const getPopularCurrencies = computed(() => store.getters['exchangeForm/popularCurrenciesWithPair']);
  const getAllCurrencies = computed(() => store.getters['exchangeForm/allCurrenciesWithPair']);

  return {
    currencyModal,

    showCurrencyModal: ({ quote, position }) => {
      let quoteCurrencyCode;
      let storeActionKey;
      let getCurrency;

      if (position === ModalTargetEnum.from) {
        quoteCurrencyCode = quote.currencyCodeTo;
        storeActionKey = 'exchangeForm/setCurrencyCodeFrom';
        getCurrency = ({ from }) => ({
          code: from,
          currency: from,
          name: t(`currencies.${from}`),
        });
      } else {
        quoteCurrencyCode = quote.currencyCodeFrom;
        storeActionKey = 'exchangeForm/setCurrencyCodeTo';
        getCurrency = ({ to, details }) => ({
          code: to,
          currency: details.toCurrency,
          name: t(`currencies.${to}`),
        });
      }

      const popularCurrencies = getPopularCurrencies
        .value(quoteCurrencyCode).map(getCurrency);
      const allCurrencies = getAllCurrencies
        .value(quoteCurrencyCode).map(getCurrency);

      currencyModal.onChange = value => store.dispatch(storeActionKey, value);
      currencyModal.currencies = {
        popular: popularCurrencies,
        all: allCurrencies,
      };
      currencyModal.hideIcons = position === ModalTargetEnum.from;
      currencyModal.display = true;
    },
  };
};
