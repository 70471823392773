<template>
  <span>
    <Pictogram
      :src="
        require('@paybis/frontend-common-lib/src/assets/images/transaction-canceled.svg')
      "
    />
    <Subtitle>{{ $t('transaction-flow.steps.cancelled.subtitle') }}</Subtitle>
    <Paragraph>
      {{ $t('transaction-flow.steps.cancelled.paragraph') }}
    </Paragraph>
    <Actions>
      <template #submit>
        <button
          :disabled="isLocked"
          class="btn btn-lg btn-secondary"
          :class="{ 'is-loading': isLocked }"
          @click="start()"
        >
          {{ $t('shared.navigation.button.start-a-new-transaction') }}
        </button>
      </template>
    </Actions>
  </span>
</template>

<script>
import Paragraph from '@paybis/frontend-common-lib/src/components/paragraph';
import Pictogram from '@paybis/frontend-common-lib/src/components/pictogram';
import Subtitle from '@paybis/frontend-common-lib/src/components/subtitle';
import { mapActions, mapGetters, mapMutations } from 'vuex';

import Actions from '../components/actions';

export default {
  components: {
    Subtitle,
    Paragraph,
    Pictogram,
    Actions,
  },
  computed: {
    ...mapGetters(['isLocked']),
  },
  methods: {
    ...mapMutations(['lock']),
    ...mapActions('transaction', ['repeatTransaction']),
    start() {
      this.lock();
      this.repeatTransaction();
    },
  },
};
</script>
