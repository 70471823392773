<template>
  <PageLayout class="auth-flow-layout">
    <AuthFlow
      v-if="!isLoading"
      :email="userEmail"
      :theme="themeConfig.id"
      :source-info="sourceInfo"
      :locale="locale"
      :partner-id="nSurePartnerId"
      :request-id="requestId"
      @authenticated="onAuth"
      @error="onError"
      @loaded="onLoaded"
    />

    <Modal
      :show="isTooManyRequestsErrorModalVisible"
      :component="$options.modals.TooManyRequestsErrorModal"
      @confirm="closeWidget"
    />
  </PageLayout>
</template>

<script>
import { ref, computed, nextTick, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { useRoutePreloader } from '@/router/PreloadRouterView';
import { emitToParent } from '@/services/widgetApiEvents';
import { ERROR_TYPES } from '@/overrides/AuthFlow/errorTypes';
import { DeviceIdentificationError } from '@/exceptions/DeviceIdentificationError';
import { DEVICE_NOT_IDENTIFIED } from '@/views/CriticalErrorScreen';

import PageLayout from '@/components/PageLayout';
import AuthFlow from '@/overrides/AuthFlow';
import {
  Modal,
  TooManyRequestsErrorModal,
} from '@/components/Modal';
import { isV2 } from '@/services/apiVersion';
import { useIsAppInitialized } from '@/composables/useIsAppInitialized';
import { isNewExchangeFormFlowEnabled } from '@/services/exchangeFormFlow';
import { PAYMENT_METHODS_V2, START_TRANSACTION, CRITICAL_ERROR, HOME, EXCHANGE_FORM } from '@/router/routes';
import { refreshQuoteWithPaymentMethodSubscriber } from '@/store/widgetQuote';
import { isApplePayOneClickFlow } from '@/services/applePayOneClickService';

const TRANSACTION_HISTORY_ITEM = 'transaction-history';

export default {
  name: 'AuthFlowScreen',

  components: {
    Modal,
    PageLayout,
    AuthFlow,
  },

  modals: {
    TooManyRequestsErrorModal,
  },

  setup() {
    const store = useStore();
    const router = useRouter();
    const { setSelfPreloadState } = useRoutePreloader();

    const isAlreadyAuthenticated = ref(false);
    const isTooManyRequestsErrorModalVisible = ref(false);
    const isLoading = computed(() => (store.getters.isThemeLoading && store.getters['request/isLoading']) || !store.getters.productId);
    const userEmail = computed(() => store.getters['request/email']);
    const nSurePartnerId = computed(() => store.getters.nSure?.nSurePartnerId);
    const requestId = computed(() => store.getters.requestId);
    const themeConfig = computed(() => store.getters.themeConfig);
    const sourceInfo = computed(() => store.getters.sourceInfo);
    const locale = computed(() => store.getters.locale);
    const invoice = computed(() => store.getters['external/funnelInvoiceId']);
    const isOneClickCheckoutFlow = computed(() => store.getters.isOneClickFlow);
    const isMenuOpened = computed(() => store.getters.isMenuOpened);
    const currentMenuItem = computed(() => store.getters.currentMenuItem);
    const lastMenuItem = computed(() => store.getters.lastMenuItem);
    const isSellCryptoFlow = computed(() => store.getters.isSellCryptoFlow);
    const selectedPaymentMethod = computed(() => {
      if (isSellCryptoFlow.value) {
        return store.getters['v2/paymentMethods/payoutMethod'].paymentMethod;
      }

      return store.getters['v2/paymentMethods/paymentMethod'].paymentMethod;
    });

    onMounted(() => {
      refreshQuoteWithPaymentMethodSubscriber(store);
    });

    const { isAppInitialized } = useIsAppInitialized();

    const reopenTransactionHistory = async () => {
      if (isMenuOpened.value) {
        // XXX: close first, then open to omit menu loader
        store.dispatch('toggleMenu');
        await nextTick();
      }

      store.dispatch('toggleMenu');
      store.dispatch('selectMenuItem', {
        item: TRANSACTION_HISTORY_ITEM,
      });
    };

    const onAuth = async tokens => {
      isAlreadyAuthenticated.value = true;

      try {
        store.dispatch('authenticate', { tokens });
        store.dispatch('getFeatureFlags');

        const quoteId = store.getters['request/quoteId'];
        const widgetQuoteId = store.getters['v2/widgetQuote/quoteId'];

        if (isV2()) {
          store.dispatch('loadClientData');

          await isAppInitialized; // this makes sure that all needed data is fetched for the next steps

          // XXX: when one-click-checkout detected, immediately land user on transaction page
          if (isOneClickCheckoutFlow.value || isApplePayOneClickFlow()) {
            router.push({
              name: START_TRANSACTION,
            });
            return;
          }

          if (isNewExchangeFormFlowEnabled()) {
            // XXX: Close menu if opened, because opened menu can overlap auth screen
            if ([currentMenuItem.value, lastMenuItem.value].includes(TRANSACTION_HISTORY_ITEM)) {
              await reopenTransactionHistory();

              // XXX: Need to redirect user back to exchange form, EXCEPT case when user not in one-click flow
              router.push({
                path: '/v2/exchange-form',
              });
              return;
            }
          }

          if (invoice.value) {
            router.push({
              name: START_TRANSACTION,
            });

            return;
          }

          if (isNewExchangeFormFlowEnabled() && !invoice.value) {
            if (quoteId) {
              const createTransactionPayload = {};
              if (isSellCryptoFlow.value) {
                createTransactionPayload.payoutMethod = selectedPaymentMethod.value;
              } else {
                createTransactionPayload.paymentMethod = selectedPaymentMethod.value;
              }

              try {
                await store.dispatch('createTransaction', createTransactionPayload);
                // eslint-disable-next-line no-empty
              } catch {} // needs to be added to prevent unhandled critical error inside auth flow when error is in createTransaction
            }

            if (widgetQuoteId) {
              router.push({
                path: '/start-transaction',
              });

              return;
            }

            router.push({
              name: START_TRANSACTION,
            });

            return;
          }

          router.push({
            name: PAYMENT_METHODS_V2,
          });

          return;
        }

        await store.dispatch('loadClientData');
        await isAppInitialized; // this makes sure that all needed data is fetched for the next steps

        if (isNewExchangeFormFlowEnabled()) {
          // XXX: Close menu if opened, because opened menu can overlap auth screen
          if ([currentMenuItem.value, lastMenuItem.value].includes(TRANSACTION_HISTORY_ITEM)) {
            await reopenTransactionHistory();

            router.push({
              name: EXCHANGE_FORM,
            });
            return;
          }
        }

        if (!isNewExchangeFormFlowEnabled() && invoice.value) {
          router.push({
            name: START_TRANSACTION,
          });
          return;
        }

        router.push({
          name: EXCHANGE_FORM,
        });
      } catch (error) {
        onLoaded();
        onError(error);
      }
    };

    // TODO: change to proper error handling
    const onError = error => {
      if (error.error instanceof DeviceIdentificationError) {
        router.push({
          name: CRITICAL_ERROR,
          query: {
            errorType: DEVICE_NOT_IDENTIFIED,
          },
        });
        return;
      }

      if (error.type === ERROR_TYPES.ERROR_BANNED) {
        store.dispatch('handleBannedUser');

        return;
      }

      if (error.type === ERROR_TYPES.ERROR_TOO_EARLY) {
        isTooManyRequestsErrorModalVisible.value = true;
        return;
      }

      let name = CRITICAL_ERROR;

      if (error.type === ERROR_TYPES.ERROR_SESSION_TIMEOUT) {
        name = HOME;
        store.dispatch('removeTokens');
      }

      router.push({ name });
    };

    const onLoaded = () => {
      // XXX: Close menu if opened, because opened menu can overlap auth screen
      if (isMenuOpened.value && !isAlreadyAuthenticated.value) {
        store.dispatch('toggleMenu');
      }

      setSelfPreloadState();
    };

    const closeWidget = () => {
      emitToParent('state', {
        state: 'closed',
      });
    };

    return {
      userEmail,
      themeConfig,
      isLoading,
      isTooManyRequestsErrorModalVisible,
      requestId,
      sourceInfo,
      locale,

      nSurePartnerId,

      onAuth,
      onError,
      onLoaded,
      closeWidget,
    };
  },
};
</script>

<style lang="scss" scoped>
.auth-flow-layout {
  padding-left: 0;
  padding-right: 0;
}
</style>
