<template>
  <span>
    <Title>{{
      $t('transaction-flow.steps.card-authorization.review.title')
    }}</Title>
    <Paragraph>{{
      $t('transaction-flow.steps.card-authorization.review.message')
    }}</Paragraph>
  </span>
</template>

<script>
import Paragraph from '@paybis/frontend-common-lib/src/components/paragraph';
import Title from '@paybis/frontend-common-lib/src/components/title';

export default {
  components: {
    Title,
    Paragraph,
  },
};
</script>
