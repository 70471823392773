import storage from '@/services/appPersistStorage';

export default () => {
  if (process.env.CI_ENVIRONMENT_NAME === 'prod') return '';

  try {
    const vuexStorage = JSON.parse(storage.getItem('vuex'));
    return vuexStorage?.selectedTheme || '';
  } catch (error) {
    return '';
  }
};
