import { getDecimalsForFormattedCryptoPrecision } from './getDecimalsForFormattedCryptoPrecision';

export class NumberFormatter {
  constructor(language) {
    this.language = language;
    this.formatOptions = {
      notation: 'standard',
    };
  }

  isCompactNumber() {
    this.formatOptions.notation = 'compact';
  }

  setMaxSignificantDigits(maxDigits) {
    this.formatOptions.maximumSignificantDigits = maxDigits;
  }

  setMaxFractionDigits(maxDigits) {
    this.formatOptions.maximumFractionDigits = maxDigits;
  }

  setCurrency(currency) {
    this.formatOptions.currency = currency;
    this.formatOptions.style = 'currency';
  }

  setUseGrouping(val) {
    this.formatOptions.useGrouping = val;
  }

  format(number) {
    const locale = `${this.language}-${this.language.toUpperCase()}`;
    const formatter = new Intl.NumberFormat(locale, this.formatOptions);

    return formatter.format(number);
  }
}

export function formatNumberForCrypto(number, language, cryptoCurrency) {
  const decimals = getDecimalsForFormattedCryptoPrecision(cryptoCurrency);

  const numberFormatter = new NumberFormatter(language);
  numberFormatter.setMaxFractionDigits(decimals);
  return numberFormatter.format(number);
}
