<template>
  <h2 class="sales-funnel-subtitle">
    <slot />
  </h2>
</template>

<script>
export default {
  name: 'Subtitle',
};
</script>

<style scoped lang="scss">
.sales-funnel-subtitle {
  font-weight: 700;
  font-size: rem(20);
  line-height: 1.4;
  text-align: center;
  margin-bottom: rem(4);

  @media screen and (min-width: $screen-lg-min) {
    margin-bottom: rem(8);
  }
}
</style>
