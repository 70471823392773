import ExchangeFormScreen from '@/views/ExchangeFormScreen/ExchangeFormScreen';
import { isNewExchangeFormFlowEnabled } from '@/services/exchangeFormFlow';

export const NAME = 'exchange-form';

export default {
  path: '/exchange-form',
  name: NAME,
  component: ExchangeFormScreen,
  meta: {
    shouldBePreloaded: true,
    requiresAuth: !isNewExchangeFormFlowEnabled(),
  },
};
