<template>
  <button
    class="btn"
    :class="{ 'is-coppied': isCopied }"
    :data-copy-label="$t('transaction-flow.data-copied')"
    @click="handleCopy"
  >
    <Icon
      v-if="hasCopyIcon"
      icon="copy"
    />
    {{ label }}
  </button>
</template>

<script>
import Icon from './icon';
import { copyToClipboard } from '../services/util';

export default {
  name: 'CopyableButton',

  components: {
    Icon,
  },

  props: {
    label: { type: String, required: false, default: '' },
    value: { type: String, required: true },
    hasCopyIcon: { type: Boolean, default: true },
  },

  data() {
    return {
      isCopied: false,
    };
  },

  methods: {
    handleCopy() {
      copyToClipboard(this.value);
      this.isCopied = true;

      setTimeout(() => {
        this.isCopied = false;
      }, 3000);
    },
  },
};
</script>

<style scoped lang="scss">
.btn {
  position: relative;

  &:before {
    content: "";
    display: block;
    opacity: 0;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
    border-radius: rem(2);
    font-weight: 700;
    font-size: rem(10);
    line-height: rem(20);
    text-align: center;
    color: $white;
    padding: 0 rem(6);
    position: absolute;
    top: rem(-16);
    left: 50%;
    transform: translateX(-50%);
    transition: opacity 0.2s linear;
  }

  &.is-coppied:before {
    content: attr(data-copy-label);
    background-color: $green;
    opacity: 0.8;
  }
}
</style>
