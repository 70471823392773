<template>
  <div
    class="progressbar"
    :style="styleObject"
  >
    <div class="progressbar__track">
      <div
        :style="{ width: progress + '%' }"
        class="progressbar__status"
      >
        {{ progress }}%
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AutoProgressBar',

  props: {
    maxWidth: { type: String, required: false, default: '' },
  },

  data() {
    return {
      progressIntervalId: null,
      progress: 0,
      styleObject: {
        maxWidth: this.maxWidth,
      },
    };
  },
  mounted() {
    this.init();
  },
  beforeUnmount() {
    clearInterval(this.progressIntervalId);
  },
  methods: {
    init() {
      this.progressIntervalId = setInterval(() => {
        if (this.progress < 100) {
          this.progress++;
        } else {
          this.progress = 0;
        }
      }, 50);
    },
  },
};
</script>

<style scoped lang="scss">
.progressbar {
  width: 100%;
  margin: auto;
  padding: rem(16) 0;

  &__track {
    position: relative;
    height: rem(6);
    border-radius: rem(12);
    background-color: #ebebeb;
  }

  &__status {
    font-size: 0;
    position: absolute;
    top: 0;
    left: 0;
    height: rem(6);
    border-radius: rem(12);
    background-color: $violet;
  }
}
</style>
