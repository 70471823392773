<template>
  <span>
    <Pictogram
      :src="
        require('@paybis/frontend-common-lib/src/assets/images/verification-block.svg')
      "
    />
    <Subtitle>{{ $t('transaction-flow.steps.fail.subtitle') }}</Subtitle>
    <p
      v-if="rejectMessage"
      class="sales-funnel-paragraph"
      v-html="formattedRejectMessage"
    />
    <Actions>
      <template #submit>
        <button
          :disabled="isLocked"
          class="btn btn-lg btn-secondary"
          :class="{ 'is-loading': isLocked }"
          @click="start()"
        >
          {{ $t('shared.navigation.button.start-a-new-transaction') }}
        </button>
      </template>
    </Actions>
  </span>
</template>

<script>
import Pictogram from '@paybis/frontend-common-lib/src/components/pictogram';
import Subtitle from '@paybis/frontend-common-lib/src/components/subtitle';
import { mapActions, mapGetters, mapMutations } from 'vuex';

import Actions from '../components/actions';
import { replaceNewLinesToTags } from '../utils/formatting';
import { useI18n } from 'vue-i18n';

export default {
  components: {
    Subtitle,
    Pictogram,
    Actions,
  },
  data() {
    const { t } = useI18n();

    return {
      t,
    };
  },
  computed: {
    ...mapGetters('transaction', ['rejectMessage', 'payout']),
    ...mapGetters(['isLocked']),
    formattedRejectMessage() {
      if (this.isTranslatableMessage()) {
        const translatedMessage = this.t(
          `transaction-flow.${this.rejectMessage}`,
          {
            cryptoName: this.payout.name,
          },
        );

        return replaceNewLinesToTags(translatedMessage);
      }

      return replaceNewLinesToTags(this.rejectMessage);
    },
  },
  methods: {
    ...mapMutations(['lock']),
    ...mapActions('transaction', ['repeatTransaction']),
    start() {
      this.lock();
      this.repeatTransaction();
    },
    isTranslatableMessage() {
      const translatable = [
        'Sorry, {cryptoName} is not supported in your region. Please select other crypto',
      ];

      return (
        this.payout.isCrypto()
        && translatable.findIndex(
          message => message === this.rejectMessage.trim(),
        ) > -1
      );
    },
  },
};
</script>

<style scoped lang="scss">
.sales-funnel-paragraph {
  font-size: rem(16);
  line-height: 1.5;
  text-align: center;

  &.is-small {
    font-size: rem(14);
    line-height: 1.29;
  }
}
</style>
