<template>
  <div>
    <div class="auth-app-header">
      {{ $t('authentication-app.forgot.heading') }}
    </div>

    <EmailInput
      :value="email"
      :error-message="errorMessage"
      :show-error="showErrorMessage"
      @valid="isSubmitDisabled = false"
      @invalid="isSubmitDisabled = true"
      @submit="handleSubmitEmail"
      @input="handleEmailInput"
    />

    <div class="auth-app-footer">
      <button
        :class="{ 'is-loading': isLoading }"
        :disabled="isSubmitDisabled"
        class="btn btn-primary btn-lg"
        @click="handleSubmitEmail"
      >
        {{ $t('authentication-app.buttons.reset') }}
      </button>
    </div>
  </div>
</template>

<script>
import EmailInput from '../../components/EmailInput';
import LegacyAuthService from '../../services/legacy-auth';
import { Session } from '../../models/session';
import { TooEarlyError } from '../../models/auth-error';
import { useI18n } from 'vue-i18n';

export default {
  name: 'ForgotPasswordView',

  components: {
    EmailInput,
  },

  props: {
    session: {
      type: Session,
      default: null,
    },
  },

  data() {
    const { t } = useI18n();

    return {
      t,
      email: '',
      errorMessage: '',
      isSubmitDisabled: true,
      isLoading: false,
      showErrorMessage: false,
    };
  },

  methods: {
    handleEmailInput(email) {
      this.email = email;
      this.showErrorMessage = false;
    },
    handleSubmitEmail() {
      if (this.isSubmitDisabled || this.isLoading) {
        this.showErrorMessage = this.isSubmitDisabled;
        return;
      }
      this.isLoading = true;
      LegacyAuthService.requestPasswordRecovery(this.email, this.$i18n.locale)
        .then(response => {
          this.$emit('success', new Session(response, this.email));
        }).catch(error => {
          this.isLoading = false;
          this.showErrorMessage = true;
          if (error instanceof TooEarlyError) {
            if (this.session !== null && this.session.isEmailSessionValid()) {
              return this.$emit('change-flow', 'otp.email');
            }
            return this.errorMessage = this.t('authentication-app.error.too-early', {
              canBeResentAfter: error.getResendTimer(),
            });
          }
          const { statusCode, isCritical } = error;
          if (typeof statusCode === 'undefined' || isCritical) {
            this.showErrorMessage = false;
            return this.$emit('critical-error', error);
          }

          this.errorMessage = this.t(`authentication-app.error.${statusCode}`);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../assets/scss/variables';
@import '../../assets/scss/btn';

.btn-link-grey{
  font-size: 0.875rem;
}
</style>
