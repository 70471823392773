import {
  AUTHENTICATED_EVENT,
  LOADED_EVENT,
  ERROR_EVENT,
  USER_BANNED_EVENT,
  MISSING_REFRESH_TOKEN_EVENT,
  SESSION_TIMEOUT_EVENT,
} from '@paybis/frontend-authentication-app/src/constants';

import '@paybis/frontend-authentication-app/src/assets/scss/main.scss';

export { default as TokenService } from '@paybis/frontend-authentication-app/src/services/token';
export { refreshExpiredToken, addAuthHeader } from '@paybis/frontend-authentication-app/src/network/interceptors/authInterceptors';
export { default as networkClient } from '@paybis/frontend-authentication-app/src/network/clients/authentication';
export { default as authService } from '@paybis/frontend-authentication-app/src/services/auth';
export { default } from '@paybis/frontend-authentication-app/src/App';

// XXX: this component should be moved into `@/components` after refactoring of auth-app
export { default as HeaderTitle } from '@paybis/frontend-authentication-app/src/components/HeaderTitle';

export const CONSTANTS = {
  AUTHENTICATED_EVENT,
  LOADED_EVENT,
  ERROR_EVENT,
  USER_BANNED_EVENT,
  MISSING_REFRESH_TOKEN_EVENT,
  SESSION_TIMEOUT_EVENT,
};
