import { canUseCookiesOrLocalStorage } from '@/utils/storageManager';
import { COOKIE_DISABLED } from '@/views/CriticalErrorScreen';
import { NAME as AUTH } from './auth';
import { NAME as EXCHANGE_FORM_PRELOADER } from './exchangeFormPreloader';
import { NAME as CRITICAL_ERROR } from './criticalError';
import { isNewExchangeFormFlowEnabled } from '@/services/exchangeFormFlow';

export const NAME = 'home';

export default {
  path: '/',
  name: NAME,
  redirect: () => {
    if (canUseCookiesOrLocalStorage()) {
      if (isNewExchangeFormFlowEnabled()) {
        return `/${EXCHANGE_FORM_PRELOADER}`;
      }

      return `/${AUTH}`;
    }

    return {
      path: `/${CRITICAL_ERROR}`,
      query: {
        errorType: COOKIE_DISABLED,
      },
    };
  },
};
