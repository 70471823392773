export const freezeBody = doFreeze => {
  if (typeof window === 'undefined') return;
  const { body } = document;

  const scrollbarWidth = getScrollBarWidth();

  body.classList.toggle('_freeze', doFreeze);

  if (doFreeze) {
    body.style.top = `-${window.scrollY}px`;

    body.style.position = 'fixed';

    if (document.dir === 'rtl') {
      body.style.paddingLeft = `${scrollbarWidth}px`;
    } else {
      body.style.paddingRight = `${scrollbarWidth}px`;
    }
  } else {
    const { top } = window.getComputedStyle(body);

    const scrollToY = parseInt(top || '0') * -1;

    body.removeAttribute('style');

    if (scrollToY) window.scrollTo(0, scrollToY);
  }
};

export const getScrollBarWidth = () => {
  const outer = document.createElement('div');

  outer.style.visibility = 'hidden';
  outer.style.overflow = 'scroll';
  outer.style.msOverflowStyle = 'scrollbar';

  document.body.appendChild(outer);

  const inner = document.createElement('div');

  outer.appendChild(inner);

  const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;

  outer.parentNode.removeChild(outer);

  return scrollbarWidth;
};
