import Api from '../api';

const apiUrl = `${process.env.VUE_APP_CARD_ENTRY_URL}/public/card-service/v1`; // eslint-disable-line no-undef

export default {
  getCardList(params = {}) {
    const url = `${apiUrl}/card`;
    return Api().get(url, { params });
  },
  createCard(cardParams) {
    const url = `${apiUrl}/card`;

    return Api().post(url, cardParams);
  },
  deleteCard(card) {
    const url = `${apiUrl}/card/${card.getId()}`;
    return Api().delete(url);
  },
  getCardStatus({ cardId }) {
    const url = `${apiUrl}/card/${cardId}/status`;
    return Api().get(url);
  },
  applePay({ paymentToken, cardholderName, invoice, billingAddress }) {
    const url = `${apiUrl}/card/apple-pay`;
    return Api().post(url, {
      payment_token: paymentToken,
      cardholder_name: cardholderName,
      invoice,
      billingAddress,
    });
  },
  googlePay({ paymentToken, cardholderName, invoice, billingAddress }) {
    const url = `${apiUrl}/card/google-pay`;
    return Api().post(url, {
      payment_token: paymentToken,
      cardholder_name: cardholderName,
      invoice,
      billingAddress,
    });
  },
};
