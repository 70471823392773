export const GLOBAL = {
  SET_LANGUAGE_SWITCHING: 'store.setLanguageSwitching',
  SET_TRANSLATION_STATUS: 'store.setTransaltionStatus',
  SET_ERROR: 'store.setLastError',
  SET_WIDGET_THEME_CONFIG: 'store.setWidgetThemeConfig',
  SET_IS_THEME_LOADING: 'store.setIsThemeLoading',
  SET_IS_INIT_COMPLETED: 'store.setIsInitCompleted',
  SET_LOCALE: 'store.setLocale',
  SET_TRANSLATION_RESOURCE: 'store.setTranslationResource',
  SET_PRODUCT_ID: 'store.setProductId',
  SET_IS_REQUEST_WITH_QUOTE: 'store.setIsRequestWithQuote',
  SET_CLIENT: 'store.setClient',
  SET_CLIENT_ID: 'store.setClientId',
  SET_WIDGET_CONFIG: 'store.setWidgetConfig',
  SET_NSURE: 'store.setNSure',
  SET_SIFT: 'store.setSift',
  SET_IS_SET_TOKEN: 'store.setIsSetToken',
  SET_IS_PAYMENT_PROCESSOR_NOT_FOUND: 'store.setIsPaymentProcessorNotFound',
  SHOW_TRANSACTION_CRYPTOCURRENCY_IS_NOT_AVAILABLE_POPUP: 'store.showTransactionCryptocurrencyIsNotAvailablePopup',
  SHOW_TRANSACTION_VALIDATION_ERROR_POPUP: 'store.showTransactionValidationErrorPopup',
  SHOW_CRYPTOCURRENCY_IS_NOT_AVAILABLE_POPUP: 'store.showCryptoCurrencyIsNotAvailablePopup',
  SHOW_COOLING_OFF_USER_POPUP: 'store.showCoolingOffUserPopup',
  HIDE_COOLING_OFF_USER_POPUP: 'store.hideCoolingOffUserPopup',
  SHOW_BANNED_USER_POPUP: 'store.showBannedUserPopup',
  TOGGLE_FCA_NOTIFICATION_POPUP: 'store.showFCANotificationPopup',
  TOGGLE_APPLE_PAY_NOTIFICATION_POPUP: 'store.toggleApplePayNotificationPopup',
  SET_CRYPTO_FLOW: 'store.setCryptoFlow',
  SET_API_VERSION: 'store.apiVersion',
  SET_ONE_CLICK_FLOW: 'store.setOneClickFlow',
  SET_ONE_TIME_TOKEN: 'store.setOneTimeToken',
  SET_SUCCESS_RETURN_URL: 'store.setSuccessReturnURL',
  SET_FAILURE_RETURN_URL: 'store.setFailureReturnURL',
};

export const MENU = {
  SET_STATE: 'menu.set.state',
  MENU_TOGGLE: 'menu.toggle',
  MENU_ITEM_OPEN: 'menu.item.open',
  MENU_LAST_ITEM: 'menu.item.last',
};

export const REQUEST = {
  SET_REQUEST: 'request.setRequest',
  SET_QUOTE_ID: 'request.setQuoteId',
  SET_IS_LOADING: 'request.setIsLoading',
  SET_EMAIL: 'request.setEmail',
};

export const TRANSACTION_HISTORY = {
  SET_ITEMS: 'transactionHistory.setItems',
  SET_IS_LOADING: 'transactionHistory.setIsLoading',
  SET_PAGINATION_META: 'transactionHistory.setPaginationMeta',
  CLEAN_STORE: 'transactionHistory.cleanStore',
};

export const CURRENCIES = {
  SET_CURRENCIES: 'currencies.setLoadedCurrencies',
  SET_CURRENCY_PAIRS: 'currencies.setCurrencyPairs',
  SET_IS_LOADING: 'currencies.setIsLoading',
  SET_FROM_DISPLAY_NAME: 'currencies.setFromDisplayName',
};

export const EXCHANGE_FORM = {
  SET_FROM_FIAT_AMOUNT: 'exchangeForm.setFromFiatAmount',
  SET_FROM_AMOUNT: 'exchangeForm.setFromAmount',
  SET_FROM_CURRENCY: 'exchangeForm.setFromCurrency',
  SET_TO_AMOUNT: 'exchangeForm.setToAmount',
  SET_TO_CURRENCY_CODE: 'exchangeForm.setToCurrencyCode',
  SET_DIRECTION: 'exchangeForm.setDirection',
  SET_IS_LOADING: 'exchangeForm.setIsLoading',
  SET_ERROR: 'exchangeForm.setErrorMessage',
  SET_IS_RECEIVED_AMOUNT: 'exchangeForm.isReceivedAmount',
  RESET_ERRORS: 'exchangeForm.resetErrors',
  SET_UNEXPECTED_QUOTE_ERROR: 'exchangeForm.unexpectedQuoteError',
};

export const PRELOAD = {
  SET_PRELOAD_STATE_FOR_AUTH: 'preload.setPreloadStateForAuth',
  SET_PRELOAD_STATE_FOR_FUNNEL: 'preload.setPreloadStateForFunnel',
  SET_PRELOAD_STATE_FOR_VERIFICATION: 'preload.setPreloadStateForVerification',
  START_PRELOAD_FOR_AUTH: 'preload.startPreloadAuth',
  START_PRELOAD_FOR_FUNNEL: 'preload.startPreloadFunnel',
  START_PRELOAD_FOR_VERIFICATION: 'preload.startPreloadVerification',
};

export const PROMO_CODE = {
  SET_PROMO_CODE: 'promoCode.setPromoCode',
  SET_CAPTURE: 'promoCode.setCapture',
  SET_ERROR: 'promoCode.setError',
};
