import { AddCard, CardItem } from '../../models/card';
import VerificationStatus from '../../models/card/verificationStatus';
import { SumsubApplicant } from '../../models/sumsub';
import CardClient from '../../services/clients/card-client';
import CardPaymentClient from '../../services/clients/card-payment-client';
import ProcessingClient from '../../services/clients/processing-client';
import SumsubClient from '../../services/clients/sumsub-client';
import Storage from '../../services/storage';
import { findCardType, removeWhitespaces } from '../../services/util';

export const cardStore = {
  namespaced: true,
  state: {
    cards: [],
    selectedCardId: 'new',
    addCard: new AddCard(),
    isChallenge: false,
    validationErrorMessagesMap: {
      'f8f7ace2-adcb-49bc-8862-08f8d11e0a3d': {
        message: 'Expiration date is invalid',
      },
      '8f055c39-2279-4a70-8082-9eeeaccbf81c': {
        message: 'Card is expired',
      },
      'fa662875-09dd-4104-a1fc-712ffdfc1147': {
        message: 'billing-address.error.state-required',
        propertyPath: 'billingAddress.state',
      },
      'd11f30ee-270b-4e4a-8f74-c798fcff94ee': {
        message: 'billing-address.error.country-not-valid',
        propertyPath: 'billingAddress.country',
      },
      '0df0fd18-aed2-4288-bc27-5f69800ca009': {
        message: 'billing-address.error.state-not-valid',
        propertyPath: 'billingAddress.state',
      },
    },
    isActivePayment: false,
    isApplePay: false,
    applePaySettings: {
      version: 3,
      paymentLabel: 'Paybis LTD',
      supportedNetworks: ['visa', 'masterCard'],
      merchantCapabilities: ['supports3DS'],
    },
    isGooglePay: false,
    googlePaySettings: {
      baseRequest: {
        apiVersion: 2,
        apiVersionMinor: 0,
      },
      tokenizationSpecification: {
        type: 'DIRECT',
        parameters: {
          protocolVersion: 'ECv2',
          publicKey: process.env.VUE_APP_GOOGLE_PAY_PUBLIC_KEY,
        },
      },
      baseCardPaymentMethod: {
        type: 'CARD',
        parameters: {
          allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
          allowedCardNetworks: ['VISA', 'MASTERCARD'],
          billingAddressRequired: true,
          billingAddressParameters: {
            format: 'FULL',
          },
        },
      },
    },
    isCardsLoaded: false,
  },
  getters: {
    cards: state => state.cards,
    addCard: state => state.addCard,
    selectedCardId: state => state.selectedCardId,
    selectedCard: state => {
      if (state.selectedCardId === state.addCard.getId()) {
        return state.addCard;
      }

      return (
        state.cards.find(card => state.selectedCardId === card.id)
        || state.addCard
      );
    },
    isChallenge: state => state.isChallenge,
    validationErrorMessagesMap: state => state.validationErrorMessagesMap,
    isActivePayment: state => state.isActivePayment,
    isApplePay: state => state.isApplePay,
    isGooglePay: state => state.isGooglePay,
    applePaySettings: state => state.applePaySettings,
    googlePaySettings: state => state.googlePaySettings,
    isCardsLoaded: state => state.isCardsLoaded,
  },
  mutations: {
    setCards(state, payload) {
      state.cards = payload;
    },
    setSelectedCardId(state, payload) {
      state.selectedCardId = payload;
    },
    startChallenge(state) {
      state.isChallenge = true;
    },
    completeChallenge(state) {
      state.isChallenge = false;
    },
    resetAddCard(state) {
      state.addCard = new AddCard();
    },
    setActivePayment(state, payload) {
      state.isActivePayment = payload;
    },
    setApplePay(state) {
      state.isApplePay = true;
    },
    clearApplePay(state) {
      state.isApplePay = false;
    },
    setGooglePay(state) {
      state.isGooglePay = true;
    },
    clearGooglePay(state) {
      state.isGooglePay = false;
    },
    setCardsLoaded(state) {
      state.isCardsLoaded = true;
    },
  },
  actions: {
    initialPayment({ getters }, requestParams) {
      return new Promise((resolve, reject) => {
        CardPaymentClient.initialPayment(requestParams)
          .then(({ data }) => {
            const {
              is_challenge_required: isChallengeRequired,
              challenge_info: challengeInfo,
              payment_id: paymentId,
            } = data;

            resolve({ isChallengeRequired, challengeInfo, paymentId });
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    getActivePayment({ commit, getters }, { invoice }) {
      return CardPaymentClient.getActivePaymentId({ invoice }).then(
        ({ data }) => {
          const { id: paymentId, card_id: cardId } = data;
          if (paymentId === null) {
            commit('setActivePayment', false);

            return;
          }
          const { addCard } = getters;
          addCard.setId(cardId);
          addCard.setPaymentId(paymentId);
          commit('setActivePayment', true);
        },
      );
    },
    getPaymentStatus({ getters, rootGetters }, { paymentId }) {
      return new Promise((resolve, reject) => CardPaymentClient.getPaymentStatus({ paymentId })
        .then(({ data }) => {
          const { status, error_code } = data;
          if (['approve_failed', '3ds_failed'].includes(status)) {
            getters.selectedCard.setError();
          }
          if (error_code) {
            const invoice = rootGetters['transaction/invoice'];
            Storage.put(`paymentErrorCode:${invoice}`, error_code);
            getters.selectedCard.setDeclineCardErrorCode(error_code);
          }

          resolve(data);
        })
        .catch(reject));
    },
    chooseFirstAvailableCard({ commit, getters }) {
      const { cards } = getters;
      if (cards.length > 0) {
        const validCard = cards.find(card => !card.isExpired());
        if (validCard) {
          // XXX: Choose first valid (non-expired) in the list
          commit('setSelectedCardId', validCard.getId());
        }
      } else {
        commit('setSelectedCardId', 'new');
      }

      return Promise.resolve();
    },
    getCards({ commit }, params) {
      return CardClient.getCardList(params).then(({ data }) => {
        commit('resetAddCard');
        commit(
          'setCards',
          data.map(card => {
            const cardItem = new CardItem(card);
            // Default visa when we can't recognize card type
            const cardType = findCardType(cardItem.getMaskedCardNumber()) || 'visa';
            cardItem.setType(cardType);

            return cardItem;
          }),
        );
        commit('setCardsLoaded');
      });
    },
    createCard(
      { getters, rootGetters },
      { currency, invoice, browserLanguage },
    ) {
      return new Promise((resolve, reject) => {
        const { addCard } = getters;
        const {
          cardNumber,
          expirationDate,
          save,
          cardholderName,
          cvv,
        } = addCard.getFormFields();

        const request = {
          [cardNumber.getName()]: removeWhitespaces(cardNumber.getValue()),
          [expirationDate.getName()]: expirationDate.getFormattedValue(),
          [save.getName()]: save.getValue(),
          currency,
          invoice,
          browser_language: browserLanguage,
        };

        request.billingAddress = addCard.billingAddress.getFormFieldValuesObject();

        const isNewFlow = rootGetters['transaction/transaction'].isNewFLow();
        const isBaseVerificationApproved = rootGetters[
          'transaction/baseVerification'
        ].isApproved();
        if (
          rootGetters['feature/cardholderNameFeature'].isEnabled()
          || (isNewFlow && !isBaseVerificationApproved)
        ) {
          request[cardholderName.getName()] = cardholderName.getValue();
        }

        CardClient.createCard(request)
          .then(response => {
            addCard.setCvv(cvv.getValue());
            resolve(response);
          })
          .catch(reject);
      });
    },
    getPreferrablePaymentProcessor(
      { commit, getters },
      { cardId, currency, invoice },
    ) {
      return new Promise((resolve, reject) => {
        CardPaymentClient.getPreferablePaymentProcessor({
          cardId,
          currency,
          invoice,
        })
          .then(({ data }) => {
            const { payment_processor_id: paymentProcessorId } = data;
            if (!paymentProcessorId) {
              return reject(
                new Error(
                  `Could not find any available payment processor for currency ${
                    currency}`,
                ),
              );
            }
            resolve({ paymentProcessorId });
          })
          .catch(reject);
      });
    },
    deleteCard({ commit, getters }, card) {
      return new Promise((resolve, reject) => {
        CardClient.deleteCard(card)
          .then(() => {
            const cards = getters.cards.filter(
              cardItem => card.getId() !== cardItem.getId(),
            );
            commit('setCards', cards);
            if (card.getId() === getters.selectedCardId) {
              return resolve(true);
            }
            resolve(false);
          })
          .catch(reject);
      });
    },
    setFormFieldErrors({ commit, getters }, errors) {
      const formFields = getters.addCard.getFormFields();
      const billingAddressFormFields = getters.addCard.billingAddress.getFormFields();

      getters.addCard.resetErrors();

      errors.forEach(error => {
        const validationErrorMessagesMap = getters.validationErrorMessagesMap[error.code];

        const message = (validationErrorMessagesMap && validationErrorMessagesMap.message)
          || error.message;
        const propertyPath = (validationErrorMessagesMap
            && validationErrorMessagesMap.propertyPath)
          || error.property_path;

        const field = formFields[propertyPath];
        if (field) {
          field.setErrorMessage(message);
        }

        if (propertyPath.includes('billingAddress')) {
          const propertyPathBillingAddress = propertyPath.replace(
            'billingAddress.',
            '',
          );
          const billingAddressField = billingAddressFormFields[propertyPathBillingAddress];
          if (billingAddressField) {
            billingAddressField.setErrorMessage(message);
          }
        }
      });
    },
  },
};

export const cardSumsubStore = {
  namespaced: true,
  state: {
    sumsubApplicant: new SumsubApplicant({}),
    sumsubVerificationPending: false,
    initiatingApplicantRequest: false,
  },
  getters: {
    sumsubApplicant: state => {
      const sumsubApplicantData = Storage.get('sumsubApplicantData');
      if (sumsubApplicantData && !state.sumsubApplicant.hasEmail()) {
        return new SumsubApplicant(JSON.parse(sumsubApplicantData));
      }

      return state.sumsubApplicant;
    },
    isSumsubVerificationPending: state => state.sumsubVerificationPending,
    initiatingApplicantRequest: state => state.initiatingApplicantRequest,
  },
  mutations: {
    setSumsubApplicant(state, payload) {
      state.sumsubApplicant = new SumsubApplicant(payload);
      Storage.put('sumsubApplicantData', JSON.stringify(payload));
    },
    sumsubVerificationPending(state) {
      state.sumsubVerificationPending = true;
    },
    sumsubVerificationCompleted(state) {
      state.sumsubVerificationPending = false;
    },
    setInitiatingApplicantRequest(state, payload) {
      state.initiatingApplicantRequest = payload;
    },
  },
  actions: {
    getSumsubApplicant({ commit, dispatch, getters }) {
      if (getters.initiatingApplicantRequest) {
        return;
      }

      commit('setInitiatingApplicantRequest', true);

      return new Promise((resolve, reject) => {
        ProcessingClient.getApplicantData()
          .then(({ data }) => {
            commit('setSumsubApplicant', data);
            if (!getters.sumsubApplicant.hasApplicantId()) {
              return ProcessingClient.requestLiveness().then(() => {
                dispatch('transaction/init', null, { root: true });
                return resolve();
              });
            }
            return resolve();
          })
          .catch(error => {
            if (!(error instanceof Error)) {
              return;
            }
            reject(error);
          });
      });
    },
    getSumsubConfig({ commit, getters }, { cardId, invoice }) {
      return new Promise((resolve, reject) => {
        const { sumsubApplicant } = getters;
        SumsubClient.registerVerification({
          applicant_id: sumsubApplicant.getApplicantId(),
          request_selfie: sumsubApplicant.getRequestSelfie(),
          card_id: cardId,
          external_user_id: sumsubApplicant.getExternalUser(),
          invoice,
        })
          .then(({ data }) => {
            const {
              access_token: accessToken,
              verification_flow_name: verificationFlowName,
              verification_status: verificationStatus,
            } = data;

            return resolve({
              accessToken,
              email: sumsubApplicant.getEmail(),
              verificationFlowName,
              verificationStatus: new VerificationStatus(verificationStatus),
            });
          })
          .catch(reject);
      });
    },
    cancelSumsubVerification({ getters }, { cardId }) {
      return new Promise((resolve, reject) => {
        SumsubClient.cancelVerification({ cardId })
          .then(resolve)
          .catch(reject);
      });
    },
  },
};
