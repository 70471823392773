<template>
  <div class="invoice-details-header">
    {{ $t('widget.title.transaction-details') }}
  </div>

  <div class="invoice-details-exchange-rate">
    <div class="invoice-details-value-to">
      {{ amountFromFormatted }} {{ currencyFrom }}
    </div>

    <div class="invoice-details-arrow">
      <span class="svg-icon icon-arrow-down-bold" />
    </div>

    <div class="invoice-details-value-from">
      {{ currencyToLabel }}
    </div>

    <span>{{ payoutMethod }}</span>
  </div>

  <div class="invoice-details__pay buttons-group">
    <button
      class="btn btn-widget btn-primary"
      :class="{ 'is-loading': isPaymentInitiating }"
      :disabled="isPaymentInitiating"
      @click="onPay"
    >
      {{ $t('transaction-flow.payment-details.card-details.pay') }}
    </button>
  </div>

  <div class="invoice-details__info invoice-info">
    <div class="invoice-info__column">
      <div class="invoice-info-item">
        <div class="invoice-info-item__title">
          {{ $t('widget.transaction-details.payment-method') }}
        </div>
        <div class="invoice-info-item__value">
          {{ paymentMethod }} {{ currencyFrom }}
        </div>
      </div>
      <div class="invoice-info-item">
        <div class="invoice-info-item__title">
          {{ $t('widget.transaction-details.original-amount-spent') }}
        </div>
        <div class="invoice-info-item__value">
          {{ amountFromFormatted }} {{ currencyFrom }}
        </div>
      </div>
      <div class="invoice-info-item">
        <div class="invoice-info-item__title">
          {{ $t('widget.transaction-details.amount-received') }}
        </div>
        <div class="invoice-info-item__value">
          {{ currencyToLabel }}
        </div>
      </div>
    </div>

    <div class="invoice-info__column">
      <div class="invoice-info-item">
        <div class="invoice-info-item__title">
          {{ $t('widget.transaction-details.payout-method') }}
        </div>
        <div class="invoice-info-item__value">
          {{ payoutMethod }}
        </div>
      </div>
      <div
        v-if="paidToLabel"
        class="invoice-info-item"
      >
        <div class="invoice-info-item__title">
          {{ $t('widget.transaction-details.paid-to') }}
        </div>
        <div class="invoice-info-item__value">
          {{ paidToLabel }}
        </div>
      </div>
    </div>
  </div>

  <div class="invoice-details-cancel">
    <button
      class="btn-link btn-link-grey"
      @click="toggleTransactionCancellationPopup()"
    >
      {{ $t('shared.navigation.button.cancel') }}
    </button>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import { emitToParent } from '@/services/widgetApiEvents';
import { isRedirectMode, depositCallbackUrl } from '@/services/frictionlessFlow';
import { isInsideIframe } from '@/utils/isInsideIframe';
import { useI18n } from 'vue-i18n';

export default {
  name: 'InvoiceDetails',

  data() {
    const { t } = useI18n();

    return {
      t,
      payTimeOut: null,
    };
  },

  beforeDestroy() {
   if (this.payTimeOut) clearTimeout(this.payTimeOut);
  },

  computed: {
    ...mapGetters('external', ['funnelInvoiceId', 'funnelPayout', 'funnelPayment', 'payoutCardMaskedByAccount']),
    ...mapGetters('transaction', ['payout', 'flowDetails']),
    ...mapGetters('widgetQuote', ['fees', 'amountFrom', 'amountToCropped', 'isQuoteReady', 'currencyCodeFrom', 'currencyTo']),
    ...mapGetters('exchangeForm', ['fromCurrency', 'toCurrencyCode', 'getCurrencyBy']),
    ...mapGetters('v2/frictionless', ['isPaymentInitiating']),
    ...mapGetters(['requestId']),

    amountTo() {
      return this.amountToCropped || '0';
    },

    amountFromFormatted() {
      const amount = Number.parseFloat(this.amountFrom);

      if (!amount) {
        return '0.00';
      }

      const signCount = amount.toString().includes('.') ? amount.toString().split('.').pop().length : 2;

      return amount.toFixed(signCount);
    },

    currencyFrom() {
      return this.isQuoteReady ? this.currencyCodeFrom : this.fromCurrency;
    },

    currencyByCurrencyTo() {
      let currency = this.currencyTo;

      if (!this.isQuoteReady) {
        currency = this.toCurrencyCode;
      }

      return this.getCurrencyBy(currency);
    },

    blockchainName() {
      const sourceTitle = this.t(`currencies.${this.currencyByCurrencyTo}`);
      const blockchainMatch = sourceTitle.match(/\((.+)\)/);

      // Get 1st capture group instead of complete match
      if (Array.isArray(blockchainMatch) && blockchainMatch[1]) {
        return blockchainMatch[1];
      }

      return null;
    },

    currencyTitle() {
      let currencyTitle = this.currencyByCurrencyTo || '';

      if (this.blockchainName) {
        currencyTitle += ` (${this.blockchainName})`;
      }

      return currencyTitle;
    },

    currencyToLabel() {
      return `${this.amountTo} ${this.currencyTitle}`;
    },

    payoutMethod() {
      return this.funnelPayout.getName();
    },

    paymentMethod() {
      return this.funnelPayment.getName();
    },

    paidToLabel() {
      const isCreditCard = this.funnelPayout.getSlug().indexOf('credit-card') !== -1;

      if (isCreditCard) {
        return this.payoutCardMaskedByAccount ?? this.t('widget.transaction-details.paid-to-new-card');
      }

      return this.funnelPayout.getAccount();
    },
  },

  methods: {
    ...mapMutations('transaction', ['toggleTransactionCancellationPopup']),
    ...mapActions('v2/frictionless', ['initiateFrictionlessPayment']),

    async onPay() {
      const data = await this.initiateFrictionlessPayment(this.requestId);
      if (data && !data.isCryptoPaymentCredentialsReady) {
        this.payTimeOut = setTimeout(() => {
          this.onPay();
        }, 5 * 1000);
        return;
      }

      if (isRedirectMode.value && depositCallbackUrl.value) {
        if (isInsideIframe()) {
          emitToParent('payment-redirect', depositCallbackUrl.value);
        } else {
          window.location.assign(depositCallbackUrl.value);
        }
        return;
      }

      emitToParent('payment-initiated', {});
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/blocks/buttons-group";

.invoice-details {
  &-header {
    text-align: center;

    font-size: rem(28);
    line-height: 1;
    font-weight: 700;
    margin-bottom: 16px;

    @media screen and (max-width: $tablet-max) {
      font-size: 1.5rem;
      text-align: left;
      margin-bottom: 1.25rem;
      margin-top: 10px;
    }
  }

  &-exchange-rate {
    margin-top: rem(34);
    margin-bottom: rem(36);
    text-align: center;
  }

  &-value-to {
    font-size: rem(18);
    font-weight: 700;
    line-height: rem(24);
    color: #000;
  }

  &-value-from {
    font-size: rem(18);
    font-weight: 700;
    line-height: rem(24);
    color: #000;
  }

  &-arrow {
    padding: 10px 0;

    .svg-icon {
      display: inline-block;
      width: 16px;
      height: 16px;
      margin-right: 5px;
      position: relative;
      top: 2px;
    }
  }

  &__pay {
    text-align: center;

    .btn {
      min-width: 162px;
    }
  }

  &-cancel {
    text-align: center;
    font-weight: 700;
    font-size: rem(18);

    .btn-link,
    .btn-link:hover {
      border-bottom: none;
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    border-top: 1px solid rgba(0, 0, 0, 0.06);
    margin-bottom: rem(50);
    margin-top: rem(30);
    padding: rem(30) 0 0 0;

    @media (min-width: $tablet-min) {
      margin-top: rem(36);
      padding-top: rem(36);
      flex-direction: row;
    }
  }
}

.invoice-info {
  &__column {
    width: 100%;

    &:not(:first-child) {
      margin-top: rem(24);
    }

    @media (min-width: $tablet-min) {
      width: 50%;

      &:not(:first-child) {
        margin-top: 0;
      }
    }
  }
}

.invoice-info-item {
  &:not(:first-child) {
    margin-top: rem(24);
  }

  &__title {
    font-size: rem(12);
    font-weight: 700;
    text-transform: uppercase;
  }

  &__value {
    margin-top: rem(4);
    font-size: rem(15);
  }
}

@media (min-width: $laptop-min) and (max-height: $widget-height-sm) {
  .invoice-details {
    &-header {
      font-size: 3.6vh;
      line-height: 4.16vh;
      margin-bottom: 4vh;
    }
  }
}

@media (min-width: $desktop-xlg-min) and (max-height: $widget-height-xlg) {
  .invoice-details {
    &-header {
      font-size: 3.7vh;
      line-height: 4.18vh;
      margin-bottom: 7.31vh;
    }
  }
}

@media (min-width: $desktop-xxlg-min) and (max-height: $widget-height-xxlg) {
  .invoice-details {
    &-header {
      font-size: 3.71vh;
      line-height: 4.24vh;
      margin-bottom: 7.42vh;
    }
  }
}
</style>
