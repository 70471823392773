<template>
  <div class="payout-waiting">
    <Title>{{ $t('transaction-flow.steps.payout-waiting.title') }}</Title>
    <Paragraph v-html="$t(payoutMessage)" />
    <FunnelLoader />
    <Paragraph>
      {{ $t('transaction-flow.steps.payout-waiting.paragraph2') }}
    </Paragraph>
  </div>
</template>

<script>
import Paragraph from '@paybis/frontend-common-lib/src/components/paragraph';
import Title from '@paybis/frontend-common-lib/src/components/title';
import { mapGetters } from 'vuex';
import FunnelLoader from '../components/funnel-loader';

export default {
  components: {
    FunnelLoader,
    Title,
    Paragraph,
  },
  computed: {
    ...mapGetters('transaction', ['payout']),
    payoutMessage() {
      return (this.payout.isBank() || this.payout.isBankSwift())
        ? 'transaction-flow.steps.payout-waiting.bank.paragraph'
        : 'transaction-flow.steps.payout-waiting.paragraph';
    },
  },
};
</script>
