import WidgetService from '@/services/widget';
import { TRANSACTION_HISTORY } from './mutations';

import { groupItemsByCreatedAt } from '@/utils/transactionHistory';

const PAGE_SIZE = 10;

export const transactionHistory = {
  namespaced: true,

  state: {
    items: {},
    isLoading: false,
    paginationMeta: undefined,
  },

  getters: {
    groupedItems: ({ items }) => groupItemsByCreatedAt(Object.values(items)),
    sortedByDay: (state, { groupedItems }) => {
      const unsortedItems = Object.entries(groupedItems).map(([timestamp, items]) => ({
        dayTimestamp: timestamp, items,
      }));
      return unsortedItems.sort((left, right) => right.dayTimestamp - left.dayTimestamp);
    },
    isLoading: ({ isLoading }) => isLoading,
    isInitiallyLoading: ({ paginationMeta, isLoading }) => typeof paginationMeta === 'undefined' && isLoading,
    hasItems: ({ items }) => Object.keys(items).length > 0,
    canLoadMore: ({ paginationMeta }) => (
      paginationMeta.page * paginationMeta.pageSize < paginationMeta.total
    ),
    isAvailable: (state, getters, rootState, rootGetters) => rootGetters.jwtTokenExists(),
    // HACK: get product id from translation path
    // TODO: Remove when transaction list will support business account product uuid
    businessAccountProductId: (state, getters, rootState, rootGetters) => rootGetters.translationResource.replace(/\D/g, ''),
  },

  mutations: {
    [TRANSACTION_HISTORY.SET_ITEMS]: (state, { data, isMerge }) => {
      state.items = data.reduce((acc, item) => ({
        ...acc,
        [item.invoice]: item,
      }), isMerge ? [] : state.items);
    },

    [TRANSACTION_HISTORY.SET_IS_LOADING]: (state, isLoading) => {
      state.isLoading = isLoading;
    },

    [TRANSACTION_HISTORY.SET_PAGINATION_META]: (state, meta) => {
      state.paginationMeta = meta;
    },

    [TRANSACTION_HISTORY.CLEAN_STORE]: state => {
      state.items = {};
      state.isLoading = false;
      state.paginationMeta = undefined;
    },
  },

  actions: {
    async getUserTransactions({ state, commit, getters }, { search, nextLoad }) {
      commit(TRANSACTION_HISTORY.SET_IS_LOADING, true);

      const previousPage = state.paginationMeta?.page ?? 0;
      const requestMeta = {
        pageSize: state.paginationMeta?.pageSize ?? PAGE_SIZE,
        page: nextLoad ? previousPage + 1 : 1,
      };
      const params = {
        search,
        productType: 'widget',
        businessAccountProductId: getters.businessAccountProductId,
        ...requestMeta,
      };
      const { data, meta } = await WidgetService.getTransactionHistory(params);

      commit(TRANSACTION_HISTORY.SET_ITEMS, { data, isMerge: !nextLoad });
      commit(TRANSACTION_HISTORY.SET_PAGINATION_META, meta.pagination);
      commit(TRANSACTION_HISTORY.SET_IS_LOADING, false);
    },

    clean({ commit }) {
      commit(TRANSACTION_HISTORY.CLEAN_STORE);
    },
  },
};
