<template>
  <span>
    <Pictogram
      :src="
        require('@paybis/frontend-common-lib/src/assets/images/verification-block.svg')
      "
    />
    <Subtitle>{{
      $t('transaction-flow.steps.payment-error.subtitle')
    }}</Subtitle>
    <Paragraph v-html="$t('transaction-flow.steps.payment-error.message')" />
    <Actions>
      <template #submit>
        <button
          :disabled="isLocked"
          class="btn btn-lg btn-secondary"
          :class="{ 'is-loading': isLocked }"
          @click="navigate()"
        >
          {{ $t('shared.navigation.button.go-to-main-page') }}
        </button>
      </template>
    </Actions>
  </span>
</template>

<script>
import Paragraph from '@paybis/frontend-common-lib/src/components/paragraph';
import Pictogram from '@paybis/frontend-common-lib/src/components/pictogram';
import Subtitle from '@paybis/frontend-common-lib/src/components/subtitle';
import UrlGenerator from '@paybis/frontend-common-lib/src/services/url';
import { mapGetters, mapMutations } from 'vuex';

import Actions from '../components/actions';

export default {
  components: {
    Subtitle,
    Paragraph,
    Pictogram,
    Actions,
  },
  computed: {
    ...mapGetters(['isLocked']),
  },
  methods: {
    ...mapMutations(['lock', 'clearLocks']),
    navigate() {
      this.lock();
      window.location = UrlGenerator.generate('/');
    },
  },
  mounted() {
    setTimeout(() => {
      this.clearLocks();
    });
  },
};
</script>
