import { reactive, computed } from 'vue';
import { useStore } from 'vuex';

const redirectToTransactionState = reactive({
  canRedirectToTransaction: true,
});

export const usePaymentMethodRouting = () => {
  const store = useStore();

  const isQuoteAmountValid = computed(() => {
    const paymentMethod = store.getters['v2/paymentMethods/paymentMethod'];
    if (!paymentMethod.quote) {
      // Early return
      return false;
    }

    const amountFrom = parseFloat(paymentMethod.quote.amountFromAmount);
    const minAmount = parseFloat(paymentMethod.minAmount.amount);
    const maxAmount = parseFloat(paymentMethod.maxAmount.amount);

    if (Number.isNaN(amountFrom) || Number.isNaN(minAmount) || Number.isNaN(maxAmount)) {
      return false;
    }

    if (amountFrom < minAmount || (maxAmount > 0 && amountFrom > maxAmount)) {
      redirectToTransactionState.canRedirectToTransaction = false;
      return false;
    }

    return true;
  });

  // XXX: If quote locked by partner
  const isQuoteLocked = computed(() => store.getters['widgetQuote/isQuoteLocked']);

  return {
    canRedirectToTransaction: computed(() => isQuoteAmountValid.value
      && isQuoteLocked.value && redirectToTransactionState.canRedirectToTransaction),
    setRedirectToTransaction: state => {
      redirectToTransactionState.canRedirectToTransaction = state;
    },
  };
};
