<template>
  <Modal
    size="sm"
    :show="show"
    @close="toggleTransactionCancellationPopup()"
  >
    <div class="transaction-popup__inner">
      <div class="transaction-popup__title">
        {{ $t('transaction-flow.cancel-popup.title') }}
      </div>
      <div class="transaction-popup__msg">
        {{ $t('transaction-flow.cancel-popup.message') }}
      </div>
      <div class="transaction-popup__actions">
        <button
          class="btn btn-lg"
          @click="toggleTransactionCancellationPopup"
        >
          {{ $t('transaction-flow.cancel-popup.no') }}
        </button>
        <button
          class="btn btn-lg btn-primary"
          @click="goCancel"
        >
          {{ $t('transaction-flow.cancel-popup.yes') }}
        </button>
      </div>
    </div>
  </Modal>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';

import Modal from '@paybis/frontend-common-lib/src/components/modal';

export default {
  name: 'CancellationPopup',

  components: {
    Modal,
  },

  props: {
    show: { type: Boolean, required: false, default: false },
  },

  methods: {
    ...mapMutations(['lock', 'unlock']),
    ...mapMutations('transaction', ['toggleTransactionCancellationPopup']),
    ...mapActions('transaction', ['cancelTransaction']),
    goCancel() {
      this.toggleTransactionCancellationPopup();
      this.lock();
      this.cancelTransaction().finally(() => this.unlock());
    },
  },
};
</script>

<style scoped lang="scss">
.transaction-popup {
  &__inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: rem(34);
  }

  &__actions {
    display: flex;
    flex-direction: row;
    justify-content: center;

    button {
      margin: rem(5);
    }
  }

  &__title {
    font-weight: 700;
    font-size: rem(18);
    line-height: rem(24);
    text-align: center;
  }

  &__msg {
    margin-top: rem(4);
    text-align: center;
    font-size: rem(14);
    line-height: rem(18);
    margin: rem(20);
  }
}
</style>
