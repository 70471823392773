<template>
  <div
    class="tabpanel"
    role="tabpanel"
  >
    <div v-if="selected">
      <slot />
    </div>
  </div>
</template>

<script>
// TODO should be removed in favor of upcoming UI kit components
export default {
  name: 'Tab',

  props: {
    title: {
      type: String, required: true,
    },
    id: {
      type: String, required: true,
    },
    icon: {
      type: String, required: false, default: '',
    },
    isSelected: {
      type: String, required: false, default: '',
    },
    isDisabled: {
      type: Boolean, required: false, default: false,
    },
    locator: {
      type: String, required: false, default: null,
    },
  },

  computed: {
    selected() {
      return this.isSelected === this.id;
    },
  },
};
</script>
