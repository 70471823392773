export default class DDCBridgerPayIFrameFactory {
  constructor() {
    this.iframeName = 'ddc';
  }

  build() {
    const name = this.iframeName;
    const element = document.body.querySelector(`iframe[name="${name}"]`);
    if (element) {
      element.parentNode.removeChild(element);
    }

    return {
      iframe: this.createIframe(),
    };
  }

  createIframe() {
    const iframe = document.createElement('iframe');
    iframe.setAttribute('name', this.iframeName);
    iframe.setAttribute('height', 1);
    iframe.setAttribute('width', 1);
    iframe.setAttribute('style', 'display: none;');

    return iframe;
  }
}
