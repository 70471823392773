<template>
  <div>
    <Title data-testid="payment-title">
      {{ $t('transaction-flow.steps.payment-details.title') }}
    </Title>
    <Content>
      <ApplePayOneClickForm
        @pay="onPay"
        @error="onError"
      />
      <RefundDisclaimer />
    </Content>
    <ApplePayOneClickFormErrorPopup
      :show="isShowPopupError"
      @close="onPopupClose"
    />
  </div>
</template>

<script setup>
import Title from '@paybis/frontend-common-lib/src/components/title';
import Content from '@paybis/frontend-common-lib/src/components/content';
import RefundDisclaimer from '@paybis/frontend-transaction-funnnel-app/src/components/refund-disclaimer.vue';

import { useRoutePreloader } from '@/router/PreloadRouterView';
import { computed, onMounted, ref, watch } from 'vue';
import ApplePayOneClickForm from '@/components/ApplePayOneClickForm/ApplePayOneClickForm.vue';
import ApplePayOneClickFormErrorPopup from '@/components/ApplePayOneClickForm/ApplePayOneClickFormErrorPopup.vue';
import { AUTH, HOME } from '@/router/routes';
import { useRouter } from 'vue-router';
import { isApplePayOneClickFlow, useApplePayOneClickStore } from '@/services/applePayOneClickService';
import { refreshQuoteSubscriber } from '@/store/widgetQuote';
import store from '@/store';

const { setSelfPreloadState } = useRoutePreloader();
const router = useRouter();
const applePayOneClickStore = useApplePayOneClickStore();
const isShowPopupError = ref(false);

const isQuoteReady = computed(() => {
  return store.getters['widgetQuote/isQuoteReady'];
});

onMounted(() => {
  refreshQuoteSubscriber(store);
});

watch(isQuoteReady, value => {
  if (!value) return;
  if (isApplePayOneClickFlow()) {
    setSelfPreloadState();
  } else {
    router.push({
      name: HOME,
    });
  }
}, { immediate: true });

async function onPay(data) {
  applePayOneClickStore.setPaymentData(data);
  router.push({ name: AUTH });
}

function onError() {
  isShowPopupError.value = true;
}

function onPopupClose() {
  isShowPopupError.value = false;
}
</script>

<style lang="scss">
</style>
