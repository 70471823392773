<template>
  <div class="cryptocurrency-is-not-available">
    <div class="cryptocurrency-is-not-available__inner">
      <i class="cryptocurrency-is-not-available__icon svg-icon icon-cancelled" />

      <span class="cryptocurrency-is-not-available__title">
        {{ t('widget.cryptocurrency-is-not-available.title') }}
      </span>

      <span class="cryptocurrency-is-not-available__text">
        {{ t('widget.cryptocurrency-is-not-available.text.single', { currencyName: restrictedWalletAsset }) }}
      </span>

      <CloseWidgetButton
        mode="modal"
        @click="$emit('confirm')"
      />
    </div>

    <SupportChat
      :chat-key="liveChatKey"
      :lang="currentLang"
    />
  </div>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';

import SupportChat from '@/components/SupportChat';
import CloseWidgetButton from '@/components/CloseWidgetButton';
import { useI18n } from 'vue-i18n';

export default {
  name: 'CryptocurrencyIsNotAvailableModal',

  components: { CloseWidgetButton, SupportChat },

  emits: ['confirm'],

  setup() {
    const store = useStore();
    const { t } = useI18n();
    const liveChatKey = computed(() => store.getters.liveChatKey);
    const currentLang = computed(() => store.getters.locale);

    const restrictedWalletAsset = computed(() => store.getters.cryptoWallets[0].cryptoAssetId);

    return {
      t,
      liveChatKey,
      currentLang,

      restrictedWalletAsset,
    };
  },
};
</script>

<style lang="scss" scoped>
.cryptocurrency-is-not-available {
  background-color: $white;
  max-width: 480px;
  border-radius: 6px;

  &__inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 56px 36px;
  }

  &__icon {
    width: 75px;
    height: 75px;
    margin-bottom: 16px;
  }

  &__title, &__text {
    display: block;
    margin-bottom: 32px;
    line-height: 1.4;
    text-align: center;
  }

  &__title {
    font-weight: 700;
    font-size: 22px;
  }

  &__text {
    font-family: $font-family;
    font-size: 18px;
  }
}
</style>
