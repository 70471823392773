<template>
  <span>
    <Title data-testid="payment-title">{{ $t('transaction-flow.steps.payment-details.title') }}</Title>
    <Paragraph>
      {{
        $t('transaction-flow.steps.payment-details.paragraph-crypto', {
          moneyService,
          cryptoCode,
        })
      }}
    </Paragraph>
    <div
      class="crypto-address"
      :class="{ 'is-loading': loading }"
    >
      <FormRow>
        <BankDetails :loading="loading">
          <CopyableItem
            :label="$t('transaction-flow.transaction_details.crypto.address')"
            :value="crypto.getAddress()"
            :loading="loading"
          >
            <Icon
              slot="action"
              icon="qr-code"
              @click.native="showQr = true"
            />
          </CopyableItem>
          <CopyableItem
            :label="$t('transaction-flow.transaction_details.crypto.amount')"
            :value="crypto.getDepositAmount()"
            :placeholder="amountPlaceholder"
            :loading="loading"
          />
          <ManualItem
            icon="phone"
            :description="
              $t('transaction-flow.manual.scan-qr', {
                moneyService,
                cryptoCode,
              })
            "
          >
            <div
              class="manual-qr"
              :class="{ 'is-loading': loading }"
            >
              <QrcodeVue
                v-if="!loading"
                :value="crypto.getAddress()"
                size="80"
              />
            </div>
          </ManualItem>
        </BankDetails>
        <ReceiveCryptoPopup
          :address-link="crypto.getAddress()"
          :show="showQr"
          @close="showQr = false"
        />
      </FormRow>
      <FormRow
        double-margin
        is-centered
      >
        <Paragraph class="mt-8 mb-8">
          {{ $t('transaction-flow.steps.payment-details.scanning') }}
          <span class="dots" />
        </Paragraph>
      </FormRow>
      <FormRow double-margin>
        <Paragraph>
          {{ $t('transaction-flow.steps.payment-details.paragraph3') }}
        </Paragraph>
      </FormRow>
    </div>
    <Actions />
  </span>
</template>

<script>
import FormRow from '@paybis/frontend-common-lib/src/components/form/form-row';
import Paragraph from '@paybis/frontend-common-lib/src/components/paragraph';
import Title from '@paybis/frontend-common-lib/src/components/title';
import CopyableItem from '@paybis/frontend-common-lib/src/components/copyable-item';
import Icon from '@paybis/frontend-common-lib/src/components/icon';
import CryptoViewModel from '@paybis/frontend-transaction-funnnel-app/src/view-models/crypto';

import Actions from '@paybis/frontend-transaction-funnnel-app/src/components/actions';
import BankDetails from '@paybis/frontend-transaction-funnnel-app/src/components/bank-details';
import ReceiveCryptoPopup from '@paybis/frontend-transaction-funnnel-app/src/components/receive-crypto-popup';
import ManualItem from '@paybis/frontend-transaction-funnnel-app/src/components/manual-item';
import QrcodeVue from 'qrcode.vue';
import eventBus from '@paybis/frontend-transaction-funnnel-app/src/helpers/eventBus';

export default {
  components: {
    CopyableItem,
    Icon,
    Title,
    Paragraph,
    FormRow,
    BankDetails,
    Actions,
    ManualItem,
    QrcodeVue,
    ReceiveCryptoPopup,
  },
  props: {
    payment: { type: Object, required: true },
  },
  data() {
    return {
      showQr: false,
    };
  },
  computed: {
    crypto() {
      return new CryptoViewModel(this.details);
    },
    loading() {
      return !this.crypto.hasAddress();
    },
    moneyService() {
      return this.payment.name;
    },
    cryptoCode() {
      return this.payment.amount.currency;
    },
    details() {
      return this.payment.getDetails();
    },
    amountPlaceholder() {
      return `${this.crypto.getDepositAmount()} ${this.cryptoCode}`;
    },
  },
  created() {
    eventBus.emit('transaction-flow.payment-details.loaded');
  },
};
</script>
<style lang="scss" scoped>
.crypto-address {
  min-height: rem(80);

  &.is-loading:after {
    top: 0;
  }
}

.dots {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;

  &::before {
    content: '...';
    display: inline-block;
    animation: dots 2s infinite steps(3, start);
    transform: translateX(-100%);
  }

  [dir='rtl'] &::before {
    transform: translateX(100%);
  }
}

@keyframes dots {
  to {
    transform: translateX(0);
  }
}
</style>
