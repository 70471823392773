<template>
  <div
    class="payment-method-item"
    @click="emitItemSelect"
  >
    <div class="payment-method-item__logo">
      <div class="item-icon">
        <img
          class="svg-icon"
          :src="iconPath"
        >
      </div>
    </div>
    <div class="payment-method-item__data">
      <div class="payment-method-item__header">
        <div class="payment-method-item__name text-bold">
          {{ itemName }}
        </div>

        <div
          v-if="!isSellCryptoFlow && isCreditCard"
          class="payment-method-item__icons"
        >
          <img :src="require('@/assets/images/payment-mc-visa-apple-google.svg')">
        </div>
      </div>

      <div class="payment-method-item__details">
        <div
          v-if="isQuoteExist"
          class="payment-method-item__amounts"
        >
          <div>
            <span class="text-bold">{{ fiatAmountTo }}</span> {{ fiatAmountToCurrency }}
          </div>

          <div>
            {{ $t('widget.for') }}

            <span class="text-bold">{{ fiatAmountFrom }}</span> {{ fiatAmountFromCurrency }}
          </div>
        </div>

        <div
          v-if="itemLabels"
          class="payment-method-item__labels"
        >
          <span
            v-for="({ label, color }, index) in itemLabels"
            :key="index"
            class="payment-method-item__label"
            :style="{ backgroundColor: color }"
          >
            {{ $t(`widget.payment-methods.labels.${label}`) }}
          </span>
        </div>
      </div>

      <div class="payment-method-item__limits">
        {{ $t('widget.payment-methods.per-tx', { minAmount, maxAmount }) }}

        <template v-if="paymentItemDescription">
          <div class="payment-method-item__delimiter" />
          {{ paymentItemDescription }}
        </template>
      </div>
    </div>

    <div class="payment-method-item__next">
      <i class="svg-icon icon-chevron-left" />
    </div>
  </div>
</template>

<script setup>
import { defineProps, defineEmits, computed } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import { getIconPath } from '@/utils/getMoneyServiceIconPath';

const INSTANT_PAYMENT_METHODS = [
  'Credit/Debit Card',
  'UK Instant Bank Transfer',
  'PIX',
  'Khipu',
  'WebPay',
  'PSE',
  'Skrill',
  'Giropay',
];

const props = defineProps({
  item: {
    type: Object,
    required: true,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(['select']);

const { t } = useI18n();
const store = useStore();

const iconPath = computed(() => getIconPath(props.item.icon));

const itemName = computed(() => props.item.displayName);
const itemLabels = computed(() => props.item.labels);
const feesOrSum = computed(() => {
  if (!props.item.quote) {
    // XXX: fees
    return `${props.item.fees.amount} ${(props.item.fees.isPercent === true ? '%' : props.item.fees.currencyCode)}`;
  }

  const { quote: { amountTo } } = props.item;
  if (Math.sign(amountTo.amount) === -1) {
    return `${props.item.fees.amount} ${(props.item.fees.isPercent === true ? '%' : props.item.fees.currencyCode)}`;
  }

  // XXX: amountTo
  return `${amountTo.amount} ${amountTo.currencyCode}`;
});

const isQuoteExist = computed(() => !!props.item.quote && props.item.quote.id);

const fiatAmountFrom = computed(() => {
  const amount = props.item.quote.amountFromAmount;

  // XXX: in sell crypto, we should display up to last sign
  if (isSellCryptoFlow.value) {
    return amount.replace(/(\.[0-9]*[1-9])0+$|\.0*$/, '$1');
  }

  return amount;
});

const fiatAmountFromCurrency = computed(() => props.item.quote.amountFrom?.currencyCode);

const fiatAmountTo = computed(() => props.item.quote.amountToAmount);
const fiatAmountToCurrency = computed(() => props.item.quote.amountTo?.currencyCode);

const minAmount = computed(() => `${props.item.minAmount.amount} ${props.item.minAmount.currencyCode}`);
const maxAmount = computed(() => `${props.item.maxAmount.amount} ${props.item.maxAmount.currencyCode}`);

const isCreditCard = computed(() => props.item.paymentMethod.indexOf('credit-card') > -1);
const isSellCryptoFlow = computed(() => store.getters.isSellCryptoFlow);

const paymentItemDescription = computed(() => {
  if (props.item.paymentMethod.indexOf('instant-bank-transfer-eu') > -1) {
    return t('widget.payment-methods.descriptions.up-two-days') || '0-2 days';
  }

  const instantDescription = t('widget.payment-methods.descriptions.instant') || 'Instant';

  if (
    INSTANT_PAYMENT_METHODS.includes(props.item.displayName)
    && props.item.displayName.includes(instantDescription)
  ) {
    return instantDescription;
  }

  return '';
});

const emitItemSelect = () => {
  if (props.disabled) {
    return;
  }

  emit('select', props.item);
};
</script>

<style lang="scss" scoped>
.payment-method-item {
  $padding-horizontal: 1rem;
  cursor: pointer;
  display: flex;
  justify-content: space-between;

  padding: 12px $padding-horizontal;
  margin: 0 calc(-#{$padding-horizontal});
  border-bottom: 1px solid $grey-04;

  &:hover {
    opacity: 0.5;
  }

  &__logo {
    .item-icon {
      $height: 36px;
      width: $height;
      height: $height;
      border-radius: calc(#{$height} / 2);
      background-color: $grey-04;
      position: relative;

      img {
        $icon-height: calc(#{$height} / 2);
        display: block;
        width: $icon-height;
        height: $icon-height;

        position: absolute;
        top: 50%;
        left: 50%;
        margin-left: calc(#{$icon-height} / 2 * -1);
        margin-top: calc(#{$icon-height} / 2 * -1);
      }
    }
  }

  &__data {
    flex-grow: 1;
    padding: 8px 20px 0;
    font-size: .9375rem;
    display: grid;
    grid-gap: 6px;

    @media (max-width: $mobile-min) {
      font-size: .8575rem;
    }
  }

  &__header {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    align-items: center;
  }

  &__icons {
    margin-left: 5px;
    font-size: 0;
  }

  &__labels {
    display: inline-flex;
    flex-wrap: wrap;
    gap: 5px 10px;
    align-items: center;
    vertical-align: middle;
  }

  &__details {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 5px 10px;
    white-space: nowrap;
  }

  &__amounts {
    @media (min-width: $height-sm-max) {
      display: flex;
      gap: 5px;
    }
  }

  &__label {
    font-size: 0.61rem;
    padding: 2px 6px;
    display: inline-block;
    border-radius: 6px;

    // Default colors
    color: #fff;
  }

  &__limits {
    font-size: 0.875rem;
    color: $grey;
  }

  &__next {
    margin-top: 6px;

    i {
      display: block;
      width: 12px;
      height: 12px;
      background-size: contain;
      transform: rotate(180deg);
    }
  }

  &__delimiter {
    display: inline-block;
    width: 4px;
    height: 4px;
    border-radius: 2px;
    background-color: currentColor;
    vertical-align: middle;
  }
}
</style>
