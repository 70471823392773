import { StorageService } from '@/services/storageService';

const WEB_VIEW_RULES = [
  // if it says it's a webview, let's go with that
  'WebView',
  // iOS webview will be the same as safari but missing "Safari"
  '(iPhone|iPod|iPad)(?!.*Safari)',
  // Android Lollipop and Above: webview will be the same as native, but it will contain "wv"
  // Android KitKat to lollipop webview will put Version/_X.X_ string (@see: https://developer.chrome.com/docs/multidevice/user-agent/)
  'Android.*(wv|Version/d+.d+)',
  // old chrome android webview agent
  'Linux; U; Android',
];

const MOB_COOKIE_NAME = 'pb-mob-app';

const webviewRegExp = new RegExp(`(${WEB_VIEW_RULES.join('|')})`, 'ig');

// @TODO remove this method if we do not use anymore
export const isWebViewUA = ua => !!ua.match(webviewRegExp);

// Better to use this method for detect WebView
export const isWebView = () => !!StorageService.get(MOB_COOKIE_NAME);
