<template>
  <div
    class="form-select"
    :class="{
      'has-error': hasError,
      'is-disabled': isDisabled,
      'has-tip': hasTipSlot
    }"
  >
    <div
      v-if="label || hasTipSlot"
      class="form-select__top"
    >
      <label
        v-if="label"
        :for="id"
        class="form-select__label"
      >
        {{ label }}
      </label>

      <span class="form-select__tip">
        <slot name="tip" />
      </span>
    </div>

    <div
      class="form-select__input"
      :class="{ 'has-focus': hasFocus }"
    >
      <v-select
        :options="options"
        :value="options.find(option => option.code === value)"
        :model-value="options.find(option => option.code === value)"
        :clearable="canClear"
        :searchable="canSearch"
        :input-id="id"
        :name="name"
        :placeholder="placeholder"
        :disabled="isDisabled"
        :filterable="true"
        :taggable="isCustomOptionEnabled"
        :append-to-body="appendToBody"
        :required="required"
        :calculate-position="calculatePosition"
        @search:focus="handleFocus"
        @search:blur="handleBlur"
        @input="setSelected"
        @update:modelValue="setSelected"
      >
        <template #no-options>
          <span>{{ $t("shared.select.no-options") }}</span>
        </template>
      </v-select>
    </div>

    <div
      v-if="hasCommentSlot"
      class="form-select__comment"
    >
      <slot name="comment" />
    </div>

    <span
      v-if="hasError"
      class="form-select__error"
      v-html="error"
    />
  </div>
</template>

<script>
export default {
  name: 'FormSelect',

  props: {
    id: { type: String, required: false, default: '' },
    name: { type: String, required: false, default: '' },
    label: { type: String, required: false, default: '' },
    placeholder: { type: String, required: false, default: '' },
    hasError: { type: Boolean, required: false, default: false },
    error: { type: String, required: false, default: '' },
    isDisabled: { type: Boolean, required: false, default: false },
    isCustomOptionEnabled: { type: Boolean, required: false, default: false },
    canClear: { type: Boolean, required: false, default: false },
    canSearch: { type: Boolean, required: false, default: true },
    value: { type: String, required: false, default: null },
    options: { type: Array, required: true },
    required: { type: Boolean, required: false },
    appendToBody: { type: Boolean, required: false },
    calculatePosition: { type: Function, required: false },
  },

  data() {
    return {
      hasFocus: false,
    };
  },

  computed: {
    hasTipSlot() {
      return !!this.$slots.tip;
    },

    hasCommentSlot() {
      return !!this.$slots.comment;
    },
  },

  mounted() {
    // Fix chrome autocomplete @see https://github.com/sagalbot/vue-select/issues/931
    document.querySelectorAll('.vs__selected-options input').forEach(el => {
      el.autocomplete = 'none';
    });
  },

  methods: {
    setSelected(option) {
      if (option === null || option === undefined) {
        this.$emit('input', null);
        this.$emit('change', null);

        return;
      }

      let value = option.label;

      if ('code' in option) {
        value = option.code;
      }

      this.$emit('input', value);
      this.$emit('change', value);
    },

    handleFocus() {
      this.hasFocus = true;
    },

    handleBlur() {
      this.hasFocus = false;
    },
  },
};
</script>

<style scoped lang="scss">
.form-select {
  width: 100%;
  position: relative;

  &.is-disabled {
    pointer-events: none;
  }

  &__top {
    display: flex;
    justify-content: space-between;
  }

  &__label {
    font-size: rem(16);
    line-height: 1.25;
  }

  &__tip {
    color: $grey-30;
    font-size: rem(14);
    line-height: 1.14;
  }

  .is-disabled &__label {
    color: $grey-30;
  }

  &__top {
    margin-bottom: rem(4);
  }

  &__input {
    position: relative;
    display: block;
    width: 100%;
    background-color: $white;
    border: none;
    border-radius: rem(6);
    font-weight: 700;
    font-size: rem(16);
    line-height: 1.25;
    box-shadow: 0 0 0 2px $black-10;
    transition: box-shadow 0.2s linear;
  }

  &__input:after {
    position: absolute;
    right: rem(14);
    top: rem(14);
    content: "\e901";
    display: inline-block;
    pointer-events: none;

    @include inline-icon;
  }

  [dir="rtl"] &__input:after {
    left: rem(14);
    right: unset;
  }

  &__input:hover {
    box-shadow: 0 0 0 2px $black-20;
  }

  &__input.has-focus {
    outline: none;
    box-shadow: 0 0 0 1px $violet-60;
  }

  .is-disabled &__input {
    background-color: $grey-04;
    color: $grey;
    box-shadow: 0 0 0 1px $grey-04;
  }

  &__input.is-regular {
    font-family: $font-family;
  }

  &.has-error &__input {
    box-shadow: 0 0 0 2px $red;
  }

  &.has-error &__input:hover {
    box-shadow: 0 0 0 2px rgba(231, 60, 64, 0.25);
  }

  &__error {
    display: block;
    margin-top: rem(4);
    font-size: rem(12);
    line-height: 1.33;
    color: $red;
  }

  &__comment {
    margin-top: rem(8);
    font-size: rem(12);
    line-height: 1.33;
  }

  @media screen and (max-width: $screen-md-max) {
    &.has-tip {
      padding-bottom: rem(28);
    }

    &.has-tip &__tip {
      position: absolute;
      bottom: rem(2);
    }
  }
}
</style>

<style lang="scss">
@import "../../assets/scss/global/lib/vselect";

.vs__search {
  height: rem(48);
  -moz-appearance: none;
  border: none;
  padding: 0 rem(16);
}

.vs__search::-webkit-input-placeholder {
  font-family: $font-family;
  color: $grey-30;
}

.vs__search:-ms-input-placeholder {
  font-family: $font-family;
  color: $grey-30;
}

.vs__search::placeholder {
  font-family: $font-family;
  color: $grey-30;
}

.vs__selected {
  padding: 0 rem(16);
  line-height: rem(48);
}

.vs__dropdown-menu {
  background-color: #ffffff;
  border: 1px solid #000000;
  box-sizing: border-box;
  box-shadow: 0px 3px 6px $black-15;
  border-radius: rem(10);
  padding: rem(6);
}

.vs__dropdown-menu::-webkit-scrollbar,
.vs__dropdown-menu::-webkit-scrollbar-track,
.vs__dropdown-menu::-webkit-scrollbar-thumb {
  background-color: transparent !important;
}

.vs__dropdown-menu::-webkit-scrollbar-thumb {
  border: 4px solid transparent !important;
  box-shadow: inset 0 0 0 4px #babac0;
}

.vs__dropdown-option {
  padding: rem(8);
  border-radius: rem(4);
  font-weight: 700;
}

.vs__dropdown-option:hover,
.vs__dropdown-option--highlight {
  background-color: $grey-04;
}

.vs__dropdown-option--selected {
  color: $grey-30;
}

[dir="rtl"] .vs__selected-options {
  direction: rtl;
}

.vs__actions {
  display: block;
}

.vs__clear {
  position: absolute;
  right: rem(34);
  top: rem(14);
}

[dir="rtl"] .vs__clear {
  right: unset;
  left: rem(34);
}

.vs__clear span {
  font-size: 0;
  color: $black-30;
  transition: color 0.2s linear;
}

.vs__clear span:before {
  content: "\e911";
  @include inline-icon;
  font-size: rem(16);
}

.vs__clear:hover span {
  color: $black;
}
</style>
