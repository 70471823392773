<template>
  <Modal
    size="sm"
    :show="show"
    @close="$emit('close')"
  >
    <ModalHeading>
      {{ $t('transaction-flow.steps.payment-details.crypto-details.transfer') }}
    </ModalHeading>
    <ModalContent class="text-center">
      <QrcodeVue
        :value="addressLink"
        size="240"
      />
      <ModalFormActions>
        <button
          class="btn btn-lg btn-primary"
          @click="$emit('close')"
        >
          {{ $t('shared.navigation.button.close') }}
        </button>
      </ModalFormActions>
    </ModalContent>
  </Modal>
</template>

<script>
import Modal from '@paybis/frontend-common-lib/src/components/modal';
import ModalContent from '@paybis/frontend-common-lib/src/components/modal-content';
import ModalHeading from '@paybis/frontend-common-lib/src/components/modal-heading';
import ModalFormActions from '@paybis/frontend-common-lib/src/components/modal-form-actions';
import QrcodeVue from 'qrcode.vue';

export default {
  name: 'ReceiveCryptoPopup',

  components: {
    Modal,
    ModalContent,
    ModalHeading,
    ModalFormActions,
    QrcodeVue,
  },

  props: {
    show: { type: Boolean, required: false, default: false },
    addressLink: { type: String, required: true },
  },
};
</script>
