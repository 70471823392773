<template>
  <Modal
    size="auto"
    modal-style="loader"
    :show="show"
    non-closable
    @close="$emit('close')"
  >
    <div class="payment-redirect-popup__inner">
      <i class="payment-redirect-popup__icon icon icon-alert" />
      <div class="payment-redirect-popup__title">
        <template v-if="isApplePay">
          {{ $t('transaction-flow.popup.payment-fallback.title.apple-pay') }}
        </template>
        <template v-else-if="isGooglePay && isGooglePayDeclined">
          {{
            $t(
              'transaction-flow.popup.payment-fallback.title.google-pay-declined',
            )
          }}
        </template>
        <template v-else-if="isGooglePay">
          {{ $t('transaction-flow.popup.payment-fallback.title.google-pay') }}
        </template>
        <template v-else>
          {{ $t('transaction-flow.popup.payment-fallback.title.no-simplex') }}
        </template>
      </div>
      <div class="payment-redirect-popup__msg">
        <template v-if="isApplePay">
          {{ $t('transaction-flow.popup.payment-fallback.message.apple-pay') }}
        </template>
        <template v-else-if="isGooglePay && isGooglePayDeclined">
          {{
            $t(
              'transaction-flow.popup.payment-fallback.message.google-pay-declined',
            )
          }}
        </template>
        <template v-else-if="isGooglePay">
          {{ $t('transaction-flow.popup.payment-fallback.message.google-pay') }}
        </template>
        <template v-else-if="payoutErrorMessage">
          {{ payoutErrorMessage }}
        </template>
        <template v-else>
          {{ $t('transaction-flow.popup.payment-fallback.message.no-simplex') }}
        </template>
      </div>
      <div class="payment-redirect-popup__submit-row is-centered">
        <div class="payment-redirect-popup__center-item">
          <button
            class="btn-back-link btn-back-link--mob-short"
            style="color: #000000"
            @click="close"
          >
            <template v-if="isApplePay">
              {{ $t('shared.navigation.button.try-again.apple-pay') }}
            </template>
            <template v-else-if="isGooglePay">
              {{ $t('shared.navigation.button.try-again.google-pay') }}
            </template>
            <template v-else>
              {{ $t('shared.navigation.button.try-again') }}
            </template>
          </button>
        </div>
      </div>
    </div>
  </Modal>
</template>

<script>
import Modal from '@paybis/frontend-common-lib/src/components/modal';
import { mapGetters } from 'vuex';

export default {
  name: 'PaymentFallbackPopup',

  components: {
    Modal,
  },

  computed: {
    ...mapGetters(['isLocked']),
    ...mapGetters('error', ['isGooglePayDeclined']),
    ...mapGetters('transaction', ['payoutErrorMessage']),
    isApplePay() {
      return this.card.hasApplePayError();
    },
    isGooglePay() {
      return this.card.hasGooglePayError();
    },
  },

  methods: {
    close() {
      this.$emit('close');
    },
  },

  props: {
    show: {
      type: Boolean,
      required: false,
      default: false,
    },
    card: {
      type: Object,
      required: true,
    },
    invoice: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped lang="scss">
.payment-redirect-popup {
  &__submit-row {
    margin-top: rem(36);
    position: relative;
  }

  &__submit-row.is-centered {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__left-item {
    position: absolute;
    left: 0;
  }

  &__right-item {
    position: absolute;
    right: 0;
  }

  &__icon {
    font-size: rem(40);
    line-height: rem(24);
    margin: auto;
    padding-bottom: rem(32);
  }

  &__inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: rem(34) rem(32) rem(32) rem(32);
  }

  &__title {
    font-weight: 700;
    font-size: rem(18);
    line-height: rem(24);
    text-align: center;
  }

  &__msg {
    width: rem(480);
    margin-top: rem(4);
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    font-size: rem(14);
    line-height: rem(18);
  }

  &__submit {
    position: relative;
    margin-top: rem(32);
    display: flex;
    justify-content: center;
  }

  &__center-item {
    &:nth-child(1) {
      margin-right: 40px;
    }
  }

  @media screen and (max-width: $screen-xs-max) {
    &__msg {
      width: auto;
    }

    &__submit-row {
      flex-direction: column;
    }

    &__center-item {
      &:nth-child(1) {
        margin: 0 0 10px;
      }
    }
  }
}

@media screen and (max-width: $screen-xs-max) {
  .btn-back-link--mob-short {
    font-size: 1rem;
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>
