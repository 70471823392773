<template>
  <Wrapper
    :has-success="callout.hasSuccess()"
    :has-warning="callout.hasWarning()"
    :has-notice="callout.hasNotice()"
    :bottom-space="false"
  >
    <template #header>
      <HeaderSteps
        :current-index="getCurrentIndex"
        :steps="getSteps"
      />
    </template>

    <template #status>
      <span
        v-show="callout.hasMessage()"
        :class="callout.getClassName()"
        v-html="formattedCalloutMessage"
      />
    </template>

    <template #body>
      <router-view />
    </template>

    <template
      v-if="!transaction.isCompleted()"
      #footer
    >
      <CurrentTransaction
        v-if="
          hasQuote &&
            (!transaction.isCompleted() || !transaction.isPayoutWaiting())
        "
        :transaction="quote"
        :type="getQuoteSignType"
      >
        <template v-if="payout.hasTag()">
          {{ payout.getAccount() }}
          <div>
            {{ $t('transaction-flow.crypto.tag') }}: {{ payout.getTag() }}
          </div>
        </template>
        <template v-else-if="payout.hasMemo()">
          {{ payout.getAccount() }}
          <div>
            {{ $t('transaction-flow.crypto.memo') }}: {{ payout.getMemo() }}
          </div>
        </template>
        <template v-else-if="!payout.isBankCard()">
          {{ payout.getAccount() }}
        </template>
      </CurrentTransaction>

      <FooterText>
        <template #left>
          <DynamicCryptoTimer
            v-if="showCryptoTimer"
            :time="getTime"
          />
        </template>

        <template #right>
          {{ $t('transaction-flow.wrapper.invoice') }}: {{ invoice }}
        </template>
      </FooterText>
    </template>

    <template
      v-if="false"
      #infobox
    >
      {{ $t('transaction-flow.wrapper.infobox.message') }}
    </template>

    <template #modal>
      <PaymentRedirectPopup
        :show="isRedirecting"
        :message="redirectMessage"
        class="payment-3ds-redirect-popup"
      />
      <CancellationPopup :show="showTransactionCancellationPopup" />
      <PaymentFallbackPopup
        :invoice="invoice"
        :card="selectedCard"
        :show="isShowPaymentFallbackPopup || computeShowPayoutFallbackPopup"
        @close="onPopupClose"
      />
      <VerificationFailedPopup
        :show="selectedCard.hasVerificationError()"
        @close="onPopupClose"
      />
      <CardIsAlreadyVerifyingPopup
        :is-locked="isLocked"
        :show="selectedCard.isAlreadyVerifying()"
        @close="onPopupClose"
      />
    </template>

    <template
      v-if="flowDetails.isZerohashPayoutStrategy()"
      #complianceMessage
    >
      {{ $t('transaction-flow.zero-hash-compliance') }}
    </template>
  </Wrapper>
</template>

<script>
import CurrentTransaction from '@paybis/frontend-common-lib/src/components/current-transaction';
import DynamicCryptoTimer from '@paybis/frontend-common-lib/src/components/dynamic-crypto-timer';
import FooterText from '@paybis/frontend-common-lib/src/components/footer-text';
import Wrapper from '@paybis/frontend-common-lib/src/components/wrapper';
import Storage from '@paybis/frontend-common-lib/src/services/storage';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import moment from 'moment';
import CancellationPopup from '../components/cancellation-popup';
import CardIsAlreadyVerifyingPopup from '../components/card-is-already-verifying-popup';
import HeaderSteps from '../components/header-steps';
import PaymentFallbackPopup from '../components/payment-fallback-popup';
import PaymentRedirectPopup from '../components/payment-redirect-popup';
import VerificationFailedPopup from '../components/verification-failed-popup';
import CalloutFactory from '../factories/CalloutFactory';
import { TransactionFlowStep } from '../models/steps';
import { replaceNewLinesToTags } from '../utils/formatting';

import eventBus from '../helpers/eventBus';
import { useI18n } from 'vue-i18n';
import store from '@/store';
import ApiHelper from '@paybis/frontend-common-lib/src/services/api-helper';
import { TRANSACTION_STATUS_REFRESH_TIME } from '../utils/refreshTime';

export default {
  name: 'Funnel',

  components: {
    Wrapper,
    HeaderSteps,
    FooterText,
    DynamicCryptoTimer,
    CurrentTransaction,
    PaymentRedirectPopup,
    PaymentFallbackPopup,
    VerificationFailedPopup,
    CardIsAlreadyVerifyingPopup,
    CancellationPopup,
  },

  data() {
    const { t } = useI18n();

    return {
      t,
      isPaymentDetailsLoaded: false,
      isPaymentProcessorNotFound: false,
      isShowPaymentFallbackPopup: false,
      transactionTimeoutId: null,
    };
  },

  computed: {
    ...mapGetters(['isRedirecting']),
    ...mapGetters('paymentErrorPopup', ['isPaymentPopupAllowed']),
    ...mapGetters('transaction', [
      'transaction',
      'extraVerification',
      'invoice',
      'payment',
      'payout',
      'showTransactionCancellationPopup',
      'flowDetails',
      'payoutErrorMessage',
    ]),
    ...mapGetters('verification', ['verification']),
    ...mapGetters('quote', ['quote', 'hasQuote']),
    ...mapGetters('cryptoTimer', ['time', 'hasCryptoTimer', 'isApproximate']),
    ...mapGetters('transaction/navigation', [
      'current',
      'getCurrentIndex',
      'steps',
    ]),
    ...mapGetters('card', ['selectedCard']),
    ...mapGetters(['isLocked']),
    callout() {
      return new CalloutFactory(
        this.transaction,
        this.verification,
        this.extraVerification,
        this.payment,
      ).build();
    },
    getQuoteSignType() {
      return this.isApproximate ? 'approximate' : 'equal';
    },
    getSteps() {
      return this.steps.map(step => {
        step.title = step.name;
        if (step.name === TransactionFlowStep.STEP_PAYOUT_DETAILS) {
          step.title = this.payout.isCrypto() ? 'wallet' : 'payout-details';
        }

        return step;
      });
    },
    getTime() {
      if (this.time === null) {
        return null;
      }

      return moment(this.time);
    },
    showCryptoTimer() {
      return this.hasCryptoTimer && !this.transaction.isCancelled();
    },
    computeShowPaymentFallbackPopup() {
      if (!this.isPaymentPopupAllowed) {
        return false;
      }

      if (this.isPaymentProcessorNotFound) {
        this.selectedCard.removeErrors();
        return true;
      }

      if (!this.payment.isFallbackAvailable()
        && this.payment.error() && Storage.get(`paymentError:${this.invoice}`) !== 'true') {
        Storage.put(`paymentError:${this.invoice}`, true);
        this.selectedCard.setError();
      }

      // Show payment fallback popup only on card error and on payment details screen
      return (
        this.selectedCard.hasError() && this.transaction.isPaymentDetails()
      );
    },
    computeShowPayoutFallbackPopup() {
      if (!this.isPaymentPopupAllowed) {
        return false;
      }
      return !!this.payoutErrorMessage;
    },
    redirectMessage() {
      if (this.selectedCard.getStatus().isAuthPending()) {
        return 'transaction-flow.popup.card-authorization.message';
      }
      return 'transaction-flow.popup.payment-redirect.message';
    },
    formattedCalloutMessage() {
      const message = this.t(this.callout.getMessage());

      return replaceNewLinesToTags(message);
    },
  },

  watch: {
    computeShowPaymentFallbackPopup: {
      immediate: true,
      handler(value) {
        if (!this.isPaymentPopupAllowed) {
          return;
        }

        if (value) {
          this.clearApplePay();
          this.clearGooglePay();
          if (this.selectedCard) {
            this.selectedCard.setCvv(null);
            this.selectedCard.removeErrors();
          }
          this.setSelectedCardId(null);
          Storage.remove(`selectedCardId:${this.invoice}`);
          this.isShowPaymentFallbackPopup = true;
        }
      },
    },
  },

  created() {
    eventBus.once('transaction-flow.payment-details.loaded', this.setIsPaymentDetailsLoaded);
    eventBus.on('transaction-flow.payment.payment-processor-not-found', this.setIsPaymentProcessorNotFound);
    eventBus.on('transaction-flow.update-transaction', this.init);
    this.init();
  },
  destroyed() {
    eventBus.detach('transaction-flow.payment-details.loaded', this.setIsPaymentDetailsLoaded);
    eventBus.detach('transaction-flow.payment.payment-processor-not-found', this.setIsPaymentProcessorNotFound);
  },
  unmounted() {
    eventBus.off('transaction-flow.update-transaction', this.init);
    clearTimeout(this.transactionTimeoutId);
  },

  methods: {
    ...mapActions('transaction', ['checkoutTransaction']),
    ...mapMutations('transaction', ['setPayoutErrorMessage']),
    ...mapMutations(['lock', 'unlock']),
    ...mapMutations('card', [
      'clearApplePay',
      'clearGooglePay',
      'setSelectedCardId',
    ]),
      ...mapActions('transaction', {
        initTransaction: 'init',
      }),

      delayedUpdate() {
        // TODO: responsibility of network layer. Remove it from component
        this.transactionTimeoutId = setTimeout(() => {
          if (store.getters['transaction/isPollingStopped']) {
            this.delayedUpdate();
          } else {
            this.init();
          }
        }, TRANSACTION_STATUS_REFRESH_TIME);
      },
      init() {
        if (this.transactionTimeoutId) {
          clearTimeout(this.transactionTimeoutId);
          this.transactionTimeoutId = null;
        }
        return this.initTransaction()
          .then(() => {
            this.delayedUpdate();
          })
          .catch(error => {
            if (error.status === 404) {
              eventBus.emit('transaction-flow.not-found');

              throw error;
            }

            if (ApiHelper.isNetworkError(error)) {
              // retry if network error
              this.delayedUpdate();

              return Promise.resolve();
            }

            throw error;
          });
      },
    onPopupClose() {
      this.isPaymentProcessorNotFound = false;
      this.isShowPaymentFallbackPopup = false;
      this.setPayoutErrorMessage(null);
    },
    setIsPaymentDetailsLoaded() {
      this.isPaymentDetailsLoaded = true;
    },
    setIsPaymentProcessorNotFound() {
      this.isPaymentProcessorNotFound = true;
    },
  },
};
</script>
