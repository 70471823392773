<template>
  <ExchangeFormInput
    :label="$t('widget.exchange-form.from-input-label')"
    :amount="amountFrom"
    :currency="quote.currencyCodeFrom"
    :currency-source="currencySourceFrom"
    :is-loading="fromInputIsLoading || fromFiatInputIsLoading"
    :error="serverError"
    :intro-hint-message="isBuyCryptoFlow ? $t('widget.exchange-form.fiat-dropdown-hint-message') : ''"
    :intro-hint-step="isBuyCryptoFlow ? 1 : 0"
    class="exchange-form__body-input"
    @amountChange="onAmountFromChange"
    @currencyTap="onCurrencyFromChange"
  />

  <ExchangeFormPromoComponent
    v-if="isBuyCryptoFlow && isPromoCodesEnabled"
    v-model="promoCode"
    :capture="capture"
    :error="promoCodeError"
    class="exchange-form__promo-component"
    @update:modelValue="handlePromoCodeChange"
  />

  <ExchangeFormSecondaryInput
    v-if="isSellCryptoFlow && showEquivalentInput"
    :label="$t('widget.exchange-form.spend-in-fiat')"
    :readonly="fromInputIsLoading || toInputIsLoading"
    :amount="amountReceived"
    :currency="receiveCurrencySell"
    class="exchange-form__body-secondary-input"
    @amountChange="onAmountFiatChange"
  />

  <ExchangeFormInput
    :label="$t('widget.exchange-form.to-input-label')"
    :amount="amountTo"
    :currency="currencyTo"
    :currency-source="currencySourceTo"
    :is-loading="toInputIsLoading || fromFiatInputIsLoading"
    :error="isBuyCryptoFlow ? methodNotFoundError : ''"
    :is-currency-disabled="isCurrencyDisabled"
    :intro-hint-message="isSellCryptoFlow ? $t('widget.exchange-form.fiat-dropdown-hint-message') : ''"
    :intro-hint-step="isSellCryptoFlow ? 1 : 0"
    class="exchange-form__body-input"
    @amountChange="onAmountToChange"
    @currencyTap="onCurrencyToChange"
  />

  <ExchangeFormSecondaryInput
    v-if="isBuyCryptoFlow && showEquivalentInput"
    :label="$t('widget.widget.exchange-form.receive-in-fiat')"
    :readonly="fromInputIsLoading || toInputIsLoading"
    :amount="amountReceived"
    :currency="receiveCurrencyBuy"
    @amountChange="onAmountFiatChange"
  />

  <ExchangeFormPromoComponent
    v-if="isSellCryptoFlow && isPromoCodesEnabled"
    v-model="promoCode"
    :capture="capture"
    :error="promoCodeError"
    @update:modelValue="handlePromoCodeChange"
  />
</template>

<script>
import { computed, nextTick, onMounted, ref, watch } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import debounce from 'lodash.debounce';

import { useSelectCurrencyModal } from '@/components/v2/SelectCurrencyModal';

import ExchangeFormInput from '@/components/ExchangeForm/ExchangeFormInput';
import ExchangeFormSecondaryInput from '../ExchangeFormSecondaryInput';
import { CRITICAL_ERROR, PAYMENT_METHODS_V2 } from '@/router/routes';
import ExchangeFormPromoComponent
  from '@/components/v2/ExchangeForm/ExchangeFormPromoComponent/ExchangeFormPromoComponent';
import { getLocaleByLanguage } from '@paybis/frontend-common-lib/src/services/util';

const INPUT_DEBOUNCE_TIME = 1000;

export const InputLoadingDirectionEnum = {
  from: Symbol('from'),
  to: Symbol('to'),
};

export default {
  name: 'ExchangeFormBody',

  components: {
    ExchangeFormPromoComponent,
    ExchangeFormInput,
    ExchangeFormSecondaryInput,
  },

  props: {
    isLoading: {
      type: Boolean,
      default: true,
    },
    quote: {
      type: Object,
      default: () => ({}),
    },
  },

  setup(props) {
    const store = useStore();
    const router = useRouter();
    const { t, locale } = useI18n();

    const promoCode = computed(() => store.getters['promoCode/promoCode']);
    const capture = computed(() => store.getters['promoCode/capture']);
    const promoCodeError = computed(() => store.getters['promoCode/error']);
    const isPromoCodesEnabled = computed(() => store.getters['request/isPromoCodesEnabled'] && !store.getters.isUkCustomer);
    const themeConfig = computed(() => store.getters.themeConfig);
    const showEquivalentInput = computed(() => themeConfig.value?.showEquivalentInput);
    const serverError = computed(() => {
      const unexpectedQuoteError = store.getters['v2/widgetQuote/unexpectedQuoteError'];
      if (unexpectedQuoteError) {
        return t(unexpectedQuoteError);
      }

      const { paymentMethod } = store.getters['v2/paymentMethods/paymentMethod'];
      const { paymentMethod: payoutMethod } = store.getters['v2/paymentMethods/payoutMethod'];
      const paymentMethodError = store.getters['v2/widgetQuote/paymentMethodError'](paymentMethod)?.message;
      const payoutMethodError = store.getters['v2/widgetQuote/payoutMethodError'](payoutMethod)?.message;

      if (paymentMethodError || payoutMethodError) {
        return paymentMethodError || payoutMethodError;
      }

      if (methodNotFoundError.value && isSellCryptoFlow.value) {
        return methodNotFoundError.value
      }

      return store.getters['v2/widgetQuote/payoutMethodError'](paymentMethod)?.message;
    });
    const methodNotFoundError = computed(() => {
      const paymentMethodError = store.getters['v2/paymentMethods/notFoundMethodsCurrency'];

      return paymentMethodError
        ? t('widget.errors.cryptocurrency-is-not-available', { currencyName: paymentMethodError })
        : null;
    });

    const { showCurrencyModal } = useSelectCurrencyModal();

    const loadingDirection = ref(InputLoadingDirectionEnum.from);
    const fromFiatInputActive = ref(false);

    const fromInputIsLoading = computed(
      () => props.isLoading && loadingDirection.value === InputLoadingDirectionEnum.to,
    );
    const toInputIsLoading = computed(
      () => props.isLoading && loadingDirection.value === InputLoadingDirectionEnum.from,
    );
    const fromFiatInputIsLoading = computed(
      () => props.isLoading && fromFiatInputActive.value,
    );

    const isSellCryptoFlow = computed(() => store.getters.isSellCryptoFlow);
    const isBuyCryptoFlow = computed(() => store.getters.isBuyCryptoFlow);

    const currencyTo = computed(() => store.getters['exchangeForm/getCurrencyBy'](props.quote.currencyCodeTo));

    const receiveCurrencySell = computed(() => currencyTo.value);
    const receiveCurrencyBuy = computed(() => props.quote.currencyCodeFrom);
    const quote = computed(() => store.getters['widgetQuote/quote']);

    watch(quote, () => {
      nextTick(() => {
        if (!promoCodeError.value && !quote.value.fees?.fee_discount) {
          store.dispatch('promoCode/setCapture', t('widget.exchange-form.promo-label'));
        }
        if (!promoCodeError.value && quote.value.fees?.fee_discount) {
          const amount = Number.parseFloat(quote.value.fees.fee_discount)
            .toLocaleString(getLocaleByLanguage(locale.value), {
              style: 'currency',
              currency: quote.value.fees.currency,
            });
          store.dispatch('promoCode/setCapture', t('widget.exchange-form.promo-discount', { amount }).toString());
        }
      });
    }, { immediate: true });

    const rawQuote = computed(() => store.getters['v2/widgetQuote/rawQuote']);

    watch(locale, value => {
      if (value) {
        if (!promoCodeError.value && !quote.value.fees?.fee_discount) {
          store.dispatch('promoCode/setCapture', t('widget.exchange-form.promo-label'));
        }
        if (!promoCodeError.value && quote.value.fees?.fee_discount) {
          const amount = Number.parseFloat(quote.value.fees.fee_discount)
            .toLocaleString(getLocaleByLanguage(value), {
              style: 'currency',
              currency: quote.value.fees.currency,
            });
          store.dispatch('promoCode/setCapture', t('widget.exchange-form.promo-discount', { amount }).toString());
        }
      }
    });

    watch(rawQuote, () => {
      if (rawQuote.value.promoCodeError) {
        store.dispatch('promoCode/setError', true);
        store.dispatch('promoCode/setCapture', rawQuote.value.promoCodeError.message);
      } else {
        store.dispatch('promoCode/setError', false);
      }
    });

    const currencySourceFrom = computed(() => {
      if (isSellCryptoFlow.value) {
        return t(`currencies.${props.quote.currencyCodeFrom}`);
      }

      return props.quote.currencySourceFrom;
    });

    const currencySourceTo = computed(() => {
      if (isSellCryptoFlow.value) {
        return props.quote.currencySourceFrom;
      }

      return t(`currencies.${props.quote.currencyCodeTo}`);
    });

    watch(currencyTo, value => {
      if (value) return;

      if (store.getters.cryptoWallets.length) {
        store.dispatch('showCryptoCurrencyIsNotAvailablePopup');
        return;
      }

      router.push({ name: CRITICAL_ERROR });
    }, { immediate: true });

    const setAmountFiatDebounced = debounce(
      value => store.dispatch('exchangeForm/setAmountFiat', value),
      INPUT_DEBOUNCE_TIME,
    );
    const setAmountFromDebounced = debounce(
      value => store.dispatch('exchangeForm/setAmountFrom', value),
      INPUT_DEBOUNCE_TIME,
    );
    const setAmountToDebounced = debounce(
      value => store.dispatch('exchangeForm/setAmountTo', value),
      INPUT_DEBOUNCE_TIME,
    );
    const setPromoCodeDebounced = debounce(
      () => store.dispatch('exchangeForm/setPromoCode'),
      INPUT_DEBOUNCE_TIME,
    );

    const deleteQuote = () => {
      store.dispatch('exchangeForm/deleteQuote');
      store.dispatch('v2/widgetQuote/deleteQuote');
      store.dispatch('v2/paymentMethods/mapPaymentMethodsWithQuote', {
        quotePaymentMethods: store.getters['v2/widgetQuote/paymentMethods'],
      }, { root: true });
    };

    const onAmountFiatChange = async value => {
      if (!value || parseFloat(value) === 0) {
        deleteQuote();
        setAmountFromDebounced.cancel();
        return;
      }
      loadingDirection.value = InputLoadingDirectionEnum.to;
      fromFiatInputActive.value = true;
      await store.dispatch('exchangeForm/setLoading', true);
      await setAmountFiatDebounced(value);
    };
    const onAmountFromChange = async value => {
      if (!value) {
        deleteQuote();
        setAmountFromDebounced.cancel();
        return;
      }

      if (parseFloat(value) === 0) {
        deleteQuote();
        await store.dispatch('exchangeForm/setAmountFrom', 0);
        return;
      }

      loadingDirection.value = InputLoadingDirectionEnum.from;
      fromFiatInputActive.value = false;
      await store.dispatch('exchangeForm/setLoading', true);
      await setAmountFromDebounced(value);
    };
    const onAmountToChange = async value => {
      if (!value) {
        deleteQuote();
        setAmountToDebounced.cancel();
        return;
      }

      if (parseFloat(value) === 0) {
        deleteQuote();
        await store.dispatch('exchangeForm/setAmountTo', 0);
        return;
      }

      loadingDirection.value = InputLoadingDirectionEnum.to;
      fromFiatInputActive.value = false;
      await store.dispatch('exchangeForm/setLoading', true);
      await setAmountToDebounced(value);
    };

    const handlePromoCodeChange = value => {
      if (!value) {
        store.dispatch('promoCode/resetPromoCodeState', t('widget.exchange-form.promo-label'));
      }
      store.dispatch('promoCode/setPromoCode', value || null);
      setPromoCodeDebounced();
    };

    const popularCurrenciesFrom = computed(() => store.getters['exchangeForm/popularCurrenciesFrom'](props.quote.currencyCodeTo)
      .map(({ from }) => ({
        code: from,
        currency: from,
        name: t(`currencies.${from}`),
      })));

    const popularCurrenciesTo = computed(() => store.getters['exchangeForm/popularCurrenciesTo'](props.quote.currencyCodeFrom)
      .map(({ to, details }) => ({
        code: to,
        currency: details.toCurrency,
        name: t(`currencies.${to}`),
      })));

    const allCurrenciesFrom = computed(() => store.getters['exchangeForm/allCurrenciesWithPair'](props.quote.currencyCodeTo)
      .map(({ from }) => ({
        code: from,
        currency: from,
        name: t(`currencies.${from}`),
      })));

    const allCurrenciesTo = computed(() => store.getters['exchangeForm/allCurrenciesWithPair'](props.quote.currencyCodeFrom)
      .map(({ to, details }) => ({
        code: to,
        currency: details.toCurrency,
        name: t(`currencies.${to}`),
      })));

    const isCurrencyDisabled = computed(() => (isSellCryptoFlow.value ? false : props.quote.currencyCodeToFixed));

    const onCurrencyFromChange = async () => {
      if (isSellCryptoFlow.value) {
        const selectedCurrency = await showCurrencyModal({
          popularCurrencies: popularCurrenciesFrom,
          allCurrencies: allCurrenciesFrom,
        });

        store.dispatch('exchangeForm/setCurrencyCodeFrom', selectedCurrency);

        return;
      }

      router.push({ name: PAYMENT_METHODS_V2 });
    };

    const onCurrencyToChange = async () => {
      if (isSellCryptoFlow.value) {
        router.push({ name: PAYMENT_METHODS_V2 });

        return;
      }
      let selectedCurrency = null;
      if (store.getters.isUkCustomer) {
        const ALLOWED_ASSETS = [
          'BTC',
          'BTC-TESTNET',
          'ETH',
          'ETH-GOERLI',
          'USDT-TRC20',
          'LTC',
          'LTC-TESTNET',
          'USDT',
          'USDT-GOERLI',
          'XRP',
          'XRP-TESTNET',
          'XLM',
          'XLM-TESTNET',
          'TRX',
          'TRX-SHASTA',
          'BNBSC',
          'BNBSC-TESTNET',
          'USDC',
        ];

        selectedCurrency = await showCurrencyModal({
          popularCurrencies: popularCurrenciesTo.value.filter(({ code }) => ALLOWED_ASSETS.includes(code)),
          allCurrencies: allCurrenciesTo.value.filter(({ code }) => ALLOWED_ASSETS.includes(code)),
        });
      } else {
        selectedCurrency = await showCurrencyModal({
          popularCurrencies: popularCurrenciesTo,
          allCurrencies: allCurrenciesTo,
        });
      }

      store.dispatch('exchangeForm/setCurrencyCodeTo', selectedCurrency);
    };

    const amountFrom = computed(() => (fromInputIsLoading.value ? '' : props.quote.amountFrom));
    const amountTo = computed(() => (toInputIsLoading.value ? '' : props.quote.amountTo));
    const amountReceived = computed(() => (toInputIsLoading.value ? '' : props.quote.amountReceived));

    onMounted(() => {
      if (!capture.value) {
        store.dispatch('promoCode/setCapture', t('widget.exchange-form.promo-label'));
      }
      store.dispatch('exchangeForm/fetchCryptoAssets');
    });

    return {
      serverError,

      amountFrom,
      fromInputIsLoading,
      onAmountFromChange,
      onCurrencyFromChange,

      amountTo,
      toInputIsLoading,
      onAmountToChange,
      onCurrencyToChange,
      currencyTo,
      fromFiatInputIsLoading,
      receiveCurrencySell,
      receiveCurrencyBuy,
      isSellCryptoFlow,
      isBuyCryptoFlow,
      isCurrencyDisabled,
      isPromoCodesEnabled,
      currencySourceFrom,
      currencySourceTo,

      amountReceived,
      onAmountFiatChange,

      showEquivalentInput,
      promoCode,
      capture,
      promoCodeError,
      handlePromoCodeChange,
      methodNotFoundError,
    };
  },
};
</script>

<style lang="scss" scoped>
.exchange-form__body-secondary-input {
  margin-bottom: 3rem;
}
.exchange-form__promo-component {
  margin-bottom: 3rem;
}

.exchange-form__body-input + .exchange-form__body-input {
  margin-top: 3rem;

  @media (min-width: $laptop-min) and (max-height: $widget-height-sm) {
    margin-top: 6.25vh;
  }

  @media (min-width: $desktop-xlg-min) and (max-height: $widget-height-xlg) {
    margin-top: 6.26vh;
  }

  @media (min-width: $desktop-xxlg-min) and (max-height: $widget-height-xxlg) {
    margin-top: 6.36vh;
  }
}
</style>
