<template>
  <a
    :href="linkToPoweredBy"
    rel="nofollow"
    target="_blank"
    class="powered-by"
  >
    <span>{{ $t('widget.powered-by') }}</span>
    <div class="powered-by__img" />
  </a>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';

export default {
  name: 'PoweredBy',
  setup() {
    const store = useStore();
    const linkToPoweredBy = computed(() => store.getters.themeConfig.poweredByLink);
    return {
      linkToPoweredBy,
    };
  },
};
</script>

<style lang="scss" scoped>
.powered-by {
  color: #fff;
  font-size: rem(16);
  line-height: rem(16);
  display: flex;
  text-decoration: initial;
  margin-left: rem(8);

  &:hover {
    opacity: 0.5;
  }

  &__img {
    display: block;
    width: rem(69);
    height: rem(19);
    background-color: $white;
    mask-image: url(~@/assets/images/widget/paybis-logo.svg);
    mask-repeat: no-repeat;
    mask-size: contain;
  }

  span {
    margin-right: rem(8);
  }
}
</style>
