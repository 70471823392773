import store from '@/store';

import PayoutAuthorizationPendingNavigation from './auth-pending';
import PayoutAuthorizationProcessingNavigation from './auth-processing';
import BaseVerificationNavigation from './base-verification';
import CancelledNavigation from './cancelled';
import CompletedNavigation from './completed';
import ExtraVerificationNavigation from './extra-verification';
import GiftCardNavigation from './gift-card';
import PaymentDetailsNavigation from './payment-details';
import CryptoAcquiringPaymentDetails from './crypto-acquiring-payment-details';
import PaymentErrorNavigation from './payment-error';
import PaymentReviewNavigation from './payment-review';
import PaymentWaitingNavigation from './payment-waiting';
import PayoutDetailsNavigation from './payout-details';
import PaymentChecksNavigation from './payment-checks';
import PayoutWaitingNavigation from './payout-waiting';
import RejectedNavigation from './rejected';
import ZerohashAcceptanceNavigation from './zerohash-acceptance';

export default class {
  constructor(
    transaction,
    baseVerification,
    steps,
    payment,
    isSumsubVerificationPending,
    isActivePayment,
    isChallenge,
    flowDetails,
    requestHasWallet,
    requestStatus,
  ) {
    this.transaction = transaction;
    this.baseVerification = baseVerification;
    this.steps = steps;
    this.payment = payment;
    this.isSumsubVerificationPending = isSumsubVerificationPending;
    this.isActivePayment = isActivePayment;
    this.isChallenge = isChallenge;
    this.flowDetails = flowDetails;
    this.requestHasWallet = requestHasWallet;
    this.requestStatus = requestStatus;
  }

  getNavigation(callback){
    if (this.transaction.isCompleted()) {
      return new CompletedNavigation(this.transaction, this.steps, callback);
    }

    if (this.transaction.isRejected()) {
      return new RejectedNavigation(this.transaction, this.steps, callback);
    }

    if (this.transaction.isPaymentError()) {
      return new PaymentErrorNavigation(this.transaction, this.steps, callback);
    }

    if (this.transaction.isCancelled()) {
      return new CancelledNavigation(this.transaction, this.steps, callback);
    }

    if (this.flowDetails.isPayoutTermsAcceptanceRequired() && this.requestHasWallet) {
      return new ZerohashAcceptanceNavigation(this.transaction, this.steps, callback);
    }

    if (this.transaction.isPayoutWaiting()) {
      return new PayoutWaitingNavigation(
        this.transaction,
        this.steps,
        callback,
      );
    }

    if (this.transaction.isVerification()) {
      if (this.baseVerification.isPending()) {
        return new BaseVerificationNavigation(
          this.transaction,
          this.steps,
          callback,
        );
      }

      return new ExtraVerificationNavigation(
        this.transaction,
        this.steps,
        callback,
      );
    }

    if (this.transaction.isOct()) {
      if (this.transaction.isPayoutAuthorizationPending()) {
        return new PayoutAuthorizationPendingNavigation(
          this.transaction,
          this.steps,
          callback,
        );
      }

      if (this.transaction.isPayoutAuthorizationProcessing()) {
        return new PayoutAuthorizationProcessingNavigation(
          this.transaction,
          this.steps,
          callback,
        );
      }
    } else {
      if (this.transaction.isPayoutDetails()) {
        return new PayoutDetailsNavigation(
          this.transaction,
          this.steps,
          callback,
        );
      }

      if (this.transaction.isPaymentDetails() && this.payment.isGiftCard()) {
        return new GiftCardNavigation(this.transaction, this.steps, callback);
      }
    }

    if (this.transaction.isPaymentChecks()) {
      return new PaymentChecksNavigation(
        this.transaction,
        this.steps,
        callback,
      );
    }

    if (
      this.payment.isElectronic()
      && !this.payment.isBankCard()
      && (this.transaction.isPaymentDetails()
        || this.transaction.isPaymentWaiting())
    ) {
      return new PaymentWaitingNavigation(
        this.transaction,
        this.steps,
        callback,
        this.payment,
        this.isChallenge,
      );
    }

    if (
      !this.isSumsubVerificationPending
      && this.transaction.isPaymentReview()
    ) {
      return new PaymentReviewNavigation(
        this.transaction,
        this.steps,
        callback,
      );
    }

    if (
      this.transaction.isPaymentDetails()
      || this.isSumsubVerificationPending
      || this.isActivePayment
      || (this.transaction.isPaymentWaiting() && this.payment.isTrustly())
    ) {
      if (this.requestStatus === 'payment_initiated') {
        return new PaymentWaitingNavigation(
          this.transaction,
          this.steps,
          callback,
          this.payment,
          this.isChallenge,
        );
      }

      if (store.getters.isPaybisAcquireTheme) {
        return new CryptoAcquiringPaymentDetails(
          this.transaction,
          this.steps,
          callback,
        );
      }

      return new PaymentDetailsNavigation(
        this.transaction,
        this.steps,
        callback,
      );
    }

    if (this.transaction.isPaymentWaiting()) {
      return new PaymentWaitingNavigation(
        this.transaction,
        this.steps,
        callback,
        this.payment,
        this.isChallenge,
      );
    }

    throw new Error('Unrecognized transaction status');
  }
}
