<template>
  <PageLayout
    v-if="currencyModal.display"
    :have-previous-step="true"
    class="currencies-layout"
    :title="$t('widget.select-currency')"
    @previous-step="cancelSelection"
  >
    <div class="currencies-wrapper">
      <input
        v-model="searchString"
        class="currency-search"
        placeholder="Search"
      >

      <template v-if="isPopularCurrenciesAvailable">
        <div
          v-if="!inSearchMode"
          class="header"
        >
          {{ $t('widget.currency-selector.popular') }}
        </div>

        <div class="list">
          <div
            v-for="(item, index) in popularCurrencies"
            :key="index"
            class="list__item"
            @click="selectCurrency(item.code)"
          >
            <SelectCurrencyItemFiat v-if="isFiatCurrency(item.code)" :item="item" />
            <SelectCurrencyItemCrypto
              v-else-if="getAssetById(item.code)"
              :item="item"
              :isEnabledAssetNames="isEnabledAssetNames"
              :asset="getAssetById(item.code)"
            />
          </div>
        </div>
      </template>

      <template v-if="allCurrencies.length">
        <div
          v-if="!inSearchMode"
          class="header"
        >
          {{ $t('widget.currency-selector.all') }}
        </div>

        <div class="list">
          <div
            v-for="(item, index) in allCurrencies"
            :key="index"
            class="list__item"
            @click="selectCurrency(item.code)"
          >
            <SelectCurrencyItemFiat v-if="isFiatCurrency(item.code)" :item="item" />
            <SelectCurrencyItemCrypto
              v-else-if="getAssetById(item.code)"
              :item="item"
              :isEnabledAssetNames="isEnabledAssetNames"
              :asset="getAssetById(item.code)"
            />
          </div>
        </div>
      </template>
    </div>
  </PageLayout>
</template>

<script>
import { useStore } from 'vuex';
import { computed, ref } from 'vue';
import PageLayout from '@/components/PageLayout';
import SelectCurrencyItemFiat from './SelectCurrencyItemFiat';
import SelectCurrencyItemCrypto from './SelectCurrencyItemCrypto';
import useSelectCurrencyModal from './useSelectCurrencyModal';

export default {
  name: 'SelectCurrencyModal',

  components: {
    PageLayout,
    SelectCurrencyItemFiat,
    SelectCurrencyItemCrypto,
  },

  setup() {
    const store = useStore();
    const searchString = ref('');
    const { currencyModal } = useSelectCurrencyModal();
    const getAssetById = computed(() => id => store.getters['exchangeForm/cryptoAssets'][id]);
    const isEnabledAssetNames = computed(() => store.getters['feature/assetNames'].isEnabled());
    const isFiatCurrency = computed(() => code => store.getters['exchangeForm/allFiatCurrencies']
      .find(currency => currency.from === code));
    const popularCurrencies = computed(() => currencyModal.currencies.popular);
    const allCurrencies = computed(() => {
      const currencies = currencyModal.currencies.all;

      if (!searchString.value) {
        // XXX: when no search, crop popular currencies
        return currencies.filter(currency => !popularCurrencies.value.find(
          popularCurrency => popularCurrency.code === currency.code,
        ));
      }

      const searchValue = searchString.value.toLowerCase().trim();
      return currencies.filter(({ code, name }) => {
        const hasCodeInputs = code.toLowerCase().includes(searchValue);
        const hasNameInputs = name.toLowerCase().includes(searchValue);
        return hasCodeInputs || hasNameInputs;
      });
    });
    const inSearchMode = computed(() => searchString.value);
    const isPopularCurrenciesAvailable = computed(() => {
      const isEmpty = popularCurrencies.value.length === 0;
      return !inSearchMode.value && !isEmpty;
    });

    const selectCurrency = currency => {
      currencyModal.onChange(currency);
      currencyModal.display = false;
      searchString.value = '';
    };
    const cancelSelection = () => {
      currencyModal.display = false;
      searchString.value = '';
    };

    return {
      currencyModal,

      allCurrencies,
      popularCurrencies,

      searchString,
      inSearchMode,

      isPopularCurrenciesAvailable,

      selectCurrency,
      cancelSelection,
      getAssetById,
      isEnabledAssetNames,
      isFiatCurrency,
    };
  },
};
</script>

<style lang="scss" scoped>
.list .list__item:last-child {
  border-bottom: 1px solid $black-06;
}

.currencies-layout {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: $white;
  border-radius: 6px;
  z-index: 101; // overlay menu icon
  padding: 24px;
  height: 100%;

  ::v-deep(.page-layout__content) {
    height: 87%;
  }

  @media screen and (min-width: $tablet-max) {
    padding: 40px 56px;
  }
}

.currencies-wrapper {
  height: 100%;
  overflow: auto;
  padding: 5px;
}

.header {
  margin: rem(28) 0 rem(12);
  color: $grey-30;
  font-size: rem(16);
  line-height: rem(20);
  font-weight: 700;
}

.currency-search {
  display: block;
  width: 100%;
  background-color: #fff;
  border: none;
  border-radius: 0.375rem;
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.25;
  padding: 0.875rem;
  box-shadow: 0 0 0 2px rgb(0 0 0 / 10%);
  appearance: none;
  transition: box-shadow .2s linear;
  box-sizing: border-box;
}

@media (min-width: $laptop-min) and (max-height: $widget-height-sm) {
  .currencies-layout {
    padding-top: 5.2vh;
  }

  .header {
    margin: calcVh(28, $widget-h-sm) 0 calcVh(12, $widget-h-sm);
    font-size: calcVh(16, $widget-h-sm);
    line-height: calcVh(20, $widget-h-sm);
  }

  .currency-search {
    font-size: calcVh(16, $widget-h-sm);
    line-height: calcVh(20, $widget-h-sm);
    padding: calcVh(14, $widget-h-sm);
  }
}

@media (min-width: $desktop-xlg-min) and (max-height: $widget-height-xlg) {
  .currencies-layout {
    padding-top: 5.22vh;
  }

  .header {
    margin: calcVh(28, $widget-h-xlg) 0 calcVh(12, $widget-h-xlg);
    font-size: calcVh(16, $widget-h-xlg);
    line-height: calcVh(20, $widget-h-xlg);
  }

  .currency-search {
    font-size: calcVh(16, $widget-h-xlg);
    line-height: calcVh(20, $widget-h-xlg);
    padding: calcVh(14, $widget-h-xlg);
  }
}

@media (min-width: $desktop-xxlg-min) and (max-height: $widget-height-xxlg) {
  .currencies-layout {
    padding-top: 5.3vh;
  }

  .header {
    margin: calcVh(28, $widget-h-xxlg) 0 calcVh(12, $widget-h-xxlg);
    font-size: calcVh(16, $widget-h-xxlg);
    line-height: calcVh(20, $widget-h-xxlg);
  }

  .currency-search {
    font-size: calcVh(16, $widget-h-xxlg);
    line-height: calcVh(20, $widget-h-xxlg);
    padding: calcVh(14, $widget-h-xxlg);
  }
}
</style>
