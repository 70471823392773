<template>
  <div
    v-if="showRegistrationWarning"
    class="registration-warning"
    v-html="$t('authentication-app.uk-authorization-warning')"
  />
  <div v-else>
    <HeaderTitle
      :is-back-button-available="isHeaderBackButtonAvailaibe"
      @back="goToExchangeFormScreen"
    >
      &nbsp;
    </HeaderTitle>

    <i class="icon icon-envelope-auth" />

    <div class="auth-app-header">
      {{ $t( `authentication-app.funnel.email-heading` ) }}
    </div>
    <p class="auth-app-funnel-hint">
      {{ $t(emailHintOverride || 'authentication-app.funnel.email-hint') }}
    </p>

    <EmailInput
      :value="email"
      :error-message="errorMessage"
      :show-error="showErrorMessage"
      class="email-input"
      @valid="isSubmitDisabled = false"
      @invalid="isSubmitDisabled = true"
      @submit="handleSubmitEmail"
      @input="handleEmailInput"
    />

    <div class="auth-app-footer auth-app-footer--centered">
      <button
        :class="{ 'is-loading': isLoading }"
        :disabled="isSubmitDisabled"
        class="btn btn-primary btn-lg"
        @click="handleSubmitEmail"
      >
        {{ $t('authentication-app.buttons.continue') }}
      </button>
    </div>
  </div>
</template>

<script>
import EmailInput from '../../components/EmailInput';
import HeaderTitle from '../../components/HeaderTitle';
import AuthService from '../../services/auth';
import { Session } from '../../models/session';
import { TooEarlyError } from '../../models/auth-error';
import { useI18n } from 'vue-i18n';
import store from '@/store';

export default {
  name: 'SalesFunnelEmailView',

  components: {
    EmailInput,
    HeaderTitle,
  },

  inject: ['emailHintOverride'],

  props: {
    session: {
      type: Session,
      default: null,
    },
    flowName: {
      type: String,
      required: true,
    },
    theme: {
      type: String,
      required: false,
    },
    sourceInfo: {
      type: Object,
      default: null,
    },
    isBackButtonAvailaibe: {
      type: Boolean,
      default: false,
    },
    isApplePayOneClickFlow: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    const { t } = useI18n();

    return {
      t,
      email: '',
      errorMessage: '',
      showErrorMessage: false,
      isSubmitDisabled: true,
      isLoading: false,
      showRegistrationWarning: false,
    };
  },
  computed: {
    isHeaderBackButtonAvailaibe() {
      if (store.getters.isOneClickFlow) return false;
      return this.isBackButtonAvailaibe;
    },
  },

  methods: {
    handleEmailInput(email) {
      this.email = email;
      this.showErrorMessage = false;
    },

    handleSubmitEmail() {
      if (this.isSubmitDisabled || this.isLoading) {
        this.showErrorMessage = this.isSubmitDisabled;
        return;
      }

      this.isLoading = true;
      AuthService.startSession(this.email, this.$i18n.locale, this.flowName, this.theme, false, this.sourceInfo)
        .then(session => this.$emit('success', session))
        .catch(error => {
          this.isLoading = false;
          this.showErrorMessage = true;
          if (error instanceof TooEarlyError) {
            return this.errorMessage = this.t('authentication-app.error.too-early', {
              canBeResentAfter: error.getResendTimer(),
            });
          }
          const { statusCode, isCritical } = error;
          if (typeof statusCode === 'undefined' || isCritical) {
            this.showErrorMessage = false;
            return this.$emit('critical-error', error);
          }

          if (statusCode === 'registration-forbidden') {
            this.showRegistrationWarning = true;
            return;
          }

          this.errorMessage = this.t(`authentication-app.error.${statusCode}`);
        });
    },

    goToExchangeFormScreen() {
      this.$router.push({
        path: '/v2/exchange-form',
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../assets/scss/btn';
@import '../../assets/scss/icon';

.icon {
  height: 6rem;
  align-self: center;
  margin-bottom: 30px;
  background-size: contain;

  @media (max-width: $laptop-min) {
    height: 4.5rem;
    margin: 0 auto 20px;
  }
}

.auth-app-header {
  margin-bottom: 30px;
}

.email-input {
  margin-bottom: 2.5rem;
}

.auth-app-funnel-hint {
  font-size: 1rem;
  margin-bottom: 2rem;
  text-align: center;
  line-height: 1.375;
}

.registration-warning {
  text-align: center;
}
</style>
