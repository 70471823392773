<template>
  <div class="sales-funnel-footer-text">
    <div class="sales-funnel-footer-text__inner">
      <div
        v-if="hasMainSlot"
        class="sales-funnel-footer-text__main"
      >
        <slot name="main" />
      </div>

      <div class="sales-funnel-footer-text__left">
        <slot name="left" />
      </div>

      <div class="sales-funnel-footer-text__right">
        <slot name="right" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FooterText',

  computed: {
    hasMainSlot() {
      return !!this.$slots.main;
    },
  },
};
</script>

<style scoped lang="scss">
.sales-funnel-footer-text {
  font-size: rem(14);
  line-height: 1.29;
  color: $grey-30;
  padding: rem(16);
  border-top: 1px solid $black-06;

  &:first-child {
    border: none;
  }

  @media screen and (max-width: $screen-md-max) {
    text-align: center;

    &__left + &__right {
      margin-top: rem(8);
    }
  }

  @media screen and (min-width: $screen-lg-min) {
    padding: rem(24);

    &__inner {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &__main {
      max-width: rem(416);
      margin: 0 auto;
      text-align: center;
    }

    &__right {
      text-align: right;
    }
  }
}
</style>
