<template>
  <div v-if="isEnabledAssetNames" class="currency-item">
    <i
      v-if="!currencyModal.hideIcons"
      class="svg-icon"
      :class="`icon-${item.currency.toLowerCase()}`"
    />
    <i18n-t
      tag="span"
      class="currency-item__description"
      :keypath="`asset-names.${item.code}`"
    >
      <template #currencyCode>
        <span class="currency-item__title">
          {{ asset.currency.code }}
        </span>
      </template>
      <template #networkName>
        <span class="currency-item__network">
          {{ asset.network?.name }}
        </span>
      </template>
      <template #blockchainName>
        <span class="currency-item__blockchain">
          {{ asset.blockchain.name }}
        </span>
      </template>
    </i18n-t>
    <div class="currency-item__next svg-icon icon-arrow-bottom-black" />
  </div>

  <div v-else class="currency-item currency-item--feature-disabled">
    <i
      v-if="!currencyModal.hideIcons"
      class="svg-icon"
      :class="`icon-${item.currency.toLowerCase()}`"
    />
    <div class="currency-item__description currency-item__description--feature-disabled">
      <div class="currency-item__title currency-item__title--feature-disabled">
        {{ item.currency }}
      </div>
      <div class="currency-item__name">
        {{ item.name }}
      </div>
    </div>
    <div class="currency-item__next svg-icon icon-arrow-bottom-black" />
  </div>
</template>

<script setup>
import useSelectCurrencyModal from './useSelectCurrencyModal';

const props = defineProps({
  item: {
    type: Object,
    required: true,
  },
  asset: {
    type: Object,
    required: true,
  },
  isEnabledAssetNames: {
    type: Boolean,
    required: true,
  },
})
const { currencyModal } = useSelectCurrencyModal();
</script>

<style lang="scss" scoped>
.currency-item {
  display: flex;
  line-height: rem(20);
  padding: rem(12) 0;
  border-top: 1px solid $black-06;
  cursor: pointer;
  align-items: center;
  justify-content: space-between;

  &--feature-disabled {
    line-height: rem(28);
    padding: rem(12) 0;
  }

  &__blockchain {
    color: $grey-30;
    font-weight: 400;
  }

  .svg-icon {
    width: 16px;
    height: 16px;
    margin-right: 8px;
    flex-shrink: 0;
  }

  &:hover {
    opacity: 0.6;
  }

  &__description {
    width: 100%;
    font-weight: 700;

    &--feature-disabled {
      display: flex;
      align-items: center;
      flex-grow: 1;
      gap: rem(45);
      font-weight: 400;
    }
  }

  .currency-item__title {
    width: rem(45);
    font-weight: 400;
    flex-grow: 0;
    color: $grey-30;

    &--feature-disabled {
      font-weight: 700;
      color: $black;
    }
  }

  &__network {
    color: $grey-30;
    font-weight: 400;
  }

  &__next {
    height: rem(12);
    background-size: cover;
    flex-grow: 0;
    transform: rotate(-90deg);
  }
}

@media (min-width: $laptop-min) and (max-height: $widget-height-sm) {
  .currency-item {
    line-height: calcVh(28, $widget-h-sm);
    padding: calcVh(12, $widget-h-sm) 0;

    .svg-icon {
      width: calcVh(16, $widget-h-sm);
      height: calcVh(16, $widget-h-sm);
      margin-right: calcVh(8, $widget-h-sm);
    }

    &__title,
    &__name {
      font-size: calcVh(16, $widget-h-sm);
    }

    &__next {
      height: calcVh(12, $widget-h-sm);
    }
  }
}

@media (min-width: $desktop-xlg-min) and (max-height: $widget-height-xlg) {
  .currency-item {
    line-height: calcVh(28, $widget-h-xlg);
    padding: calcVh(12, $widget-h-xlg) 0;

    .svg-icon {
      width: calcVh(16, $widget-h-xlg);
      height: calcVh(16, $widget-h-xlg);
      margin-right: calcVh(8, $widget-h-xlg);
    }

    &__title,
    &__name {
      font-size: calcVh(16, $widget-h-xlg);
    }

    &__title {
      width: calcVh(65, $widget-h-xlg);
    }

    &__next {
      height: calcVh(12, $widget-h-xlg);
    }
  }
}

@media (min-width: $desktop-xxlg-min) and (max-height: $widget-height-xxlg) {
  .currency-item {
    line-height: calcVh(28, $widget-h-xxlg);
    padding: calcVh(12, $widget-h-xxlg) 0;

    .svg-icon {
      width: calcVh(16, $widget-h-xxlg);
      height: calcVh(16, $widget-h-xxlg);
      margin-right: calcVh(8, $widget-h-xxlg);
    }

    &__title,
    &__name {
      font-size: calcVh(16, $widget-h-xxlg);
    }

    &__title {
      width: calcVh(65, $widget-h-xxlg);
    }

    &__next {
      height: calcVh(12, $widget-h-xxlg);
    }
  }
}
</style>
