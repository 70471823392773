/* eslint-disable no-underscore-dangle */

import eventBusFactory from 'js-event-bus';

if (!window.__pb_eventBus) {
  window.__pb_eventBus = eventBusFactory();
}

const eventBusInstance = window.__pb_eventBus;

export default eventBusInstance;
