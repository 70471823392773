<template>
  <div class="recaptcha">
    <div
      v-if="isReCaptchaLoading"
      class="recaptcha__loader"
    />
    <VueRecaptcha
      v-show="!isReCaptchaLoading"
      ref="reCaptcha"
      :sitekey="siteKey"
      :load-recaptcha-script="loadScript"
      :size="size"
      :badge="badge"
      @render="onReCaptchaRendered"
      @verify="onReCaptchaVerify"
    />
    <div
      v-if="errorMessage !== ''"
      class="recaptcha__error"
    >
      {{ errorMessage }}
    </div>
  </div>
</template>

<script>
import VueRecaptcha from 'vue-recaptcha';

export default {
  name: 'ReCaptchaInput',
  components: {
    VueRecaptcha,
  },
  props: {
    errorMessage: {
      type: String,
      default: '',
    },
    loadScript: {
      type: Boolean,
      default: true,
    },
    size: {
      type: String,
      default: 'invisible',
    },
    badge: {
      type: String,
      default: 'inline',
    },
  },
  data() {
    return {
      // eslint-disable-next-line no-undef
      siteKey: VUE_APP_RECAPTCHA_SITEKEY,
      isReCaptchaLoading: true,
    };
  },
  methods: {
    execute() {
      this.$refs.reCaptcha.execute();
    },
    reset() {
      this.$refs.reCaptcha.reset();
    },
    onReCaptchaVerify(reCaptcha) {
      this.$emit('verify', reCaptcha);
    },
    onReCaptchaRendered() {
      this.isReCaptchaLoading = false;
    },
  },
};
</script>
<style lang="scss">
.recaptcha {
  margin-top: 2rem;
  min-height: 60px;

  &__error {
    display: block;
    margin-top: 4px;
    font-size: 12px;
    line-height: 1.33;
    color: $red;
    text-align: center;
  }
  & .grecaptcha-badge {
    box-shadow: none !important;
    margin: 0 auto;
  }
  &__loader {
    position: relative;
    display: flex;
    flex-direction: column;
    padding-top: rem(24);
    overflow: hidden;
    background-color: #f4f5f4;
    height: 60px;
    width: 256px;
    margin: 0 auto;

    &:before {
      content: ' ';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 50%;
      z-index: 1;
      width: 500%;
      margin-left: -250%;
      -webkit-animation: phAnimation 2s linear infinite;
      animation: phAnimation 2s linear infinite;
      background: linear-gradient(
              to right,
              rgba(255, 255, 255, 0) 46%,
              rgba(255, 255, 255, 0.8) 50%,
              rgba(255, 255, 255, 0) 54%
      )
      50% 50%;
    }

    [dir='rtl'] &:before {
      margin-left: unset;
      margin-right: -250%;
      -webkit-animation: rtlPhAnimation 2s linear infinite;
      animation: rtlPhAnimation 2s linear infinite;
    }
  }

  @keyframes phAnimation {
    0% {
      transform: translate3d(-30%, 0, 0);
    }
    100% {
      transform: translate3d(30%, 0, 0);
    }
  }

  @keyframes rtlPhAnimation {
    0% {
      transform: translate3d(30%, 0, 0);
    }
    100% {
      transform: translate3d(-30%, 0, 0);
    }
  }
}
</style>
