<template>
  <div
    v-if="showRegistrationWarning"
    class="registration-warning"
    v-html="$t('authentication-app.uk-authorization-warning')"
  />
  <div v-else>
    <div class="auth-app-header">
      {{ $t('authentication-app.singup.heading') }}
    </div>

    <EmailInput
      :value="email"
      :error-message="errorMessage"
      :show-error="showErrorMessage"
      @valid="isSubmitDisabled = false"
      @invalid="isSubmitDisabled = true"
      @submit="handleSubmitEmail"
      @input="handleEmailInput"
    />

    <div class="auth-app-footer">
      <button
        :class="{ 'is-loading': isLoading }"
        :disabled="isSubmitDisabled"
        class="btn btn-primary btn-lg"
        @click="handleSubmitEmail"
      >
        {{ $t('authentication-app.buttons.continue') }}
      </button>

      <div class="auth-app-footer__secondary">
        <a
          href="#"
          class="btn-link"
          @click.prevent="$emit('change-flow', 'login')"
        >
          {{ $t('authentication-app.buttons.registered.title') }}
          <br>
          {{ $t('authentication-app.buttons.registered.cta') }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import EmailInput from '../../components/EmailInput';
import AuthService from '../../services/auth';
import { Session } from '../../models/session';
import { TooEarlyError } from '../../models/auth-error';
import { useI18n } from 'vue-i18n';

export default {
  name: 'SignupEmailView',

  components: {
    EmailInput,
  },

  props: {
    session: {
      type: Session,
      default: null,
    },
  },
  data() {
    const { t } = useI18n();

    return {
      t,
      email: '',
      errorMessage: '',
      showErrorMessage: false,
      isSubmitDisabled: true,
      isLoading: false,
      showRegistrationWarning: false,
    };
  },

  methods: {
    handleEmailInput(email) {
      this.email = email;
      this.showErrorMessage = false;
    },

    handleSubmitEmail() {
      if (this.isSubmitDisabled || this.isLoading) {
        this.showErrorMessage = this.isSubmitDisabled;
        return;
      }
      this.isLoading = true;
      AuthService.startSession(this.email, this.$i18n.locale)
        .then(session => this.$emit('success', session))
        .catch(error => {
          this.isLoading = false;
          this.showErrorMessage = true;
          if (error instanceof TooEarlyError) {
            if (this.session !== null && this.session.isEmailSessionValid()) {
              return this.$emit('change-flow', 'otp.email');
            }
            return this.errorMessage = this.t('authentication-app.error.too-early', {
              canBeResentAfter: error.getResendTimer(),
            });
          }
          const { statusCode, isCritical } = error;
          if (typeof statusCode === 'undefined' || isCritical) {
            this.showErrorMessage = false;
            return this.$emit('critical-error', error);
          }

          if (statusCode === 'registration-forbidden') {
            this.showRegistrationWarning = true;
            return;
          }

          this.errorMessage = this.t(`authentication-app.error.${statusCode}`);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../assets/scss/btn';

.registration-warning {
  text-align: center;
}
</style>
