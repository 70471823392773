<template>
  <div
    class="checkbox__wrapper"
    :class="{
      'has-error': hasError,
    }"
  >
    <label class="checkbox__fauxbox">
      <input
        :id="id"
        v-model="inputVal"
        :name="name"
        type="checkbox"
        @change="$event.stopPropagation()"
      >

      <span />
    </label>

    <div class="checkbox__label">
      <slot />

      <span
        v-if="hasError"
        class="checkbox__error"
      >{{ error }}</span>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

// TODO: The logic pretty simple. Should be replaced with the plain CSS classes
export default defineComponent({
  name: 'Checkbox',

  props: {
    id: {
      type: String,
      required: false,
      default: '',
    },
    name: {
      type: String,
      required: false,
      default: '',
    },
    hasError: {
      type: Boolean,
      required: true,
      default: false,
    },
    error: {
      type: String,
      required: false,
      default: '',
    },
    value: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  emits: ['change'],

  data() {
    return {
      inputVal: this.value,
    };
  },

  watch: {
    inputVal(val) {
      this.$emit('change', val);
    },
  },
});
</script>

<style scoped lang="scss">
.checkbox {
  &__wrapper {
    position: relative;
    display: inline-flex;
  }

  &__fauxbox {
    display: block;
    position: relative;
    cursor: pointer;
    font-size: rem(16);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    width: rem(16);
    min-width: rem(16);
    height: rem(18);
  }

  &__fauxbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  &__fauxbox span {
    position: absolute;
    top: rem(2);
    left: 0;
    height: rem(16);
    width: rem(16);
    border: 1px solid #dbdbdb;
    background-color: $grey-04;
    border-radius: rem(2);
    transition: border-color 0.2s linear;
  }

  [dir='rtl'] &__fauxbox span {
    right: 0;
    left: unset;
  }

  &__fauxbox input:checked ~ span:after {
    content: '\e912';
    @include inline-icon;
    position: absolute;
    top: -1px;
    left: -1px;
  }

  [dir='rtl'] &__fauxbox input:checked ~ span:after {
    right: -1px;
    left: unset;
  }

  &__fauxbox:hover span {
    border-color: $black-20;
  }

  &__wrapper.has-error &__fauxbox span {
    border-color: $red;
  }

  &__wrapper.has-error &__fauxbox:hover span {
    border-color: rgba(231, 60, 64, 0.25);
  }

  &__label {
    margin-left: rem(12);
    font-size: rem(14);
    line-height: 1.25;
  }

  &__description {
    color: #b2b2b2;
    margin-top: rem(2);
  }

  [dir='rtl'] &__label {
    margin-right: rem(12);
    margin-left: unset;
  }

  &__label a {
    border-bottom: 1px solid $black-15;
    transition: border-color 0.2s linear;
  }

  &__label a:hover {
    border-color: $black-60;
  }

  &__error {
    display: block;
    margin-top: rem(4);
    font-size: rem(12);
    line-height: 1.33;
    color: $red;
  }
}
</style>
