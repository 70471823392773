<template>
  <Modal
    append-to-body
    size="auto"
    modal-style="loader"
    :show="show"
    non-closable
    @close="emit('close')"
  >
    <div class="apple-pay-one-click-form-error-popup__inner">
      <i class="apple-pay-one-click-form-error-popup__icon icon icon-alert" />
      <div class="apple-pay-one-click-form-error-popup__title">
        {{ $t('transaction-flow.popup.payment-fallback.title.apple-pay') }}
      </div>
      <div class="apple-pay-one-click-form-error-popup__msg">
        {{ $t('transaction-flow.popup.payment-fallback.message.apple-pay') }}
      </div>
      <div class="apple-pay-one-click-form-error-popup__submit-row is-centered">
        <div class="apple-pay-one-click-form-error-popup__center-item">
          <button
            class="btn-back-link btn-back-link--mob-short"
            style="color: #000000"
            @click="close"
          >
            {{ $t('shared.navigation.button.try-again.apple-pay') }}
          </button>
        </div>
      </div>
    </div>
  </Modal>
</template>

<script setup>
import Modal from '@paybis/frontend-common-lib/src/components/modal';

const emit = defineEmits(['close']);
defineProps({
  show: {
    type: Boolean,
    required: false,
    default: false,
  },
});

function close() {
  emit('close');
}
</script>

<style scoped lang="scss">
.apple-pay-one-click-form-error-popup {
  &__submit-row {
    margin-top: rem(36);
    position: relative;
  }

  &__submit-row.is-centered {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__left-item {
    position: absolute;
    left: 0;
  }

  &__right-item {
    position: absolute;
    right: 0;
  }

  &__icon {
    font-size: rem(40);
    line-height: rem(24);
    margin: auto;
    padding-bottom: rem(32);
  }

  &__inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: rem(34) rem(32) rem(32) rem(32);
  }

  &__title {
    font-weight: 700;
    font-size: rem(18);
    line-height: rem(24);
    text-align: center;
  }

  &__msg {
    width: rem(480);
    margin-top: rem(4);
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    font-size: rem(14);
    line-height: rem(18);
  }

  &__submit {
    position: relative;
    margin-top: rem(32);
    display: flex;
    justify-content: center;
  }

  &__center-item {
    &:nth-child(1) {
      margin-right: 40px;
    }
  }

  @media screen and (max-width: $screen-xs-max) {
    &__msg {
      width: auto;
    }

    &__submit-row {
      flex-direction: column;
    }

    &__center-item {
      &:nth-child(1) {
        margin: 0 0 10px;
      }
    }
  }
}

@media screen and (max-width: $screen-xs-max) {
  .btn-back-link--mob-short {
    font-size: 1rem;
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>
