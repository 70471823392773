import { initAPI } from '@paybis/frontend-common-lib/src/services/api';

import { install as setupDI } from './views-injection';
import { install as installRoute } from './route/index';
import { install as installStore } from './store/index';

import component from './App';

export default (() => {
  const installable = component;

  installable.install = (
    Vue,
    { store, router, app = null, injectViews = {} },
  ) => {
    setupDI({
      appType: app || 'paybis',
      config: injectViews,
    });
    installStore(store, app);
    installRoute(router, store, app);

    initAPI(store);

    Vue.component('TransactionFunnel', installable);
  };

  return installable;
})();
