<template>
  <div
    class="form-row"
    :class="{
      'double-margin': doubleMargin,
      wrap: wrap,
      'is-centered': isCentered,
      'is-lg': media === 'lg'
    }"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: 'FormRow',
  props: {
    media: { type: String, default: 'lg' },
    doubleMargin: { type: Boolean, reqired: false, default: false },
    wrap: { type: Boolean, reqired: false, default: false },
    isCentered: { type: Boolean, reqired: false, default: false },
  },
};
</script>

<style scoped lang="scss">
.form-row {
  display: flex;

  & + & {
    margin-top: rem(16);
  }

  & + &.double-margin {
    margin-top: rem(32);
  }

  &.wrap {
    flex-wrap: wrap;
    margin: rem(-8) rem(-12);
  }

  &.is-centered {
    justify-content: center;
  }

  &.is-lg {
    @media screen and (max-width: $screen-md-max) {
      flex-wrap: wrap;

      &.wrap {
        padding: rem(8) rem(12);
      }
    }
  }
}
</style>
