import { reactive } from 'vue';

export const ModalTargetEnum = {
  from: Symbol('from'),
  to: Symbol('to'),
};

const currencyModal = reactive({
  onChange: () => {},
  currencies: {
    popular: [],
    all: [],
  },
  hideIcons: true,
  display: false,
});

export default () => ({
  currencyModal,

  showCurrencyModal: ({ popularCurrencies, allCurrencies, extra = {} }) => {
    const { hideIcons = false } = extra;

    currencyModal.currencies = {
      popular: popularCurrencies,
      all: allCurrencies,
    };
    currencyModal.hideIcons = hideIcons;
    currencyModal.display = true;

    return new Promise(resolve => {
      currencyModal.onChange = resolve;
    });
  },
});
