import IntroJs from 'intro.js';
import 'intro.js/introjs.css';
import { StorageService } from '@/services/storageService';
import { captureException } from '@/services/ErrorService/Sentry';

const cookieExpiration = new Date().setFullYear(new Date().getFullYear() + 10); // expires in 10 years
const intro = IntroJs();
intro.setOptions({
  exitOnEsc: false,
  exitOnOverlayClick: false,
  disableInteraction: true,
  showBullets: false,
  showProgress: false,
  showStepNumbers: false,
  overlayOpacity: 0.6,
  nextLabel: 'OK',
  doneLabel: 'OK',
});

const ResizeObserverSuper = window.ResizeObserver;
const resizeObserver = new ResizeObserverSuper(() => {
  setTimeout(() => {
    window.dispatchEvent(new Event('resize'));
  }, 0);
});
resizeObserver.observe(document.documentElement);

export const IntroService = {
  getCookieKeyForHints(sessionName) {
    return `userSeenHints_${sessionName}`;
  },
  getHintsUserData(sessionName) {
    let hintUserData = StorageService.get(this.getCookieKeyForHints(sessionName));
    try {
      // JSON parse is used for old storage data so hints do not break
      hintUserData = JSON.parse(hintUserData);
      this.setHintsUserData(sessionName, hintUserData);
    } catch (e) {
      captureException(e);
    }
    return hintUserData;
  },
  setHintsUserData(sessionName, payload) {
    StorageService.set(this.getCookieKeyForHints(sessionName), payload, new Date(cookieExpiration));
  },
  isNewUserForHints(sessionName) {
    return !StorageService.get(this.getCookieKeyForHints(sessionName));
  },
  onHintChange(sessionName) {
    intro.onchange(element => {
      const hintsUserData = {
        currentStep: element.getAttribute('data-step'),
        hintsSeen: false,
      };
      this.setHintsUserData(sessionName, hintsUserData);
    });
  },
  onCompleteIntro(sessionName) {
    intro.oncomplete(() => {
      const currentIntroUserData = this.getHintsUserData(sessionName);
      if (intro._introItems.length - 1 === intro._currentStep) {
        currentIntroUserData.hintsSeen = true;
      }
      this.setHintsUserData(sessionName, currentIntroUserData);
    });
  },
  exitIntroSession() {
    intro.exit([true]);
  },
  initHintsIntro(sessionName) {
    this.onHintChange(sessionName);
    this.onCompleteIntro(sessionName);
    intro.start();
  },
  startIntro(sessionName) {
    if (this.isNewUserForHints(sessionName)) {
      this.initHintsIntro(sessionName);
      return;
    }
    if (!this.getHintsUserData(sessionName).hintsSeen) {
      const currentHintsUserData = this.getHintsUserData(sessionName);
      this.initHintsIntro(sessionName);
      intro.goToStep(Number(currentHintsUserData.currentStep));
    }
  },
};
