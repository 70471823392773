<template>
  <FormInput
    :value="value"
    :label="$t('authentication-app.email.title')"
    :error="error"
    :has-error="showError"
    type="email"
    inputmode="email"
    name="email"
    @input="handleEmailInput"
    @keyup.enter.native="$emit('submit')"
    @paste="onPaste"
  />
</template>

<script>
import EmailValidator from 'email-validator';

import FormInput from '../FormInput';
import { useI18n } from 'vue-i18n';

export default {
  name: 'EmailInput',

  components: {
    FormInput,
  },

  props: {
    value: {
      type: String,
      required: true,
    },
    errorMessage: {
      type: String,
      default: '',
    },
    showError: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    const { t } = useI18n();

    return {
      t,
    };
  },

  computed: {
    error() {
      return this.errorMessage === '' ? this.t('authentication-app.errors.email') : this.errorMessage;
    },
    isEmailValid() {
      return EmailValidator.validate(this.value);
    },
  },

  watch: {
    value() {
      if (this.isEmailValid) {
        this.$emit('valid');
        return;
      }

      this.$emit('invalid');
    },
  },

  methods: {
    handleEmailInput(value) {
      this.$emit('input', value);
    },

    onPaste(event) {
      this.$emit('input', event.clipboardData.getData('Text').trim());
    },
  },
};
</script>
