<template>
  <div>
    <FunnelLoader/>
    <Title>
      {{ $t('transaction-flow.steps.payment-waiting.apm.title') }}
    </Title>
    <Paragraph>{{ waitingTimeLabel }}</Paragraph>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import Paragraph from '@paybis/frontend-common-lib/src/components/paragraph';
import Title from '@paybis/frontend-common-lib/src/components/title';

import FunnelLoader from './funnel-loader';
import { useI18n } from 'vue-i18n';

export default {
  components: {
    FunnelLoader,
    Title,
    Paragraph,
  },

  data() {
    const { t } = useI18n();

    return {
      t,
    };
  },

  computed: {
    ...mapGetters('transaction', ['payment']),

    waitingTimeLabel() {
      const { slug } = this.payment;
      let key = `transaction-flow.money-service.${slug}.payment-waiting.apm.waiting-time`;

      if (slug.includes('token_io') || slug.includes('tokenio')) {
        key = 'transaction-flow.steps.payment-waiting.apm.waiting-time-days';
      } else if (!this.t(key)) {
        key = 'transaction-flow.steps.payment-waiting.apm.waiting-time';
      }

      return this.t(key);
    },
  },
};
</script>

<style lang="scss">
</style>
