<template>
  <button
    class="apple-pay-button-with-text btn btn-primary btn-lg"
    :class="{
      'is-loading': isDisabled || isLoading
    }"
    :lang="lang"
    :disabled="isDisabled || isLoading"
    @click.prevent="startSession"
  >
    <span class="apple-pay-button-with-text__image">
      <img
        :src="
          require('@paybis/frontend-common-lib/src/assets/images/apple-pay.svg')
        "
      >
    </span>
  </button>
</template>

<script setup>
import CardPaymentClient from '@paybis/frontend-common-lib/src/services/clients/card-payment-client';
import { captureException } from '@paybis/frontend-common-lib/src/plugins/sentry';
import store from '@/store';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';

const props = defineProps({
  lang: { type: String, required: true },
  settings: { type: Object, required: true },
  countryCode: { type: String, required: false, default: null },
  disabled: { type: Boolean, required: false, default: false },
  amount: { type: String, required: true },
  amountCurrency: { type: String, required: true },
});

const isDisabled = ref(false);
const { t } = useI18n();

const isLoading = computed(() => {
  return !props.amount || !props.amountCurrency || !props.countryCode;
});

const emit = defineEmits(['pay', 'error']);

let session = null;

function startSession() {
  if (isDisabled.value) {
    return;
  }
  isDisabled.value = true;

  const {
    version,
    supportedNetworks,
    merchantCapabilities,
    paymentLabel: label,
  } = props.settings;
  const total = { label, amount: props.amount };

  try {
    session = new ApplePaySession(version, {
      countryCode: props.countryCode,
      currencyCode: props.amountCurrency,
      requiredBillingContactFields: [
        'postalAddress',
      ],
      requiredShippingContactFields: [
        'email',
      ],
      supportedNetworks,
      merchantCapabilities,
      total,
    });

    session.onvalidatemerchant = ({ validationURL: validationUrl }) => {
      CardPaymentClient.validatePaymentSession({ validationUrl }).then(({ data }) => {
        if (typeof data !== 'object') {
          const error = new Error('Received invalid response for ApplePay!');
          handleError(error, { data });
          return;
        }
        session.completeMerchantValidation(data);
      }).catch(error => {
        handleError(error);
      });
    };

    session.onpaymentauthorized = ({ payment }) => {
      const { token, billingContact = {} } = payment;
      const {
        givenName = '',
        familyName = '',
        countryCode = '',
        postalCode = '',
        administrativeArea = null,
        locality = '',
        addressLines = [],
      } = billingContact;

      const validationErrors = checkRequiredFields(billingContact);
      if (validationErrors) {
        session.completePayment(validationErrors);
        return;
      }

      store.dispatch('request/updateEmail', payment.shippingContact.emailAddress, { root: true });

      const payload = {
        token: token.paymentData,
        cardholderName: `${givenName} ${familyName}`.trim(),
        session,
        currency: props.amountCurrency,
        language: props.lang,
        };

      payload.billingAddress = {
        address: addressLines.map(el => el.trim()).filter(el => el).join(', '),
        city: locality.trim(),
        country: countryCode,
        state: countryCode === 'US' ? administrativeArea.trim() : null,
        zip: postalCode.trim(),
      };

      emit('pay', payload);

      // Close applePay popup with payment success
      session.completePayment({ status: 0 });
    };

    const updateParams = { newTotal: total };
    session.onpaymentmethodselected = event => {
      session.completePaymentMethodSelection(updateParams);
    };

    session.onshippingmethodselected = event => {
      session.completeShippingMethodSelection(updateParams);
    };

    session.onshippingcontactselected = event => {
      session.completeShippingContactSelection(updateParams);
    };

    session.oncancel = () => {
      isDisabled.value = false;
    };

    session.begin();
  } catch (error) {
    handleError(error);
  }
}

function handleError(error, extra = {}) {
  // Dismiss applePay popup with payment failure
  if (session) session.completePayment({ status: 1 });

  isDisabled.value = false;
  const errorInst = error instanceof Error ? error : new Error(error.data.message);

  const { status } = error;
  if (status && status === 400) {
    return;
  }

  captureException({
    error: errorInst,
    extra,
  });
  emit('error', { error });
}

function checkRequiredFields({ familyName, givenName, locality, addressLines, countryCode, postalCode }) {
  const errors = [];
  const address = addressLines.map(el => el.trim()).filter(el => el).join(', ');

  if (!familyName.trim() || !givenName.trim()) {
    errors.push(new ApplePayError('billingContactInvalid', 'name', t('transaction-flow.apple-pay.validation.name')));
  }

  if (!locality.trim()) {
    errors.push(new ApplePayError('billingContactInvalid', 'locality', t('validators.billing-address.aft.city.required')));
  }

  if (address.length === 0) {
    errors.push(new ApplePayError('billingContactInvalid', 'addressLines', t('validators.billing-address.aft.address.required')));
  }

  if (!countryCode) {
    errors.push(new ApplePayError('billingContactInvalid', 'countryCode', t('validators.billing-address.aft.country.required')));
  }

  if (!postalCode.trim()) {
    errors.push(new ApplePayError('billingContactInvalid', 'postalCode', t('validators.billing-address.aft.zip.required')));
  }

  if (errors.length > 0) {
    return {
      status: ApplePaySession.STATUS_FAILURE,
      errors,
    };
  }

  return null;
}
</script>
<style scoped lang="scss">
.apple-pay-button-with-text {
  cursor: pointer;
  display: flex;
  --apple-pay-scale: 1; /* (height / 32) */

  &__image {
    display: flex;
    height: 100%;
    align-items: center;
    transition: opacity;
  }

  & > .text {
    font-family: -apple-system;
    font-weight: 700;
    margin-right: calc(6px * var(--apple-pay-scale));
  }

  &.is-loading &__image {
    opacity: 0;
  }
}
</style>
