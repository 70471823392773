export class Callout {
  constructor(message = '', className = '') {
    this.message = message;
    this.className = className;

    this.success = false;
    this.warning = false;
    this.notice = false;
  }

  hasMessage() {
    return this.message !== '';
  }

  getClassName() {
    return this.className;
  }

  getMessage() {
    return this.message;
  }

  hasSuccess() {
    return this.success;
  }

  hasWarning() {
    return this.warning;
  }

  hasNotice() {
    return this.notice;
  }
}

export class NoticeCallout extends Callout {
  constructor(message, className) {
    super(message, className);
    this.notice = true;
  }
}

export class WarningCallout extends Callout {
  constructor(message, className) {
    super(message, className);
    this.warning = true;
  }
}

export class SuccessCallout extends Callout {
  constructor(message, className) {
    super(message, className);
    this.success = true;
  }
}
