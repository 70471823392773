import { useStore } from 'vuex';

export const useIsAppInitialized = () => {
  const store = useStore();

  return {
    getAppInitializedPromise: () => new Promise(resolve => {
      store.watch(() => store.getters.isInitCompleted, isInitialized => {
        if (isInitialized) {
          resolve();
        }
      }, { immediate: true });
    }),
  };
};
