<template>
  <ul class="sales-funnel-header-steps">
    <li
      v-for="(step, i) in steps"
      :key="i"
      class="sales-funnel-header-steps__item"
      :class="{ 'is-active': i <= currentIndex }"
    >
      {{ $t('transaction-flow.header-steps.' + step.title) }}
    </li>
  </ul>
</template>

<script>
export default {
  name: 'HeaderSteps',

  props: {
    steps: { type: Array, required: false, default: () => [] },
    currentIndex: { type: Number, required: false, default: 0 },
  },
};
</script>

<style scoped lang="scss">
.sales-funnel-header-steps {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  @media screen and (max-width: $screen-md-max) {
    padding: rem(22) rem(16);
    margin: 0 rem(-5);

    &__item {
      font-size: 0;
      display: block;
      width: rem(6);
      height: rem(6);
      border-radius: 50%;
      margin: 0 rem(5);
      background-color: $black-15;
    }

    &__item.is-active {
      background-color: $black;
    }
  }

  @media screen and (min-width: $screen-lg-min) {
    padding: rem(20) rem(24);
    margin: 0 rem(-12);

    &__item {
      position: relative;
      font-weight: 700;
      font-size: rem(14);
      line-height: 1.14;
      color: $grey-30;
      white-space: nowrap;
      padding: rem(4) 0;
      margin: 0 rem(12);
    }

    &__item.is-active {
      color: $black;
    }

    &__item + &__item:before {
      content: '\e927';
      @include inline-icon;
      font-size: rem(16);
      position: absolute;
      left: rem(-20);
      top: rem(4);
    }

    [dir='rtl'] &__item + &__item:before {
      content: '\e928';
      right: rem(-20);
      left: unset;
    }
  }
}
</style>
