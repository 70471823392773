<template>
  <div class="local-header">
    <i class="svg-icon icon-failed" />
    {{ $t('widget.cancelled.title') }}
  </div>

  <div class="local-body">
    <p class="local-body-info">
      {{ $t('widget.cancelled.info') }}
    </p>
  </div>

  <div class="local-footer">
    <MerchantRedirect
      v-if="failureReturnURL"
      :url="failureReturnURL"
    />
    <div class="buttons-group">
      <CloseWidgetButton
        v-if="isCloseButtonVisible"
        mode="view"
        @click="closeWidget"
      />
      <BackToMerchantButton
        v-if="failureReturnURL"
        :url="failureReturnURL"
      />
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

import { canClose } from '@/services/widgetSettings';
import { emitToParent, STATES } from '@/services/widgetApiEvents';
import CloseWidgetButton from '@/components/CloseWidgetButton/CloseWidgetButton';
import BackToMerchantButton from '@/components/BackToMerchantButton/BackToMerchantButton';
import { mapGetters } from 'vuex';
import MerchantRedirect from '@/components/MerchantRedirect/MerchantRedirect.vue';

export default defineComponent({
  name: 'CustomCancelled',
  components: { MerchantRedirect, BackToMerchantButton, CloseWidgetButton },
  computed: {
    ...mapGetters(['failureReturnURL']),
    isCloseButtonVisible() {
      return canClose();
    },
  },

  methods: {
    closeWidget() {
      emitToParent('state', {
        state: STATES.STATE_CANCELLED,
      });
    },
  },
});
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/blocks/buttons-group";

.local {
  &-header {
    text-align: center;
    margin-bottom: -20px;

    font-size: rem(28);
    line-height: 1;
    font-weight: 700;
    margin-bottom: 16px;
  }

  &-body {
    text-align: center;
    margin-bottom: 40px;
  }

  &-body-info {
    max-width: rem(370);
    text-align: center;
    margin: 0 auto rem(24) auto;

    @media (max-width: $tablet-max) {
      width: auto;
    }
  }

  &-footer {
    text-align: center;
  }
}

.svg-icon {
  display: block;
  margin: rem(20) auto rem(24) auto;
  width: rem(96);
  height: rem(96);

  @media (max-width: $tablet-max) {
    width: 80px;
    height: 80px;
  }
}

.btn-link {
  color: inherit;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);

  &:hover {
    border-color: rgba(0, 0, 0, 0.6);
  }
}

@media (min-width: $laptop-min) and (max-height: $widget-height-sm) {
  .local {
    &-header {
      font-size: 3.6vh;
      line-height: 4.16vh;
      margin-bottom: 4vh;
    }

    &-body {
      margin-bottom: calcVh(40, $widget-h-sm);
    }

    &-footer-info {
      font-size: calcVh(16, $widget-h-sm);
      line-height: calcVh(20, $widget-h-sm);
      margin: 0 auto calcVh(24, $widget-h-sm) auto;
    }
  }

  .svg-icon {
    margin: calcVh(20, $widget-h-sm) auto calcVh(24, $widget-h-sm) auto;
    width: calcVh(96, $widget-h-sm);
    height: calcVh(96, $widget-h-sm);
  }
}

@media (min-width: $desktop-xlg-min) and (max-height: $widget-height-xlg) {
  .local {
    &-header {
      font-size: 3.7vh;
      line-height: 4.18vh;
      margin-bottom: 7.31vh;
    }
  }
}

@media (min-width: $desktop-xxlg-min) and (max-height: $widget-height-xxlg) {
  .local {
    &-header {
      font-size: 3.71vh;
      line-height: 4.24vh;
      margin-bottom: 7.42vh;
    }
  }
}
</style>
