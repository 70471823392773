<template>
  <div class="text-button">
    <svg
      v-if="loading"
      class="text-button__loader"
    >
      <circle
        class="text-button__path"
        cx="20"
        cy="20"
        r="15"
        fill="none"
        stroke-width="3"
      />
    </svg>
    <template v-else>
      <span
        class="text-button__icon"
        :class="icon ? `text-button__icon--${icon}`: ''"
      />
      <span class="text-button__text">
        <slot />
      </span>
    </template>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'TextButton',

  props: {
    icon: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
});
</script>

<style scoped lang="scss">
.text-button {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: rem(56);
  background: $grey-04;
  border-radius: 0 0 6px 6px;
  text-align: center;

  &__loader {
    display: flex;
    animation: rotate 2s linear infinite;
    width: 40px;
    height: 40px;
  }

  &__path {
    stroke: $black;
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
  }

  &__text {
    font-weight: 700;
    font-size: rem(18);
    line-height: rem(24);
    user-select: none;

    @media (max-width: $laptop-middle) {
      font-size: 16px;
    }
  }

  &__icon {
    position: relative;
    display: inline-block;
    width: rem(18);
    height: rem(18);
    margin-right: rem(8);
    vertical-align: middle;
    flex-shrink: 0;

    &--chat,
    &--file {
      background-color: #000;
      mask-repeat: no-repeat;
      mask-size: contain;
    }

    &--file {
      mask-image: url(~@/assets/images/widget/file.svg);
    }

    &--chat {
      mask-image: url(~@/assets/images/widget/chat.svg);
    }
  }
}
</style>
