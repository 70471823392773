import { ref } from 'vue';
import { useStore } from 'vuex';

export const usePaymentMethodsInit = () => {
  const store = useStore();

  const isLoaded = ref(true);

  const waitPaymentMethods = () => new Promise(async resolve => {
    if (!isLoaded.value) {
      resolve();
      return;
    }

    isLoaded.value = false;
    await store.dispatch('v2/paymentMethods/getMethods');

    isLoaded.value = true;
    resolve();
  });

  return {
    waitPaymentMethods,
    isLoaded,
  };
};
