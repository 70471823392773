<template>
  <div class="load-screen">
    <div class="load-screen__loader" />
  </div>
</template>

<style lang="scss" scoped>
.load-screen{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40vh;

  &__loader {
    @include spinerIcon;

    margin: 0 auto;
  }
}
</style>
