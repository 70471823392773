<template>
  <div class="tabs">
    <div class="tabs__head">
      <div class="tabs__head-scroll">
        <div class="tabs__head-inner">
          <button
            v-for="(tab, i) in tabList"
            :key="i"
            class="tabs__toggle"
            :class="{
              'is-selected': tab.id === selected,
              'is-disabled': tab.isDisabled,
            }"
            :data-testid="tab.dataTestid"
            @click="handleSelect(tab)"
          >
            {{ tab.title }}
          </button>
        </div>
      </div>
    </div>

    <slot />
  </div>
</template>

<script>
// TODO should be removed in favor of upcoming UI kit components
export default {
  name: 'Tabs',

  data() {
    return {
      tabList: [],
      selected: '',
    };
  },

  created() {
    const slots = this.$slots.default();
    const propsAccessor = 'props';

    slots.forEach(tab => {
      if (!tab.tag) return;

      const tabProps = tab[propsAccessor];

      this.tabList.push(tabProps);
    });

    this.selected = slots[0][propsAccessor]['is-selected'];
  },

  methods: {
    handleSelect(selectedTab) {
      if (selectedTab.isDisabled) {
        return;
      }

      this.selected = selectedTab.id;

      this.$emit('select', selectedTab.id);
    },
  },
};
</script>

<style scoped lang="scss">
@import '../../assets/scss/variables';

.tabs {
  &__head {
    display: flex;
    justify-content: center;
    padding: 16px 0;
    margin-bottom: 4px;
  }

  &__head-scroll {
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    margin: 0 -16px;
  }

  &__head-inner {
    /** START: Quick-fix to display items on new line, if tab title is too long **/
    /*display: inline-flex;*/
    /*justify-content: center;*/
    display: block;
    text-align: center;
    /** END of quick-fix **/
    margin: auto;
    padding: 0 16px;
  }

  &__toggle {
    font-weight: 700;
    font-size: 16px;
    line-height: 32px;
    display: inline-flex;
    align-items: center;
    padding: 0 8px;
    border-radius: 4px;
    white-space: nowrap;
    transition: background-color 0.2s linear;
  }

  &__toggle + &__toggle {
    margin-left: 10px;
  }

  [dir='rtl'] &__toggle + &__toggle {
    margin-right: 10px;
    margin-left: unset;
  }

  &__toggle.is-disabled {
    color: $grey-30;
    cursor: auto;
  }

  &__toggle.is-selected {
    background-color: $beige;
  }

  &__toggle .icon {
    margin-right: 8px;
  }

  [dir='rtl'] &__toggle .icon {
    margin-left: 8px;
    margin-right: unset;
  }

  @media screen and (min-width: $screen-lg-min) {
    &__head {
      margin-bottom: 16px;
    }
  }
}
</style>
