<template>
  <div class="cancel-confirm">
    <div class="cancel-confirm__inner">
      <div class="cancel-confirm__text">
        {{ $t('transaction-flow.cancel-popup.title') }}

        <small class="cancel-confirm__subtitle">
          {{ $t('transaction-flow.cancel-popup.message') }}
        </small>
      </div>

      <div class="cancel-confirm__buttons">
        <div
          class="btn btn-xlg btn-secondary bold"
          @click="$emit('reject')"
        >
          {{ $t('widget.confirmation-modal.no') }}
        </div>
        <div
          class="btn btn-xlg btn-primary bold"
          @click="$emit('confirm')"
        >
          {{ $t('widget.confirmation-modal.yes') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VerificationCancelModal',

  emits: ['reject', 'confirm'],
};
</script>

<style lang="scss" scoped>
.cancel-confirm {
  margin-top: -60px;
  background-color: $white;
  background-clip: padding-box;
  max-width: rem(520);
  padding: rem(34);
  border-radius: rem(6);

  &__inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
  }

  &__subtitle {
    font-family: $font-family;
    font-size: rem(18);
    line-height: rem(24);
    text-align: center;
    display: block;
    margin-top: rem(24);
  }

  &__text {
    margin-bottom: rem(32);
    font-size: rem(22);
    line-height: rem(28);
    font-weight: 700;
    text-align: center;

    @media (max-width: $tablet-max) {
      margin: 0 20px 24px 20px;
    }
  }

  &__buttons {
    @media (max-width: $tablet-max) {
      width: 100%;
      padding: 0 24px;
    }
  }

  .btn {
    width: rem(116);

    @media (max-width: $tablet-max) {
      width: 100%;
    }
  }

  .btn:first-child {
    margin: 0 24px 0 0;

    @media (max-width: $tablet-max) {
      margin: 0 0 12px 0;
    }
  }
}
</style>
