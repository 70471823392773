<template>
  <span>
    <Title>{{
      $t('transaction-flow.steps.payment-details.sumsub-widget.title')
    }}</Title>
    <Loader
      v-if="!isSumsubWidgetInitialized"
      class="mt-32 mb-32 ml-auto mr-auto"
    />
    <Paragraph v-show="isSumsubWidgetInitialized">
      <div
        id="sumsub-websdk-container"
        class="verification-sumsub-frame"
      />
      <div
        v-show="showCancelButton"
        class="sales-funnel-actions"
      >
        <button
          :disabled="isLocked"
          class="btn btn-reject"
          @click="showCancellationPopup = true"
        >
          {{ $t('shared.navigation.button.cancel') }}
        </button>
      </div>
    </Paragraph>
    <CancellationPopup
      :is-locked="isLocked"
      :show="showCancellationPopup"
      @cancel="cancel"
      @close="showCancellationPopup = false"
    />
  </span>
</template>

<script>
import Loader from '@paybis/frontend-common-lib/src/components/loader';
import { captureException } from '@paybis/frontend-common-lib/src/plugins/sentry';
import Paragraph from '@paybis/frontend-common-lib/src/components/paragraph';
import Title from '@paybis/frontend-common-lib/src/components/title';
import { SumsubWebsdkError } from '@paybis/frontend-common-lib/src/models/sumsub';
import CardClient from '@paybis/frontend-common-lib/src/services/clients/card-client';
import { errorHandler as SumsubWidgetErrorHandler } from '@paybis/frontend-common-lib/src/services/sumsub';
import snsWebSdk from '@sumsub/websdk';
import { mapActions, mapGetters, mapMutations } from 'vuex';

import { STEP_ERROR } from '../models/steps';
import CancellationPopup from './verification-cancellation-popup';

const DEFAULT_VERIFICATION_FLOW_NAME = 'CardEntry';

export default {
  components: {
    Title,
    Paragraph,
    Loader,
    CancellationPopup,
  },

  props: {
    card: { type: Object, required: true },
    invoice: { type: String, required: true },
  },

  data() {
    return {
      isSumsubWidgetInitialized: false,
      getCardStatusTimeout: null,
      getPaymentStatusTimeout: null,
      getWidgetConfigParamsTimeout: null,
      showCancellationPopup: false,
      showCancelButton: true,
    };
  },

  computed: {
    ...mapGetters('request', ['requestId', 'invoiceId']),
    ...mapGetters(['themeConfig', 'clientId']),
    ...mapGetters(['language']),
    ...mapGetters(['isLocked']),
  },
  beforeDestroy() {
    if (this.getCardStatusTimeout) clearTimeout(this.getCardStatusTimeout);
    if (this.getPaymentStatusTimeout) clearTimeout(this.getPaymentStatusTimeout);
    if (this.getWidgetConfigParamsTimeout) clearTimeout(this.getWidgetConfigParamsTimeout);
  },
  methods: {
    ...mapActions('cardSumsub', [
      'getSumsubConfig',
      'cancelSumsubVerification',
    ]),
    ...mapActions('card', ['getPaymentStatus']),
    ...mapMutations('cardSumsub', ['sumsubVerificationCompleted']),
    ...mapMutations(['lock', 'clearLocks']),
    getCardStatus() {
      return new Promise((resolve, reject) => {
        CardClient.getCardStatus({ cardId: this.card.getId() })
          .then(({ data }) => {
            this.card.getStatus().setStatus(data.status);
            if (this.card.getStatus().isDuplicate()) {
              clearTimeout(this.getCardStatusTimeout);

              return resolve();
            }
            this.getCardStatusTimeout = setTimeout(() => {
              this.getCardStatus().then(response => {
                resolve(response);
              });
            }, 20000);
          })
          .catch(error => reject(error));
      });
    },
    handlePaymentStatus() {
      return new Promise((resolve, reject) => {
        const handlePaymentStatusWithDelay = () => {
            this.getPaymentStatusTimeout = setTimeout(() => {
                this.handlePaymentStatus().then(response => {
                    resolve(response);
                 });
             }, 1000);
        };
        const paymentId = this.card.getPaymentId();
        if (!paymentId) {
            handlePaymentStatusWithDelay();
            return;
        }
        this.getPaymentStatus({ paymentId })
          .then(({ status }) => {
            if (
              ['void_requested', 'voided', 'void_failed'].indexOf(status) > -1
            ) {
              return resolve();
            }

           handlePaymentStatusWithDelay();
          })
          .catch(reject);
      });
    },
    processCardStatus() {
      return this.getCardStatus().then(() => {
        if (this.card.getStatus().isDuplicate()) {
          this.card.setPaymentId(null);
          this.$emit('verificationError');

          return;
        }

        captureException({
          error: new Error('SumsubWidget: Unhandled card status'),
          extra: {
            invoice: this.invoice,
            cardId: this.card.getId(),
            cardStatus: this.card.getStatus().status,
          },
        });
      });
    },
    requestWidgetConfigParams() {
      return new Promise((resolve, reject) => {
        const cardId = this.card.getId();
        const { invoice } = this;
        this.getSumsubConfig({ cardId, invoice })
          .then(
            ({
              accessToken,
              email,
              verificationFlowName,
              verificationStatus,
            }) => {
              if (verificationStatus.isCreated()) {
                return resolve({
                  accessToken,
                  email,
                  verificationFlowName,
                });
              }

              if (
                verificationStatus.isVerified()
                || verificationStatus.isNotVerified()
              ) {
                return this.processCardStatus();
              }

              // verificationStatus.isNotCreated()
              this.getWidgetConfigParamsTimeout = setTimeout(() => {
                this.requestWidgetConfigParams().then(response => {
                  resolve(response);
                });
              }, 4000);
            },
          )
          .catch(error => {
            reject(error);
          });
      });
    },
    startWidget({ accessToken, email }) {
      const snsWebSdkInstance = snsWebSdk
        .init(accessToken, () => {})
        // eslint-disable-next-line no-undef
        .withBaseUrl(process.env.VUE_APP_SUMSUB_API_URL)
        .withConf({
          lang: this.language,
          email,
          uiConf: {
            hotjar: {
              userId: this.clientId,
              userData: {
                email,
                invoice: this.invoiceId,
              },
            },
          },
        })
        .onMessage((type, payload) => {
          if (this.themeConfig.hotJarId && ['idCheck.onInitialized', 'idCheck.onReady'].includes(type)) {
            // XXX: log for coinmama
            if (window.hj) {
              window.hj('event', 'verification_started');
            }
          }
          if (type === 'idCheck.onInitialized') {
            this.isSumsubWidgetInitialized = true;
            this.clearLocks();

            this.processCardStatus();
          }
          if (type === 'idCheck.actionStatus') {
            if (payload.reviewStatus === 'pending') {
              this.showCancelButton = false;
            }
            if (
              payload.reviewStatus === 'completed'
              && ['GREEN', 'RED'].indexOf(payload.reviewResult.reviewAnswer) > -1
            ) {
              if (
                payload.reviewResult.reviewRejectType === 'RETRY'
                && payload.reviewResult.reviewAnswer === 'RED'
              ) {
                this.showCancelButton = true;
              }
            }
          }
        })
        .on('onError', error => {
          if (window.hj) {
            window.hj('event', 'verification_error');
          }
          const handleResult = SumsubWidgetErrorHandler.handle(
            new SumsubWebsdkError(error),
            email,
          );
          if (handleResult.isFatal()) {
            this.$router.push({ name: STEP_ERROR });
          }
        })
        .build();

      snsWebSdkInstance.launch('#sumsub-websdk-container');
      // eslint-disable-next-line no-extra-boolean-cast
      if (snsWebSdkInstance.iframe && Boolean(process.env.VUE_APP_HOTJAR_ENABLED)) {
        snsWebSdkInstance.iframe.setAttribute('data-hj-allow-iframe', '');
      }
    },
    cancel() {
      this.lock();
      return new Promise((resolve, reject) => {
        clearTimeout(this.getCardStatusTimeout);
        this.cancelSumsubVerification({ cardId: this.card.getId() })
          .then(() => {
            this.handlePaymentStatus().then(response => {
              resolve(response);
            });
          })
          .catch(error => {
            reject(error);
          });
      }).then(() => {
        this.showCancellationPopup = false;
        this.card.setPaymentId(null);
        this.$emit('verificationCancelled');
      });
    },
  },
  mounted() {
    if (window.hj) {
      window.hj('event', 'verification_widget');
    }
    try {
      this.requestWidgetConfigParams().then(configParams => {
        this.startWidget(configParams);
      });
    } catch (error) {
      captureException({
        error,
        extra: {
          invoice: this.invoice,
          cardId: this.card.getId(),
          cardStatus: this.card.getStatus().status,
        },
      });

      this.card.setPaymentId(null);
      this.$emit('verificationError');
    }
  },
};
</script>

<style scoped lang="scss">
.sales-funnel-actions {
  position: relative;
  margin-top: rem(32);
}
/* Styles based on .verification-video-frame */
.verification-sumsub-frame {
  min-height: 500px;
  @media screen and (min-width: 482px) {
    margin: -2.5rem calc(-50vw + #{rem(240)}) -3.5rem;
  }

  @media screen and (min-width: 676px) {
    margin: -2.5rem rem(-104) -3.5rem;
  }
}
</style>
