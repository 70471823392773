<template>
  <div class="phone-otp">
    <div class="auth-app-header">
      {{ $t(`authentication-app.${flowName}.phone-otp-heading`) }}
    </div>

    <i class="icon icon-verify-phone phone-otp__icon" />

    <p class="sent-notice">
      {{
        $t('authentication-app.otp.phone.sent-verification-code', {
          phone: session.getPhoneOtpSession().getContext(),
        })
      }}
      <br>
      {{
        $t('authentication-app.otp.phone.input-code-label', {
          phone: session.getPhoneOtpSession().getContext(),
        })
      }}
    </p>

    <Otp
      :resend-time="session.getPhoneOtpSession().getCanBeResentAfter()"
      :error-message="errorMessage"
      :is-loading="isLoading"
      @success-otp="onSuccessOtp"
      @submit="onSuccessOtp"
      @incomplete-otp="onIncompleteOtp"
      @resend-otp="onResendOtp"
      @back="handleBack"
    />
  </div>
</template>

<script>
import Otp from '../../components/Otp';
import { Session } from '../../models/session';
import AuthService from '../../services/auth';
import { OtpError } from '../../models/auth-error';
import { useI18n } from 'vue-i18n';

export default {
  name: 'PhoneOtpView',

  components: {
    Otp,
  },

  props: {
    session: {
      type: Session,
      required: true,
    },
    flowName: {
      type: String,
      default: 'funnel',
    },
  },

  data() {
    const { t } = useI18n();

    return {
      t,
      errorMessage: '',
      isLoading: false,
    };
  },

  computed: {
    otpSession() {
      return this.session.getPhoneOtpSession();
    },
    userPhoneNumber() {
      return this.session.getPhoneOtpSession().getContext();
    },
  },

  methods: {
    onIncompleteOtp() {
      this.errorMessage = '';
    },

    onResendOtp() {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      AuthService.resendPhoneOtp(this.session).catch(error => {
        const { statusCode, isCritical } = error;
        if (typeof statusCode === 'undefined' || isCritical) {
          return this.$emit('critical-error', error);
        }

        this.errorMessage = this.t(`authentication-app.error.${statusCode}`);
      }).finally(() => {
        this.isLoading = false;
      });
    },

    onSuccessOtp(otp) {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      AuthService.confirmPhoneOtp(this.session, otp, true)
        .then(tokens => this.$emit('success-otp-phone', tokens))
        .catch(error => {
          this.isLoading = false;
          if (error instanceof OtpError) {
            const { canBeResentAt, expirationTime, attempts } = error;
            this.session.setPhoneOtpSession(expirationTime, canBeResentAt, attempts, this.userPhoneNumber);
          }
          if (this.otpSession.hasExpired() || this.otpSession.hasNoAttemptsLeft()) {
            return this.$emit('session-expired', error);
          }

          const { statusCode, isCritical } = error;
          if (typeof statusCode === 'undefined' || isCritical) {
            return this.$emit('critical-error', error);
          }

          this.errorMessage = this.t(`authentication-app.error.${statusCode}`, {
            sessionTimer: this.otpSession.getExpirationTimer(),
            attempts: this.otpSession.getAttempts(),
          });
        });
    },

    handleBack() {
      if (this.isLoading) {
        return;
      }
      this.$emit('back');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../assets/scss/variables';
@import '../../assets/scss/icon';

.phone-otp {
  &__icon {
    display: block;
    width: 118px;
    height: 96px;
    margin: 0 auto 24px;
    background-size: cover;

    @media (max-width: $screen-md-max) {
      width: 98px;
      height: 80px;
    }
  }
}

.sent-notice {
  font-size: 1rem;
  margin-bottom: 10px;
  text-align: center;
  line-height: 1.375;
}
</style>
