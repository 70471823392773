import { ref } from 'vue';

const isVerificationLoaded = ref(false);

let resolver;

export const waitForVerificationWidget = new Promise(resolve => {
  if (isVerificationLoaded.value) {
    return resolve();
  }

  resolver = resolve;
});

export const setVerificationAsLoaded = () => {
  isVerificationLoaded.value = true;
  resolver();
};
