<template>
  <div
    class="form-birthdaydatepicker"
    :class="{
      'has-error': hasError,
      'is-disabled': isDisabled,
      'has-tip': hasTipSlot
    }"
  >
    <div
      v-if="label || hasTipSlot"
      class="form-birthdaydatepicker__top"
    >
      <label
        v-if="label"
        :for="id"
        class="form-birthdaydatepicker__label"
      >
        {{ label }}
      </label>

      <span class="form-birthdaydatepicker__tip">
        <slot name="tip" />
      </span>
    </div>

    <datetime
      v-model="datetime"
      :flow="['year', 'month', 'date']"
      input-class="form-birthdaydatepicker__input"
      :auto="true"
      :format="format"
      :max-datetime="maxDatetime"
      :placeholder="placeholder"
      :input-id="id"
      :locale="locale"
    />

    <span
      v-if="hasError"
      class="form-birthdaydatepicker__error"
      v-html="error"
    />
  </div>
</template>

<script>
export default {
  name: 'FormBirthdayPicker',

  props: {
    id: { type: String, required: false, default: '' },
    name: { type: String, required: false, default: '' },
    placeholder: { type: String, required: false, default: '' },
    label: { type: String, required: false, default: '' },
    hasError: { type: Boolean, required: false, default: false },
    error: { type: String, required: false, default: '' },
    isRange: { type: Boolean, required: false, default: false },
    isDoublePaned: { type: Boolean, required: false, default: false },
    isDisabled: { type: Boolean, required: false, default: false },
    locale: { type: String, required: false, default: null },
    value: { type: String, required: false, default: 'en' },
    maxDatetime: {
      type: String,
      required: false,
      default: new Date().toISOString(),
    },
  },

  data() {
    return {
      datetime: this.value,
      format: { year: 'numeric', month: 'numeric', day: 'numeric' },
    };
  },

  computed: {
    hasTipSlot() {
      return !!this.$slots.tip;
    },
  },

  watch: {
    datetime(value) {
      this.$emit('input', value);
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../assets/scss/global/lib/vue-datetime.scss";

.form-birthdaydatepicker {
  position: relative;
  width: 100%;

  &.is-disabled {
    pointer-events: none;
  }

  &__top {
    display: flex;
    justify-content: space-between;
    margin-bottom: rem(4);
  }

  &__label {
    font-size: rem(16);
    line-height: 1.25;
  }

  &__tip {
    color: $grey-30;
    font-size: rem(14);
    line-height: 1.14;
  }

  .is-disabled &__label {
    color: $grey-30;
  }

  ::v-deep &__input {
    position: relative;
    display: block;
    width: 100%;
    background-color: $white;
    border: none;
    border-radius: rem(6);
    font-weight: 700;
    font-size: rem(16);
    line-height: 1.25;
    padding: rem(14);
    box-shadow: 0 0 0 2px $black-10;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    cursor: pointer;
    transition: box-shadow 0.2s linear;
  }

  ::v-deep &__input:hover {
    box-shadow: 0 0 0 2px $black-20;
  }

  ::v-deep &__input:focus {
    outline: none;
    box-shadow: 0 0 0 1px $violet-60;
  }

  ::v-deep &__input:disabled,
  &.is-disabled ::v-deep &__input {
    background-color: $grey-04;
    color: $grey;
    box-shadow: 0 0 0 2px $grey-04;
  }

  ::v-deep &__input::-webkit-input-placeholder {
    font-family: $font-family;
    color: $grey-30;
  }

  ::v-deep &__input:-ms-input-placeholder {
    font-family: $font-family;
    color: $grey-30;
  }

  ::v-deep &__input::placeholder {
    font-family: $font-family;
    color: $grey-30;
  }

  &.has-error ::v-deep &__input {
    box-shadow: 0 0 0 2px $red;
  }

  &.has-error ::v-deep &__input:hover {
    box-shadow: 0 0 0 2px rgba(231, 60, 64, 0.25);
  }

  &__error {
    display: block;
    margin-top: rem(4);
    font-size: rem(12);
    line-height: 1.33;
    color: $red;
  }

  & > .vdatetime {
    &:before {
      content: "\e901";
      position: absolute;
      right: rem(12);
      top: rem(16);
      z-index: 99;
      pointer-events: none;

      @include inline-icon;
    }
  }

  [dir="rtl"] & > .vdatetime {
    &:before {
      left: rem(12);
      right: unset;
    }
  }

  &.is-disabled .vdatetime:before {
    color: $grey-30;
  }

  @media screen and (max-width: $screen-md-max) {
    &.has-tip {
      padding-bottom: rem(28);
    }

    &.has-tip &__tip {
      position: absolute;
      bottom: rem(2);
    }
  }
}
</style>
