<template>
  <div>
    <div class="auth-app-header">
      {{ $t('authentication-app.funnel.phone-heading') }}
    </div>

    <p class="auth-app-funnel-hint">
      {{ $t('authentication-app.funnel.phone-hint') }}
    </p>

    <PhoneInput
      :country="countryCode"
      :error-message="errorMessage"
      :show-error="showErrorMessage"
      :is-disabled="isLoading"
      @valid="isSubmitDisabled = false"
      @invalid="isSubmitDisabled = true"
      @phoneInput="handlePhoneInput"
      @submit="handleSubmitPhone"
    />

    <div class="auth-app-checkboxes">
      <Checkbox
        class="auth-app-checkboxes__checkbox"
        :checked="agreeToTosAndPrivacy"
        :error="agreementError"
        @change="handleAgreementCheckboxChange"
      >
        <!-- eslint-disable-next-line vue/no-v-html -->
        <div v-html="$t('authentication-app.phone.agree-to-terms-and-privacy')" />
      </Checkbox>
    </div>

    <ReCaptchaInput
      ref="reCaptcha"
      :error-message="reCaptchaError"
      @verify="onReCaptchaVerify"
    />

    <div class="auth-app-footer auth-app-footer--centered">
      <button
        :class="{ 'is-loading': isLoading }"
        :disabled="isSubmitDisabled"
        class="btn btn-primary btn-lg"
        @click="handleSubmitPhone"
      >
        {{ $t('authentication-app.buttons.continue') }}
      </button>
    </div>
  </div>
</template>

<script>
import PhoneInput from '../../components/PhoneInput';
import Checkbox from '../../components/Checkbox';
import AuthService from '../../services/auth';
import { Session } from '../../models/session';
import { TooEarlyError } from '../../models/auth-error';
import ReCaptchaInput from '../../components/ReCaptchaInput';
import { useI18n } from 'vue-i18n';

export default {
  name: 'PhoneFunnelView',

  components: {
    PhoneInput,
    Checkbox,
    ReCaptchaInput,
  },

  props: {
    session: {
      type: Session,
      required: true,
    },

    countryCode: {
      type: String,
      required: false,
    },
  },

  data() {
    const { t } = useI18n();

    return {
      t,
      phone: '',
      showAgreementErrors: false,
      agreeToTosAndPrivacy: false,
      errorMessage: '',
      showErrorMessage: false,
      isSubmitDisabled: true,
      isLoading: false,
      reCaptcha: '',
      reCaptchaError: '',
    };
  },

  computed: {
    agreementError() {
      if (this.showAgreementErrors && !this.agreeToTosAndPrivacy) {
        return this.t('authentication-app.errors.tos');
      }

      return '';
    },
  },

  methods: {
    onReCaptchaVerify(reCaptcha) {
      this.reCaptcha = reCaptcha;
      this.submitForm();
    },

    handleAgreementCheckboxChange(event) {
      this.agreeToTosAndPrivacy = event.target.checked;
    },

    handlePhoneInput(newValue) {
      this.phone = newValue;
      this.showErrorMessage = false;
    },

    handleSubmitPhone() {
      if (this.isLoading || this.isSubmitDisabled) {
        this.showErrorMessage = this.isSubmitDisabled;
        return;
      }
      if (!this.agreeToTosAndPrivacy) {
        this.showAgreementErrors = true;
        return;
      }
      this.isLoading = true;
      this.$refs.reCaptcha.execute();

      if (this.reCaptcha === '') {
        return;
      }

      this.submitForm();
    },

    submitForm() {
      AuthService.startRegistration(this.reCaptcha, this.session, this.phone, this.$i18n.locale)
        .then(session => this.$emit('success-phone', session))
        .catch(error => {
          this.isLoading = false;
          this.showErrorMessage = true;
          if (error instanceof TooEarlyError) {
            if (this.session.isPhoneSessionValid()) {
              return this.$emit('change-flow', 'otp.phone');
            }
            return this.errorMessage = this.t('authentication-app.error.too-early', {
              canBeResentAfter: error.getResendTimer(),
            });
          }
          const { statusCode, isCritical } = error;
          if (typeof statusCode === 'undefined' || isCritical) {
            return this.$emit('critical-error', error);
          }
          if (statusCode === 'invalid-recaptcha') {
            this.reCaptchaError = this.t('authentication-app.error.recaptcha');
            return;
          }

          this.errorMessage = this.t(`authentication-app.error.${statusCode}`);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../assets/scss/btn';
.auth-app-funnel-hint {
  font-size: 1rem;
  margin-bottom: 2rem;
  text-align: center;
  line-height: 1.375;
}
</style>
