<template>
  <p
    class="sales-funnel-paragraph"
    :class="{ 'is-small': small, 'is-bold': bold }"
  >
    <slot />
  </p>
</template>

<script>
export default {
  name: 'Paragraph',

  props: {
    small: { type: Boolean, required: false, default: false },
    bold: { type: Boolean, required: false, default: false },
  },
};
</script>

<style scoped lang="scss">
.sales-funnel-paragraph {
  font-size: rem(16);
  line-height: 1.5;
  text-align: center;

  &.is-small {
    font-size: rem(14);
    line-height: 1.29;
  }

  &.is-bold {
    font-weight: 700;
  }
}
</style>
