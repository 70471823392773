<template>
  <div
    class="form-row-slot"
    :class="{
      'is-v-center': verticalCenter,
      'is-xs': media === 'xs',
      'is-lg': media === 'lg',
      'is-wide': gap === 'wide'
    }"
    :style="{ width: width }"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: 'FormRowSlot',

  props: {
    gap: { type: String, default: 'normal' },
    media: { type: String, default: 'lg' },
    width: { type: String, required: false, default: '' },
    verticalCenter: { type: Boolean, required: false, default: false },
  },
};
</script>

<style scoped lang="scss">
@mixin row-media($name) {
  min-width: 100%;

  & + & {
    margin-top: rem(16);
  }

  @media screen and (min-width: $name) {
    min-width: auto;
    & + & {
      margin-top: 0;
      margin-left: var(--gap);
    }

    [dir="rtl"] & + & {
      margin-right: var(--gap);
      margin-left: unset;
    }

    .wrap &,
    [dir="rtl"] .wrap & {
      margin: rem(8) rem(12);
    }
  }
}

.form-row-slot {
  --gap: 1rem;

  &.is-wide {
    --gap: 1.5rem;
  }

  &.is-v-center {
    align-self: center;
  }

  &.is-xs {
    @include row-media(0);
  }

  &.is-lg {
    @include row-media($screen-lg-min);
  }
}
</style>
