<template>
  <div>
    <div
      class="modal"
      :class="[`modal--${size}`, `modal--${modalStyle}`, show && 'show']"
    >
      <div
        class="modal__dialog"
        @click="handleCloseOutside"
      >
        <div
          ref="content"
          class="modal__content"
        >
          <button
            v-if="!nonClosable"
            class="modal__close"
            @click="$emit('close')"
          >
            {{ $t("shared.navigation.button.close") }}
          </button>

          <slot />
        </div>
      </div>
    </div>
    <div
      class="modal__backdrop"
      :class="{ show: show }"
    />
  </div>
</template>

<script>
import { freezeBody } from '../utils/FreezeBody.js';

export default {
  name: 'Modal',

  props: {
    size: { type: String, required: false, default: '' },
    modalStyle: { type: String, required: false, default: 'default' },
    show: { type: Boolean, required: false, default: false },
    nonClosable: { type: Boolean, required: false, default: false },
    appendToBody: { type: Boolean, required: false, default: false },
  },
  watch: {
    show: {
      handler(value) {
        value ? freezeBody(true) : this.handlerClose();
        if (value && this.appendToBody) {
          document.body.appendChild(this.$el);
        }
      },
      immediate: true,
    },
  },

  beforeUnmount() {
    if (this.appendToBody && this.$el && this.$el.parentNode) {
      this.$el.parentNode.removeChild(this.$el);
      this.handlerClose();
    }
  },

  methods: {
    handleCloseOutside(e) {
      if (this.nonClosable) return false;

      const { content } = this.$refs;
      if (!content) {
        return;
      }
      const { target } = e;
      if (content !== target && !content.contains(target)) this.$emit('close');
    },
    handlerClose() {
      if (typeof window === 'undefined') return;
      this.$nextTick(() => {
        const lengthActiveModals = document.querySelectorAll('.modal.show')
          .length;
        if (lengthActiveModals < 1) {
          freezeBody(false);
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
  display: none;
  transition: opacity 0.15s linear;

  &.show {
    display: block;
  }

  &__dialog {
    position: relative;
    width: auto;
    display: flex;
    align-items: center;
    min-height: 100%;
    max-height: 100%;
    overflow: auto;
  }

  &__dialog:before {
    display: block;
    content: "";
    height: 100vh;
  }

  &__content {
    position: relative;
    display: flex;
    flex-direction: column;
    margin: auto;
    width: 100%;
    min-height: 100vh;
    pointer-events: auto;
    background-color: $white;
    background-clip: padding-box;
    outline: 0;
  }

  &--loader &__content,
  &--violet &__content {
    background-color: $violet;
  }

  &--error &__content {
    max-width: rem(416);
    width: calc(100% - 48px);
    min-height: rem(60);
    margin: rem(56) auto auto;
    border-radius: rem(6);
  }

  &--error &__dialog {
    align-items: flex-start;
  }

  &__backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    background-color: rgba($black, 0.25);
    z-index: 1049;
    opacity: 0;
    transition: opacity 0.15s linear, width 0s linear 0.15s,
      height 0s linear 0.15s;
  }

  &__backdrop.show {
    opacity: 1;
    width: 100vw;
    height: 100vh;
    transition: opacity 0.15s linear, width 0s linear 0s, height 0s linear 0s;
  }

  &__close {
    position: absolute;
    top: rem(16);
    right: rem(16);
    z-index: 99;
    width: rem(32);
    height: rem(32);
    font-size: 0;
    transition: color 0.2s linear;
  }

  [dir="rtl"] &__close {
    left: rem(16);
    right: unset;
  }

  &__close:hover {
    color: $violet;
  }

  &__close:after {
    content: "\e932";
    @include inline-icon;
    font-size: rem(22);
  }

  @media screen and (max-width: $screen-md-max) {
    &__content {
      width: 100%;
      height: 100vh;
    }
  }

  @media screen and (min-width: $screen-lg-min) {
    &--without-cropping &__content {
      overflow: visible;
    }

    &__content {
      overflow: hidden;
      border-radius: rem(6);
      min-height: rem(60);
    }

    &--auto &__content {
      width: auto;
    }

    &--lg &__content {
      width: rem(672);
    }

    &--sm &__content {
      width: rem(480);
    }

    &--loader &__content {
      margin: rem(32) auto auto auto;
    }

    &--error &__content {
      margin: rem(128) auto auto;
    }
  }
}
</style>
