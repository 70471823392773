import ExchangeFormScreenV2 from '@/views/v2/ExchangeFormScreen/ExchangeFormScreen';
import { isNewExchangeFormFlowEnabled } from '@/services/exchangeFormFlow';

export const NAME = 'v2-exchange-form';

export default {
  path: '/v2/exchange-form',
  name: NAME,
  component: ExchangeFormScreenV2,
  meta: {
    shouldBePreloaded: true,
    requiresAuth: !isNewExchangeFormFlowEnabled(),
  },
};
