<template>
  <TransactionFunnel v-if="canShowFunnel" />
</template>

<script>
import { computed, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { TransactionFlowStep } from '@paybis/frontend-transaction-funnnel-app/src/publicApi';
import { useRoutePreloader } from '@/router/PreloadRouterView';
import { isV2 } from '@/services/apiVersion';
import { useIsAppInitialized } from '@/composables/useIsAppInitialized';
import { isNewExchangeFormFlowEnabled } from '@/services/exchangeFormFlow';
import { AUTH, COOLING_OFF_SCREEN, EXCHANGE_FORM_V2, CRITICAL_ERROR } from '@/router/routes';
import { UserForbiddenError } from '@/exceptions/UserForbiddenError';

import eventBus from '@/helpers/eventBus';

// TODO: this component should contain inner router. The problems with the current approach is that the component works as a redirection block.
// So it is impossible to use any logic inside it
export default {
  name: 'TransactionFlowScreen',

  setup() {
    const store = useStore();
    const router = useRouter();
    const { setSelfPreloadState } = useRoutePreloader();
    const { isAppInitialized } = useIsAppInitialized();

    const isExchangeFormBeforeAuth = computed(() => isNewExchangeFormFlowEnabled());
    const currentStep = computed(() => store.getters['external/funnelNavigationCurrent']);
    const isOneClickCheckoutFlow = computed(() => store.getters.isOneClickFlow);
    const invoice = computed(() => store.getters['external/funnelInvoiceId']);

    const canShowFunnel = computed(() => {
      if (!isExchangeFormBeforeAuth.value) {
        if (isOneClickCheckoutFlow.value && !invoice.value) {
          return false;
        }

        return true;
      }

      return !!invoice.value;
    });

    const isSellCryptoFlow = computed(() => store.getters.isSellCryptoFlow);
    const selectedPaymentMethod = computed(() => {
      if (isSellCryptoFlow.value) {
        return store.getters['v2/paymentMethods/payoutMethod'].paymentMethod;
      }

      return store.getters['v2/paymentMethods/paymentMethod'].paymentMethod;
    });

    const isShowCoolingOffPopUp = computed(() => store.getters.isShowCoolingOffPopUp);

    onMounted(async () => {
      if (!isExchangeFormBeforeAuth.value && !isOneClickCheckoutFlow.value) {
        return;
      }

      if (isV2()) {
        const createTransactionPayload = {};
        if (isSellCryptoFlow.value) {
          createTransactionPayload.payoutMethod = selectedPaymentMethod.value;
        } else {
          createTransactionPayload.paymentMethod = selectedPaymentMethod.value;
        }

        await isAppInitialized;

        try {
          await store.dispatch('loadClientData');

          if (isShowCoolingOffPopUp.value) {
            router.push({
              name: COOLING_OFF_SCREEN,
            });
            return;
          }

          await store.dispatch('createTransaction', createTransactionPayload);
        } catch (error) {
          if (error instanceof UserForbiddenError) {
            setSelfPreloadState();

            if (error.message === 'COOLING_OFF_PERIOD') {
              router.push({
                name: EXCHANGE_FORM_V2,
              });

              return;
            }

            router.push({
              name: AUTH,
            });
          }

          if (error.message === 'Could not create transaction') {
            router.push({ name: CRITICAL_ERROR });

            return
          }
        }

        return;
      }

      await isAppInitialized; // this makes sure that all needed data is fetched for the next steps

      await store.dispatch('createTransaction');
    });

    eventBus.once('transaction-flow.loaded', () => {
      // prevents notifying preloader about the current step, because it redirects to the basic-verification
      if (currentStep.value.name === TransactionFlowStep.STEP_BASE_VERIFICATION) return;

      setSelfPreloadState();
    });

    return {
      canShowFunnel,
    };
  },
};
</script>
