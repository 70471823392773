export const FieldType = {
  TYPE_TEXT: 'text',
  TYPE_EMAIL: 'email',
  TYPE_DATETIME: 'datetime',
  TYPE_DATE: 'date',
  TYPE_SELECT: 'select',
  TYPE_DECIMAL: 'decimal',
  TYPE_INTEGER: 'integer',
  TYPE_DOCUMENT: 'document',
  TYPE_ALPHANUMERIC: 'alphanumeric',
};

export const InputType = {
  TYPE_TEXT: 'text',
  TYPE_NUMBER: 'number',
  TYPE_EMAIL: 'email',
  TYPE_TEL: 'tel',
};

export const InputMode = {
  MODE_NUMERIC: 'numeric',
  MODE_DECIMAL: 'decimal',
  MODE_TEXT: 'text',
  MODE_TEL: 'tel',
  MODE_EMAIL: 'email',
};

export const MappedFieldType = {
  input: [
    FieldType.TYPE_TEXT,
    FieldType.TYPE_EMAIL,
    FieldType.TYPE_INTEGER,
    FieldType.TYPE_DECIMAL,
    FieldType.TYPE_ALPHANUMERIC,
  ],
  select: [FieldType.TYPE_SELECT],
  datetime: [FieldType.TYPE_DATETIME, FieldType.TYPE_DATE],
  document: [FieldType.TYPE_DOCUMENT],
};
