<template>
  <div class="close-confirm">
    <div class="close-confirm__inner">
      <div class="close-confirm__text">
        {{ $t('widget.confirmation-modal.logout-confirm-text') }}
      </div>

      <div class="close-confirm__buttons">
        <div
          class="btn btn-xlg btn-white-secondary bold"
          @click="$emit('reject')"
        >
          {{ $t('widget.confirmation-modal.no') }}
        </div>
        <div
          class="btn btn-xlg btn-white bold"
          @click="handleLogout"
        >
          <Loader
            v-if="isLogoutPressed"
            size="32px"
          />
          <span v-else> {{ $t('widget.confirmation-modal.yes') }} </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import Loader from '@/components/Loader/Loader.vue';
import { defineEmits, ref } from 'vue';

const emit = defineEmits(['reject', 'confirm']);

const isLogoutPressed = ref(false);

const handleLogout = () => {
  if (isLogoutPressed.value) {
    return;
  }

  isLogoutPressed.value = true;
  emit('confirm');
};
</script>

<style lang="scss" scoped>
.close-confirm {
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;

  &__inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    top: -60px;
  }

  &__text {
    max-width: rem(320);
    margin-bottom: rem(32);
    font-size: rem(22);
    line-height: rem(28);
    color: #fff;
    font-weight: 700;
    text-align: center;

    @media (max-width: $tablet-max) {
      margin: 0 20px 24px 20px;
    }
  }

  &__buttons {
    display: flex;

    @media (max-width: $tablet-max) {
      display: block;
      width: 100%;
      padding: 0 24px;
    }
  }

  .btn {
    width: rem(116);
    height: rem(64);

    @media (max-width: $tablet-max) {
      width: 100%;
    }
  }

  .btn:first-child {
    margin: 0 24px 0 0;

    @media (max-width: $tablet-max) {
      margin: 0 0 12px 0;
    }
  }
}
</style>
