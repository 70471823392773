import { handleUrlParams as handleLedgerUrlParams, supports as supportsLedgerParams } from './ledger/params';
import { handleUrlParams as handlePaybisUrlParams, supports as supportsPaybisParams } from './paybis/params';

export const handleUrlParams = urlParams => {
  if (supportsLedgerParams(urlParams)) {
    return handleLedgerUrlParams(urlParams);
  }

  if (supportsPaybisParams(urlParams)) {
    return handlePaybisUrlParams(urlParams);
  }

  return {};
};
