<template>
  <div class="wrap-trustly-widget">
    <div id="trustly-widget" />
  </div>
</template>

<script setup>
import TrustlyService from '../../services/trustlyInitService';
import { computed, defineEmits, onMounted, defineProps } from 'vue';
import store from '@/store';
import { freezeBody } from '@paybis/frontend-common-lib/src/utils/FreezeBody';

store.commit('transaction/stopPollingTransaction');

const emit = defineEmits(['close', 'ready']);
const props = defineProps({
  trustlyInst: {
    type: TrustlyService,
    required: true,
  },
});

onMounted(async () => {
  const session = await props.trustlyInst.getSession();

  if (!session) {
    try {
      await props.trustlyInst.startSession();
    } catch (e) {
      // Session already exist
      if (e.status === 409) {
        store.commit('transaction/startPollingTransaction');
        return;
      }

      throw new Error(e);
    }
  }

  await props.trustlyInst.initTrustly();
  emit('ready');

  Trustly.addPanelListener((command, obj) => {
    if (command === 'open') {
      freezeBody(true);
    } else if (command === 'close') {
      store.commit('transaction/startPollingTransaction');
      store.commit('apmPayment/setIsLoading', true);
      freezeBody(false);
      emit('close');
    }
  });
});
</script>

<style lang="scss" scoped>
.wrap-trustly-widget {
  position: relative;
}
</style>
