import { createRouter, createWebHashHistory } from 'vue-router';
import store from '@/store';
import { trackPageview } from '@/utils/sift';
import {
  home,
  auth,
  criticalError,
  startTransaction,
  exchangeFormPreloader,
  exchangeForm,
  zerohashAcceptance,
  transaction,
  paymentMethodsV2,
  exchangeFormV2,
  HOME,
  AUTH,
  applePayOneClick,
  coolingOffScreen,
} from '@/router/routes';

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes: [
    home,
    auth,
    criticalError,
    startTransaction,
    exchangeFormPreloader,
    exchangeForm,
    zerohashAcceptance,
    transaction,
    paymentMethodsV2,
    exchangeFormV2,
    applePayOneClick,
    coolingOffScreen,
  ],
});

router.beforeEach((to, from, next) => {
  const isLoggedIn = store.getters.jwtTokenExists();
  const isRouteSecured = to.matched.some(record => record.meta.requiresAuth);
  if (!isRouteSecured || isLoggedIn) return next();

  return next({ name: AUTH });
});

router.beforeResolve(() => {
  trackPageview();
  return true;
});

// PD-11039: garanties that the route always starts from the first step
setTimeout(() => {
  router.push({ name: HOME });
}, 0);

export default router;
