import { reactive } from 'vue';

const componentsRegistry = {};
const DummyComponent = () => ({});

export default {
  targets: {},

  setShownState({ key, state, target: targetName, isWithoutBlur }) {
    this.targets[targetName].component = state ? componentsRegistry[key] : DummyComponent;
    this.targets[targetName].isShown = state;
    this.targets[targetName].isWithoutBlur = isWithoutBlur;
  },

  registerTarget({ name }) {
    this.targets[name] = reactive({
      isShown: false,
      component: DummyComponent,
      isWithoutBlur: false,
    });
    return this.targets[name];
  },

  unregisterTarget({ name }) {
    delete this.targets[name];
  },

  registerModal({ key, component }) {
    componentsRegistry[key] = component;
  },

  unregisterModal({ key }) {
    delete componentsRegistry[key];
  },
};
