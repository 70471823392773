<template>
  <div class="validation-error-modal">
    <div class="validation-error-modal__inner">
      <i class="validation-error-modal__icon svg-icon icon-cancelled" />

      <span class="validation-error-modal__title">
        {{ $t('widget.cryptocurrency-is-not-available.title') }}
      </span>

      <span class="validation-error-modal__text">
        {{ $t('widget.cryptocurrency-is-not-available.text', {
          service_from: isSellCryptoFlow ? $t(`currencies.${quote.currencyCodeFrom}`) : quote.currencySourceFrom,
          service_to: isSellCryptoFlow ? quote.currencySourceFrom : $t(`currencies.${quote.currencyCodeTo}`)
        }) }}
      </span>

      <CloseWidgetButton
        mode="modal"
        @click="$emit('confirm')"
      />
    </div>

    <SupportChat
      :chat-key="liveChatKey"
      :lang="currentLang"
    />
  </div>
</template>

<script>
import { computed, ref } from 'vue';
import { useStore } from 'vuex';

import SupportChat from '@/components/SupportChat';
import CloseWidgetButton from '@/components/CloseWidgetButton';

export default {
  name: 'TransactionValidationErrorModal',

  components: { CloseWidgetButton, SupportChat },

  emits: ['confirm'],

  setup() {
    const store = useStore();
    const liveChatKey = computed(() => store.getters.liveChatKey);
    const currentLang = computed(() => store.getters.locale);
    const isSellCryptoFlow = computed(() => store.getters.isSellCryptoFlow);

    const quote = ref(store.getters['exchangeForm/quote']);

    return {
      liveChatKey,
      currentLang,
      isSellCryptoFlow,

      quote,
    };
  },
};
</script>

<style lang="scss" scoped>
.validation-error-modal {
  background-color: $white;
  max-width: 480px;
  border-radius: 6px;

  &__inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 56px 36px;
  }

  &__icon {
    width: 75px;
    height: 75px;
    margin-bottom: 16px;
  }

  &__title, &__text {
    display: block;
    margin-bottom: 32px;
    line-height: 1.4;
    text-align: center;
  }

  &__title {
    font-weight: 700;
    font-size: 22px;
  }

  &__text {
    font-family: $font-family;
    font-size: 18px;
  }
}
</style>
