import PaymentMethodsScreen from '@/views/v2/PaymentMethodsScreen';
import { isNewExchangeFormFlowEnabled } from '@/services/exchangeFormFlow';

export const NAME = 'v2-payment-methods';

export default {
  path: '/v2/payment-methods',
  name: 'v2-payment-methods',
  component: PaymentMethodsScreen,
  meta: {
    shouldBePreloaded: true,
    requiresAuth: !isNewExchangeFormFlowEnabled(),
  },
};
