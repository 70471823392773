import { TransactionFlowStep as step } from '../models/steps';
import vi from '../views-injection';

export const install = (router, store, app = null) => {
  const routes = [
    {
      path: `/${step.STEP_TRANSACTION_FLOW}`,
      name: step.STEP_TRANSACTION_FLOW,
      component: vi.TransactionFlow,
      children: [
        {
          path: step.STEP_CARD_AUTHORIZATION,
          name: step.STEP_CARD_AUTHORIZATION,
          component: vi.CardAuthorization,
        },
        {
          path: step.STEP_GIFT_CARD,
          name: step.STEP_GIFT_CARD,
          component: vi.GiftCard,
        },
        {
          path: step.STEP_PAYOUT_DETAILS,
          name: step.STEP_PAYOUT_DETAILS,
          component: vi.PayoutDetails,
        },
        {
          path: step.STEP_EXTRA_VERIFICATION,
          name: step.STEP_EXTRA_VERIFICATION,
          component: vi.ExtraVerification,
        },
        {
          path: step.STEP_PAYMENT_CHECK,
          name: step.STEP_PAYMENT_CHECK,
          component: vi.PaymentCheck,
        },
        {
          path: step.STEP_PAYMENT_DETAILS,
          name: step.STEP_PAYMENT_DETAILS,
          component: vi.PaymentDetails,
        },
        {
          path: step.STEP_CRYPTO_ACQUIRING_PAYMENT_DETAILS,
          name: step.STEP_CRYPTO_ACQUIRING_PAYMENT_DETAILS,
          component: vi.CryptoAcquiringPaymentDetails,
        },
        {
          path: step.STEP_PAYMENT_WAITING,
          name: step.STEP_PAYMENT_WAITING,
          component: vi.PaymentWaiting,
        },
        {
          path: step.STEP_PAYMENT_REVIEW,
          name: step.STEP_PAYMENT_REVIEW,
          component: vi.PaymentReview,
        },
        {
          path: step.STEP_PAYOUT_WAITING,
          name: step.STEP_PAYOUT_WAITING,
          component: vi.PayoutWaiting,
        },
        {
          path: step.STEP_COMPLETED,
          name: step.STEP_COMPLETED,
          component: vi.Completed,
        },
        {
          path: step.STEP_PAYMENT_ERROR,
          name: step.STEP_PAYMENT_ERROR,
          component: vi.PaymentError,
        },
        {
          path: step.STEP_REJECTED,
          name: step.STEP_REJECTED,
          component: vi.Rejected,
        },
        {
          path: step.STEP_CANCELLED,
          name: step.STEP_CANCELLED,
          component: vi.Cancelled,
        },
        {
          path: step.STEP_BASE_VERIFICATION,
          name: step.STEP_BASE_VERIFICATION,
          component: vi.BasicVerification,
        },
      ],
    },
  ];

  routes.forEach(route => {
    router.addRoute(route);
  });

  router.beforeEach((to, from, next) => {
    store.commit('transaction/navigation/setCurrentStep', to.name);

    next();
  });
};
