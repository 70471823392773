const listCryptoDecimals = {
  BAT: 4,
  ADA: 6,
  XRP: 6,
  XLM: 7,
  USDT: 6,
  LUNA: 6,
  TRX: 6,
  USDC: 6,
};

export function getDecimalsForFormattedCryptoPrecision(cryptoCurrency) {
  return listCryptoDecimals[cryptoCurrency] || 8;
}
