import { ref } from 'vue';

export const isSiftLoaded = ref(false);

export function loadSiftScript() {
  if (isSiftLoaded.value) {
    return;
  }

  const script = document.createElement('script');
  script.src = process.env.VUE_APP_SIFT_SCRIPT_URL;
  script.async = true;
  document.body.appendChild(script);
  script.onload = function() {
    isSiftLoaded.value = true;
  };
}
