<template>
  <div class="sales-funnel-actions-disclaimer">
    <template v-if="payout.isBankCard()">
      {{ $t('transaction-flow.payment-details.disclaimer-for-continue') }}
    </template>
    <template v-else>
      {{ $t('transaction-flow.payment-details.disclaimer') }}
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters('transaction', ['payout']),
  },
  name: 'RefundDisclaimer',
};
</script>

<style lang="scss" scoped>
.sales-funnel-actions-disclaimer {
  text-align: center;
  font-size: .875rem;
  line-height: 1.125rem;
  color: $grey;
  margin-top: 1.5rem;
}
</style>
