<template>
  <div class="payment-status-waiting">
    <Title data-testid="payment-title">
      {{ $t('transaction-flow.payment-details.checking-your-info.title') }}
    </Title>

    <Pictogram
      :src="
        require('@paybis/frontend-common-lib/src/assets/images/verification-process.svg')
      "
    />
    <Paragraph :bold="true">
      {{
        $t(
          'transaction-flow.steps.payment-details.payment-status-waiting-screen.paragraph',
        )
      }}
    </Paragraph>
    <Loader class="mt-32 mb-32 ml-auto mr-auto" />
  </div>
</template>

<script>
import Title from '@paybis/frontend-common-lib/src/components/title';
import Loader from '@paybis/frontend-common-lib/src/components/loader';
import Paragraph from '@paybis/frontend-common-lib/src/components/paragraph';
import Pictogram from '@paybis/frontend-common-lib/src/components/pictogram';

export default {
  name: 'PaymentStatusWaiting',

  components: {
    Title,
    Pictogram,
    Paragraph,
    Loader,
  },
};
</script>

<style lang="scss" scoped>
.payment-status-waiting {
  .sales-funnel-title {
    text-align: center;

    @media screen and (max-width: $tablet-max) {
      font-size: 1.5rem;
      text-align: left;
      margin-bottom: 1.25rem;
      margin-top: 10px;
    }
  }
}
</style>
