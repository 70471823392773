<template>
  <div
    class="form-phone-input"
    :class="{
      'has-focus': hasFocus,
      'has-error': error,
      'is-disabled': isDisabled
    }"
  >
    <div class="form-phone-input__label">
      {{ $t('authentication-app.phone.input-label') }}
    </div>

    <div class="form-phone-input__row">
      <div class="form-phone-input__input-container">
        <div class="form-phone-input__wrapper">
          <div class="form-phone-input__code">
            <v-select
              :options="codes"
              :value="code"
              :model-value="code"
              :clearable="false"
              :searchable="true"
              :disabled="isDisabled"
              @input="setCountryCode"
              @update:modelValue="setCountryCode"
            >
              <template #selected-option="{ code, label }">
                <span class="form-phone-input__code-option">
                  <i :class="`flag flag:${code}`" />
                  {{ label }}
                </span>
                <i class="icon icon-arrow-bottom" />
                <i class="icon icon-arrow-top" />
              </template>

              <template #option="{ code, label }">
                <span class="form-phone-input__code-option">
                  <i :class="`flag flag:${code}`" />
                  {{ label }}
                </span>
              </template>

              <template #no-options="{ searching }">
                <template v-if="searching">
                  <span class="not-found">{{ $t('authentication-app.errors.phone-code-not-found') }}</span>
                </template>
              </template>
            </v-select>
          </div>

          <div class="form-phone-input__main">
            <input
              :value="phone"
              :placeholder="placeholder"
              :disabled="isDisabled"
              name="phone"
              type="number"
              pattern="^[0-9]+?$"
              inputmode="numeric"
              @input="setPhone"
              @focus="handleFocus"
              @blur="handleBlur"
            >
          </div>
        </div>

        <span
          v-if="showError"
          class="form-phone-input__error"
        >{{ error }}</span>
      </div>

      <div class="form-phone-input__submit">
        <slot name="submit" />
      </div>
    </div>
  </div>
</template>

<script>
import { countryDialCodes } from './PhoneInput.utils';
import { useI18n } from 'vue-i18n';

// TODO remove this component in favor of component from upcoming UI kit
export default {
  name: 'PhoneInput',

  props: {
    placeholder: {
      type: String, required: false, default: '',
    },
    errorMessage: {
      type: String, required: false, default: '',
    },
    isDisabled: {
      type: Boolean, required: false, default: false,
    },
    country: {
      type: String, required: false, default: '',
    },
    showError: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    const { t } = useI18n();

    return {
      t,
      phone: '',
      code: {},
      codes: countryDialCodes,
      hasFocus: false,
    };
  },

  computed: {
    phoneNumber() {
      if (typeof this.code === 'undefined' || typeof this.code.label === 'undefined') {
        return '';
      }

      return `${this.code.label}${this.phone}`;
    },
    isPhoneValid() {
      const validPhoneRegex = /^\+(?:[0-9] ?){6,14}[0-9]$/;

      return validPhoneRegex.test(this.phoneNumber);
    },
    error() {
      return this.phone !== '' && !this.isPhoneValid
        ? this.t('authentication-app.errors.invalid-phone') : this.errorMessage;
    },
  },

  watch: {
    phoneNumber() {
      if (this.isPhoneValid) {
        return this.$emit('valid');
      }

      this.$emit('invalid');
    },
  },

  mounted() {
    this.code = this.getCountryDailCode(this.country);
  },

  methods: {
    setCountryCode(val) {
      this.code = val;
      this.$emit('phoneInput', this.phoneNumber);
    },

    setPhone(e) {
      this.phone = e.target.value;
      this.$emit('phoneInput', this.phoneNumber);
    },

    handleFocus() {
      this.hasFocus = true;
    },

    handleBlur() {
      this.hasFocus = false;
    },

    getCountryDailCode(countryCode) {
      if (countryCode === '') {
        return countryDialCodes[0];
      }

      return countryDialCodes.find(({ code }) => code.toUpperCase() === countryCode.toUpperCase());
    },
  },
};
</script>

<style lang="scss" scoped>
// TODO preferable to replace flags with our svg icons and use as direct links
// Current approach takes too many bytes to carry icons
@import '~country-flag-icons/3x2/flags.css';

.flag {
  min-width: 20px;
  max-width: 20px;
  height: 16px;
  margin-right: 10px;
  background-position: center;
  display: inline-block;
  background-size: contain;
  background-repeat: no-repeat;
}

.form-phone-input {
  max-width: 100%;
  width: 100%;

  &__label {
    margin-bottom: 8px;
    font-size: 16px;
    line-height: 16px;
  }

  &__input-container {
    flex: 1;
  }

  &__wrapper {
    display: flex;
    box-shadow: inset 0 0 0 2px $black-10;
    border-radius: 6px;
    transition: box-shadow 0.2s linear;
  }

  &__wrapper:hover {
    box-shadow: inset 0 0 0 2px $black-20;
  }

  &__wrapper:hover &__main {
    border-color: #cbcccb;
  }

  &.has-focus &__wrapper {
    box-shadow: inset 0 0 0 1px $violet-60;
  }

  &.has-focus &__main {
    border-color: $violet-60;
  }

  &.has-error &__wrapper {
    box-shadow: inset 0 0 0 2px $red;
  }

  &.has-error &__wrapper:hover {
    box-shadow: inset 0 0 0 2px rgba(231, 60, 64, 0.25);
  }

  &.has-error &__main {
    border-color: $red;
  }

  &.has-error &__wrapper:hover &__main {
    border-color: rgba(231, 60, 64, 0.25);
  }

  &__code {
    position: relative;
    min-width: 110px;
  }

  &__main {
    flex: 1;
    width: 100px;
  }

  &__code + &__main {
    transition: border-color 0.2s linear;
    border-left: 1px solid #e4e5e4;
  }

  [dir='rtl'] &__code + &__main {
    border-right: 1px solid #e4e5e4;
    border-left: none;
  }

  &__code-option {
    display: flex;
    align-items: center;
    pointer-events: none;
  }

  [dir='rtl'] &__code-option {
    flex-direction: row-reverse;
  }

  &__code-option img {
    display: block;
    width: 16px;
    height: 16px;
    margin-right: 10px;
  }

  [dir='rtl'] &__code-option img {
    margin-left: 10px;
    margin-right: unset;
  }

  [dir='rtl'] &__code:after {
    left: 8px;
    right: unset;
  }

  &__main input {
    font-weight: 700;
    height: 46px;
    padding: 14px;
    background-color: transparent;
    border: none;
    width: 100%;
  }

  &__main input:focus {
    outline: none;
  }

  &__submit {
    margin-top: 8px;
  }

  ::v-deep &__submit .btn {
    padding: 0 10px;
    width: 90px;
  }

  &__main input[type=number]::-webkit-inner-spin-button,
  &__main input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &__main input[type="number"] {
    -moz-appearance:textfield;
  }

  &__main input::-webkit-input-placeholder {
    font-family: $font-family;
    color: $grey-30;
  }

  &__main input:-ms-input-placeholder {
    font-family: $font-family;
    color: $grey-30;
  }

  &__main input::placeholder {
    font-family: $font-family;
    color: $grey-30;
  }

  &__error {
    display: block;
    margin-top: 4px;
    font-size: 12px;
    line-height: 1.33;
    color: $red;
  }

  &.is-disabled {
    pointer-events: none;
  }

  &.is-disabled &__label {
    color: $grey-30;
  }

  &.is-disabled &__wrapper {
    background-color: $grey-04;
    color: $grey;
    box-shadow: inset 0 0 0 2px $grey-04;
  }

  @media screen and (max-width: $screen-md-max) {
    &__submit .btn {
      width: 100%;
    }
  }

  @media screen and (min-width: $screen-lg-min) {
    &__row {
      display: flex;
      justify-content: space-between;
    }

    &__submit {
      margin-top: 0;
      margin-left: 16px;
    }

    [dir='rtl'] &__submit {
      margin-right: 16px;
      margin-left: unset;
    }
  }
}
</style>

<style lang="scss">
@import '../../assets/scss/vselect';

.form-phone-input {
  & .not-found {
    font-size: .75rem;
  }
  & .icon-arrow-bottom,
  & .icon-arrow-top {
    margin-left: 1rem;

    [dir='rtl'] & {
      margin-left: 0;
      margin-right: 1rem;
    }
  }
  & .icon-arrow-top,
  & .vs--open .icon-arrow-bottom {
    display: none;
  }
  & .vs--open .icon-arrow-top {
    display: inline;
  }

  & .vs--single.vs--searching .vs__selected {
    display: flex;
    visibility: hidden;
  }

  & .vs--open .vs__search {
    position: absolute;
    display: block;
    height: 48px;
    box-sizing: border-box;
    -moz-appearance: none;
    border: none;
    font-weight: 700;
    padding: 0 16px;
    width: 100%;
  }

  & .vs__selected {
    padding: 0 16px;
    line-height: 48px;
    font-weight: 700;
  }

  & .vs__dropdown-menu {
    background-color: $white;
    border: 1px solid $black;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 6px;
    overflow-x: hidden;

    &::-webkit-scrollbar,
    &::-webkit-scrollbar-track {
      background-color: transparent;
    }
  }

  & .vs__dropdown-option {
    font-weight: 700;
    padding: 8px;
    border-radius: 4px;
  }

  & .vs__dropdown-option:hover,
  & .vs__dropdown-option--highlight {
    background-color: $grey-04;
  }

  & .vs__dropdown-option--selected {
    color: $grey-30;
  }

  & .vs__selected-options {
    cursor: pointer;
  }

  & .vs--single.vs--open .vs__selected {
    position: relative;
  }

  [dir='rtl'] & .vs__selected-options {
    flex-direction: row-reverse;
  }
}
</style>
