import AuthFlow from '@/views/AuthFlow/AuthFlow';

export const NAME = 'auth';

export default {
  path: '/auth',
  name: NAME,
  component: AuthFlow,
  meta: {
    shouldBePreloaded: true,
  },
};
