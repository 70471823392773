<template>
  <div class="sales-funnel-actions">
    <div
      v-if="hasBackSlot"
      class="sales-funnel-actions__back"
    >
      <slot name="back" />
    </div>

    <div
      v-if="hasSubmitSlot"
      class="sales-funnel-actions__submit"
    >
      <slot name="submit" />
    </div>

    <div
      v-if="hasAccessorySlot"
      class="sales-funnel-actions__accessory"
    >
      <slot name="accessory" />
    </div>

    <div
      v-if="hasCancel"
      :class="cancelClass.block"
    >
      <button
        :disabled="isLocked"
        :class="cancelClass.button"
        data-testid="cancelbutton"
        @click="toggleTransactionCancellationPopup()"
      >
        {{ $t('shared.navigation.button.cancel') }}
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

export default {
  name: 'Actions',

  props: {
    cancelButtonDefaultClass: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  computed: {
    ...mapGetters('transaction/navigation', {
      hasCancelButton: 'hasCancel',
    }),
    ...mapGetters(['isLocked']),

    hasBackSlot() {
      return !!this.$slots.back;
    },

    hasSubmitSlot() {
      return !!this.$slots.submit;
    },

    hasAccessorySlot() {
      return !!this.$slots.accessory;
    },

    hasCancel() {
      return !this.hasBackSlot && this.hasCancelButton;
    },

    cancelClass() {
      return {
        block: {
          'sales-funnel-actions__cancel': this.hasSubmitSlot,
          'sales-funnel-actions__submit': !this.hasSubmitSlot,
        },
        button: {
          'is-loading': this.isLocked,
          'btn-reject': this.hasSubmitSlot || this.cancelButtonDefaultClass,
          'btn btn-lg btn-secondary':
            !this.hasSubmitSlot && !this.cancelButtonDefaultClass,
        },
      };
    },
  },

  methods: {
    ...mapMutations('transaction', ['toggleTransactionCancellationPopup']),
  },
};
</script>

<style scoped lang="scss">
.sales-funnel-actions {
  position: relative;
  margin-top: rem(22);

  &__back,
  &__accessory {
    position: absolute;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: flex-start;

    @media screen and (max-width: 400px) {
      position: static;
      padding: 10px 0 0;
      justify-content: center;
      text-align: center;
    }
  }

  &__back {
    left: 0;
  }

  [dir='rtl'] &__back {
    right: 0;
    left: unset;
  }

  &__submit {
    display: flex;
    justify-content: center;
  }

  &__accessory {
    right: 0;
  }

  [dir='rtl'] &__accessory {
    left: 0;
    right: unset;
  }

  &__cancel {
    display: flex;
    justify-content: center;
    margin-top: rem(20);
  }

  @media screen and (min-width: $screen-lg-min) {
    margin-top: rem(56);
    margin-bottom: rem(32);

    &__cancel {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      display: flex;
      align-items: center;
      margin: 0;
    }

    [dir='rtl'] &__cancel {
      right: 0;
      left: unset;
    }
  }
}
</style>
