import ExchangeFormPreloader from '@/views/ExchangeFormPreloader';

export const NAME = 'exchange-form-preloader';

export default {
  path: '/exchange-form-preloader',
  name: NAME,
  component: ExchangeFormPreloader,
  meta: {
    shouldBePreloaded: true,
  },
};
