// This store blackboxes all external store(from common-lib, transaction-funnel, etc) calls. It is needed for
// - the future refactoring
// - accurate management of dependencies
// TODO: should be deleted after fixing FE architecture.

// eslint-disable-next-line import/no-cycle
import sumsubErrors from '@paybis/frontend-common-lib/src/store/modules/sumsubErrors';
// eslint-disable-next-line import/no-cycle
import verification from '@paybis/frontend-common-lib/src/store/modules/verification';

export default {
  namespaced: true,

  getters: {
    error: (state, getters, globalState, globalGetters) => globalGetters['error/error'],
    errorIsUnauthorized: (state, getters, globalState, globalGetters) => globalGetters['error/isUnauthorized'],

    funnelInvoiceId: (state, getters, globalState, globalGetters) => globalGetters['transaction/invoice'],
    funnelTransaction: (state, getters, globalState, globalGetters) => globalGetters['transaction/transaction'],
    funnelPayment: (state, getters, globalState, globalGetters) => globalGetters['transaction/payment'],
    funnelPayout: (state, getters, globalState, globalGetters) => globalGetters['transaction/payout'],
    transactionPayoutAccount: (state, getters) => getters.funnelPayout.getAccount(),
    transactionPayoutTag: (state, getters) => getters.funnelPayout.getTag()
      || getters.funnelPayout.getMemo(),
    funnelNavigationCurrent: (state, getters, globalState, globalGetters) => globalGetters['transaction/navigation/current'],
    funnelRejectMessage: (state, getters, globalState, globalGetters) => globalGetters['transaction/rejectMessage'],

    funnelQuote: (state, getters, globalState, globalGetters) => globalGetters['quote/quote'],
    funnelQuotePayment: (state, getters, globalState, globalGetters) => globalGetters['quote/paymentAmount'] || {},
    funnelQuotePayout: (state, getters, globalState, globalGetters) => globalGetters['quote/payoutAmount'] || {},
    funnelQuoteFees: (state, getters, globalState) => globalState.transaction.fees,
    funnelHasQuote: (state, getters, globalState, globalGetters) => globalGetters['quote/hasQuote'] || false,

    funnelTransactionFlowDetails: (state, getters, globalState, globalGetters) => globalGetters['transaction/flowDetails'],

    payoutDetailsFields: (state, getters, globalState, globalGetters) => globalGetters['payoutDetailsFields/fields'],

    cryptoTimerSecondsLeft: (state, getters, globalState, globalGetters) => globalGetters['cryptoTimer/secondsLeft'],
    cryptoTimerExpiration: (state, getters, globalState, globalGetters) => globalGetters['cryptoTimer/time'],

    isPaymentFailed: (state, getters, globalState, globalGetters) => globalGetters['transaction/payoutErrorMessage'] || (!globalGetters['card/isActivePayment'] && (globalGetters['card/selectedCard'].hasError() || globalGetters['transaction/isPaymentFailed']) && getters.funnelTransaction.isPaymentDetails()),
    cardIsActivePayment: (state, getters, globalState, globalGetters) => globalGetters['card/isActivePayment'],
    payoutCardMaskedByAccount: (state, getters, globalState, globalGetters) => {
      const account = getters.transactionPayoutAccount;
      const cards = globalGetters['card/cards'];
      if (!account) {
        return null;
      }

      const maskedCardNumber = cards.find(card => card.id === account)?.maskedCardNumber ?? '';

      return maskedCardNumber.substring(maskedCardNumber.length - 5);
    },
  },

  actions: {
    clearCustomFields({ dispatch }) {
      return dispatch('payoutDetailsFields/clearFields', {}, {
        root: true,
      });
    },
    transactionInit({ commit, dispatch }) {
      commit('transaction/updateTransaction', { status: null, flags: {} }, { root: true });

      return dispatch('transaction/init', {}, {
        root: true,
      });
    },
    setSettingsAppType({ dispatch }, type) {
      dispatch('settings/setAppType', type, {
        root: true,
      });
    },
    setFunnelInvoiceId({ commit }, { invoiceId }) {
      return commit('transaction/setInvoice', invoiceId, {
        root: true,
      });
    },
    submitTransactionPayoutDetails({ dispatch }, params) {
      return dispatch('transaction/submitTransactionPayoutDetails', params, {
        root: true,
      });
    },
    setPayoutDetailsFieldErrors({ dispatch }, errors) {
      return dispatch('payoutDetailsFields/setFieldErrors', errors, {
        root: true,
      });
    },
    updatePayoutDetailsField({ commit }, field) {
      return commit('payoutDetailsFields/updateField', field, {
        root: true,
      });
    },
    resetVerification({ commit }) {
      return commit('verification/resetVerification', {}, {
        root: true,
      });
    },
    resetErrorState({ dispatch }) {
      return dispatch('error/resetState', {}, {
        root: true,
      });
    },
    setError({ dispatch }, error) {
      return dispatch('error/setError', error, {
        root: true,
      });
    },
    initCryptoTimer({ commit, dispatch }, { expiresAt }) {
      // FIX: duplicates logic, but without side effects
      // @see https://gitlab.com/techcloud/shared/frontend-common-lib/blob/6afee1fdcb93e5904cb4d4ac533c7c459c1d22d9/src/store/modules/cryptoTimer.js#L27
      commit('cryptoTimer/setTime', expiresAt, {
        root: true,
      });
      commit('cryptoTimer/setDynamic', true, {
        root: true,
      });

      const SECOND_IN_MILLISECONDS = 1000;
      const secondsLeft = (expiresAt - Date.now()) / SECOND_IN_MILLISECONDS;
      commit('cryptoTimer/setSecondsLeft', secondsLeft, {
        root: true,
      });

      dispatch('cryptoTimer/startTimer', {}, {
        root: true,
      });
    },

    resetFunnelCardErrorState({ rootGetters, commit }) {
      if (rootGetters['card/selectedCard']) {
        rootGetters['card/selectedCard'].removeErrors();
        commit('transaction/setIsPaymentFailed', false, { root: true });
        commit('transaction/setPayoutErrorMessage', null, { root: true });
      }
    },

    setFeatureFlags({ commit }, features) {
      commit('feature/setFeatures', features, {
        root: true,
      });
    },

    setLanguage({ commit }, language) {
      commit('language', language, {
        root: true,
      });
    },
  },
};

// HACK: external dependencies, dirty. We cannot move it into externalStore without pain,
// because this module requires by `transaction-funnel`, but not registering inside it.
// The same story with `sumsubErrors`, it's requires by `common-lib` inside `sumsubService`.
export const registerVerificationModules = store => {
  store.registerModule('verification', verification);
  store.registerModule('sumsubErrors', sumsubErrors);
};
