<template>
  <Modal
    size="sm"
    :show="show"
    @close="$emit('close')"
  >
    <div class="transaction-popup__inner">
      <div class="transaction-popup__title">
        {{ $t('transaction-flow.verification-cancel-popup.title') }}
      </div>
      <div class="transaction-popup__msg">
        {{ $t('transaction-flow.verification-cancel-popup.message') }}
      </div>
      <div class="transaction-popup__actions">
        <button
          :disabled="isLocked"
          class="btn btn-lg"
          @click="$emit('close')"
        >
          {{ $t('transaction-flow.cancel-popup.no') }}
        </button>
        <button
          :class="{ 'is-loading': isLocked }"
          :disabled="isLocked"
          class="btn btn-lg btn-primary"
          @click="$emit('cancel')"
        >
          {{ $t('transaction-flow.cancel-popup.yes') }}
        </button>
      </div>
    </div>
  </Modal>
</template>

<script>
import Modal from '@paybis/frontend-common-lib/src/components/modal';

export default {
  name: 'CancellationPopup',

  components: {
    Modal,
  },

  props: {
    isLocked: {
      type: Boolean,
      required: false,
      default: false,
    },
    show: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>

<style scoped lang="scss">
.transaction-popup {
  &__inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: rem(34);
  }

  &__actions {
    display: flex;
    flex-direction: row;
    justify-content: center;

    button {
      margin: rem(5);
    }
  }

  &__title {
    font-weight: 700;
    font-size: rem(18);
    line-height: rem(24);
    text-align: center;
  }

  &__msg {
    margin-top: rem(4);
    text-align: center;
    font-size: rem(14);
    line-height: rem(18);
    margin: rem(20);
  }
}
</style>
