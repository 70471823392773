<template>
  <div class="too-early">
    <div class="too-early__inner">
      <i class="too-early__icon svg-icon icon-cancelled" />

      <span class="too-early__title">
        {{ $t('widget.errors.too-many-requests.title') }}
      </span>

      <span class="too-early__text">
        {{ $t('widget.errors.too-many-requests.description') }}
      </span>

      <CloseWidgetButton
        mode="modal"
        @click="$emit('confirm')"
      />
    </div>

    <SupportChat
      :chat-key="themeConfig.zenDeskKey"
      :lang="currentLang"
    />
  </div>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';

import SupportChat from '@/components/SupportChat';
import CloseWidgetButton from '@/components/CloseWidgetButton';

export default {
  name: 'TooManyRequestsErrorModal',

  components: { CloseWidgetButton, SupportChat },

  emits: ['confirm'],

  setup() {
    const store = useStore();
    const themeConfig = computed(() => store.getters.themeConfig);
    const currentLang = computed(() => store.getters.locale);

    return {
      themeConfig,
      currentLang,
    };
  },
};
</script>

<style lang="scss" scoped>
.too-early {
  background-color: $white;
  max-width: 480px;
  border-radius: 6px;

  &__inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 56px 36px;
  }

  &__icon {
    width: 75px;
    height: 75px;
    margin-bottom: 16px;
  }

  &__title, &__text {
    display: block;
    margin-bottom: 32px;
    line-height: 1.4;
    text-align: center;
  }

  &__title {
    font-weight: 700;
    font-size: 22px;
  }

  &__text {
    font-family: $font-family;
    font-size: 18px;
  }
}
</style>
