<template>
  <main
    v-show="!isLoading"
    class="main"
  >
    <div
      class="sales-funnel-wrapper"
      :class="{
        'has-warning': hasWarning,
        'has-success': hasSuccess,
        'has-notice': hasNotice,
        'has-bottom-space': bottomSpace,
        'is-widget': isWidget
      }"
    >
      <div class="sales-funnel-wrapper__container">
        <div
          v-if="hasHeaderSlot"
          class="sales-funnel-wrapper__header"
        >
          <slot name="header" />
        </div>
        <div
          v-if="hasStatusSlot"
          class="sales-funnel-wrapper__status"
        >
          <slot name="status" />
        </div>
        <div class="sales-funnel-wrapper__body">
          <slot name="body" />
        </div>
        <div
          v-if="hasBodyWideSlot"
          class="sales-funnel-wrapper__body-wide"
        >
          <slot name="bodyWide" />
        </div>
        <div
          v-if="hasFooterSlot"
          class="sales-funnel-wrapper__footer"
        >
          <slot name="footer" />
        </div>
      </div>
      <div class="sales-funnel-wrapper__postfix">
        <p
          v-if="hasComplianceMessage"
          class="sales-funnel-wrapper__postfix-compliance"
        >
          <slot name="complianceMessage" />
        </p>
        {{ $t("transaction-flow.shared.secured") }}
      </div>
      <div
        v-if="hasInfoboxSlot"
        class="sales-funnel-wrapper__infobox"
      >
        <slot name="infobox" />
      </div>
    </div>
    <slot name="modal" />
  </main>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'Wrapper',

  props: {
    hasWarning: { type: Boolean, required: false, default: false },
    hasSuccess: { type: Boolean, required: false, default: false },
    hasNotice: { type: Boolean, required: false, default: false },
    bottomSpace: { type: Boolean, required: false, default: false },
    isLoading: { type: Boolean, required: false, default: false },
  },

  computed: {
    ...mapGetters('settings', ['isWidget']),

    hasComplianceMessage() {
      return !!this.$slots.complianceMessage;
    },

    hasHeaderSlot() {
      return !!this.$slots.header;
    },

    hasStatusSlot() {
      return !!this.$slots.status;
    },

    hasBodySlot() {
      return !!this.$slots.body;
    },

    hasBodyWideSlot() {
      return !!this.$slots.bodyWide;
    },

    hasFooterSlot() {
      return !!this.$slots.footer;
    },

    hasInfoboxSlot() {
      return !!this.$slots.infobox;
    },
  },
};
</script>

<style scoped lang="scss">
.sales-funnel-wrapper {
  position: relative;
  width: 100%;
  padding: rem(24) rem(16);

  &.is-widget {
    max-width: 100%;
  }

  &__container {
    background-color: $white;
    border-radius: rem(10);
    width: rem(672);
    max-width: 100%;
    margin: 0 auto;
  }

  &__header {
    border-radius: rem(10) rem(10) 0 0;
  }

  &.has-warning &__header,
  &.has-warning &__status {
    background-color: rgba($red, 0.1);
  }

  &.has-success &__header,
  &.has-success &__status {
    background-color: rgba($green, 0.1);
  }

  &.has-notice &__header,
  &.has-notice &__status {
    background-color: $grey-04;
  }

  &__status {
    padding: 0 rem(16) rem(10) rem(16);
    font-weight: 700;
    font-size: rem(14);
    line-height: 1.29;
    text-align: center;
  }

  &.has-warning &__status {
    color: $red;
  }

  &.has-success &__status {
    color: $green;
  }

  &__body {
    width: rem(448);
    max-width: 100%;
    margin: 0 auto;
    padding: rem(24) rem(16);
  }

  &.has-bottom-space &__body {
    padding-bottom: rem(40);
  }

  &__footer {
    background-color: $grey-04;
    border-radius: 0 0 rem(10) rem(10);
  }

  &__postfix {
    text-align: center;
    margin-top: rem(16);
    font-size: rem(14);
    line-height: 1.14;
  }

  &__postfix-compliance {
    padding-bottom: rem(8);
  }

  &__infobox {
    width: rem(672);
    max-width: 100%;
    background-color: $black-20;
    border-radius: rem(6);
    font-size: rem(14);
    line-height: 1.29;
    color: $white;
  }

  &__infobox:before {
    content: "\e920";
    @include inline-icon;

    position: absolute;
    left: rem(16);
    top: rem(17);
    font-size: rem(16);
  }

  [dir="rtl"] &__infobox:before {
    right: rem(16);
    left: unset;
  }

  @media screen and (max-width: $screen-md-max) {
    &__infobox {
      position: relative;
      margin: rem(16) auto 0 auto;
      padding: rem(16) rem(16) rem(16) rem(44);
    }

    [dir="rtl"] &__infobox {
      padding: rem(16) rem(44) rem(16) rem(16);
    }
  }

  @media screen and (min-width: $screen-lg-min) {
    width: cols("lg", 12);
    margin: 0 auto;
    padding: rem(64) 0 rem(80) 0;

    &__header {
      border-bottom: 1px solid $black-06;
    }

    &__status {
      padding: rem(10) rem(16);
      font-weight: 700;
      font-size: rem(14);
      line-height: 1.29;
      text-align: center;
    }

    &__body {
      max-width: rem(416);
      margin: 0 auto;
      padding: rem(36) 0 rem(24) 0;
    }

    &.has-bottom-space &__body {
      padding-bottom: rem(64);
    }

    &__body-wide {
      padding: rem(36) 0 rem(24) 0;
    }

    &__postfix {
      margin-top: rem(24);
    }

    &__infobox {
      position: absolute;
      right: 0;
      top: rem(64);
      width: cols("lg", 2);
      max-width: rem(160);
      padding: rem(48) rem(16) rem(32) rem(16);
    }

    [dir="rtl"] &__infobox {
      left: 0;
      right: unset;
    }
  }

  @media screen and (min-width: $screen-xlg-min) {
    width: cols("xlg", 12);

    &__infobox {
      right: 0;
    }
  }
}
</style>
