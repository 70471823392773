<template>
  <span>
    <Title>{{
      $t('transaction-flow.steps.payment-waiting.crypto.title')
    }}</Title>
    <Paragraph>
      {{ waitingTimeLabel }}
    </Paragraph>
    <FunnelLoader />
    <CryptoPaymentData :payment="payment" />
  </span>
</template>

<script>
import Paragraph from '@paybis/frontend-common-lib/src/components/paragraph';
import Title from '@paybis/frontend-common-lib/src/components/title';
import { mapGetters } from 'vuex';

import CryptoPaymentData from './crypto-payment-data';
import FunnelLoader from './funnel-loader';
import { useI18n } from 'vue-i18n';

export default {
  components: {
    FunnelLoader,
    Title,
    Paragraph,
    CryptoPaymentData,
  },
  data() {
    const { t, te } = useI18n();

    return {
      t,
      te,
    };
  },
  computed: {
    ...mapGetters('transaction', ['payment']),
    waitingTimeLabel() {
      let key = `transaction-flow.money-service.${this.payment.slug}.payment-waiting.crypto.waiting-time`;
      if (!this.te(key)) {
        key = 'transaction-flow.steps.payment-waiting.crypto.waiting-time';
      }

      return this.t(key);
    },
  },
};
</script>
