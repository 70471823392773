import FormFactory from '../../factories/FormFactory';
import Payment from '../../models/money-service/payment';
import Payout from '../../models/money-service/payout';
import Transaction from '../../models/transaction';
import { PayoutDetailsField } from '../../models/transaction/field';
import BasicVerification from '../../models/verification/basic';
import ExtraVerification from '../../models/verification/extra';
import { ExtraVerificationField } from '../../models/verification/field';
import FrontClient from '../../services/clients/front-client';
import ProcessingClient from '../../services/clients/processing-client';
import { Fees } from '../../models/transaction/fees';
import { FlowDetails } from '../../models/transaction/flowDetails';
import ApmClient from '../../services/clients/apm-client';
import ApmPayment from '../../models/apmPayment';
import { captureException } from '@paybis/frontend-common-lib/src/plugins/sentry';

export default {
  namespaced: true,
  state: {
    baseVerification: new BasicVerification(),
    extraVerification: new ExtraVerification(),

    invoice: null,
    transaction: new Transaction(),

    payment: new Payment(),
    payout: new Payout(),

    rejectMessage: null,
    showTransactionCancellationPopup: false,
    isApplePay: false,

    fees: new Fees(),

    flowDetails: new FlowDetails(),

    isPaymentFailed: false,

    payoutErrorMessage: null,
    apmPayment: new ApmPayment(),

    isPollingStopped: false,
  },
  getters: {
    baseVerification: state => state.baseVerification,
    extraVerification: state => state.extraVerification,

    transaction: state => state.transaction,
    invoice: state => state.invoice,

    payment: state => state.payment,
    payout: state => state.payout,

    flowDetails: state => state.flowDetails,
    rejectMessage: state => state.rejectMessage,
    showTransactionCancellationPopup: state => state.showTransactionCancellationPopup,
    canCancelVerification: state => state.transaction.isNewFLow()
      && state.transaction.isCardEntry()
      && state.transaction.isCancellable(),
    isPaymentFailed: state => state.isPaymentFailed,
    payoutErrorMessage: state => state.payoutErrorMessage,
    apmPayment: state => state.apmPayment,
    isPollingStopped: state => state.isPollingStopped,
  },
  mutations: {
    setBaseVerification(state, { status }) {
      state.baseVerification = new BasicVerification(status);
    },
    setExtraVerification(state, { status, fields }) {
      state.extraVerification = new ExtraVerification(status, fields);
    },

    updateTransaction(state, { status, flags }) {
      state.transaction = new Transaction(status, flags);
    },
    setInvoice(state, payload) {
      state.invoice = payload;
    },

    setPayment(
      state,
      {
        name,
        icon,
        iconName,
        slug,
        type,
        canCheckout,
        details,
        isRefused,
        isError,
        amount,
        flowType,
        autoFallbackTimer,
        fallbackAvailable,
        status,
      },
    ) {
      state.payment = new Payment(
        name,
        icon,
        slug,
        type,
        canCheckout,
        details,
        isRefused,
        isError,
        iconName,
        amount,
        flowType,
        autoFallbackTimer,
        fallbackAvailable,
        status,
      );
    },

    setPayout(
      state,
      { name, icon, iconName, slug, type, account, canAuthorizeCard, crypto, binanceDetails },
    ) {
      state.payout = new Payout(
        name,
        icon,
        slug,
        type,
        account,
        canAuthorizeCard,
        crypto,
        iconName,
        binanceDetails,
      );
    },

    setRejectMessage(state, payload) {
      state.rejectMessage = payload;
    },

    toggleTransactionCancellationPopup(state) {
      state.showTransactionCancellationPopup = !state.showTransactionCancellationPopup;
    },

    setFees(state, payload) {
      state.fees = new Fees(payload);
    },

    setFlowDetails(
      state,
      { payout_strategy, is_payout_terms_acceptance_required },
    ) {
      state.flowDetails = new FlowDetails(
        payout_strategy,
        is_payout_terms_acceptance_required,
      );
    },
    setIsPaymentFailed(state, payload) {
      state.isPaymentFailed = payload;
    },

    setPayoutErrorMessage(state, value) {
      state.payoutErrorMessage = value;
    },
    setApmPayment(state, value) {
      state.apmPayment = new ApmPayment(value);
    },
    stopPollingTransaction(state) {
      state.isPollingStopped = true;
    },
    startPollingTransaction(state) {
      state.isPollingStopped = false;
    },
  },
  actions: {
    relevantFallback({ rootGetters }, invoice) {
      return ProcessingClient.getRelevantTransactionByInvoice(invoice, rootGetters.language);
    },
    init({ dispatch, getters, rootGetters, commit }) {
      const { invoice } = getters;
      return ProcessingClient.getTransactionByInvoice(
        invoice,
        rootGetters.language,
      ).then(({ data }) => new Promise(async (resolve, reject) => {
        // Pre-init
        const { flags, payment, status, payout } = data;
        const transaction = new Transaction(status, flags);
        dispatch('setPayment', payment);
        dispatch('setPayout', payout);
        dispatch('v2/paymentMethods/setPaymentOrPayoutMethodFromTransaction', {}, { root: true });

        const isBankCard = getters.payment.isBankCard() || getters.payout.isBankCard();

        if (getters.payment.isTrustly() && transaction.isPaymentDetails()) {
          const { data: dataApmPayment } = await ApmClient.getPayment(invoice);
          commit('setApmPayment', dataApmPayment);
        }
        if (getters.transaction.status === null && isBankCard) {
          const url = new URL(window.location);
          const paymentId = url.searchParams.get('payment_id');

          // Don NOT remove this piece of code.
          // Strictly required to correct work of funnel inside the widget env.
          // @see https://gitlab.com/techcloud/shared/frontend-common-lib/-/merge_requests/143
          if (
            transaction.isPaymentDetails()
              || transaction.isPayoutDetails()
          ) {
            const cardParams = {};
            await dispatch(
              'card/getCards',
              cardParams,
              {
                root: true,
              },
            ).catch(error => {
              reject(error);
            });
          }

          if (paymentId !== null) {
            url.searchParams.delete('payment_id');
            window.history.replaceState(null, null, url);

            return dispatch(
              'card/getPaymentStatus',
              { paymentId },
              { root: true },
            ).finally(() => {
              if (rootGetters['card/selectedCard'].hasError()) {
                commit('setIsPaymentFailed', true);
              }
              resolve(data);
            });
          }

          const promise = dispatch(
            'card/getActivePayment',
            { invoice },
            { root: true },
          ).finally(() => {
            resolve(data);
          });

          if (
            transaction.isPaymentDetails()
              || transaction.isPaymentReview()
          ) {
            return promise;
          }
        }

        return resolve(data);
      }).then(data => {
        if (!getters.isPollingStopped) {
          dispatch('setTransaction', data);
        }

        if (rootGetters['v2/widgetQuote/hasError']) {
          dispatch('v2/paymentMethods/setPaymentOrPayoutMethodFromTransaction', {}, { root: true });

          commit('v2/widgetQuote/setQuoteError', false, { root: true });
        }

        return data;
      })).catch(error => {
        captureException({ error });
      });
    },
    setTransaction(
      { commit, dispatch, getters, rootGetters },
      {
        status,
        flags,
        reject_message: rejectMessage,
        payment,
        payout,
        auth,
        verification,
        fees,
        flow_details: flowDetails,
      },
    ) {
      if (flowDetails) {
        commit('setFlowDetails', flowDetails);
      }
      commit('setFees', fees);
      commit('updateTransaction', { status, flags });

      if (rejectMessage) {
        commit('setRejectMessage', rejectMessage);
      }

      dispatch('setPayment', payment);

      dispatch('setPayout', payout);

      dispatch('setQuote', { payment, payout });

      dispatch(
        'cryptoTimer/init',
        {
          expiresAt: payout.amount.expires_at,
          dynamic: payout.amount.dynamic,
        },
        { root: true },
      );

      if (auth) {
        dispatch('cardAuth/init', auth, { root: true });
      }

      if (verification) {
        commit('setBaseVerification', verification.base);
        commit('setExtraVerification', verification.extra);

        if ('extra' in verification && 'fields' in verification.extra) {
          const oldExtraVerificationFields = rootGetters['extraVerificationFields/fields'];

          const fields = verification.extra.fields.map(field => {
            const extraVerificationField = new ExtraVerificationField(field);

            if (getters.extraVerification.isRejected() || extraVerificationField.isSumSubAction()) {
              const oldExtraVerificationField = oldExtraVerificationFields.find(
                oldExtraVerificationField => oldExtraVerificationField.getId()
                  === extraVerificationField.getId(),
              );

              if (oldExtraVerificationField) {
                if (
                  (oldExtraVerificationField.isUploaded()
                    && extraVerificationField.isRejected()) || extraVerificationField.isSumSubAction()
                ) {
                  commit(
                    'extraVerificationFields/updateField',
                    extraVerificationField,
                    { root: true },
                  );
                }

                if (
                  oldExtraVerificationField.hasValue()
                  && oldExtraVerificationField.getValue()
                    !== extraVerificationField.getValue()
                ) {
                  extraVerificationField.setUnverified();
                  extraVerificationField.setErrorMessage(null);
                }
              }
            }

            return extraVerificationField;
          });
          dispatch('extraVerificationFields/init', fields, { root: true });
        }
      }

      dispatch('initNavigation');
    },
    initNavigation({ getters, dispatch, rootGetters }) {
      dispatch('navigation/init', {
        transaction: getters.transaction,
        baseVerification: getters.baseVerification,
        extraVerification: getters.extraVerification,
        payment: getters.payment,
        isSumsubVerificationPending:
          rootGetters['cardSumsub/isSumsubVerificationPending'],
        isActivePayment: rootGetters['card/isActivePayment'],
        isChallenge: rootGetters['card/isChallenge'],
        payout: getters.payout,
        flowDetails: getters.flowDetails,

        // XXX: scope violation
        requestHasWallet: rootGetters['request/hasWallet'],
        requestStatus: rootGetters['request/requestStatus'],
      });
    },
    setPayment(
      { commit, dispatch, getters, rootGetters },
      {
        details,
        service,
        type,
        can_checkout: canCheckout,
        is_refused: isRefused,
        is_error: isError,
        amount,
        flow_type: flowType,
        auto_fallback_timer_value: autoFallbackTimer,
        fallback_available: fallbackAvailable,
        status,
      },
    ) {
      commit('setPayment', {
        name: service.name,
        icon: service.icon,
        iconName: service.icon_name,
        slug: service.slug,
        type,
        canCheckout,
        details,
        isRefused,
        isError,
        amount,
        flowType,
        autoFallbackTimer,
        fallbackAvailable,
        status,
      });
    },
    setPayout(
      { commit, dispatch },
      { details, service, type, account, can_authorize_card, crypto, binance_details },
    ) {
      // eslint-disable-line camelcase
      commit('setPayout', {
        name: service.name,
        icon: service.icon,
        iconName: service.icon_name,
        slug: service.slug,
        type,
        account,
        canAuthorizeCard: can_authorize_card,
        crypto,
        binanceDetails: binance_details,
      });

      if (details) {
        const fields = details.map(field => new PayoutDetailsField(field));
        dispatch('payoutDetailsFields/init', fields, { root: true });
      }
    },
    setQuote({ dispatch }, quote) {
      dispatch('quote/init', quote, { root: true });
    },
    cancelTransaction({ dispatch, getters }) {
      const { invoice } = getters;
      return ProcessingClient.cancelTransactionByInvoice(invoice).then(
        ({ data }) => {
          dispatch('setTransaction', data);
          if (!getters.transaction.isCancelled()) {
            return Promise.reject(new Error());
          }

          return data;
        },
      );
    },
    submitTransactionPayoutDetails(
      { commit, dispatch, getters, rootGetters },
      { isAgreedWithZhCompliance = false, isPaybisAccount = false },
    ) {
      const { invoice } = getters;
      const fields = {
        isPaybisAccount,
      };
      let hasError = false;
      rootGetters['payoutDetailsFields/fields'].forEach(field => {
        if (field.isAccount() && field.isEmpty()) {
          field.setErrorMessage('This field is required error');
          hasError = true;
        }

        fields[field.getId()] = field.getValue() || '';
      });

      if (hasError) {
        return Promise.reject(new Error());
      }

      if (getters.flowDetails.isPayoutTermsAcceptanceRequired()) {
        fields.isPayoutTermsAccepted = isAgreedWithZhCompliance;
      }

      return ProcessingClient.submitTransactionPayoutDetailsByInvoice(
        invoice,
        fields,
        rootGetters.language,
      ).then(({ data }) => {
        const { status, flags, payment } = data;
        commit('updateTransaction', { status, flags });
        dispatch('setPayment', payment);

        if (
          getters.payment.checkoutable()
          && !getters.transaction.isVerification()
        ) {
          return new Promise((resolve, reject) => {
            commit('initRedirect', null, { root: true });
            dispatch('checkoutTransaction').catch(error => {
              commit('destroyRedirect', null, { root: true });
              reject(error);
            });
          });
        }

        dispatch('setTransaction', data);
        return Promise.resolve(data);
      });
    },
    markTransactionAsPaid({ dispatch, getters }) {
      const { invoice } = getters;
      return ProcessingClient.markTransactionAsPaidByInvoice(invoice).then(
        ({ data }) => {
          dispatch('setTransaction', data);

          return data;
        },
      );
    },
    checkoutTransaction({ getters, rootGetters }) {
      const { invoice } = getters;
      return ProcessingClient.checkoutTransactionByInvoice(
        invoice,
        rootGetters.language,
      ).then(({ data }) => {
        if (data.form) {
          const formElement = new FormFactory(data.form).createFormElement();
          document.body.appendChild(formElement);
          formElement.submit();
          return new Promise(() => {});
        }

        if (data.link) {
          window.location = data.link;
          return new Promise(() => {});
        }

        return Promise.reject(new Error());
      });
    },
    authorizeTransaction({ getters }) {
      const { invoice } = getters;
      return ProcessingClient.authorizeTransactionByInvoice(invoice).then(
        ({ data }) => {
          if (data.form) {
            const formElement = new FormFactory(data.form).createFormElement();
            document.body.appendChild(formElement);
            formElement.submit();
            return;
          }

          if (data.link) {
            window.location = data.link;
            return;
          }

          return Promise.reject(new Error());
        },
      );
    },
    repeatTransaction({ getters }) {
      const { invoice } = getters;
      return FrontClient.repeatTransactionByInvoice(invoice).then(
        ({ data }) => {
          if (data.link) {
            window.location = data.link;
            return;
          }

          return Promise.reject(new Error());
        },
      );
    },
    submitGiftCard({ dispatch, getters }, giftCardNumber) {
      const { invoice } = getters;
      return ProcessingClient.submitGiftCard(invoice, giftCardNumber).then(
        ({ data }) => {
          dispatch('setTransaction', data);

          return data;
        },
      );
    },
  },
};
