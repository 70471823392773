<template>
  <div class="payment-review">
    <Title data-testid="payment-is-under-review">
      {{ title }}
    </Title>
    <Paragraph>
      {{ message }}
    </Paragraph>
    <FunnelLoader />
    <CryptoPaymentData
      v-if="payment.isCrypto()"
      :payment="payment"
    />
  </div>
</template>

<script>
import Paragraph from '@paybis/frontend-common-lib/src/components/paragraph';
import Title from '@paybis/frontend-common-lib/src/components/title';
import { mapGetters } from 'vuex';

import CryptoPaymentData from '../components/crypto-payment-data';
import FunnelLoader from '../components/funnel-loader';
import { useI18n } from 'vue-i18n';

export default {
  components: {
    FunnelLoader,
    Title,
    Paragraph,
    CryptoPaymentData,
  },
  data() {
    const { t } = useI18n();

    return {
      t,
    };
  },
  computed: {
    ...mapGetters('transaction', ['payment', 'payout']),
    title() {
      if (this.payout.isBankCard()) return this.t('transaction-flow.steps.payment-review.card-payout.title');

      return this.t('transaction-flow.steps.payment-review.title');
    },
    message() {
      if (this.payout.isBankCard()) return this.t('transaction-flow.steps.payment-review.card-payout.waiting-time');

      return this.t('transaction-flow.steps.payment-review.waiting-time');
    },
  },
};
</script>
