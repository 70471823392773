<template>
  <WidgetHeader @close-widget="toggleCloseConfirmModal" />

  <div class="widget-base">
    <WidgetContentArea
      class="widget-base__content-area"
      @is-widget-loaded="onWidgetLoaded"
    />

    <ZhDisclaimer
      v-if="flowDetails.isZerohashPayoutStrategy()"
      skin="mobile"
    />
  </div>

  <WidgetFooter />
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';

import WidgetFooter from '@/components/WidgetFooter';
import WidgetContentArea from '@/components/WidgetContentArea';
import WidgetHeader from '@/components/WidgetHeader';
import ExchangeRateExpiration from '@/components/ExchangeRateExpiration';
import { useIsTransactionInFinalStatus } from '@/composables/transactionStatus';

import {
  shouldChangeAppearanceForError as showLeftSidebar,
  isErrorRoute as hideTransactionDetails,
  widgetWrapperAlignment,
} from '@/composables/errorScreenAppearance';

import ZhDisclaimer from '../components/ZhDisclosure/ZhDisclaimer';

export default {
  name: 'WidgetWrapper',

  components: {
    ZhDisclaimer,
    WidgetFooter,
    WidgetHeader,
    WidgetContentArea,

  },
  setup(props, { emit }) {
    const store = useStore();
    const toggleCloseConfirmModal = () => {
      emit('closeWidget');
    };
    const isTransactionInFinalStatus = useIsTransactionInFinalStatus();

    const flowDetails = computed(() => store.getters['transaction/flowDetails']);

    return {
      toggleCloseConfirmModal,

      isTransactionInFinalStatus,

      hideTransactionDetails,
      showLeftSidebar,
      widgetWrapperAlignment,
      flowDetails,
    };
  },
};
</script>

<style lang="scss" scoped>
</style>
