<template>
  <div class="sales-funnel-current-transaction">
    <div class="sales-funnel-current-transaction__pair">
      <div
        class="sales-funnel-current-transaction__item"
        data-testid="fiatamount"
      >
        <img
          :src="transaction.sell.icon"
          :alt="transaction.sell.title"
          class="sales-funnel-current-transaction__icon"
          :title="transaction.sell.title"
        >
        <span>{{ sellAmountWithCurrency }}</span>
      </div>
      <div
        class="sales-funnel-current-transaction__arrow"
        :class="{
          'is-equal': type === 'equal',
          'is-approximate': type === 'approximate'
        }"
      />
      <div
        class="sales-funnel-current-transaction__item"
        data-testid="cryptoamount"
      >
        <img
          :src="transaction.buy.icon"
          :alt="transaction.buy.title"
          class="sales-funnel-current-transaction__icon"
          :title="transaction.buy.title"
        >
        <span :class="payoutAmountChangeClass">{{
          buyAmountWithCurrency
        }}</span>
      </div>
    </div>
    <div
      ref="copyable"
      class="sales-funnel-current-transaction__secondary"
      :class="{ copyable: isCopyable, copied: isCopied }"
      @click="handleCopy"
    >
      <slot />
    </div>
  </div>
</template>

<script>
import { copyToClipboard, parseAmount } from '../services/util';

export default {
  name: 'CurrentTransaction',

  props: {
    transaction: { type: Object, required: true },
    type: { type: String, required: false, default: 'equal' },
  },

  data() {
    return {
      isCopyable: false,
      isCopied: false,
      copiedText: null,
    };
  },

  computed: {
    sellAmountWithCurrency() {
      return (
        `${parseAmount(this.transaction.sell.amount)
        } ${
          this.transaction.sell.currency}`
      );
    },
    buyAmountWithCurrency() {
      return (
        `${parseAmount(this.transaction.buy.amount)
        } ${
          this.transaction.buy.currency}`
      );
    },
    payoutAmountChangeClass() {
      const { originalAmount } = this.transaction.buy;
      const currentAmount = this.transaction.buy.amount;

      return {
        'text-green': currentAmount > originalAmount,
        'text-red': currentAmount < originalAmount,
      };
    },
  },

  mounted() {
    this.isCopyable = !this.$refs.copyable.children.length;
  },

  methods: {
    handleCopy() {
      const { copyable } = this.$refs;

      if (!this.isCopyable) {
        return;
      }

      copyToClipboard(copyable.innerHTML.trim());
      this.isCopied = true;

      setTimeout(() => {
        this.isCopied = false;
      }, 3000);
    },
  },
};
</script>

<style scoped lang="scss">
.sales-funnel-current-transaction {
  width: 100%;
  max-width: rem(416);
  margin: auto;
  padding: rem(16);

  &__item {
    display: flex;
    align-items: center;
    font-weight: 700;
    font-size: rem(16);
    line-height: 1.25;
    justify-content: center;
  }

  &__icon {
    display: block;
    max-width: rem(16);
    max-height: rem(16);
    margin-right: rem(8);
  }

  [dir="rtl"] &__icon {
    margin-left: rem(8);
    margin-right: unset;
  }

  &__arrow {
    width: rem(40);
    height: rem(20);
    text-align: center;
    margin: rem(4) auto;
  }

  &__arrow:after {
    @include inline-icon;
  }

  &__arrow.is-equal:after {
    content: "\e917";
  }

  &__arrow.is-approximate:after {
    content: "\e91f";
  }

  &__secondary {
    font-size: rem(14);
    line-height: 1.3;
    text-align: center;
    color: $grey-30;
    margin-top: rem(10);
    word-wrap: break-word;
    position: relative;
  }

  &__secondary.copyable {
    cursor: copy;
  }

  &__secondary::before {
    content: "Copied to clipboard";
    display: block;
    opacity: 0;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
    border-radius: rem(2);
    font-weight: 700;
    font-size: rem(10);
    line-height: rem(20);
    text-align: center;
    color: $white;
    padding: 0 rem(6);
    position: absolute;
    top: rem(-24);
    left: 50%;
    transform: translateX(-50%);
    transition: opacity 0.2s linear;
    pointer-events: none;
  }

  &__secondary.copied:before {
    background-color: $black;
    opacity: 0.8;
  }

  &__secondary-input {
    position: absolute;
    left: -2000vw;
    opacity: 0;
  }

  @media screen and (min-width: $screen-lg-min) {
    padding: rem(24) 0;

    &__pair {
      display: flex;
      justify-content: center;
    }

    &__arrow {
      margin: 0;
    }

    &__arrow.is-equal:after {
      content: "\e915";
    }
  }
}
</style>
