<template>
  <span>
    <Pictogram
      :src="
        require('@paybis/frontend-common-lib/src/assets/images/verification-process.svg')
      "
    />
    <Paragraph :bold="true">
      {{
        $t(
          'transaction-flow.steps.payment-details.3ds-waiting-screen.paragraph',
        )
      }}
    </Paragraph>
    <Loader class="mt-32 mb-32 ml-auto mr-auto " />
    <Paragraph v-show="counter > 3">
      {{
        $t(
          'transaction-flow.steps.payment-details.3ds-waiting-screen.waiting-to-long',
        )
      }}
    </Paragraph>
  </span>
</template>

<script>
import Loader from '@paybis/frontend-common-lib/src/components/loader';
import Paragraph from '@paybis/frontend-common-lib/src/components/paragraph';
import Pictogram from '@paybis/frontend-common-lib/src/components/pictogram';

export default {
  name: 'ChallengeWaiting',

  components: {
    Pictogram,
    Paragraph,
    Loader,
  },

  props: {
    counter: { type: Number, required: true, default: 0 },
  },
};
</script>
