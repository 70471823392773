<template>
  <Loader v-if="isInitiallyLoading" />
  <PageLayout
    v-else
    :title="$t('widget.transaction-history.title')"
    class="small-title"
    :have-previous-step="true"
  >
    <div
      class="transaction-history"
    >
      <FormInput
        :value="search"
        class="transaction-history__search"
        name="search"
        placeholder="Search"
        type="text"
        @input="searchTransaction"
      />

      <Loader
        v-if="isLoading"
        class="transaction-history__loader"
      />
      <template v-if="hasItems">
        <TransactionHistoryScrollableList
          :transactions="transactions"
          class="transaction-history__list"
          :class="{
            'with-load-more': canLoadMore,
          }"
          @transaction-item-select="redirectToTransaction"
        />

        <footer
          v-if="canLoadMore"
          class="transaction-history__footer"
        >
          <button
            class="transaction-history__loadmore"
            :class="{
              'is-loading': isLoading,
            }"
            @click="getUserTransactions({ search, nextLoad: true })"
          >
            <i class="transaction-history__loadmore-icon svg-icon icon-arrow-long-down-black" />
            <span class="transaction-history__loadmore-title">
              {{ $t('widget.load-more') }}
            </span>
          </button>
        </footer>
      </template>
      <div
        v-else-if="search"
        class="transaction-history__search-placeholder"
      >
        <span class="transaction-history__search-icon" />
        <span class="transaction-history__search-note">No result found</span>
      </div>
      <div
        v-else
        class="no-transactions"
      >
        {{ $t('widget.transaction-history.no-items') }}
      </div>
    </div>
  </PageLayout>
</template>

<script>
// TODO move all menu pages into separate folder
import { ref, computed, onMounted, defineComponent, onUnmounted } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import debounce from 'lodash.debounce';
import FormInput from '@paybis/frontend-common-lib/src/components/form/form-input';
import { useWidgetMenuStyles } from '../../composables/useWidgetMenuStyles';

import Loader from '@/components/Loader';
import PageLayout from '@/components/PageLayout';
import TransactionHistoryScrollableList from '@/components/TransactionHistoryList';

const INPUT_DEBOUNCE_TIME = 1000;

export default defineComponent({
  name: 'TransactionHistory',

  components: {
    Loader,
    PageLayout,
    TransactionHistoryScrollableList,
    FormInput,
  },

  setup() {
    const store = useStore();
    const router = useRouter();

    const search = ref('');

    const isLoading = computed(() => store.getters['transactionHistory/isLoading']);
    const isInitiallyLoading = computed(() => store.getters['transactionHistory/isInitiallyLoading']);
    const transactions = computed(() => store.getters['transactionHistory/sortedByDay']);
    const canLoadMore = computed(() => store.getters['transactionHistory/canLoadMore']);
    const getUserTransactions = searchText => store.dispatch('transactionHistory/getUserTransactions', searchText);
    const cleanHistoryStorage = () => store.dispatch('transactionHistory/clean');
    const hasItems = computed(() => store.getters['transactionHistory/hasItems']);

    const redirectToTransaction = async transaction => {
      await store.dispatch('request/redirectToInvoice', {
        invoiceId: transaction.invoice,
      });
    };

    const searchTransaction = debounce(
      searchText => {
        search.value = searchText;
        getUserTransactions({ search: searchText, nextLoad: false });
      },
      INPUT_DEBOUNCE_TIME,
    );

    const { setWidgetMenuStyles } = useWidgetMenuStyles();

    onMounted(async () => {
      setWidgetMenuStyles({
        padding: '2.5rem',
      });

      try {
        await getUserTransactions({ search: search.value, nextLoad: false });
      } catch (e) {
        router.push({ path: '/auth' }).then(result => {
          if (result instanceof Error) {
            store.dispatch('toggleMenu');
            store.dispatch('selectMenuItem', {});
          }
        });
      }
    });

    onUnmounted(() => {
      setWidgetMenuStyles({});
      cleanHistoryStorage();
    });

    return {
      search,
      transactions,
      canLoadMore,
      isLoading,
      isInitiallyLoading,
      hasItems,
      searchTransaction,
      redirectToTransaction,
      getUserTransactions,
    };
  },
});
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/blocks/small-title";

$gap: 100px;
$load-more-padding: 24px;
$load-more-line-height: 16px;
$widget-menu-padding: 2.5rem;
$widget-menu-padding-mobile: 24px;
$load-more-button-height: calc(#{$load-more-padding} * 2 + #{$load-more-line-height} + #{$gap});

.no-transactions {
  text-align: center;
  margin-top: calc(50% - 1em);
  font-size: 20px;
}

.page-layout {
  display: block;
}

.transaction-history {
  height: 100%;

  &__loader {
    height: calc(100% - 90px);
    background: #ffffff;
    position: relative;
    z-index: 1;
  }

  &__search {
    margin-bottom: 24px;

    &-placeholder {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      height: calc(100% - 90px);
    }

    &-icon {
      display: flex;
      width: 40px;
      height: 40px;
      background: url(~@/assets/images/widget/search.svg) center no-repeat;
      background-size: 40px;
    }

    &-note {
      display: block;
      padding-top: 16px;
      font-size: 14px;
      line-height: 1.2;
      color: $grey-30;
      font-weight: 700;
    }
  }

  &__loadmore {
    display: inline-block;
    position: relative;

    &.is-loading {
      @include spinerIcon();

      text-indent: -9999px;
      width: 16px;
      height: 16px;

      &::after {
        width: 16px;
        height: 16px;
      }
    }
  }

  &__loadmore-icon {
    display: inline-block;
    vertical-align: middle;
    width: 16px;
    height: 16px;
    margin-right: 4px;
  }

  &__loadmore-title {
    transition: border-color 0.2s linear;
    border-bottom: 1px solid $black-15;

    .transaction-history__loadmore:hover & {
      border-color: $black-60;
    }
  }

  &__list {
    flex-shrink: 2;
    height: calc(100% - #{$load-more-button-height} - 24px);

    &.with-load-more {
      height: calc(100% - #{$load-more-button-height} - 12px);
    }
  }

  @media screen and (min-width: $screen-sm-min) {
    &__list.with-load-more {
      height: calc(100% - #{$load-more-button-height});
    }
  }

  @media screen and (min-width: $screen-md-min) {
    &__list.with-load-more {
      height: calc(100% - #{$load-more-button-height} - #{$widget-menu-padding} + 40px);
    }
  }

  @media screen and (min-width: $laptop-min) {
    &__list {
      height: calc(100% - #{$gap});
    }

    &__list.with-load-more {
      height: calc(100% - #{$load-more-button-height} + #{$widget-menu-padding} - 36px);
    }
  }
}

.transaction-history__footer {
  padding-top: $load-more-padding;
  padding-bottom: $load-more-padding;
  line-height: $load-more-line-height;
  text-align: center;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: $white;
  border-radius: 5px;
}
</style>
