<template>
  <div
    class="tabpanel"
    role="tabpanel"
  >
    <div v-if="selected">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Tab',

  props: {
    title: { type: String, required: true },
    icon: { type: String, required: false, default: '' },
    isSelected: { type: String, required: false, default: '' },
    isDisabled: { type: Boolean, required: false, default: false },
    locator: { type: String, required: false, default: null },
    dataTestid: { type: String, required: false, default: '' },
  },

  computed: {
    selected() {
      return this.isSelected === this.title;
    },
  },
};
</script>
