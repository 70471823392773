import eventBusFactory from 'js-event-bus';

let eventBusInstance;

// In SSR mode we have not window object
if (window) {
  if (!window.__pb_eventBus) {
    window.__pb_eventBus = eventBusFactory();
  }

  eventBusInstance = window.__pb_eventBus;
} else {
  eventBusInstance = eventBusFactory();
}

export default eventBusInstance;
