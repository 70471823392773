<template>
  <WidgetHeader @close-widget="toggleCloseConfirmModal" />

  <div class="widget-base">
    <div
      class="widget-base__body"
      :style="widgetWrapperAlignment"
    >
      <div
        v-if="!showLeftSidebar"
        class="widget-base__partner-info fade-out-animated"
      >
        <WidgetPartnerInfo v-if="!hideTransactionDetails" />
      </div>

      <div
        class="widget-base__screen fade-out-animated"
        :class="{ 'widget-base__screen--without-sidebar': showLeftSidebar }"
      >
        <WidgetContentArea
          class="widget-base__content-area"
          @is-widget-loaded="onWidgetLoaded"
        />

        <ExchangeRateExpiration
          v-if="!isTransactionInFinalStatus && !hideTransactionDetails && !isPaybisAcquireTheme"
          class="widget-base__exchange-rate"
        />

        <ZhDisclaimer
          v-if="flowDetails.isZerohashPayoutStrategy()"
          skin="mobile"
        />
      </div>
    </div>
  </div>

  <WidgetFooter />
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';

import WidgetFooter from '@/components/WidgetFooter';
import WidgetContentArea from '@/components/WidgetContentArea';
import WidgetPartnerInfo from '@/components/WidgetPartnerInfo';
import WidgetHeader from '@/components/WidgetHeader';
import ExchangeRateExpiration from '@/components/ExchangeRateExpiration';
import { useIsTransactionInFinalStatus } from '@/composables/transactionStatus';

import {
  shouldChangeAppearanceForError as showLeftSidebar,
  isErrorRoute as hideTransactionDetails,
  widgetWrapperAlignment,
} from '@/composables/errorScreenAppearance';

import ZhDisclaimer from '../components/ZhDisclosure/ZhDisclaimer';

export default {
  name: 'WidgetWrapper',

  components: {
    ZhDisclaimer,
    WidgetFooter,
    WidgetHeader,
    WidgetContentArea,
    WidgetPartnerInfo,

    ExchangeRateExpiration,
  },
  setup(props, { emit }) {
    const store = useStore();
    const toggleCloseConfirmModal = () => {
      emit('closeWidget');
    };
    const isTransactionInFinalStatus = useIsTransactionInFinalStatus();
    const isPaybisAcquireTheme = computed(() => store.getters.isPaybisAcquireTheme);
    const flowDetails = computed(() => store.getters['transaction/flowDetails']);

    return {
      toggleCloseConfirmModal,

      isTransactionInFinalStatus,
      isPaybisAcquireTheme,

      hideTransactionDetails,
      showLeftSidebar,
      widgetWrapperAlignment,
      flowDetails,
    };
  },
};
</script>

<style lang="scss" scoped>
$simpleFadeBg: simpleFadeBg 1s both ease-in-out;

// Animate blocks only when wrapper was animated
.fade-out-animated {
  opacity: 0;
  transform: translateY(40px);
  transition: transform 800ms ease, opacity 800ms ease;
}

.widget-wrapper--animated .fade-out-animated {
  opacity: 1;
  transform: translateY(0);
}

.widget-base {
  flex-grow: 1;

  @media (max-width: 450px) {
    min-height: -webkit-fill-available;
  }

  &.widget-hidden {
    opacity: 0;
  }

  &::before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    z-index: -1;
    transition: all .3s ease-in-out;
  }

  &::after {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    background: rgba(255, 255, 255, 0.001);
    z-index: -2;
  }

  // bg animation
  &--animated {
    &::before, &::after {
      animation: $simpleFadeBg;
    }
  }
}

.widget-base__body {
  display: flex;
  align-items: stretch;

  @media (max-width: $tablet-max) {
    display: block;
  }
}

.widget-base__screen {
  flex: 1;

  &--without-sidebar {
    justify-content: center;
    align-items: center;
    display: flex;
  }

  @media (max-width: $tablet-max) {
    padding: 0 12px;
  }
}

.widget-base__content-area {
  margin: 0 50px;

  @media (min-width: $laptop-min) {
    display: flex;
    flex-direction: column;
    min-height: 600px;
  }

  @media (min-width: $desktop-min) {
    margin-right: 80px;
  }

  @media (max-width: $tablet-max) {
    max-width: 608px;
    margin: 0 auto 0;
  }
}

.widget-base__partner-info {
  width: 50%;

  @media (max-width: $laptop-max) {
    width: 45%;
  }

  @media (max-width: $tablet-max) {
    display: block;
    width: auto;
  }
}

.widget-base__exchange-rate {
  margin: 24px 0;

  display: block;
  text-align: center;

  @media (min-width: $tablet-max) {
    display: none;
  }
}

.full-screen-loader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.2);

  .loading-icon {
    top: 30%;

    @media (max-width: $tablet-max) {
      &::after {
        background-color: #fff;
      }
    }
  }
}

// animations
@keyframes fadeOutBlocks {
  0% {
    opacity: 0;
    transform: translateY(40px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes simpleFadeBg {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
</style>
