<template>
  <div class="cooling-off-screen">
    <div class="container">
      <h2 class="cooling-off-screen__title">
        {{ t('fca-notification.invitation-popup.title') }}
      </h2>

      <div
        v-if="isError"
        class="cooling-off-screen__error"
      >
        {{ t('fca-notification.invitation-popup.error-message') }}
      </div>

      <div class="cooling-off-screen__actions">
        <button
          class="cooling-off-screen__btn btn btn-secondary btn-lg"
          :class="{ 'is-loading': isLoading }"
          :disabled="isLoading"
          @click="() => saveUserDecision(true)"
        >
          {{ t('fca-notification.invitation-popup.button-stay') }}
        </button>

        <button
          class="cooling-off-screen__btn btn btn-secondary btn-lg"
          :class="{ 'is-loading': isLoading }"
          :disabled="isLoading"
          @click="() => saveUserDecision(false)"
        >
          {{ t('fca-notification.invitation-popup.button-leave') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useLogout } from '@/composables/useLogout';
import { useExchangeFormRouting } from '@/composables/useExchangeFormRouting';

import ProcessingClient from '@paybis/frontend-common-lib/src/services/clients/processing-client';

export default {
  name: 'CoolingOffScreen',

  setup() {
    const { t } = useI18n();

    const { logoutWidget } = useLogout();
    const { navigate } = useExchangeFormRouting();

    const isLoading = ref(false);
    const isError = ref(false);

    const saveUserDecision = async isUserStay => {
      isError.value = false;
      isLoading.value = true;

      try {
        await ProcessingClient.saveUserDecisionAfterInvitationPopUp(isUserStay);
      } catch (e) {
        isError.value = true;
        isLoading.value = false;
        return;
      }

      if (isUserStay) {
        await navigate();
        return;
      }

      await logoutWidget();
    };

    return {
      saveUserDecision,

      isLoading,
      isError,

      t,
    };
  },
};
</script>

<style lang="scss" scoped>
.cooling-off-screen {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50vh;

  &__title {
    font-size: 22px;
    line-height: 28px;
    padding-bottom: 1rem;
    text-align: center;
    font-weight: 700;

    @media screen and (min-width: 1000px) {
      font-size: 1.75rem;
      line-height: 1;
      padding: 3.875rem 1.5rem 2rem;
    }
  }

  &__error {
    margin-top: 16px;
    font-size: 14px;
    line-height: 1.33;
    color: red;
    text-align: center;
  }

  &__btn {
    white-space: normal;
    line-height: 1.2;
    padding-top: 12px;
    padding-bottom: 12px;
  }

  &__actions {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
}
</style>
