<template>
  <div
    class="exchange-secondary-input"
    :class="{ 'has-focus': hasFocus }"
  >
    <span class="exchange-secondary-input__text">
      {{ $t('widget.widget.exchange-form.receive-in-fiat') }} –
    </span>
    <input
      ref="fiatInput"
      :value="fiatAmount"
      :readonly="readonly"
      type="text"
      inputmode="decimal"
      step="0.01"
      class="exchange-secondary-input__input"
      :style="{ width: `${fiatAmount.toString().length}ch` }"
      @input="setFiatAmount"
      @focus="hasFocus = true"
      @focusout="hasFocus = false"
    >
    <span class="exchange-secondary-input__label">{{ currency }}</span>
  </div>
</template>

<script>
import { ref, watch } from 'vue';
import debounce from 'lodash.debounce';
import { filterNumberValue } from '@/utils/filterNumberValue';

export default {
  name: 'ExchangeFormSecondaryInput',

  props: {
    currency: {
      type: String,
      default: '',
    },
    amount: {
      type: String,
      default: '0',
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['amountChange'],

  setup(props, { emit }) {
    const hasFocus = ref(false);
    const fiatInput = ref(null);
    const fiatAmount = ref(0);

    const setFiatAmount = ({ target }) => {
      target.value = filterNumberValue(target.value);

      fiatAmount.value = target.value || 0;

      if (target.value === props.amount) return;

      sendFiatAmount(target.value);
    };

    const sendFiatAmount = debounce(value => {
      emit('amountChange', value);
    }, 1000);

    // Select text with zero value for better UX
    watch(hasFocus, focusValue => {
      if (focusValue && !fiatAmount.value) {
        fiatInput.value.select();
      }
    });

    watch(() => props.amount, amountValue => {
      fiatAmount.value = amountValue ? Number(amountValue) : 0;
    }, {
      immediate: true,
    });

    return {
      fiatAmount,
      fiatInput,
      hasFocus,

      setFiatAmount,
    };
  },
};
</script>

<style scoped lang="scss">
.exchange-secondary-input {
  display: inline-block;
  border-radius: 6px;
  border: 1px solid transparent;
  padding: 5px;
  margin-top: 8px;
  font-weight: 700;
  font-size: 14px;
  line-height: 1.2;
  transition: border-color .2s linear;

  &__text {
    font-family: $font-family;
    color: $black-30;
    margin-right: 8px;
  }

  &.has-focus {
    border-color: $black-10;
  }

  &__input {
    min-width: 1ch;
    max-width: 10ch;
    padding: 2px 0;
    border-bottom: 1px solid $black-30;
    border-radius: 0;
    box-shadow: none;
    transition: border-color .2s linear, min-width .5s linear;
  }

  &__input:focus {
    outline: none;
  }

  &.has-focus &__input {
    border-color: transparent;
  }

  &__input::-webkit-inner-spin-button,
  &__input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &__label {
    color: $black;
    margin-left: 4px;
    transition: color 0.2s linear;
  }

  &.has-focus &__label {
    color: $black-30;
  }

  @media screen and (max-width: $screen-md-max) {
    &__input {
      font-size: rem(16);
      transform: scale(0.875);
      margin-left: rem(-6);
    }
  }
}
</style>
