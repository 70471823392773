// TODO: transfer to private methods `#publish(error)` when babel-jest will support class private methods
/* eslint-disable no-underscore-dangle */
import { reactive } from 'vue';
import TokenService from '@paybis/frontend-common-lib/src/services/token';
import WidgetError from './WidgetError';
import store from '@/store'; // it can be loaded before store init
import { emitToParent } from '../widgetApiEvents';
import { captureException, setUser } from './Sentry';
import ErrorTypeEnum from './ErrorTypeEnum';
import { getCorrelationId } from '@paybis/frontend-common-lib/src/services/correlation';

class ErrorService {
  errorStack = reactive([]);

  correlationId = getCorrelationId();

  push(errorTypeName, errorBody = {}) {
    const error = new WidgetError(errorTypeName, errorBody, {
      correlationId: this.correlationId,
    });
    this.errorStack.push(error);
    // TODO: transfer to private methods `#publish(error)` when babel-jest will support class private methods
    this._publish(error);
  }

  _publish(error) {
    this._postToStore(error);
    this._postToSentry(error);

    if (error.sdkVisible) {
      this._postToPartner(error);
    }
  }

  _postToSentry(error) {
    captureException(error);
  }

  _postToPartner(error) {
    emitToParent('error', {
      correlationId: error.correlationId,
      stack: error.stack,
      message: error.message,
      type: error.type.description,
    });
  }

  _postToStore(error) {
    store?.dispatch('setError', error);
  }
}

const errorService = new ErrorService();

window.addEventListener('error', ({ error }) => {
  errorService.push(ErrorTypeEnum.UNHANDLED_ERROR, error);
});

store?.watch(
  (state, getters) => getters['external/error'],
  (error = {}) => {
    errorService.push(ErrorTypeEnum.TRANSACTION_FUNNEL_ERROR, error);
  },
);

store?.subscribeAction(({ type: actionType }) => {
  if (actionType !== 'setTokens') return;

  const user = TokenService.getUser();
  setUser(user);
});

export default errorService;
