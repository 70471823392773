import { computed } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

import { isApplePayOneClickFlow } from '@/services/applePayOneClickService';
import { EXCHANGE_FORM, TRANSACTION } from '@/router/routes';
import { usePaymentMethodsInit } from '@/composables/usePaymentMethodsInit';
import { useIndexPage } from '@/composables/useIndexPage';
import { isV2 } from '@/services/apiVersion';

export const useExchangeFormRouting = () => {
  const store = useStore();
  const router = useRouter();
  const { waitPaymentMethods } = usePaymentMethodsInit();
  const { indexPage } = useIndexPage();

  const funnelInvoiceId = computed(() => store.getters['external/funnelInvoiceId']);
  const isOneClickCheckoutFlow = computed(() => store.getters.isOneClickFlow);

  const navigate = async () => {
    if (funnelInvoiceId.value) {
      router.push({ name: TRANSACTION });
      return;
    }
  
    if (isApplePayOneClickFlow()){
      router.push({ name: indexPage.value });
      return;
    }
  
    if (isOneClickCheckoutFlow.value){
      router.push({ name: TRANSACTION });
      return;
    }
  
    if (isV2()) {
      await waitPaymentMethods();
  
      store.dispatch('v2/widgetQuote/updatePaymentMethodByQuote', {}, { root: true });
  
      router.push({ name: indexPage.value });
      return;
    }
  
    router.push({ name: EXCHANGE_FORM });
  };

  return {
    navigate,
  };
};
