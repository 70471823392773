<template>
  <Pictogram
    class="funnel-loader"
    :src="
      require('@paybis/frontend-common-lib/src/assets/images/sales-funnel-loader.gif')
    "
  />
</template>

<script>
import Pictogram from '@paybis/frontend-common-lib/src/components/pictogram';

export default {
  components: {
    Pictogram,
  },
};
</script>

<style scoped lang="scss">
.funnel-loader {
  ::v-deep img {
    width: 140px;
  }
}
</style>
