<template>
  <ExchangeForm v-if="canShowExchangeForm" />
</template>

<script>
import { computed, watch, onMounted } from 'vue';
import { useStore } from 'vuex';
import ExchangeForm from '@/components/v2/ExchangeForm';
import { useRoutePreloader } from '@/router/PreloadRouterView';
import { isNewExchangeFormFlowEnabled } from '@/services/exchangeFormFlow';

export default {
  name: 'ExchangeFormScreen',

  components: {
    ExchangeForm,
  },

  setup() {
    const store = useStore();
    const isCurrenciesLoaded = computed(() => store.getters['exchangeForm/isLoaded']);
    const isExchangeFormBeforeAuth = computed(() => isNewExchangeFormFlowEnabled());

    const canShowExchangeForm = computed(() => !isExchangeFormBeforeAuth.value || isCurrenciesLoaded.value);

    const { setSelfPreloadState } = useRoutePreloader();

    watch(isCurrenciesLoaded, () => {
      if (!isExchangeFormBeforeAuth.value || !isCurrenciesLoaded.value) {
        return;
      }

      setSelfPreloadState();
    }, { immediate: true });

    onMounted(() => {
      if (!isExchangeFormBeforeAuth.value) {
        setSelfPreloadState();
      }
    });

    return {
      canShowExchangeForm,
    };
  },
};
</script>
