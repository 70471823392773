import ProcessingClient from '@paybis/frontend-common-lib/src/services/clients/processing-client';
import { createApplePaySDK } from '../factories/ApplePaySDKFactory';

export default {
  namespaced: true,
  state: {
    initiatingApplePay: false,
    canUseApplePay: false,
    userCountry: null,
  },
  getters: {
    initiatingApplePay: state => state.initiatingApplePay,
    canUseApplePay: state => state.canUseApplePay,
    userCountry: state => state.userCountry,
  },
  mutations: {
    setInitiatingApplePay(state, payload) {
      state.initiatingApplePay = payload;
    },
    setCanUseApplePay(state, payload) {
      state.canUseApplePay = payload;
    },
    setUserCountry(state, payload) {
      state.userCountry = payload;
    },
  },
  actions: {
    initApplePay({ commit, dispatch, getters }) {
      if (getters.initiatingApplePay) {
        return;
      }

      commit('setInitiatingApplePay', true);

      return ProcessingClient.getUser()
        .then(({ data }) => {
          createApplePaySDK();

          if (data.country === null) {
            return;
          }

          commit('setUserCountry', data.country);
          commit('setCanUseApplePay', true);
        })
        .finally(() => {
          commit('setInitiatingApplePay', false);
        });
    },
  },
};
