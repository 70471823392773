<template>
  <div class="modal-form-actions">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'ModalFormActions',
};
</script>

<style scoped lang="scss">
.modal-form-actions {
  display: flex;
  justify-content: center;
  margin-top: rem(32);

  &.mt--sm,
  &.mt--sm-24 {
    margin-top: rem(24);
  }

  &.flex--col {
    flex-direction: column;
  }

  @media screen and (min-width: $screen-lg-min) {
    margin-top: rem(56);
    &.mt--sm {
      margin-top: rem(40);
    }
  }
}
</style>
