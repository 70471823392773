import {
  FieldType,
  InputMode,
  InputType,
  MappedFieldType,
} from '../models/type';

const STEP = '0.01';

const isType = (htmlInputType, fieldType) => {
  if (!MappedFieldType[htmlInputType]) {
    return false;
  }

  return MappedFieldType[htmlInputType].includes(fieldType);
};

export default {
  isInput(field) {
    return isType('input', field.getType());
  },

  isSelect(field) {
    return isType('select', field.getType());
  },

  isDateTime(field) {
    return isType('datetime', field.getType());
  },

  isDocument(field) {
    return isType('document', field.getType());
  },

  getFieldType(field) {
    const textTypes = [
      this.TYPE_ALPHANUMERIC,
      this.TYPE_TEXT,
      this.TYPE_DECIMAL,
      this.TYPE_INTEGER,
    ];
    if (textTypes.includes(field.getType())) {
      // XXX: Use type 'text' for numeric inputs, as type 'number' allows 'e', '.' and '.'.
      // If user enters invalid value, such as '123.' or '12e3', an empty value is sent to server,
      // and if field is not mandatory, no validation error is thrown.
      // This can cause undesired outcome: User enters an invalid destination tag for XRP,
      // form is submitted successfully with an empty value, no value is saved in DB.
      // This can cause XRP to be sent to an unknown location.
      return FieldType.TYPE_TEXT;
    }
    if (field.getType() === FieldType.TYPE_EMAIL) {
      return InputType.TYPE_EMAIL;
    }

    return field.getType();
  },

  getInputMode(field) {
    if (field.getType() === FieldType.TYPE_INTEGER) {
      return InputMode.MODE_NUMERIC;
    }
    if (field.getType() === FieldType.TYPE_DECIMAL) {
      return InputMode.MODE_DECIMAL;
    }
    if (field.getType() === FieldType.TYPE_EMAIL) {
      return InputMode.MODE_EMAIL;
    }

    return InputMode.MODE_TEXT;
  },

  getStep(field) {
    if (field.getType() === FieldType.TYPE_DECIMAL) {
      return STEP;
    }

    return null;
  },

  getMinValue(field) {
    if (field.getType() === FieldType.TYPE_DECIMAL) {
      return '0';
    }

    return null;
  },

  getMaxValue(field) {
    if (field.getType() === FieldType.TYPE_DECIMAL) {
      return field.getMaxLength();
    }

    return null;
  },

  getMaxLengthValue(field) {
    if (field.getType() === FieldType.TYPE_DECIMAL) {
      return null;
    }

    return field.getMaxLength();
  },

  getOptions(field) {
    if (field.getOptions()) {
      const options = [];
      for (const [code, label] of Object.entries(field.getOptions())) {
        options.push({
          label,
          code,
        });
      }

      return options;
    }

    return null;
  },

  getValue(field) {
    if (this.isDateTime(field)) {
      return new Date(field.getValue());
    }

    return field.getValue();
  },

  getAcceptAttr(field) {
    let accept = 'image/png,image/jpeg';
    if (field.isPdfAllowed) {
      accept += ',application/pdf';
    }

    return accept;
  },
};
