<template>
  <div class="payment-check mt-32 mb-32">
    <Loader class="ml-auto mr-auto" />
  </div>
</template>

<script>
import Loader from '@paybis/frontend-common-lib/src/components/loader';

export default {
  components: {
    Loader,
  },
};
</script>
