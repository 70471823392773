<template>
  <div className="electroinc-payment-waiting">
    <Title>
      {{ $t("transaction-flow.cool-off-view.title") }}
    </Title>
    <Paragraph>
      <span v-html="$t('transaction-flow.cool-off-view.paragraph')" /> {{ coolingOffFinishTime }}
    </Paragraph>
    <FunnelLoader />
  </div>
</template>

<script setup>
import Paragraph from '@paybis/frontend-common-lib/src/components/paragraph';
import Title from '@paybis/frontend-common-lib/src/components/title';
import FunnelLoader from './funnel-loader';
import { computed } from 'vue';

const props = defineProps({
  coolOffTimeLeft: String,
});
const coolingOffFinishTime = computed(() => {
  const [hours, minutes] = props.coolOffTimeLeft.split(':');

  return `${hours.padStart(2, 0)}:${minutes.padStart(2, 0)}`;
});

</script>
