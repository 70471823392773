<template>
  <div>
    <FunnelLoader />
    <Subtitle>
      {{ $t('transaction-flow.steps.apm-payment-error.title') }}
    </Subtitle>
    <Paragraph
      v-html="$t('transaction-flow.steps.apm-payment-error.message')"
    />
  </div>
</template>

<script>
import Paragraph from '@paybis/frontend-common-lib/src/components/paragraph';
import Subtitle from '@paybis/frontend-common-lib/src/components/subtitle';
import UrlGenerator from '@paybis/frontend-common-lib/src/services/url';
import { mapGetters, mapMutations } from 'vuex';

import FunnelLoader from './funnel-loader';

export default {
  components: {
    Paragraph,
    Subtitle,
    FunnelLoader,
  },
  computed: {
    ...mapGetters(['isLocked']),
  },
  methods: {
    ...mapMutations(['lock', 'clearLocks']),
    navigate() {
      this.lock();
      window.location = UrlGenerator.generate('/');
    },
  },
  mounted() {
    setTimeout(() => {
      this.clearLocks();
    });
  },
};
</script>

<style lang="scss" scoped>
</style>
