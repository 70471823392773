<template>
  <div
    class="promo-component"
    :class="{'promo-component--error': props.error}"
  >
    <input
      ref="el"
      v-model="code"
      v-input-width:110="code"
      class="promo-component__input"
      type="text"
    >
    <div class="promo-component__arrow-wrapper">
      <svg
        class="promo-component__arrow"
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="28"
        fill="none"
        viewBox="0 0 10 28"
      >
        <path
          d="M20 6c0-3.314.314-6 0-6h-7.912C10.15 0 8.332.206 7.205 2.513l-5.714
             8a6 6 0 0 0 0 6.974l5.714 8A6 6 0 0 0 12.088 28H20c.314 0 0-2.686 0-6V6Z"
        />
      </svg>
    </div>
    <div class="promo-component__capture">
      {{ props.capture }}
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { useIMask } from 'vue-imask';
import vInputWidth from '@/directives/InputWidth';

const props = defineProps({
  modelValue: {
    type: String,
  },
  capture: {
    type: String,
    required: true,
  },
  error: {
    type: Boolean,
    required: true,
  },
});

const emits = defineEmits(['update:modelValue']);
const { el } = useIMask({
  mask: /^[a-zA-Z0-9]*$/,
});

const code = computed({
  get: () => props.modelValue,
  set: value => emits('update:modelValue', value),
});

</script>

<style scoped lang="scss">
.promo-component {
  display: inline-flex;
  max-width: 100%;
  margin: 13px 5px 0 5px;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  [dir='rtl'] & {
    flex-flow: row-reverse;
  }

  &__input {
    width: 110px;
    height: 28px;
    margin-left: 5px;
    margin-right: 15px;
    font-family: "Graphik", sans-serif;
    font-size: 14px;
    &:focus {
      outline: none;
    }
  }

  &__arrow-wrapper {
    position: relative;
  }

  &__arrow {
    position: absolute;
    top: 0;
    right: -1px;
    color: #5F70DB;
    fill: currentColor;
  }

  &__capture {
    display: flex;
    height: 28px;
    padding-right: 5px;
    padding-left: 1px;
    align-items: center;
    font-family: "Graphik", sans-serif;
    font-size: 14px;
    font-weight: bold;
    color: #FFF;
    white-space: nowrap;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    background-color: #5F70DB;
  }

  &--error &__arrow {
    color: #E73C40;
    fill: currentColor;
  }

  &--error &__capture {
    background-color: #E73C40;
  }
}
</style>
