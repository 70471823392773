<template>
  <div class="sales-funnel-pictogram">
    <img
      :src="src"
      alt="pictogram"
    >
  </div>
</template>

<script>
export default {
  name: 'Pictogram',

  props: {
    src: { type: String, required: true },
  },
};
</script>

<style scoped lang="scss">
.sales-funnel-pictogram {
  margin: rem(24) 0;

  & img {
    display: block;
    margin: 0 auto;
  }

  @media screen and (min-width: $screen-lg-min) {
    &:first-child {
      margin-top: rem(16);
    }
  }
}
</style>
